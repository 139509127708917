import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";

export const notifySuccess = message =>
  toast.success(
    <Fragment>
      <FontAwesomeIcon icon={faCheckCircle} /> {message}
    </Fragment>
  );
export const notifyWarning = message =>
  toast.warn(
    <Fragment>
      <FontAwesomeIcon icon={faExclamationCircle} /> {message}
    </Fragment>
  );
export const notifyError = message =>
  toast.error(
    <Fragment>
      <FontAwesomeIcon icon={faExclamationCircle} /> {message}
    </Fragment>
  );
export const notifyInfo = message => toast.info(message);
