import React from "react";
import UserForm from "../components/forms/UserForm";
import Master from "../components/containers/Master";
import Breadcrumb from "../components/elements/Breadcrumb";
import { navigationResources } from "../resources/resources";

const UserPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.user.breadcrumb} />
      <div className="container-fluid">
        <UserForm></UserForm>
      </div>
    </Master>
  );
};

export default UserPage;
