import LocalizedStrings from "react-localization";
import * as pt from "./pt";
import * as en from "./en";
import { getStoreLanguage, setStoreLanguage } from "../storage/language";

export let _localResources = [];
const default_lang = "pt";

// Global Resources
export const globalResources = new LocalizedStrings({
  pt: pt.Global,
  en: en.Global,
});

export const websiteResources = new LocalizedStrings({
  pt: pt.Website,
  en: en.Website,
});

export const loginResources = new LocalizedStrings({
  pt: pt.Login,
  en: en.Login,
});

export const navigationResources = new LocalizedStrings({
  pt: pt.Navigation,
  en: en.Navigation,
});

// Local Resources [START]

export function DashboardPageResources() {
  return localResources({
    pt: pt.DashboardPage,
    en: en.DashboardPage,
  });
}

export function UserPageResources() {
  return localResources({
    pt: pt.UserPage,
    en: en.UserPage,
  });
}

export function CondominiumPageResources() {
  return localResources({
    pt: pt.CondominiumPage,
    en: en.CondominiumPage,
  });
}

export function FractionsDebtsTableResources() {
  return localResources({
    pt: pt.FractionsDebtsTable,
    en: en.FractionsDebtsTable,
  });
}

export function DocumentsTableResources() {
  return localResources({
    pt: pt.DocumentsTable,
    en: en.DocumentsTable,
  });
}

export function NoticesTableResources() {
  return localResources({
    pt: pt.NoticesTable,
    en: en.NoticesTable,
  });
}

export function BlogTableResources() {
  return localResources({
    pt: pt.BlogTable,
    en: en.BlogTable,
  });
}

export function VotingsTableResources() {
  return localResources({
    pt: pt.VotingsTable,
    en: en.VotingsTable,
  });
}

export function ReceiptsTableResources() {
  return localResources({
    pt: pt.ReceiptsTable,
    en: en.ReceiptsTable,
  });
}

export function CurrentAccountingTableResources() {
  return localResources({
    pt: pt.CurrentAccounts,
    en: en.CurrentAccounts,
  });
}

export function OccurrencesTableResources() {
  return localResources({
    pt: pt.OccurrencesTable,
    en: en.OccurrencesTable,
  });
}

export function BankBalancesTableResources() {
  return localResources({
    pt: pt.BankBalancesTable,
    en: en.BankBalancesTable,
  });
}

export function BudgetAnalysisTableResources() {
  return localResources({
    pt: pt.BudgetAnalysis,
    en: en.BudgetAnalysis,
  });
}

export function ExerciseBudgetResources() {
  return localResources({
    pt: pt.ExerciseBudget,
    en: en.ExerciseBudget,
  });
}

export function BalanceResources() {
  return localResources({
    pt: pt.Balance,
    en: en.Balance,
  });
}

export function ExerciseExpensesResources() {
  return localResources({
    pt: pt.ExerciseExpenses,
    en: en.ExerciseExpenses,
  });
}

export function MessagesResources() {
  return localResources({
    pt: pt.Messages,
    en: en.Messages,
  });
}

export function InquiriesResources() {
  return localResources({
    pt: pt.Inquiries,
    en: en.Inquiries,
  });
}

// Local Resources [END]

export function localResources(resources) {
  let _language = getStoreLanguage();
  if (!_language || _language === "") {
    _language = default_lang;
    setStoreLanguage(_language);
  }
  let resource = {
    key: require("uuid/v4")(),
    local: new LocalizedStrings(resources),
    cleanup: function () {
      for (var i = 0; i < _localResources.length; i++) {
        if (_localResources[i].key === this.key) {
          _localResources.splice(i, 1);
          break;
        }
      }
    },
  };

  resource.local.setLanguage(_language);
  _localResources.push(resource);
  return resource;
}

export function setLanguage(lang, reload) {
  globalResources.setLanguage(lang);
  websiteResources.setLanguage(lang);
  loginResources.setLanguage(lang);
  navigationResources.setLanguage(lang);

  for (var i = 0; i < _localResources.length; i++) {
    _localResources[i].local.setLanguage(lang);
  }

  setStoreLanguage(lang);
  if (reload) window.location.reload(false);
}

export function startLanguage() {
  let lang = getStoreLanguage();
  if (!lang || lang === "") {
    lang = default_lang;
    setStoreLanguage(lang);
  }

  globalResources.setLanguage(lang);
  websiteResources.setLanguage(lang);
  loginResources.setLanguage(lang);
  navigationResources.setLanguage(lang);

  for (var i = 0; i < _localResources.length; i++) {
    _localResources[i].local.setLanguage(lang);
  }

  setStoreLanguage(lang);
}

export function getCurrent() {
  let lang = getStoreLanguage();
  if (!lang || lang === "") {
    lang = default_lang;
    setStoreLanguage(lang);
  }
  return lang;
}
