import React, { Fragment, useState, useEffect } from "react";
import { globalResources } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTrash, faCamera } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import { getBase64 } from "../../assets/utils";
import { Input } from "reactstrap";
import { Field } from "react-final-form";
import "react-dropzone-uploader/dist/styles.css";
import Resizer from "react-image-file-resizer";

const ImagePicker = (props) => {
  const {
    label,
    image,
    onUpload,
    failUpload,
    maxWidth,
    maxHeight,
    disabled,
    onRemove,
    addButton,
    PickerRef,
    remove = true,
    name,
    addImageIcon = faImage,
    addImageText = globalResources.addPhoto,
  } = props;
  const [formInput, setInput] = useState(null);

  const handleChangeStatus = (file, status) => {
    if (status === "preparing") {
    }
    if (status === "headers_received") {
      if (maxWidth || maxHeight) {
        Resizer.imageFileResizer(
          file.file,
          maxWidth,
          maxHeight,
          "JPEG",
          100,
          0,
          (base64) => {
            onUploadEvent(base64.split(",")[1], file);
          },
          "base64"
        );
      } else {
        getBase64(
          file.file,
          (base64) => {
            onUploadEvent(base64, file);
          },
          (error) => {
            if (failUpload) failUpload(error);
          }
        );
      }
    } else if (status === "aborted") {
      if (failUpload) failUpload();
    }
  };

  const RenderLabel = ({ children }) => {
    if (label) {
      return (
        <div className="form-group">
          <label>{label}</label>
          {children}
        </div>
      );
    } else {
      return children;
    }
  };

  const onUploadEvent = (image, file) => {
    if (onUpload) onUpload(image, file);
    if (formInput) formInput.onChange(image);
  };

  const onRemoveEvent = (e) => {
    if (onRemove) onRemove();
    if (formInput) formInput.onChange(null);
    e.stopPropagation();
    e.preventDefault();
  };

  const renderer = (render) => ({ input, ...rest }) => render(input, rest);

  let inputUpdate = null;
  useEffect(() => {
    if (inputUpdate !== null) {
      setInput(inputUpdate);
      inputUpdate = null;
    }
  });

  const RenderFormValue = renderer((input, props) => {
    if (!formInput || input.value !== formInput.value) {
      inputUpdate = input;
    }

    return <Input style={{ display: "none" }} {...input} readOnly={true} />;
  });

  const img = formInput ? formInput.value : image;

  const imageStyle = img
    ? {
        backgroundImage: `url(${
          img.length > 500
            ? "data:image/png;base64," + img
            : "'" + img + `?v=${new Date().getTime()}'`
        })`,
      }
    : "";

  return (
    <div className={`image-picker${img ? " with-image" : ""}`}>
      {name && (
        <Field name={name} readOnly={true} component={RenderFormValue} />
      )}
      <RenderLabel>
        <Dropzone
          ref={PickerRef}
          key="dropzone"
          getUploadParams={() => ({ url: "https://httpbin.org/post" })}
          onChangeStatus={handleChangeStatus}
          canCancel={false}
          multiple={false}
          disabled={disabled}
          PreviewComponent={() => (
            <div className="dropzone-image-uploading"></div>
          )}
          SubmitButtonComponent={null}
          inputContent={
            <Fragment key="_t">
              <div className="dropzone-image-container" key="zone">
                {img && (
                  <Fragment>
                    <div className="img" style={imageStyle} />
                    {remove && (
                      <a
                        href="!#"
                        className="link-button remove"
                        title={globalResources.deletePhoto}
                        onClick={onRemoveEvent}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </a>
                    )}
                  </Fragment>
                )}
                {!img && (
                  <Fragment>
                    <FontAwesomeIcon className="no-image" icon={addImageIcon} />
                    <span>{addImageText}</span>
                  </Fragment>
                )}
              </div>
              {addButton && (
                <div key="add" className="addButton">
                  <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                  <span>{addButton}</span>
                </div>
              )}
            </Fragment>
          }
          accept="image/*"
        />
      </RenderLabel>
    </div>
  );
};

export default ImagePicker;
