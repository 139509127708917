import React from "react";
import Header from "../elements/Header";
import Footer from "../elements/Footer";
import SideNavbar from "../elements/SideNavbar";
import { useSelector } from "react-redux";

const Master = (props) => {
  let themeClass = "";
  const user = useSelector((state) => state.user);

  if (user.detail) {
    if (user.detail.applicationTheme) {
      switch (user.detail.applicationTheme.value) {
        case 1:
          themeClass = "";
          break;
        case 2:
          themeClass = "dark-theme";
          break;
        default:
          themeClass = "";
          break;
      }
    }
  }
  
  return (
    <div className={`root-container ${themeClass}`}>
      <SideNavbar />
      <div className="overlay"></div>
      <Header />
      <div className="page-container">
        <div className="page-content-wrapper">
          <div className="page-content">{props.children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
  
};

export default Master;
