import React, { useState, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { required } from "../../assets/forms/validation";
import {
  RenderInput,
  RenderPhoneInput,
  RenderUserTextarea,
  RenderRadio,
  DatePickerAdapter,
} from "../../assets/forms/render";
import { UserPageResources, globalResources } from "../../resources/resources";
import Card from "../../components/containers/Card";
import ImagePicker from "../../components/elements/ImagePicker";
import { notifySuccess, notifyError } from "../../components/elements/Notify";
import "react-image-lightbox/style.css";
import { UploadImage, SaveUser } from "../../actions/user";
import ChangePasswordForm from "./ChangePasswordForm";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { UPDATE_USER } from "../../actions/types";
import FormSelect from "../forms/FormSelect";
import {
  toGenderSelectOption,
  toThemeSelectOption,
} from "../../assets/FormatAndParse";

const UserForm = (props) => {
  const user = useSelector((state) => state.user.detail);
  const dispatch = useDispatch();
  const [mode, setMode] = useState(0);
  const photoRef = React.createRef();
  const theme = useRef(null);

  const [resources] = useState(UserPageResources);
  const onSubmit = async (values, form) => {
    SaveUser(values)
    .then((response) => {
      if (values.applicationTheme) {
        values.applicationTheme = toThemeSelectOption(values.applicationTheme.id, values.language);
      }

      if (values.gender) {
        values.gender = toGenderSelectOption(values.gender.id, values.language);
      }
      
      form.initialize(values);
      dispatch({
        type: UPDATE_USER,
        user: values,
      });
      notifySuccess(resources.local.details.success);
    })
    .catch((e) => {
      let message = "";
      if (e.response.data && e.response.data.error)
        message = e.response.data.error;
      switch (message.toLowerCase()) {
        case "".toLowerCase():
          notifyError("");
          break;
        default:
          notifyError(resources.local.details.fail);
          break;
      }
    });
  };
  
  const onUpload = (base64, file) => {
    UploadImage(base64, file.file.type, file.file.name);
  };

  function handleThemeChange(value){
    if(value===globalResources.light){
      theme.current={value:1, label:globalResources.light};
    }
    else{
      theme.current={value:2, label:globalResources.dark};
    }
  }

  function checkValues(values, form){
    // CHECK IF THE THEME IS THE ONE THATS CURRENTLY ASSOCIATED TO THE USER
    if(theme.current && theme.current!=values.applicationTheme){
      values.applicationTheme=theme.current;
    }
    // CHECK IF THE NUMBER IS FROM PORTUGAL, IN CASE IT IS THEN VERIFY IF ITS SETUP PROPERLY
    const phone=values.phone;
    const first3numbers= phone ? phone.slice(0,3) : "";
    if (first3numbers==="351"){
      if (phone[3]!=9 || phone.length!=12){
        form.initialize(values);
        notifyError(resources.local.insertValidPhoneNumber);
      }
      else{
        onSubmit(values, form);
      }
    }
    else{
      onSubmit(values, form);
    }
  }
  
  return mode === 0 ? (
    <Form
      initialValues={user}
      subscription={{ submitting: true, pristine: true }}
      onSubmit={checkValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <Fragment>
          <form
            className="user"
            onSubmit={(e) => {
              handleSubmit(e, form);
              e.preventDefault();
            }}
          >
            <div className="row">
              <div className="col-md-auto">
                <Card subclass="card-profile-image">
                  <ImagePicker
                    PickerRef={photoRef}
                    name="profileImage.url"
                    remove={false}
                    addImageText=""
                    addImageIcon={undefined}
                    onUpload={onUpload}
                    maxWidth={325}
                    maxHeight={325}
                    addButton={resources.local.photo.change}
                  ></ImagePicker>
                </Card>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-primary btn-change-password m-b-l"
                      onClick={() => setMode(1)}
                    >
                      {resources.local.password.title}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <Card title={resources.local.details.title}>
                  <div className="row">
                    <div className="col-lg-4">
                      <Field
                        name="name"
                        type="text"
                        label={resources.local.details.name}
                        validate={required}
                        component={RenderInput}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <Field
                        name="email"
                        type="text"
                        readOnly
                        label={resources.local.details.email}
                        component={RenderInput}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-group m-b-0">
                        <label htmlFor="name">
                          {resources.local.details.applicationTheme}
                        </label>
                      </div>
                      <div className="row"  onChange={(event)=>{
                              handleThemeChange(event.target.value);
                            }
                          }
                        >
                        <div className="col-auto">
                          <Field
                            id="light"
                            name="applicationTheme.label"
                            type="radio"
                            label={globalResources.light}
                            value={globalResources.light}
                            component={RenderRadio}
                          />
                        </div>
                        <div className="col-auto">
                          <Field
                            id="dark"
                            name="applicationTheme.label"
                            type="radio"
                            label={globalResources.dark}
                            value={globalResources.dark}
                            component={RenderRadio}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <Field
                        label={resources.local.details.gender}
                        name="gender"
                        placeholder={resources.local.details.gender}
                        isClearable={false}
                        isDisabled={false}
                        valueOnly={true}
                        className="user-profile__select"
                        classNamePrefix="user-profile__select"
                        validate={required}
                        component={FormSelect}
                        options={[
                          {
                            value: 1,
                            label: globalResources.male,
                          },
                          {
                            value: 2,
                            label: globalResources.female,
                          },
                        ]}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <Field
                        name="phone"
                        type="text"
                        readOnly
                        label={resources.local.details.phone}
                        component={RenderPhoneInput}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-group m-b-0">
                        <label htmlFor="name">
                          {resources.local.details.language}
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <Field
                            id="pt"
                            name="language"
                            type="radio"
                            label={globalResources.languages.pt}
                            value="pt-PT"
                            component={RenderRadio}
                          />
                        </div>
                        <div className="col-auto">
                          <Field
                            id="en"
                            name="language"
                            type="radio"
                            label={globalResources.languages.en}
                            value="en-GB"
                            component={RenderRadio}
                          />
                        </div>
                      </div>
                    </div>
                    {/* OLD METHOD TO CHANGE THE APPLICATION THEME
                    <div className="col-lg-4 col-sm-6">
                      <Field
                        label={resources.local.details.applicationTheme}
                        name="applicationTheme"
                        placeholder={resources.local.details.applicationTheme}
                        isClearable={false}
                        isDisabled={false}
                        valueOnly={true}
                        className="user-profile__select"
                        classNamePrefix="user-profile__select"
                        validate={required}
                        component={FormSelect}
                        options={[
                          {
                            value: 1,
                            label: globalResources.light,
                          },
                          {
                            value: 2,
                            label: globalResources.dark,
                          },
                        ]}
                      />
                    </div>*/}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <Field
                        name="address"
                        type="text"
                        label={resources.local.details.address}
                        component={RenderUserTextarea}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <Field
                        label={resources.local.details.birthDate}
                        name="birthDate"
                        validate={required}
                        dateFormat="dd-MM-yyyy"
                        component={DatePickerAdapter}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-end m-t-m">
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={submitting || pristine}
                      >
                        {globalResources.verbs.save}
                      </button>
                      {/* {message && message.length > 0 && <i>{message}</i>} */}
                    </div>
                  </div>
                </Card>
                <div className="row">
                  <div className="col">
                    <h5>{resources.local.note.title}</h5>
                    <p>{resources.local.note.text.p1}</p>
                    <p>{resources.local.note.text.p2}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Fragment>
      )}
    />
  ) : (
    <ChangePasswordForm
      after={() => {
        setMode(0);
      }}
    ></ChangePasswordForm>
  );
};

export default UserForm;
