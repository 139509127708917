import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import DocumentsTable from "../components/tables/DocumentsTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const DocumentsPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.documents.breadcrumb} />
      <div className="container-fluid">
        <DocumentsTable></DocumentsTable>
      </div>
    </Master>
  );
};

export default DocumentsPage;
