import React, { Fragment, useState, useEffect } from "react";
import Pager from "./Pager";

const List = ({
  pageSize: _pageSize = 30,
  render,
  databinder,
  loading,
  nodata,
  filters,
  hidePager = false,
}) => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(_pageSize);
  const [pageCount, setPageCount] = useState(1);
  const [hidePagination, setHidePagination] = useState(false);

  const RenderPager = () => {
    return (
      <Pager
        nextPage={() => {
          setPage(page + 1);
        }}
        previousPage={() => {
          setPage(page - 1);
        }}
        gotoPage={(page) => {
          setPage(page);
        }}
        canPreviousPage={page > 0}
        canNextPage={page + 1 < pageCount}
        pageCount={pageCount}
        pageIndex={page}
        pageSize={pageSize}
        setPageSize={(size) => {
          setPage(0);
          setPageSize(size);
        }}
      ></Pager>
    );
  };

  useEffect(() => {
    databinder(page + 1, pageSize, (data, records) => {
      setData(data);
      const pages = Math.ceil(records / pageSize);
      if (pages != pageCount) setPageCount(pages);
      if (pages === 1) setHidePagination(true);
      setData(data);
    });
  }, [page, pageSize, filters]);

  if (loading && data === null) {
    return loading;
  } else {
    if (data && data.length > 0) {
      return (
        <Fragment>
          <div className="custom-list">
            {data.map((item) => render(item))}
            {!hidePager && !hidePagination && RenderPager()}
          </div>
        </Fragment>
      );
    } else {
      if (nodata) {
        return nodata;
      } else {
        return <Fragment></Fragment>;
      }
    }
  }
};

export default List;
