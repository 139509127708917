import React, { useState, useEffect, useRef} from "react";
import Table, {
  Column,
  Number,
  NoData,
  Loading,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetDebts } from "../../actions/condominium";
import Card from "../containers/Card";
import {
  FractionsDebtsTableResources,
  globalResources,
} from "../../resources/resources";
import { useSelector } from "react-redux";

const FractionsDebtsTable = () => {
  const [data, setData] = useState();
  const [resources] = useState(FractionsDebtsTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const activeBudget = useRef(true);

  useEffect(() => {
    if (condominium) {
      activeBudget.current=true;
      setData();
      GetDebts(condominium.id).then((response) => {
        response.data.forEach((doc) => {
            doc.date = new window.Date(doc.date);
        });
        setData(response.data);
      }).catch(err => {
        const errorMessage = globalResources.apiError.activeBudgetNotFound;
        if(err.response.data && err.response.data.error===errorMessage){
          activeBudget.current=false;
        } 
        setData([]);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);
  
  return (
    <div>
      <Card subclass="max-width">
        <p>
          {resources.local.notUpdatedInformation}
        </p>
        <Table data={data} pager={{ pageIndex: 0 }}>
          <Column
            Header={resources.local.fraction}
            Cell={(table) => {
              const doc = table.row.original;
              let fractionText=doc.fractionTextualIdentifier;

              if (fractionText===resources.local.other_debts){
                fractionText=resources.local.otherDebts;
              }

              if(doc.isDebtFromActualFractionEntities===false){
                  fractionText+=resources.local.previousOwners;
              }
              return (
                <span>
                  {fractionText}
                </span>
              );
            }}
            Footer={globalResources.totalLabel}
          />
          <Number 
            Header={resources.local.inDebt}
            Header_has_currency={true}
            accessor="balanceValue"
            Total={true} 
          />
          <Loading value={data === undefined}>
            <IsLoading text={resources.local.loading} />
          </Loading>
        </Table>
      </Card>

      {(!activeBudget.current) &&
        <Card>{globalResources.noActiveBudget}</Card>
      }
      {(data !== undefined && data.length === 0 && activeBudget.current) &&
        <Card>{resources.local.noDebts}</Card>
      }
    </div>
  );
};

export default FractionsDebtsTable;