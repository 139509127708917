import React, { Fragment } from "react";

export const ForCondominiums = (language) =>
  language === "pt" ? (
    <Fragment>
      <p>
        Usando o{" "}
        <a
          href="https://www.improxy.com/software-de-gestao-de-condominios-gecond/"
          target="_blank"
          rel="noopener noreferrer"
        >
          software de gestão de condomínios GECOND
        </a>
        , a informação gerada nesta plataforma é automaticamente disponibilizada
        aos seus condóminos no Gecond, o meu condomínio, criando um canal
        digital e seguro, sem necessidade de contacto presencial.
      </p>
      <p>
        Uma administração moderna, ativa e transparente com informação
        disponível 24 horas por dia.
      </p>
      <p>
        <a
          href="https://www.improxy.com/software-de-gestao-de-condominios-gecond/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Veja mais informações
        </a>
      </p>
    </Fragment>
  ) : (
    <Fragment>
      <p>
        Using the{" "}
        <a
          href="https://www.improxy.com/software-de-gestao-de-condominios-gecond/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GECOND condominium management software
        </a>
        , the information generated on this platform is automatically available
        to your tenants at Gecond, my condominium, creating a digital and secure
        channel, without the need for face-to-face contact.
      </p>
      <p>
        A modern, active and transparent administration with information
        available 24 hours a day.
      </p>
      <p>
        <a
          href="https://www.improxy.com/software-de-gestao-de-condominios-gecond/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check more information
        </a>
      </p>
    </Fragment>
  );
