import axios from "axios";
import qs from "querystring";
import { get_token, LogOut } from "../storage/token";
import { ApiCall, ApiCallDev, client_id } from "../assets/config";

export function validSession(response, token) {
  if (
    token &&
    token.length > 0 &&
    response &&
    response.data &&
    response.data.Message &&
    response.data.Message.toLowerCase() ===
      "authorization has been denied for this request."
  ) {
    LogOut();
    return false;
  }
  return true;
}

export const apiAuthRequest = (a) => {
  a.token = get_token();
  return apiRequest(a);
};

export const apiAuthRequestDev = (a) => {
  a.token = get_token();
  return apiRequestDev(a);
};

export const apiRequestLocalFile = (a) => {
  if (!a.method) a.method = "get";

  let config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  switch (a.method.toLocaleLowerCase()) {
    case "delete":
    case "patch":
    case "post":
      return fetch(a.url, {
        ...config,
        method: a.method,
        body: JSON.stringify(a.data),
      });
    default:
      return fetch(a.url, {
        ...config,
        method: a.method,
        params: a.data,
      });
  }
};

export const apiRequestDev = (a) => {
  a.url = a.urlComplete ? a.urlComplete : ApiCallDev(a.url);
  if (!a.method) a.method = "get";

  let config = {
    headers: {
      "Content-Type": a.contentType ? a.contentType : "application/json",
      client_id: client_id(),
      Authorization: "Bearer " + a.token,
    },
  };

  switch (a.method.toLocaleLowerCase()) {
    case "delete":
    case "patch":
    case "post":
      return fetch(a.url, {
        ...config,
        method: a.method,
        body: JSON.stringify(a.data),
      });
    default:
      return fetch(a.url, {
        ...config,
        method: a.method,
        params: a.data,
      });
  }
};

axios.defaults.withCredentials = true;

export const apiRequest = (a) => {
  //console.log({ a });
  a.url = ApiCall(a.url);
  if (a.urlComplete) a.url = a.urlComplete;
  if (!a.method) a.method = "get";

  //console.log("a: ", a);

  let config = {
    url: a.url,
    withCredentials: true,
    responseType: "json",
    headers: {
      "Content-Type": a.contentType ? a.contentType : "application/json",
      client_id: client_id(),
    },
  };

  //console.log("config: ", config);

  if (a.token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + a.token,
    };
  }

  switch (a.method.toLocaleLowerCase()) {
    case "delete":
    case "patch":
    case "post":
      return axios({
        ...config,
        method: a.method,
        timeout: 1000 * 60,
        data: a.form ? qs.stringify(a.data) : a.data,
      }).catch((e) => {
        validSession(e.response, a.token);
        throw e;
      });
    default:
      return axios({
        ...config,
        method: a.method,
        params: a.data,
      }).catch((e) => {
        validSession(e.response, a.token);
        throw e;
      });
  }
};

export const apiAsyncRequest = async (url, data, method = "get") => {
  if (method.toLocaleLowerCase() === "post") {
    return await axios({
      method: "POST",
      url,
      timeout: 1000 * 60,
      withCredentials: true,
      responseType: "json",
      data: data,
    });
  } else {
    return await axios({
      method: method,
      url,
      withCredentials: true,
      responseType: "json",
      params: data,
    });
  }
};

export const apiFetch = (url, data, method = "GET") => {
  data = {
    ...data,
    client_id: client_id(),
  };
  let keyValuePairs = [];
  for (const key in data) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    );
  }

  return fetch(`${ApiCall(url)}?${keyValuePairs.join("&")}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    mode: "no-cors",
    credentials: "omit", // include, *same-origin, omit
  });
};
