import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";

export const createRenderer =
  (render) =>
  ({
    input,
    meta: { touched, error, warning, active },
    label,
    help,
    placeholder,
    requiredField,
    ...rest
  }) =>
    (
      <div
        className={[
          error && touched ? "error" : "",
          active ? "active" : "",
        ].join(" form-group ")}
      >
        <label htmlFor={input.name}>{label}</label>
        {requiredField && <span className="error"> *</span>}
        <span className="help"> {help}</span>
        {render(
          input,
          label,
          rest.readOnly,
          rest.autoFocus,
          placeholder,
          rest.other
        )}
        {touched &&
          ((error && (
            <div className="form-feedback">
              <span>{error}</span>
            </div>
          )) ||
            (warning && (
              <div className="form-feedback">
                <span>{warning}</span>
              </div>
            )))}
      </div>
    );

export const RenderInput = createRenderer(
  (input, label, readOnly, autoFocus, placeholder, other) => (
    <input
      {...input}
      placeholder={placeholder ? placeholder : label}
      readOnly={readOnly}
      autoFocus={autoFocus}
      className="form-control"
      {...other}
    />
  )
);

export const RenderInputCMD = createRenderer(
  (input, label, readOnly, autoFocus, placeholder, other) => (
    <input
      {...input}
      placeholder={placeholder ? placeholder : label}
      readOnly={readOnly}
      autoFocus={autoFocus}
      className="form-control field-test"
      {...other}
    />
  )
);

export const createCheckboxRenderer =
  (render) =>
  ({ input, meta: { touched, error, warning, active }, label, ...rest }) =>
    (
      <div
        className={[
          error && touched ? "error" : "",
          active ? "active" : "",
        ].join(" checkbox ")}
      >
        {render(input, label, rest)}
        <label htmlFor={input.name}>{label}</label>
        {touched &&
          ((error && (
            <div className="form-feedback">
              <span>{error}</span>
            </div>
          )) ||
            (warning && (
              <div className="form-feedback">
                <span>{warning}</span>
              </div>
            )))}
      </div>
    );

export const RenderCheckbox = createCheckboxRenderer((input) => (
  <input {...input} id={input.name} type="checkbox" />
));

export const createRadioRenderer =
  (render) =>
  ({
    input,
    meta: { touched, error, warning, active },
    label,
    id,
    ...rest
  }) => {
    return (
      <div
        className={[
          error && touched ? "error" : "",
          active ? "active" : "",
        ].join("radio radio-success ")}
      >
        {render(input, label, rest)}
        <label htmlFor={input.name}>{label}</label>
        {touched &&
          ((error && (
            <div className="form-feedback">
              <span>{error}</span>
            </div>
          )) ||
            (warning && (
              <div className="form-feedback">
                <span>{warning}</span>
              </div>
            )))}
      </div>
    );
  };

export const RenderRadio = createRadioRenderer((input) => {
  return <input {...input} type="radio" />;
});

export const RenderTextarea = createRenderer(
  (input, label, readOnly, other) => (
    <textarea
      {...input}
      placeholder={label}
      className="form-control"
      rows="6"
      readOnly={readOnly}
      {...other}
    />
  )
);

export const RenderUserTextarea = createRenderer(
  (input, label, readOnly, other) => (
    <textarea
      {...input}
      placeholder={label}
      className="form-control"
      rows="3"
      readOnly={readOnly}
      {...other}
    />
  )
);

export const RenderPhoneInput = createRenderer((input, label) => {
  return (
    <PhoneInput
      {...input}
      placeholder={label}
      dropdownClass="form-dropdown"
      inputClass="form-control"
      preferredCountries={["pt", "lt", "cv", "mz", "br"]}
      country={"pt"}
      masks={{ pt: "........." }}
      enableAreaCodeStretch={false}
      countryCodeEditable={false}
    />
  );
});

export const RenderPhoneInputCMD = createRenderer((input, label) => {
  return (
    <PhoneInput
      {...input}
      placeholder={label}
      dropdownClass="form-dropdown field-test"
      inputClass="form-control field-test"
      preferredCountries={["pt", "lt", "cv", "mz", "br"]}
      country={"pt"}
      masks={{ pt: "........." }}
      enableAreaCodeStretch={false}
      countryCodeEditable={false}
    />
  );
});

export const DatePickerAdapter = ({
  input: { onChange, value, name },
  meta: { touched, error, warning, active },
  label,
  help,
  ...rest
}) => (
  <div
    className={[error && touched ? "error" : "", active ? "active" : ""].join(
      " form-group "
    )}
  >
    <label htmlFor={name}>{label}</label>
    <span className="help"> {help}</span>
    <DatePicker
      selected={value ? new Date(value) : value}
      className="form-control"
      placeholder={label}
      onChange={(date) => onChange(date)}
      {...rest}
    />
    {touched &&
      ((error && (
        <div className="form-feedback">
          <span>{error}</span>
        </div>
      )) ||
        (warning && (
          <div className="form-feedback">
            <span>{warning}</span>
          </div>
        )))}
  </div>
);
