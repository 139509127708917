import React, { Fragment, useState, useEffect } from "react";
import Table, {
  Column,
  Option,
  Number,
  NoData,
  Loading,
  DateTime,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import {
  GetIdentificationDocumentTypes,
  GetAssembly,
  DeleteSignedLegalDocument,
  GetUnsignedLegalDocument,
  GetSignedLegalDocument,
} from "../../actions/votes";
import Card from "../containers/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  VotingsTableResources,
  navigationResources,
  globalResources,
} from "../../resources/resources";
import VotingsForm from "../forms/VotingsForm";
import RepresentativeForm from "../forms/RepresentativeForm";
import SignDocumentForm from "../forms/SignDocumentForm";
import Breadcrumb from "../elements/Breadcrumb";
import { useSelector } from "react-redux";
import { toVotingStatusLabel } from "../../assets/FormatAndParse";
import { notifyError, notifySuccess } from "../../components/elements/Notify";

const AssemblyForm = (props) => {
  const [assembly, setAssembly] = useState(props.assembly);
  const condominium = useSelector((state) => state.user.current_condominium);
  const [identificationDocumentTypes, setIdentificationDocumentTypes] =
    useState([]);
  const { clearAssembly } = props;
  const [resources] = useState(VotingsTableResources);
  const [activeTab, setActiveTab] = useState("VOTE");
  const [unsignedDocument, setUnsignedDocument] = useState();
  const [signedDocument, setSignedDocument] = useState();
  const changesAllowed =
    assembly.userVotingStatus &&
    (assembly.userVotingStatus.id === 3 || assembly.userVotingStatus.id === 5)
      ? false
      : true;

  const showJustVotes =
    assembly.requiresLegalRepresentativeDocument &&
    assembly.userVotingStatus.id !== 1 &&
    assembly.userVotingStatus.id !== 2
      ? false
      : true;

  // GET ASSEMBLY INFO
  const getAssembly = () => {
    GetAssembly(assembly.id)
      .then((response) => response.json())
      .then((result) => {
        setAssembly(result);
        //console.log(result);
      })
      .catch((e) => {});
  };

  // GO BACK WHEN CHANGING CONDOMINIUM
  useEffect(() => {
    if (assembly.agendaItems !== undefined) {
      props.clearAssembly();
    }
  }, [condominium]);

  // CALL THE FUNCTION THAT GETS THE ASSEMBLY INFO, WHEN THIS COMPONENT IS FIRST RENDERED, AND ALSO HANDLES IF THE USER CAN OR NOT MAKE CHANGES
  useEffect(() => {
    if (assembly.agendaItems === undefined) {
      getAssembly();
    }
  }, [assembly]);

  // GET SIGNED DOCUMENT
  const getSignedLegalDocument = () => {
    GetSignedLegalDocument(assembly.id)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.url) {
          setSignedDocument(result);
        } else {
          setSignedDocument(null);
        }
      })
      .catch((e) => {
        setSignedDocument(null);
      });
  };

  // GET UNSIGNED DOCUMENT
  const getUnsignedLegalDocument = () => {
    GetUnsignedLegalDocument(assembly.id)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.url) {
          setUnsignedDocument(result);
        } else {
          setUnsignedDocument(null);
        }
      })
      .catch((e) => {
        setUnsignedDocument(null);
      });
  };

  useEffect(() => {
    if (unsignedDocument === undefined) {
      getUnsignedLegalDocument();
    }
    if (signedDocument === undefined) {
      getSignedLegalDocument();
    }
  }, [unsignedDocument, signedDocument]);

  // GET THE IDENTIFICATION DOCUMENT TYPES ALLOWED
  useEffect(() => {
    if (identificationDocumentTypes.length === 0) {
      GetIdentificationDocumentTypes()
        .then((response) => response.json())
        .then((result) => {
          setIdentificationDocumentTypes(result);
        })
        .catch((error) => {});
    }
  }, [identificationDocumentTypes]);

  function changeTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  // GET ASSEMBLY INFO
  const deleteSignedDocument = () => {
    DeleteSignedLegalDocument(assembly.id)
      .then((response) => response.text())
      .then((result) => {
        if (result.length === 0) {
          notifySuccess(resources.local.details.successDeleteSignedDocument);
          getAssembly();
          getSignedLegalDocument();
        } else {
          clearAssembly();
          notifyError(resources.local.details.failDeleteSignedDocument);
        }
      })
      .catch((e) => {
        clearAssembly();
        notifyError(resources.local.details.failDeleteSignedDocument);
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        page={navigationResources.votings.vote}
        parent={{
          name: navigationResources.votings.breadcrumb,
          onClick: () => {
            clearAssembly();
          },
        }}
      />
      <div className="row">
        <div className={`${!showJustVotes ? "col-lg-6" : "col-lg"}`}>
          {!showJustVotes && changesAllowed && (
            <div
              className={`curtain ${
                activeTab !== "VOTE" ? "active" : "inactive"
              }`}
              onClick={() => {
                changeTab("VOTE");
              }}
            />
          )}
          <VotingsForm
            assembly={assembly}
            goNext={() => {
              setActiveTab("REPRESENTATIVE");
            }}
            clearAssembly={() => {
              clearAssembly();
            }}
            resources={resources}
            getAssembly={() => {
              getAssembly();
            }}
          />
        </div>
        {!showJustVotes && (
          <div className="col-lg-6">
            {changesAllowed && (
              <div
                className={`curtain ${
                  activeTab !== "REPRESENTATIVE" ? "active" : "inactive"
                }`}
                onClick={() => {
                  assembly.userVotingStatus &&
                    assembly.userVotingStatus.id !== 1 &&
                    assembly.userVotingStatus.id !== 2 &&
                    changeTab("REPRESENTATIVE");
                }}
              />
            )}
            <RepresentativeForm
              assembly={assembly}
              resources={resources}
              goNext={() => {
                setActiveTab("SIGN");
              }}
              clearAssembly={() => {
                clearAssembly();
              }}
              identificationDocumentTypes={identificationDocumentTypes}
              resetUnsignedDocument={() => {
                setUnsignedDocument();
              }}
              signedDocument={signedDocument}
              unsignedDocument={unsignedDocument}
              resetSignedDocument={() => {
                setSignedDocument();
              }}
              getAssembly={() => {
                getAssembly();
              }}
              deleteSignedDocument={() => {
                deleteSignedDocument();
              }}
            />
          </div>
        )}

        {/*assembly.requiresLegalRepresentativeDocument &&
                    <div className="col-lg-4">
                        <div className={`curtain ${activeTab!=="SIGN" ? "active" : "inactive"}`} onClick={()=>{changeTab("SIGN")}}/>
                        <SignDocumentForm assembly={assembly} resources={resources}
                            unsignedDocument={unsignedDocument} signedDocument={signedDocument}
                            resetSignedDocument={()=>{setSignedDocument()}}/>
                    </div>
                */}
      </div>
    </Fragment>
  );
};

export default AssemblyForm;
