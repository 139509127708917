import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SetClientIdPage from "./serverScripts/setClientId";
import "./styles/styles.scss";
import DashboardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import HomePage_article from "./pages/HomePage_article";
import LoginPage from "./pages/LoginPage";
import RecoverPage from "./pages/RecoverPage";
import DocumentsPage from "./pages/DocumentsPage";
import MessagesPage from "./pages/MessagesPage";
import NoticesPage from "./pages/NoticesPage";
import VotingsPage from "./pages/VotingsPage";
import ReceiptsPage from "./pages/ReceiptsPage";
import UserPage from "./pages/UserPage";
import CondominiumPage from "./pages/CondominiumPage";
import FractionsDebtsPage from "./pages/FractionsDebtsPage";
import OccurrencesPage from "./pages/OccurrencesPage";
import OccurrencePage from "./pages/OccurrencePage";
import TermsPage from "./pages/TermsPage";
import TermsUsePage from "./pages/TermsUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ActivatePage from "./pages/ActivatePage";
import BlogPage from "./pages/BlogPage";
import BankBalancesPage from "./pages/BankBalancesPage";
import BudgetAnalysisPage from "./pages/BudgetAnalysisPage";
import ExerciseBudgetPage from "./pages/ExerciseBudgetPage";
import BalancePage from "./pages/BalancePage";
import ExerciseExpensesPage from "./pages/ExerciseExpensesPage";
import SupportPage from "./pages/SupportPage";
import InquiriesPage from "./pages/InquiriesPage";
import { isAuthenticatedRoute, isAuthenticatedRouteBlog } from "./assets/auth";
import { Navigation as navigationResources_pt } from "./resources/pt";
import { Navigation as navigationResources_en } from "./resources/en";
import CurrentAccountsPage from "./pages/CurrentAccountsPage";

const IndexSetPages = (props) => {
  const [checkedClientId, setCheckedClientId] = useState(false);

  const changeState = () => {
    setCheckedClientId(true);
  };

  if (!checkedClientId) {
    return <SetClientIdPage setHasChecked={() => changeState()} />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={[
              navigationResources_pt.dashboard.path,
              navigationResources_en.dashboard.path,
            ]}
            component={isAuthenticatedRoute(DashboardPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.user.path,
              navigationResources_en.user.path,
            ]}
            component={isAuthenticatedRoute(UserPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.condominium.path,
              navigationResources_en.condominium.path,
            ]}
            component={isAuthenticatedRoute(CondominiumPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.fractionsDebts.path,
              navigationResources_en.fractionsDebts.path,
            ]}
            component={isAuthenticatedRoute(FractionsDebtsPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.occurrences.path,
              navigationResources_en.occurrences.path,
            ]}
            component={isAuthenticatedRoute(OccurrencesPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.occurrences.path_edit,
              navigationResources_en.occurrences.path_edit,
            ]}
            component={isAuthenticatedRoute(OccurrencePage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.documents.path,
              navigationResources_en.documents.path,
            ]}
            component={isAuthenticatedRoute(DocumentsPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.notices.path,
              navigationResources_en.notices.path,
            ]}
            component={isAuthenticatedRoute(NoticesPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.votings.path,
              navigationResources_en.votings.path,
            ]}
            component={isAuthenticatedRoute((props) => (
              <VotingsPage key={Date.now()} />
            ))}
          />
          {/* <Route
            exact
            path={[
              navigationResources_pt.receipts.path,
              navigationResources_en.receipts.path,
            ]}
            component={isAuthenticatedRoute(ReceiptsPage)}
          /> */}
          <Route
            exact
            path={[
              navigationResources_pt.currentAccounting.path,
              navigationResources_en.currentAccounting.path,
            ]}
            component={isAuthenticatedRoute(CurrentAccountsPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.messages.path,
              navigationResources_en.messages.path,
            ]}
            component={isAuthenticatedRoute(MessagesPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.messages.path_condominium,
              navigationResources_en.messages.path_condominium,
            ]}
            component={isAuthenticatedRoute((props) => (
              <MessagesPage filterCondominium={true} />
            ))}
          />
          <Route
            exact
            path="/terms/:language?/:user?/:token?/:recoveryCode?"
            component={(props) => <TermsPage {...props} />}
          />
          <Route
            exact
            path={navigationResources_pt.inquiries.path}
            component={(props) => <InquiriesPage {...props} />}
          />
          <Route
            exact
            path={[
              navigationResources_pt.terms_of_use.path,
              navigationResources_en.terms_of_use.path,
            ]}
            component={(props) => <TermsUsePage {...props} />}
          />
          <Route
            exact
            path={[
              navigationResources_en.privacy_policy.path,
              navigationResources_pt.privacy_policy.path,
            ]}
            component={(props) => <PrivacyPolicyPage {...props} />}
          />
          <Route
            exact
            path="/login/:status?"
            render={(props) => <HomePage {...props} />}
          />
          <Route
            exact
            path="/recover/:code"
            render={(props) => <RecoverPage {...props} />}
          />
          <Route
            exact
            path={[
              navigationResources_en.activate.path,
              navigationResources_pt.activate.path,
            ]}
            render={(props) => <ActivatePage {...props} />}
          />
          <Route
            exact
            path={[
              navigationResources_pt.bankBalances.path,
              navigationResources_en.bankBalances.path,
            ]}
            component={isAuthenticatedRoute(BankBalancesPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.budgetAnalysis.path,
              navigationResources_en.budgetAnalysis.path,
            ]}
            component={isAuthenticatedRoute(BudgetAnalysisPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.exerciseBudget.path,
              navigationResources_en.exerciseBudget.path,
            ]}
            component={isAuthenticatedRoute(ExerciseBudgetPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.balance.path,
              navigationResources_en.balance.path,
            ]}
            component={isAuthenticatedRoute(BalancePage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.exerciseExpenses.path,
              navigationResources_en.exerciseExpenses.path,
            ]}
            component={isAuthenticatedRoute(ExerciseExpensesPage)}
          />
          <Route
            exact
            path={[
              navigationResources_pt.blog.path,
              navigationResources_en.blog.path,
            ]}
            component={isAuthenticatedRouteBlog(BlogPage)}
          />
          <Route exact path="/blog/:name" component={HomePage_article} />
          <Route exact path="/support/:token" component={SupportPage} />
        </Switch>
      </BrowserRouter>
    );
  }
};

export default IndexSetPages;
