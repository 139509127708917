import React, { Fragment, useState, useEffect } from "react";
import LoginForm from "../components/forms/LoginForm";
import Footer from "../components/website/Footer";
import Navbar from "../components/elements/Navbar";
import { ForCondominiums } from "../templates/forcondominiums";
import { ForOwners } from "../templates/forowners";
import {
  websiteResources,
  loginResources,
  getCurrent,
  setLanguage,
  globalResources,
  navigationResources,
} from "../resources/resources";
import { InstallPWA } from "../InstallPWA/InstallPWA";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { client_id, default_client_id } from "../assets/config";
import Card from "../components/containers/Card";
import { number } from "../assets/forms/validation";
import IsLoading from "../components/elements/IsLoading";
import HomePage_article from "./HomePage_article";
import { GetBlog } from "../actions/blog";
import { rewriteName } from "../assets/utils";

const HomePage_blog = (props) => {
  const blogValues_labels=websiteResources.blogText.blogValues;
  const [blog, setBlog]=useState((props.location && props.location.state && props.location.state.blog) ? props.location.state.blog : undefined);
  const [detail, setDetail]=useState(0);
  const [page, setPage]=useState(1);
  let _lang = getCurrent();
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);
  const articlesPerPage = 6;

  useEffect(() => {
    if(client_id()===default_client_id && blog===undefined){
      GetBlog().then((response) => response.json())
      .then(result => {
        setBlog(result);
      })
      .catch(e => {setBlog([])});
    } else if(client_id()!==null && client_id()!==default_client_id){
        props.history.replace(navigationResources.dashboard.path)
    }
  }, [blog]);

  useEffect(() => {
    if (lang && lang.split("-")[0] !== getCurrent()) {
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  function renderArticles(){

    var moment = require("moment");
    const startArticlesIndex = articlesPerPage*(page-1);
    const articlesBlog = blog.slice(startArticlesIndex, startArticlesIndex + articlesPerPage);
    return(
    <ul className="container">
        <p className="blog-breadcrumb">
          <a className="active" onClick={()=>{props.history.push(navigationResources.dashboard.path)}}>{websiteResources.home}</a>
          <a>{'>'}</a>
          <a>{websiteResources.blog}</a>
        </p>
        <div className="row">
        {articlesBlog.map((item, index) => {
            const texts = (item.texts && item.texts.length>0) ? item.texts.filter(item=> item.culture===blogValues_labels.culture) : undefined; 
            let name_rewrite = rewriteName(texts[0].title);
            return(
                <li key={"blogArticle-"+item.id} className={`col-md-6 m-b-xxl ${((isMobile && index<1) || (!isMobile && index<2)) ? "" : "m-t-xxxxxl"} article-container`}>
                    <a className="cur-point" onClick={()=>{props.history.push(`/blog/${name_rewrite+"-"+item.id}`, { blog: blog })}}>
                      <div className="article-image-container">
                        <div style={{ backgroundImage: `url(${(item.image && item.image.url) ? item.image.url : "/images/blog-banner.jpg"})` }} className="article-image"/>
                        {item.creationDate && <span className="article-date">{moment(item.creationDate).format("DD-MM-YYYY")}</span>}
                      </div>
                    </a>
                    {(texts && texts.length>0) && 
                      <div className="article-text-container">
                        <h2 className="title">{texts[0].title}</h2>
                        <p className="introduction">{texts[0].introduction}</p>
                      </div>
                    }
                    <button
                        className="btn btn-primary"
                        onClick={()=>{props.history.push(`/blog/${name_rewrite+"-"+item.id}`, { blog: blog })}}
                    >{websiteResources.blogText.readMore}</button>
                </li>
            );
        })}
    
        </div>
    </ul>
    );
  }

  function makePageSelector(){
      const numberOfPossiblePages=Math.ceil(blog.length/articlesPerPage);
      return(
        <div className="page-selector-buttons">
            <button
                disabled={page===1 ? true : false}
                className="btn btn-primary btn-page-selector"
                onClick={()=>{setPage(page-1)}}
            >{"<"}</button>

            <button
                className={`btn btn-primary btn-page-selector ${page===1 ? "disabled" : ""}`}
                onClick={()=>{
                    const prevPage = ((page===numberOfPossiblePages && numberOfPossiblePages>2) ? page-2 : page-1);
                    setPage(prevPage);
                }}
            >{page!==1 ? ((page===numberOfPossiblePages && numberOfPossiblePages>2) ? page-2 : page-1) : 1}</button>
            <button
                className={`btn btn-primary btn-page-selector ${((page!==1 && page!==numberOfPossiblePages) || (page===2 && numberOfPossiblePages===2)) ? "disabled" : ""}`}
                onClick={()=>{
                    const nextPage = (page===1 ? 2 : numberOfPossiblePages-1);
                    setPage(nextPage)
                }}
            >{(page>1 && page<numberOfPossiblePages) ? page : (page===1 ? 2 : (numberOfPossiblePages>2 ? numberOfPossiblePages-1 : numberOfPossiblePages)) }</button>
            {numberOfPossiblePages>2 &&
                <button
                    className={`btn btn-primary btn-page-selector ${page===numberOfPossiblePages ? "disabled" : ""}`}
                    onClick={()=>{
                        const nextPage = (page===1 ? 3 : page+1);
                        setPage(nextPage);
                    }}
                >{page!==numberOfPossiblePages ? (page===1 ? 3 : page+1) : numberOfPossiblePages}</button>
            }

            <button
                disabled={page===numberOfPossiblePages ? true : false}
                className="btn btn-primary btn-page-selector"
                onClick={()=>{setPage(page+1)}}
            >{">"}</button>
        </div>
      );
  }
  
  return (
    <Fragment>
      <Navbar
        onLanguageChange={(language) => {
            setLang(language);
        }}
        onModeChange={(newMode)=>{
            newMode===0 && props.history.push(navigationResources.dashboard.path)
        }}
        nameMode0={(client_id()===default_client_id) ? websiteResources.home : ""}
        nameMode1={(client_id()===default_client_id) ? websiteResources.blog : ""}
        mode={(client_id()===default_client_id) ? 1 : ""}
      />
    {(blog && blog.length>0) ? (
      <div className="banner">
        <div className="banner-item-blog">
            <span>
                <p>{websiteResources.blogText.banner.p1}</p>
                <p>{websiteResources.blogText.banner.p2}</p>
            </span>
        </div>
        <Card subclass="blog-container m-b-0" >
            {blog.length>0 &&
                <div className="articles-container">
                    {renderArticles()}
                    {blog.length>articlesPerPage &&
                        makePageSelector()
                    }
                </div>
            }
        </Card>
      </div>)
      :
      (<Card>
        <IsLoading text={websiteResources.loading} />
      </Card>)
    }
    <Footer />
    </Fragment>
  );
};

export default HomePage_blog;
