export let Global = {
  required: "Required field",
  takePhoto: "Take photo",
  addPhoto: "Insert image",
  addPhotoSuccess: "Image inserted successfully!",
  addPhotoFail: "It was not possible to insert the image ...",
  deletePhoto: "Remove the image",
  deletePhotoSuccess: "Image removed successfully!",
  deletePhotoFail: "It was not possible to remove the image ...",
  hasntVoted: "Hasn't voted",
  votingIncomplete: "Voting incomplete",
  hasntSignedFile: "Hasn't signed file",
  complete: "Complete",
  notOpen: "Not open",
  notAvailableYet: "Not available yet",
  unavailable: "Unavailable",
  active: "Active",
  inactive: "Inactive",
  verbs: {
    save: "Save",
    save_alt: "Save",
    cancel: "Cancel",
    remove: "Remove",
    register: "Register",
    download: "Descarregar",
    change: "Change",
    accept: "Accept",
    decline: "Decline",
    vote: "Vote",
    select: "Select",
    submit: "Submit",
    add: "Add",
    print: "Print",
    goBack: "Go back",
  },
  totalLabel: "Total:",
  date: "Date",
  page: "Page",
  goToPage: "| go to page:",
  show: "Show",
  languages: {
    pt: "Portuguese",
    en: "English",
  },
  terms: "Terms and conditions of use",
  privacyPolicy: "Privacy policy",
  new: "New",
  occurrence: "occurrence",
  seeAllMessages: "View all messages",
  signOut: "Sign out",
  condominium: "Condominium",
  chooseCondominium: "Choose the condominium",
  step: "Step",
  rightsReserved: "All rigths reserved.",
  followGecond: "Follow Gecond my condominium",
  gecondmycondominium: "Gecond my condominium",
  gecondLogoPath: "/images/logo-gecond-my-condominium.png",
  gecondLogoWhitePath: "/images/logo-gecond-my-condominium-w.png",
  installApp: "Install Gecond my condominium",
  close: "Close",
  install: "Install",
  light: "Light",
  dark: "Dark",
  male: "Male",
  female: "Female",
  CC: "Citizens card",
  passport: "Passport",
  entityTicket: "Entity ticket",
  residencePermit: "Residence permit",
  validations: {
    minLength: (min) => `Must be at least ${min} characters`,
    maxLength: (max) => `Must be a maximum of ${max} characters`,
    number: "Must be a number",
    email: "Invalid email",
    alphaNumeric: "Alphanumeric characters only",
    phoneNumber: "Invalid phone number, must be 9 digits",
    complexPassword:
      "The password must have a minimum of 8 digits, \n at least one uppercase letter, \n a lowercase letter and a number.",
  },
  currencySymbol: " (%currency%)",
  currencySymbol_replaceString: "%currency%",
  yes: "Yes",
  no: "No",
  continue: "Continue",
  cancel: "Cancel",
  noActiveBudget: "No active budget.",
  apiError: {
    activeBudgetNotFound: "ActiveBudgetNotFound",
  },
  openInquiries: "Open inquiries",
  openInquirie: "Open inquirie",
  loading: "Loading data",
  ok: "Ok",
  inviteExpired_message1:
    "Sorry, the link you are trying to use to register in “Gecond, o meu condomínio” has expired.",
  inviteExpired_message2:
    "Please use a more recent access invitation or ask your condominium administration to send you a new invitation.",
  answers: "answers",
  errorPDF: "There was an error getting the declaration.",
};

export let Navigation = {
  dashboard: {
    link: "Dashboard",
    breadcrumb: "Dashboard",
    path: "/",
  },
  user: {
    link: "Profile",
    breadcrumb: "PROFILE",
    path: "/user",
  },
  condominium: {
    link: "Condominium",
    breadcrumb: "Condominium",
    path: "/condominium",
  },
  fractionsDebts: {
    link: "Fractions debts",
    breadcrumb: "FRACTONS DEBTS",
    path: "/fractions_debts",
  },
  notices: {
    link: "In debt values",
    breadcrumb: "IN DEBT VALUES",
    path: "/notices",
  },
  votings: {
    link: "Assemblies",
    breadcrumb: "ASSEMBLIES",
    vote: "VOTE",
    legalRepresentative: "LETTER OF ATTORNEY",
    finalize: "FINALIZE",
    path: "/assemblies",
  },
  // receipts: {
  //   link: "Receipts",
  //   breadcrumb: "Receipts",
  //   path: "/receipts",
  // },
  currentAccounting: {
    link: "CurrentAccounting",
    breadcrumb: "CurrentAccounting",
    path: "/current-accounting",
  },
  messages: {
    link: "Messages",
    breadcrumb: "Messages",
    unread: "unread messages",
    path: "/messages",
    path_condominium: "/condominium-messages",
  },
  blog: {
    link: "Blog",
    breadcrumb: "BLOG",
    path: "/blog",
    edit: "ARTICLE",
    new: "NEW ARTICLE",
  },
  bankBalances: {
    link: "Bank balances",
    breadcrumb: "BANK BALANCES",
    path: "/bank-balances",
  },
  budgetAnalysis: {
    link: "Budget analysis",
    breadcrumb: "Budget analysis",
    path: "/budget-analysis",
  },
  exerciseBudget: {
    link: "Exercise budget",
    breadcrumb: "Exercise budget",
    path: "/exercise-budget",
  },
  balance: {
    link: "Balance",
    breadcrumb: "Balance",
    path: "/balance",
  },
  exerciseExpenses: {
    link: "Exercise expenses",
    breadcrumb: "Exercise expenses",
    path: "/exercise-expenses",
  },
  occurrences: {
    link: "Occurrences",
    breadcrumb: "OCCURRENCES",
    edit: "OCCURRENCE",
    new: "NEW OCCURRENCE",
    pending: "pending occurrences",
    path: "/occurrences",
    path_edit: "/occurrence",
  },
  documents: {
    link: "Documents",
    breadcrumb: "Documents",
    path: "/documents",
  },
  terms_of_use: {
    path: "/terms-of-use",
  },
  privacy_policy: {
    path: "/privacy-policy",
  },
  activate: {
    path: "/activate",
  },
  inquiries: {
    breadcrumb: "INQUIRIES",
    path: "/inquiries",
    link: "Inquiries",
    inquirie: "Inquirie",
    stats: "Participation statistics",
  },
};

export const DashboardPage = {
  welcome: {
    title: "Welcome!",
    p1: "Welcome to GECOND.COM, a more practical and safer way to monitor the management of your condominium.",
    p2: "The information available here, as well as its accuracy and timeliness, is the responsibility of the management of the condominium.",
    p3: "To clarify any doubts, you can open a new case or contact your administration using the usual methods.",
    newOccurrence: "New occurrence",
    noCondo:
      "Currently, no condominium has been assigned to this account. For any clarification, contact your administration using the usual methods.",
  },
  notices: {
    title: "In debt",
    seeall: "View documents",
  },
  messages: {
    title: "Messages",
    unread: "unread",
    seeall: "View all messages",
  },
  occurrences: {
    title: "Occurrences",
    seeall: "View all occurrences",
    pending: "pending",
  },
  documents: {
    title: "Documents",
    seeall: "View all documents",
  },
};

export const Login = {
  title: "Gecond",
  subtitle: "Condominium management",
  welcome: "Welcome!",
  accountAccess: "Log in to your account.",
  userName: "User",
  userPassword: "Password",
  login: "Login",
  demo: "Demo",
  demo_user: "demo@gecond.com",
  demo_password: "Gecond2020",
  clickHereToTry: "Click here to try",
  recoverEmail: "Email",
  recover: "Lost your password?",
  recoverSubmit: "Recover Password",
  recoverBack: "Go back",
  confirmRecover: "Recover",
  recoverTitle: "Recover Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  recoverPasswordNoMach: "The indicated password is not the same.",
  recoverSuccess: "The password has been changed.",
  recoverExpired: "Password recover request expired.",
  activateExpired:
    "The password activation request has expired. If you received more than one invitation please use the link in the most recent email.",
  activateCriticalFail:
    "It was not possible to activate the password. To have access to the platform please contact your condo manager to make a new invitation, or use the feature to recover the password using the email from where you received the invitation",
  recoverFailed:
    "It was not possible to recover your password, please try again.",
  activateFailed: "It was not possible to activate the password.",
  activateTitle: "Activate Gecond my condominium",
  activateSubTitle: "Set password.",
  confirmActivate: "Enable",
  activation: "Account",
  feedback: {
    fail: "The access data is incorrect.",
    error: "Unable to access account currently.",
    recoverEmailSent: "Password recovery email was sent.",
    recoverEmailFail:
      "It was not possible to send the password recovery email, please try again.",
    recoverEmailNotFound: "The email you specified is not in the system.",
    cannotRedefinePassword:
      "The user demo@gecond.com cannot redefine password because it's a demonstration account.",
    termsFail: "There was a problem, please try again",
    authenticationFailPopUp1: "Invalid access credentials.",
    authenticationFailPopUp2:
      "Please make sure your password is correct or request to recover it.\n",
    authenticationFailPopUp3: "Click here to recover password",
    userNotFoundPopUp1: `There is no registered user with this access data.`,
    userNotFoundPopUp2:
      "Please contact your condominium administration, and request and access to Gecond, my condominium.",
    userNotFoundPopUp3:
      "Initial registration for access will be sent by your administration via email.",
  },
};

export const Website = {
  banner: {
    appTitle: "New mobile app",
    title: "My condominium always in my hand",
    download: "Download now",
    playstoreLink:
      "https://play.google.com/store/apps/details?id=com.improxy.Gecond",
    appstoreLink:
      "https://apps.apple.com/pt/app/gecond-o-meu-condom%C3%ADnio/id1541353491",
    p1: "Do you want to communicate and access your condominium information, without complications?",
    p2: "Nothing easier, with GECOND, my condominium, the website that allows, in a safe way, to communicate and access all the information of your condominium, 24 hours a day.",
    p3: "Now you can consult everything on the same site, without having to search for papers, emails or make phone calls.",
    p4: "Keep your condominium close at hand.",
    p5: "Call your condominium administration to request this service.",
  },
  currentAccount: {
    title: "Account",
    text: "Check your current account status and print duplicate billing notices and receipts",
  },
  messages: {
    title: "Messages",
    text: "Send and view messages from your condominium administration",
  },
  documents: {
    title: "Documents",
    text: "Consult meeting documents and others, shared by the condominium administration",
  },
  descriptions: {
    condominium: {
      title: "For condominium administrations",
      text: {
        p1: "Using the GECOND condominium management software, the information generated on this platform is automatically available to your tenants at Gecond, my condominium, creating a digital and secure channel, without the need for face-to-face contact.",
        p2: "A modern, active and transparent administration with information available 24 hours a day.",
        p3: "Check more information",
      },
    },
    owner: {
      title: "For apartment owners",
      text: {
        p1: "Gecond, my condominium is a service provided by condominium administrations that use Gecond condominium management software.",
        p2: "As a apartment owner, you will be able to access the paid and unpaid amounts of your fractions, print copies of them, communicate with the condominium administration and access information that it may provide, such as minutes of meetings, internal regulations, etc.",
      },
    },
  },
  promotional_video: "https://www.youtube.com/watch?v=9uSGkfUDAjY",
  home: "HOME",
  blog: "BLOG",
  blogTitle: "Latest entries from our blog",
  moreArticles: "More Articles",
  loading: "Loading data",
  blogText: {
    banner: {
      p1: "Stay up to date with the latest news on",
      p2: "condos with our blog",
    },
    blogValues: {
      culture: "en-GB",
    },
    readMore: "Read more",
  },
};

export const UserPage = {
  insertValidPhoneNumber: "That number is not valid, please try again",
  details: {
    title: "Information",
    name: "Name",
    email: "Email",
    phone: "Mobile phone",
    address: "Address",
    success: "Success.",
    fail: "It was not possible to change the information.",
    language: "Language",
    gender: "Gender",
    applicationTheme: "Application Theme",
    birthDate: "Birth Date",
  },
  photo: {
    title: "Photo",
    change: "Change",
  },
  password: {
    title: "Change password",
    old: "Current password",
    new: "New password",
    confirm: "Confirm password",
    success: "The password has been changed.",
    fail: "It was not possible to change the password.",
    NoMach: "The password is not the same.",
    submit: "Change",
  },
  note: {
    title: "Note:",
    text: {
      p1: "Changes to this personal data will not change the existing data in the condominium administration.",
      p2: "If you want to report changes to the administration, we suggest opening a new case for that purpose.",
    },
  },
};

export const DocumentsTable = {
  title: "TABLE OF ASSOCIATED DOCUMENTS",
  date: "Date",
  fileName: "Document",
  description: "Description",
  download: "Download document",
  noDocuments: "No documents",
  loading: "Loading data",
};

export const NoticesTable = {
  title: "NOTICE AND CREDIT TABLE",
  fraction: "Fraction",
  document: "Document",
  date: "Date",
  valueInDebt: "Value in debt",
  balance: "Balance",
  description: "Description",
  loading: "Loading data",
  noNotices: "No notices or credits",
  noticeNumber: "Notice no ",
  creditNumber: "Credit no ",
  dueDate: "Due date",
  dueDocuments: "expired documents and expiring in the next 15 days",
  expiredDocuments: "Expired documents",
  allDocuments: "overdue and overdue documents",
  pay: "Pay",
  MBWAY_title:
    "The MB WAY payment is processed by LUSOPAY, Instituição de Pagamento, Lda.",
  entity: "Entity:",
  reference: "Reference:",
  amount: "Amount:",
  MBWAY_image: "/images/MBWAY.png",
  MBWAY_white_image: "/images/MBWAY-w.png",
  IBAN_title: "Payment by bank transfer to IBAN:",
  owedBalance: "Owed balance",
  creditorBalance: "Creditor balance",
  value: "value",
  insertValidValue: "Please insert a valid value",
  noPaymentOption: "No payment option available",
  paymentValue: "Payment value",
  continuePayment: "Continue to payment",
  printEveryDocument: "Print every document",
  IBANCopied: "IBAN copied",
  download: "Download document",
  copy: "Copy",
  notUpdatedInformation:
    "This information may not be updated, as there may still be pending entries.",
  payOnline: "Pay online",
  directBank: "By bank directly on the web",
  billPayment: "Bill payment",
  totalToPay: "Total amount",
  ibanPaymentNotFound:
    "There is currently no payment method available through the application. Please contact your administration.",
};

export const VotingsTable = {
  ordinaryAssembly: "Ordinary Assembly",
  extraordinaryAssembly: "Extraordinary Assembly",
  digitalVoting: "Digital voting",
  votingStatus: "Voting status: ",
  administrationsValidation: "Administration's validation: ",
  dateToVote:
    "You can vote on the Assembly of {%START_DATE%} till the date limit of {%END_DATE%}.",
  dateTillVote: "You can vote on the Assembly till {%END_DATE%}.",
  loading: "Loading data",
  noVotes: "No assemblies",
  agendaItems: "Agenda items: ",
  subject: "Subject",
  start: "Start Date",
  end: "Completion Date",
  vote: "Vote",
  informative: "Informative",
  voting: "Voting",
  voting: "Finalize",
  representative: "Representative",
  otherRepresentative: "Another representative",
  failList: "Unable to read votes",
  chooseOption: "Choose an option",
  cantChangeVotes: "Can't change votes",
  cantChangeRepresentative: "Can't change representative",
  fillAllOtherRepresentativeFields:
    "Fill all of the fields regarding your representative",
  noSelection: "No voting is selected!",
  selected: "Selected",
  loading: "Loading data",
  assemblyPresidentLabel: "Assembly president",
  printSignSubmitContract: "Print, sign and attach signed power of attorney.",
  SignedContract: "Signed power of attorney.",
  signWMobileKey: "Sign with mobile key",
  addRepresentationDocument: "Attach power of attorney already signed",
  printRepresentationDocument: "Print power of attorney to sign",
  digitallySignRepresentation: "Sign with Digital Mobile Key",
  printSignedRepresentationDocument: "Print signed representation document",
  printDocumentAlrAdded: "Print Power of Attorney already added.",
  changeVotesOrRepresentative: "Change voting or representation",
  checkRepresentationDocument: "Check representation document",
  openVoting1: "1 Open Assembly",
  openVotings: "{%NUMBER_VOTES%} Open Assemblies",
  participateNow: "Participate now!",
  checkDocumentation: "Check documentation",
  assemblyRequiresLegalDocument:
    "This assembly requires the issuance of a power of attorney.",
  assemblyDoesntRequireLegalDocument:
    "This assembly doesn't require the issuance of a power of attorney.",
  printContract: "Print contract.",
  votingAgendaItems: "Voting on the agenda items",
  presentialAssemblyDate: "Presential assembly date: ",
  videoconferenceAssemblyDate: "Videoconference assembly date: ",
  mixedAssemblyDate: "Mixed assembly date: ",
  alternativeAssemblyDate: "Second convocation of the assembly: ",
  proxyIssue: "Proxy issue",
  proxyIssue_description_p1:
    "The issue of the power of attorney is mandatory and, for the vote to be considered, it must be printed, signed and attached to this vote.",
  proxyIssue_description_p2:
    "To comply with legal requirements, after completing the vote, you must choose the representative and print the power of attorney. After signing, you must scan and attach it.",
  proxyIssue_description_p3:
    "Alternatively, you can send the power of attorney already signed to the condominium administration by other means.",
  selectRepresentative: "Select your representative",
  condominiumAdministrationRepresentative:
    "Condominium management representative",
  indicateMyRepresentative: "Indicate my representative",
  details: {
    representativeName: "Representative name",
    typeDocument: "Type of identification document",
    number: "Number",
    expirationDate: "Expiration date",
    numberCC: "Citizens card number",
    expirationDateCC: "Citizens card expiration date",
    numberPassport: "Passport number",
    expirationDatePassport: "Passaport expiration date",
    numberEntityTicket: "Entity ticket number",
    expirationDateEntityTicket: "Entity ticket expiration date",
    numberResidencePermit: "Residence permit number",
    expirationDateResidencePermit: "Residence permit expiration date",
    successRepresentative: "Representative changed successfully.",
    failRepresentative: "It was not possible to set the representative.",
    successVotes: "Votes changed successfully.",
    failVotes: "It was not possible to set the votes.",
    duplicatedAttachment: "Duplicated file name",
    addDocumentSuccess: "File added",
    addDocumentFail: "Failed to add file",
    inFavor: "In favor",
    against: "Against",
    abstent: "Abstention",
    failDeleteSignedDocument:
      "It was not possible to delete the signed power of attorney.",
    successDeleteSignedDocument: "Power of attorney deleted successfully.",
    CMDSignFail: "An error has occurred, please try again.",
  },
  validation_states: {
    valid: "Validated by the administration",
    valid_description:
      "Your vote will be considered in the Assembly. If you want to make any changes, please contact the condominium administration.",
    invalid: "Invalid voting",
    invalid_description:
      "Your vote was considered invalid. In case of doubt, please contact the condominium administration.",
    validating: "Not validated by the Administration",
  },
  assemblyIsntAvailableYet: "Assembly isn't available yet.",
  checkAgendaItems: "Check agenda items",
  goToVoting: "Go to the voting",
  checkVoting: "Check voting",
  deleteSignedDocumentConfirmationMessage:
    "If you wish to continue, the previously added signed power of attorney will be deleted, and you will need to print, sign and attach again.",
  votingForFractions_singular: "Voting for fraction: ",
  votingForFractions_plural: "Voting for fractions: ",
  noFullOwnership_infoText:
    "Joint owners in a joint ownership regime will have to issue a personalized power of attorney or attend the face-to-face meeting.",
  documentsForConsultation: "Documents for consultation",
  download: "Download document",
  videoconference: "Video conference",
  videoconferenceAccess: "Access to video conference",
  videoconferenceDescription:
    "It should be available moments before the start of the meeting",
  videoconferenceOtherInfo: "Additional videoconference information:",
  accessPassword: "Access password",
  minutesMeeting: "Minutes of the meeting",
  minutesSubscriptionButton: "Minutes subscription statement",
  minutesSubscriptionPopup_description:
    "You must print the statement and send it to the administration of the condominium, duly signed, and by letter or email.",
  CMDSignFormTitle: "Sign with Digital Mobile Key",
  phoneNumber: "Mobile number",
  signaturePin: "Subscription PIN",
  pin_question: "PIN?",
  fillAllFields: "Fill in all fields",
  forceSMS_title: "Security Code Confirmation",
  forceSMS_description:
    "To continue, enter the code you received on your mobile phone via the Gov Authentication application within the next 5 minutes",
  forceSMS_input: "security code",
  forceSMS_resend: "Send by SMS",
  plsWait: "Please wait",
  nextStepIndication: "After this step your document will be signed. ",
  newNextStepIndication:
    "After this step your document will be signed and will be irreversible. The entire subscription process is the sole legal responsibility of the subscriber.",
  newNextStepIndicationLink: "You can consult CMD's policies at ",
  stepIndicationLink: "www.autenticacao.gov.pt/cmd-assinatura",
  officialDocumentation: "Official documentation",
  clickToActivateCMD:
    "Click to activate or manage your Digital Mobile Key subscription",
  linkToActivateCMD: "https://www.autenticacao.gov.pt/cmd-pedido-chave",
  signManually_title:
    "Print the power of attorney, manually sign, scan and attach signed power of attorney",
  signDigitally_title: "Sign using the Digital Mobile Key I have",
  invalidFile: "Ficheiro invalido",
  confirmThisFile: "Are you sure this is the document to sign?",
  confirm: "Confirm",
  enterData: "Enter data",
  amaLogoPath: "/images/ama-logo.svg",
  option1: "Option 1 - ",
  option2: "Option 2 - ",
  smsExplanation: "To send the security code by SMS click 'SEND BY SMS'",
  cmdSignSuccess: "Power of attorney successfully signed",
  downloadDocument: "Download signed document",
  assignDescription1: "(1/4 Preview the document)",
  assignDescription2: "(2/4 Authentication with digital mobile key)",
  assignDescription3: "(3/4 Security Code Confirmation)",
  assignDescription4: "(4/4 Signed document)",
  dontSavePin:
    "The pin is neither saved locally nor transferred, and each document signature must fill in the data.",
  loadingPDF: "Loading document. Please wait.",
};

export const ReceiptsTable = {
  title: "RECEIPT TABLE",
  document: "Document",
  date: "Date",
  number: "Number",
  value: "Value",
  payment: "Payment",
  type: "Type",
  bank: "Bank",
  docNumber: "Document No.",
  paymentMethods: {
    1: "Cash",
    2: "Check",
    3: "Transfer",
    4: "MB",
    5: "Direct debit",
    6: "Other",
    7: "Payshop",
    8: "Deposit",
    9: "TPA",
  },
  loading: "Loading data",
  noReceipts: "No receipts",
  notUpdatedInformation:
    "This information may not be up to date. All payments made are only considered after validation and bank reconciliation. In case of doubt please contact the condominium administration.",
  warning: "Warning",
  sendEmail: "Send by email",
  typeDocument: "Tipo de documento",
};

export const CurrentAccounts = {
  noAccounts: "No current accounts",
  loading: "Loading data",
  warning: "Warning",
  sendEmail: "Send by email",
  typeDocument: "Document Type",
  documentTypes: {
    initialBalance: "Initial balance",
    warning: "Warning",
    receipt: "Receipt",
    extraRevenue: "Extra revenue",
    creditInAdvance: "Advance",
    credit: "Credit",
    refund: "Refund",
  },
  document: "Document",
  fraction: "Fraction",
  emailSent: "Email sent successfully.",
  description: "Description",
  notUpdatedInformation:
    "This information may not be up to date. All payments made are only considered after validation and bank reconciliation. In case of doubt please contact the condominium administration.",
  debitBalance: "Outstanding balance",
  creditBalance: "Credit Balance",
  warningTwelveMonths:
    "Documents issued in the last 12 months - up to today - regardless of the due date.",
  emited12Months: "Movements released in the last 12 months.",
  goToNoticeCreditsPage: "Check the amounts payable",
  type: "Type",
  paymentMethods: {
    1: "Cash",
    2: "Check",
    3: "Transfer",
    4: "MB",
    5: "Direct debit",
    6: "Other",
    7: "Payshop",
    8: "Deposit",
    9: "TPA",
  },
};

export const OccurrencesTable = {
  title: "Occurrences",
  subject: "Subject",
  start: "Start Date",
  end: "Completion Date",
  add: "New Occurrence",
  noOccurrences: "No occurrences",
  loading: "Loading data",
  unStarted: "Unstarted",
  onGoing: "Ongoing",
  completed: "Completed",
  rating: "Rating",
  takeRatingNow: "Take rating now",
  rate: "rate",
  detail: {
    new: "New occurrence",
    edit: "Edit occurrence",
    done: "Occurrence",
    subject: "Subject",
    type: "Type",
    obs: "Description",
    success: "Occurrence saved",
    fail: "Failed to save occurrence",
    removeSuccess: "Occurrence removed",
    removeFail: "Failed to remove occurrence",
    ratingSuccess: "Occurrence rated",
    ratingFail: "Failed to rate occurrence",
    date: "Date",
    noAttachments: "No attachments",
    attachments: "Attachments",
    addAttachments: "Add",
    addAttachmentTitle: "New file",
    addAttachmentSuccess: "File added",
    addAttachmentFail: "Failed to add file",
    removeAttachmentSuccess: "File removed",
    removeAttachmentFail: "Failed to remove file",
    duplicatedAttachment: "Duplicated file name",
    fileName: "File",
    interventionStatus: "Intervention status",
    closeDate: "Close date",
    rating: "Rating ",
    administrationInformation: "Administration comments",
    unStarted: "Unstarted",
    onGoing: "Ongoing",
    completed: "Completed",
    in_connector: " in ",
  },
};

export const CondominiumPage = {
  info: "CONDOMINIUM INFORMATION",
  condominium: "Condominium",
  subtitle: "Condominium info",
  exercise: "Active exercise",
  contributor: "VAT",
  iban: "IBAN",
  name: "Name",
  address: "Address",
  fractions: "Fractions",
  quotas: "Annual Quotas",
  code: "Code",
  description: "Description",
  permilage: "Permilage",
  budget: "Budget",
  fund: "Reserve Fund",
  insurance: "Insurance",
  photo: "Photo",
  total: "Total:",
  annualQuota: "Total",
  noFractions: "No fractions",
  loading: "A Carregar dados",
};

export const FractionsDebtsTable = {
  fraction: "Fraction",
  inDebt: "In debt",
  other_debts: "other_debts",
  otherDebts: "Other debts",
  previousOwners: " (previous owners)",
  noDebts: "No information available regarding your debts",
  loading: "Loading data",
  total: "Total:",
  notUpdatedInformation:
    "This information may not be up to date. All payments made are only considered after validation and bank reconciliation. In case of doubt please contact the condominium administration.",
};

export const Messages = {
  title: "Messages",
  failList: "Unable to read messages",
  failDetail: "Unable to read message content",
  unread: "Unread",
  noMessages: "No messages",
  noSelection: "No message is selected!",
  noFractions: "No fractions",
};

export const BlogTable = {
  number: "Number",
  date: "Date",
  subject: "Title",
  image: "Image",
  newArticle: "New article",
  loading: "Loading data",
  loadingImage: "Loading image",
  noArticles: "No articles",
  edit: "edit",
  remove: "remove",
  detail: {
    new: "new article",
    edit: "edit article",
    image: "image",
    subject: "title",
    subject_pt: "portuguese title",
    subject_en: "english title",
    introduction: "introduction",
    introduction_pt: "portuguese introduction",
    introduction_en: "english introduction",
    description: "description",
    description_pt: "portuguese description",
    description_en: "english description",
    status_label: "article status",
    attachments: "Attachments",
    addImage: "Add Image",
    editImage: "Edit Image",
    fileName: "File",
    removeSuccess: "Article removed",
    removeFail: "Failed to remove article",
    success: "Article saved",
    fail: "Failed to save article",
  },
  tableValues: {
    culture: "en-GB",
  },
};

export const BankBalancesTable = {
  info_p1: "Unconsolidated figures obtained on {%DATE%} at {%TIME%}.",
  info_p2: "There may be payments or receipts not yet posted.",
  loading: "Loading data",
  cashAccountBalance: "Cash account balance: ",
  noAccountTypes: {
    currentAccounts: "No information available",
    savingsAccounts: "No information available",
    reservationAccounts: "No information available",
  },
  accountTypes: {
    cash: "Cashier",
    current: "Current accounts",
    savings: "Savings accounts",
    reservation: "Reserve fund accounts",
  },
  tableHeaders: {
    accountType: "Account type",
    balance: "Balance",
    description: "Description",
    bank: "Bank",
    iban: "IBAN",
  },
};

export const BudgetAnalysis = {
  info_p1: "Values obtained on {%DATE%} at {%TIME%}.",
  info_p2: "There may be payments or receipts not yet posted.",
  title: "Budget analysis",
  loading: "Loading data",
  date_connector: " till ",
  tableHeaders: {
    expenses: "Expenses",
    budgeted: "Budgeted",
    posted: "Posted",
    deviation: "Deviation",
    payed: "Payed",
    unpayed: "Unpayed",
    revenues: "Revenues",
  },
  expensesTotal: "Expenses total",
  zoneTotal: "Zone total",
  revenuesTotal: "Revenues total",
  total: "Total",
  noData: "No data",
  static_description: {
    expenses: {
      budgeted: "Budgeted: budget item amount.",
      posted:
        "Posted: value of supplier documents issued during the year, associated with the item and of the budget type.",
      deviation: "Deviation: difference between Budgeted and Launched.",
      payed:
        "Payed: settled value of documents of budget type suppliers, for the year, for each item.",
      unpayed: "Unpayed: launched - Settled.",
    },
    revenues: {
      budgeted: "Budgeted: budget item amount.",
      posted:
        "Posted: value of notices of extraordinary revenues in the year, of the budget type and associated with the item.",
      deviation: "Deviation: difference between Budgeted and Launched.",
      payed:
        "Payed: amount received, in the year, of the notices of revenue of the budget type, for each item.",
      unpayed: "Unpayed: launched - Received.",
    },
  },
  noValues: "No information available",
};

export const ExerciseBudget = {
  info_p1: "Values obtained on {%DATE%} at {%TIME%}.",
  info_p2: "There may be payments or receipts not yet posted.",
  title: "Budget",
  loading: "Loading data",
  date_connector: " till ",
  tableHeaders: {
    rubrics: "Rubrics",
    value: "Values",
  },
  subtotal: "SubTotal",
  total: "Total",
  budgetTotal: "Budget total",
  quotas_title: "Quotas to be collected",
  quotas_tableHeaders: {
    zones: "Zones",
    budget: "Budget",
    reserveFund: "Reserve Fund",
    insurance: "Insurance",
    total: "Total",
  },
  noValues: "No information available",
};

export const Balance = {
  liabilities: "Liabilities",
  info_p1: "Values obtained on {%DATE%} at {%TIME%}.",
  info_p2: "Swing to {%DATE%}",
  total: "Total",
  cashAndBankBalances: "Cash and bank balances",
  unpaid: "Unpaid",
  equity: "Equity",
  liabilities_plus_equity: "Liabilities + Equity",
  noValues: "No information available",
  accountTypes: {
    cash: "Cash",
    current: "Current accounts",
    savings: "Term accounts",
    reservation: "Reserve Fund",
  },
  ownersDebts: "Owners debts",
  extraRevenues: "Extra revenue to be received",
  ownersCredits: "Owners credits",
  unpayedExpenses: "Debts to suppliers",
  reservationFunds: "Reserve funds",
  noReservationFunds: "No reservation funds",
};

export const ExerciseExpenses = {
  noValues: "No information available",
  tableHeaders: {
    emission: "Emission",
    rubric: "Rubric",
    amount: "Amount ",
    balance: "Unpayed",
  },
};

export const Inquiries = {
  email: "Email",
  fillValidEmail: "Enter a valid email",
  EmailPopUpTitle: "Enter your email",
  noInquiries: "No inquiries",
  invalidInquirie: "The survey is no longer available",
  inquirieStatus: "Inquirie status: ",
  statusLabels: {
    closed: "Closed",
    waiting_opening: "Waiting opening",
    in_progress: "In progress",
  },
  openingDate: "Opening date: ",
  closingDate: "Closing date: ",
  fillInquirie: "Fill inquirie",
  consultInquirie: "Consult inquirie",
  checkSubmitedAnswers: "Consult answers given",
  reviewAnswers: "Review answers",
  viewResults: "Check results",
  openInquiries: "Ongoing inquiries",
  unfinishedInquiries: "Unfinished inquiries",
  allInquiries: "All inquiries",
  attachments: "Attachments",
  questions: "Questions",
  required: "Required answer",
  availableChars: "{%NUM%} available characters",
  selectOption: "Select an answer",
  previous: "Previous",
  next: "Next",
  continue: "Continue",
  submit: "Submit",
  ignore: "Ignore",
  answersFailed: "It was not possible to save your answers.",
  commentsFailed: "It was not possible to save your comments.",
  comments: "Final comments",
  cantSubmitEmptyComment: "You cannot submit an empty comment.",
  fillAllRequires: "Fill in all mandatory questions",
  final: "Process completion",
  finish: "Finish",
  errorOccurred: "An error occurred",
  successFinish: "Survey completed successfully",
  numberAnswers: "Number of answers",
  perThousand: "Per thousand",
  perUser: "Per user",
  participatingUsers: "Participantes",
  usersThatDidntRespond: "Non-participants",
  participations: "Participations",
  total: "Total: ",
  inquirieNotOpen: "The inquirie is not yet open to participation",
  inquirieWhenOpen: "You can complete the inquirie from {%START_DATE%}",
  resultsWillBeAvailable:
    "The results will be visible after the inquirie closes.",
  tyForParticipating1: "Thank you for participating",
  tyForParticipating2: "Your feedback is important to us.",
  inquirieValidBetweenDates:
    "Inquirie conducted between {%START_DATE%} and {%END_DATE%}",
  betweenDates1: "From {%START_DATE%} ",
  betweenDates2: "till {%END_DATE%}",
  backToInquirie: "Back to inquirie",
  invalidAccess:
    "Invalid access. Check if the email you entered matches the mailbox from which you got this access.",
};
