import { useEffect } from "react";

const default_client_id = "FC8320BD-6242-4E7A-B3D5-DE4F74A3B9A6";

const image = "logo_image";
const name = "company_name";
const clientId = "client_id";

function getClientId() {
  return sessionStorage.getItem(clientId);
}

function setClientId(id) {
  sessionStorage.setItem(clientId, id);
}

function setLogoImage(image_url) {
  sessionStorage.setItem(image, image_url);
}

function setCompanyName(company_name) {
  sessionStorage.setItem(name, company_name);
}

const SetClientId_Page = (props) => {
  const url = new URL(window.location.href); // GET URL
  const searchParams = new URLSearchParams(url.search); // GET PARAMS OF THE URL
  const client_id = searchParams.get("app"); // GET APP PARAM
  const client_id_in_storage = getClientId(); // CHECK CLIENT_ID WITHIN THE SESSION STORAGE
  //REMOVE THE APP GET PARAM
  window.history.replaceState &&
    window.history.replaceState(
      null,
      "",
      window.location.pathname +
        window.location.search.replace(/[\?&]app=[^&]+/, "").replace(/^&/, "?")
    );

  function setChecked() {
    props.setHasChecked();
  }

  useEffect(() => {
    if (client_id && client_id !== client_id_in_storage) {
      let apipath =
        "https://api.improxy.com/v1/ClientApplications/SubsidiariesFilter";
      if (
        window.location.hostname === "dev.gecond.com" ||
        window.location.hostname === "localhost"
      ) {
        apipath =
          "https://api.improxy.com/v1/ClientApplications/SubsidiariesFilter";
      }
      fetch(apipath, {
        method: "GET",
        headers: {
          client_id: client_id,
        },
      })
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (!result.hasOwnProperty("error")) {
            result = result[0];
            if (result.isMainSubsidiary) {
              setCompanyName(result.commercialName);
              if (result.logotype["url"]) {
                setLogoImage(result.logotype["url"]);
              } else {
                setLogoImage(null);
              }
              setClientId(client_id);
            } else {
              setLogoImage(null);
              setCompanyName(null);
              setClientId(default_client_id);
            }
          } else {
            setLogoImage(null);
            setCompanyName(null);
            setClientId(default_client_id);
          }
          setChecked();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      if (!client_id_in_storage || client_id_in_storage === "null") {
        setClientId(default_client_id);
      }
      setChecked();
    }
  });
  return null;
};
export default SetClientId_Page;
