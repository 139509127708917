import React, { Fragment } from "react";
import RecoverForm from "../components/forms/RecoverForm";
import { loginResources } from "../resources/resources";

const RecoverPage = (props) => {
  return (
    <Fragment>
      <div className="container full-height">
        <div className="row full-height justify-content-center align-items-center">
          <div className="col-lg-9">
            <div className="card-login">
              <div className="card-login__image"></div>
              <div className="flex-1">
                <div className="card-login-form">
                  <div className="card-header">
                    <div className="card-title">
                      <img src="/images/logo.png" alt="" width="175" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="login-welcome">
                      <span>{loginResources.title}</span>{" "}
                      {loginResources.recoverTitle}
                    </div>
                    <RecoverForm {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecoverPage;
