import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({ title, subtitle, children, subclass, onClose }) => {
  return (
    <div className={`card card-default ${subclass}`}>
      {title && (
        <div className="card-header ">
          <div className="card-title">{title}</div>
          {onClose && (
            <div className="card-close">
              <FontAwesomeIcon
                onClick={onClose}
                icon={faTimes}
              ></FontAwesomeIcon>
            </div>
          )}
        </div>
      )}
      <div className="card-body">
        {subtitle && <h5>{subtitle}</h5>}
        {children}
      </div>
    </div>
  );
};

export default Card;
