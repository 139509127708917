import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import BlogTable from "../components/tables/BlogTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const BlogPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.blog.breadcrumb} />
      <div className="container-fluid">
        <BlogTable></BlogTable>
      </div>
    </Master>
  );
};

export default BlogPage;
