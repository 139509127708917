import React, { Fragment } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import Pager from "./Pager";

export const Column = () => <Fragment></Fragment>;
export const Number = () => <Fragment></Fragment>;
export const Header = () => <Fragment></Fragment>;
export const Date = () => <Fragment></Fragment>;
export const DateTime = () => <Fragment></Fragment>;
export const Option = () => <Fragment></Fragment>;
export const NoData = (props) => props.children;
export const Loading = (props) => props.children;

Column.colType = "Column";
Number.colType = "Number";
Header.colType = "Header";
Date.colType = "Date";
DateTime.colType = "DateTime";
Option.colType = "Option";
NoData.colType = "NoData";
Loading.colType = "Loading";

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #f8f9fa;
  }

  ::-webkit-scrollbar {
    height: 0.8rem;
    background-color: #f8f9fa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
  }

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const headerProps = (props, { column }) => getStyles(props, column);

const cellProps = (props, { cell }) => getStyles(props, cell.column);

const footerProps = (props, a) => {
  return [
    props,
    {
      style: {
        boxSizing: "border-box",
        minWidth: a.column.minWidth,
        width: a.column.totalWidth,
      },
    },
  ];
};

const getStyles = (props, column) => [
  props,
  {
    className: column.className,
  },
];

const Table = (props) => {
  let {
    pager,
    sortBy,
    tag,
    onRowClick,
    data,
    disabledSort,
    open,
    screen,
    setDataReceipt,
  } = props;
  const _pageSize = !pager ? 0 : pager.pageSize ? pager.pageSize : 30;

  let currency_symbol = "";
  if (data && data.length > 0) {
    if (data[0].currency) {
      currency_symbol = " (" + data[0].currency.symbol + ")";
    }
  }
  if (data && data.currency && data.currency.symbol) {
    currency_symbol = " (" + data.currency.symbol + ")";
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  function BuildTable(table, paged, footer) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      page,
      rows,
      prepareRow,
    } = table;

    return (
      <Fragment>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(headerProps)}
                    className={
                      column.className ? "th " + column.className : "th"
                    }
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {currency_symbol != "" &&
                        column.Header_has_currency &&
                        currency_symbol}
                      <span className="sort-table">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                            ></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon icon={faSortUp}></FontAwesomeIcon>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(paged ? page : rows).map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={(e) => {
                    if (onRowClick) onRowClick(e, row);
                  }}
                  className="tr"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps(cellProps)}
                        className={
                          cell.column.className
                            ? "td " + cell.column.className
                            : "td"
                        }
                      >
                        <span>{cell.render("Cell")}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {footer && (
            <tfoot className="tfoot">
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()} className="tr">
                  {footerGroup.headers.map((column) => (
                    <td
                      {...column.getFooterProps(footerProps)}
                      className={
                        column.className ? "td " + column.className : "td"
                      }
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </Fragment>
    );
  }

  const RenderTableWithPager = ({ columns, data, footer }) => {
    const _table = useTable(
      {
        columns,
        data,
        defaultColumn,
        tag: tag,
        disableSortBy: disabledSort,
        initialState: {
          pageIndex: pager.pageIndex ? pager.pageIndex : 0,
          pageSize: _pageSize,
          sortBy: sortBy ? sortBy : [],
        },
      },
      useSortBy,
      usePagination
    );
    const {
      state: { pageIndex, pageSize },
    } = _table;

    return (
      <Fragment>
        {BuildTable(_table, true, footer)}
        <Pager {..._table} pageIndex={pageIndex} pageSize={pageSize}></Pager>
      </Fragment>
    );
  };

  function RenderTable({ columns, data, footer }) {
    return BuildTable(
      useTable(
        {
          columns,
          data,
          tag: tag,
          defaultColumn,
          disableSortBy: disabledSort,
          initialState: {
            sortBy: sortBy ? sortBy : [],
          },
        },
        useSortBy
      ),
      false,
      footer
    );
  }

  let _NoData;
  let _Loading;
  let _Footer = false;

  const getColumns = (children) => {
    var moment = require("moment");
    let columns = [];
    children.map((child, i) => {
      switch (child.type.colType) {
        case "NoData":
          _NoData = child.props.children;
          break;
        case "Loading":
          if (child.props.value) _Loading = child.props.children;
          break;
        case "Column":
          columns.push({
            ...child.props,
          });
          break;
        case "Number":
          let _column = {
            ...child.props,
            align: child.props.align ? child.props.align : "right",
            className: child.props.className
              ? child.props.className + " tableNumber"
              : "tableNumber",
            Cell: (props) => (
              <span>
                {props.value && props.value !== null
                  ? formatNumber(
                      toFixed(
                        parseFloat(props.value.toString().replace(",", ".")),
                        2
                      )
                    )
                  : "0.00"}
              </span>
            ),
          };

          if (child.props.Total) {
            _column.Footer = (info) => {
              let total = 0;

              info.rows.forEach((row) => {
                total =
                  total +
                  parseFloat(
                    row.values[child.props.accessor]
                      .toString()
                      .replace(",", ".")
                  );
              });

              return <Fragment>{formatNumber(toFixed(total, 2))}</Fragment>;
            };
          }
          columns.push(_column);
          break;

        case "Date":
          columns.push({
            ...child.props,
            align: child.props.align ? child.props.align : "center",
            className: child.props.className
              ? child.props.className + " tableDate"
              : "tableDate",

            Cell: (props) => (
              <span>
                {!props.value ? "" : moment(props.value).format("DD-MM-YYYY")}
              </span>
            ),
          });
          break;

        case "DateTime":
          columns.push({
            ...child.props,
            align: child.props.align ? child.props.align : "center",
            className: child.props.className
              ? child.props.className + " tableDate"
              : "tableDate",
            Cell: (props) => {
              return (
                <span>
                  {props.value && props.value === null
                    ? ""
                    : moment(props.value).format("DD-MM-YYYY")}
                </span>
              );
            },
          });
          break;

        case "Option":
          columns.push({
            className: "collapse",
            Header: () => null,
            ...child.props,
          });
          break;

        case "Header":
          columns.push({
            Header: child.props.name,
            columns: getColumns(child.props.children),
            Footer: child.props.Footer,
          });
          break;
        default:
          break;
      }

      if (child.props.desc !== undefined && child.props.desc !== null) {
        if (!sortBy) sortBy = [];
        sortBy.push({
          id: child.props.accessor,
          desc: child.props.desc,
        });
      }

      if (
        !_Footer &&
        child.props.Footer !== undefined &&
        child.props.Footer !== null
      )
        _Footer = true;
    });
    return columns;
  };

  let column_values = getColumns(props.children);
  const columns = React.useMemo(() => column_values, []);

  if (_Loading) {
    return _Loading;
  } else {
    if (data && data.length > 0) {
      return (
        <Styles>
          {pager && _pageSize < data.length && (
            <RenderTableWithPager
              columns={columns}
              data={data}
              footer={_Footer}
            />
          )}
          {(!pager || _pageSize >= data.length) && (
            <RenderTable columns={columns} data={data} footer={_Footer} />
          )}
        </Styles>
      );
    } else {
      if (_NoData) {
        return _NoData;
      } else {
        return <Fragment></Fragment>;
      }
    }
  }
};

export default Table;
