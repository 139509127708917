import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { UserDetail, UserSettings } from "../../actions/user";
import { GetDashboard } from "../../actions/dashboard";
import { GetAssemblies } from "../../actions/votes";
import { GetInquiriesDispatch } from "../../actions/inquiries";
import { globalResources, navigationResources } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { toSelectOptions } from "../../assets/FormatAndParse";
import { SET_DEFAULT_CONDOMINIUM } from "../../actions/types";
import { LogOut } from "../../storage/token";
import ReactTooltip from "react-tooltip";
import { Navigation as navigationResources_pt } from "../../resources/pt";
import { Navigation as navigationResources_en } from "../../resources/en";
import { getStoreRestrictedPages } from "../../storage/restricted_pages";

const Header = ({ history }) => {
  const user = useSelector((state) => state.user);
  const inquiries = useSelector((state) => state.inquiries);
  const userSettings = useSelector((state) => state.userSettings);
  const condominium = useSelector((state) => state.user.current_condominium);
  const dispatch = useDispatch();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      opacity: 1,
      zIndex: 9999,
      background: state.isSelected
        ? "rgba(44, 179, 74, 0.6) !important"
        : "#ffffff",
      color: state.isSelected ? "#ffffff" : "#353f4d",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      zIndex: 99999,
    }),
  };
  
  // CHECK IF CONDOMINIUM HAS PERMISSION TO USE THAT PAGE
  useEffect(() => {
    if (condominium && condominium.gecondComSettings) {
      let showFractionsDebts = condominium.gecondComSettings.showFractionsDebts;
      let showOccurrences = condominium.gecondComSettings.showOccurrences;
      let showBankBalances = condominium.gecondComSettings.showBankBalances;
      let showBudgetAnalysis = condominium.gecondComSettings.showBudgetAnalysis;
      let showExerciseBudget = condominium.gecondComSettings.showExerciseBudget;
      let showExerciseExpenses = condominium.gecondComSettings.showExerciseExpenses;
      let showBalance = condominium.gecondComSettings.showBalance;
      let showAssemblies = condominium.gecondComSettings.showAssemblies;
      let path = window.location.pathname;
      if(window.location.pathname.slice(-1)==="/") path=path.substring(0, path.length-1);
      switch(path){
        case navigationResources_pt.fractionsDebts.path:                                                  // FRACTIONS DEBTS
        case navigationResources_en.fractionsDebts.path:
          if(!showFractionsDebts)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.occurrences.path:                                                     // OCCURRENCES
        case navigationResources_en.occurrences.path:
          if(!showOccurrences)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.bankBalances.path:                                                    // BANK BALANCES
        case navigationResources_en.bankBalances.path:
          if(!showBankBalances)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.budgetAnalysis.path:                                                  // BUDGET ANALYSIS
        case navigationResources_en.budgetAnalysis.path:
          if(!showBudgetAnalysis)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.exerciseBudget.path:                                                  // EXERCISE BUDGET
        case navigationResources_en.exerciseBudget.path:
          if(!showExerciseBudget)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.exerciseExpenses.path:                                                // EXERCISE EXPENSES
        case navigationResources_en.exerciseExpenses.path:
          if(!showExerciseExpenses)  history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.balance.path:                                                         // BALANCE
        case navigationResources_en.balance.path:
          if(!showBalance) history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.blog.path:                                                            // BLOG
        case navigationResources_en.blog.path:
          if(!userSettings || userSettings.isBlogEditor!==true) history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.votings.path:                                                            // ASSEMBLIES
        case navigationResources_en.votings.path:
          if(!showAssemblies) history.push(navigationResources_pt.dashboard.path);
          break;
        case navigationResources_pt.user.path:                                                              // USER
        case navigationResources_en.user.path:
          if(getStoreRestrictedPages()==="profile") history.push(navigationResources_pt.dashboard.path);
          break;
      }
    }
  }, [condominium]);

  // ON START

  useEffect(() => {
    if (!user.detail) {
      dispatch(UserDetail());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userSettings) {
      dispatch(UserSettings());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (condominium) {
      dispatch(GetDashboard(condominium.id));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  useEffect(() => {
    if (condominium && condominium?.gecondComSettings?.showAssemblies) {
      dispatch(GetAssemblies(condominium.id));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  useEffect(() => {
    if (!inquiries) {
      dispatch(GetInquiriesDispatch(true, 1, 1));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderUser() {
    if (user.detail) {
      return (
        <Link to={navigationResources.user.path}/*"/user"*/>
          <div className="d-flex user-profile align-items-center">
            <div className="user-profile__name">{user.detail.name}</div>
            <div
              className="user-profile__thumbnail"
              style={{
                backgroundImage: `url('${
                  user.detail.profileImage.url
                }?v=${new Date().getTime()}')`,
              }}
            ></div>
          </div>
        </Link>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  let condominium_data = [];
  if (user.current_condominium)
    condominium_data = toSelectOptions(user.condominiums, "id", "name");

  let showOccurrences=false;
  if(condominium && condominium.gecondComSettings){
    showOccurrences = condominium.gecondComSettings.showOccurrences;
  }

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col">
            <div className="condo-profile">
              {condominium && (
                <div className="condo-profile__name align-items-center">
                  <div>{(user.condominiums && user.condominiums.length >1) ? 
                    globalResources.chooseCondominium : globalResources.condominium} </div>
                  <div className="flex-1">
                    {user.condominiums && user.condominiums.length > 1 && (
                      <Select
                        className="condo-profile__select"
                        classNamePrefix="condo-profile__select"
                        styles={customStyles}
                        options={condominium_data}
                        value={condominium_data.filter(
                          (option) =>
                            option.value == user.current_condominium.id
                        )}
                        onChange={(cond) => {
                          dispatch({
                            type: SET_DEFAULT_CONDOMINIUM,
                            id: cond.value,
                          });
                        }}
                      />
                    )}
                    {user.condominiums && user.condominiums.length === 1 && (
                      <div className="condo-profile__single">
                        {user.current_condominium.name}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-auto header-right">
            <div className="d-flex align-items-center">
              {/*(condominium && showOccurrences) && (
                <div className="condo-occurrencies">
                  <Link to={navigationResources.occurrences.path_edit}/>
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                    {globalResources.new}{" "}
                    <span>{globalResources.occurrence}</span>
                  </Link>
                </div>
              )*/}
              <ul className="d-flex notifications">
                {condominium && (
                  <li>
                    <Link
                      to={navigationResources.messages.path}/*"/messages"*/
                      data-tip={globalResources.seeAllMessages}
                    >
                      <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                    </Link>
                  </li>
                )}
              </ul>
              <div className="">{renderUser()}</div>
              <div className="sign-out">
                <div onClick={LogOut} data-tip={globalResources.signOut}>
                  <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip className="tooltip" place="bottom" effect="solid" />
      </div>
    </div>
  );
};

export default withRouter(Header);
