import React, { useState, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  navigationResources,
  globalResources,
} from "../../resources/resources";
import { LogOut } from "../../storage/token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import {
  faUser,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCompass,
  faFileAlt,
  faEnvelope,
  faFolderOpen,
  faMoneyBillAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFile,
  faSignOutAlt,
  faVoteYea,
  faUniversity,
  faChartBar,
  faChartArea,
  faBalanceScale,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { client_id, default_client_id } from "../../assets/config";
import { getLogoImage, getCompanyName } from "../../storage/company_details";
import { isTodayBetweenDates } from "../../assets/utils.js";
import { getStoreRestrictedPages } from "../../storage/restricted_pages";

const SideMenu = () => {
  const [menuCollapse, setMenuCollapse] = useState("");
  const dashboard = useSelector((state) => state.dashboard);
  const inquiries = useSelector((state) => state.inquiries);
  const user = useSelector((state) => state.user);
  const userSettings = useSelector((state) => state.userSettings);
  const condominium = useSelector((state) => state.user.current_condominium);
  const votes = useSelector((state) => state.votes);
  const [numOpenAssemblies, setNumOpenAssemblies] = useState(0);

  useEffect(() => {
    if (votes && votes.length > 0) {
      let count = 0;
      votes.map((item) => {
        if (
          isTodayBetweenDates(
            item.onlineVotingStartDate,
            item.onlineVotingEndDate
          )
        ) {
          count++;
        }
      });
      setNumOpenAssemblies(count);
    } else {
      setNumOpenAssemblies(0);
    }
  }, [votes]);

  const toggleMenuCollapse = () => {
    if (menuCollapse === "active") {
      setMenuCollapse("");
    } else {
      setMenuCollapse("active");
    }
  };

  const renderLogo = () => {
    if (client_id() !== default_client_id && getLogoImage() !== "null") {
      return <img src={getLogoImage()} alt={getCompanyName()} height="60" />;
    } else {
      if (user && user.detail && user.detail.applicationTheme) {
        switch (user.detail.applicationTheme.value) {
          case 1:
            return (
              <img src={globalResources.gecondLogoPath} alt="" height="40" />
            );
          case 2:
            return (
              <img
                src={globalResources.gecondLogoWhitePath}
                alt=""
                height="40"
              />
            );
        }
      }
      return <img src={globalResources.gecondLogoPath} alt="" height="40" />;
    }
  };

  let showFractionsDebts = false;
  let showOccurrences = false;
  let showBankBalances = false;
  let showBudgetAnalysis = false;
  let showExerciseBudget = false;
  let showBalance = false;
  let showExerciseExpenses = false;
  let showAssemblies = false;
  if (condominium && condominium.gecondComSettings) {
    showFractionsDebts = condominium.gecondComSettings.showFractionsDebts;
    showOccurrences = condominium.gecondComSettings.showOccurrences;
    showBankBalances = condominium.gecondComSettings.showBankBalances;
    showBudgetAnalysis = condominium.gecondComSettings.showBudgetAnalysis;
    showExerciseBudget = condominium.gecondComSettings.showExerciseBudget;
    showBalance = condominium.gecondComSettings.showBalance;
    showExerciseExpenses = condominium.gecondComSettings.showExerciseExpenses;
    showAssemblies = condominium.gecondComSettings.showAssemblies;
  }

  return (
    <nav className={`page-sidebar ${menuCollapse}`}>
      <div className="toggle-menu" onClick={toggleMenuCollapse}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="sidebar-header">{renderLogo()}</div>
      <div className="sidebar-menu">
        <div className="menu-items">
          <ul className="menu-items scrollbar">
            <li>
              <NavLink
                to={navigationResources.dashboard.path}
                /*"/"*/ exact
                activeClassName="selected"
              >
                <div className="menu-item">
                  <div>
                    <span className="title">
                      {navigationResources.dashboard.link}
                    </span>
                  </div>
                  <div className="icon-thumbnail">
                    <FontAwesomeIcon icon={faCompass}></FontAwesomeIcon>
                  </div>
                </div>
              </NavLink>
            </li>
            {user && user.current_condominium && (
              <Fragment>
                {showAssemblies && (
                  <li>
                    <NavLink
                      to={navigationResources.votings.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.votings.link}
                          </span>
                          {numOpenAssemblies > 0 && (
                            <span className="red-circle">
                              {numOpenAssemblies}
                            </span>
                          )}
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon icon={faVoteYea}></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to={navigationResources.inquiries.path}
                    activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.inquiries.link}
                        </span>
                        {inquiries && inquiries.totalResults > 0 && (
                          <span className="red-circle green">
                            {inquiries.totalResults}
                          </span>
                        )}
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faWpforms}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={navigationResources.messages.path_condominium} //"/condominium_messages"
                    activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.messages.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                {showOccurrences && (
                  <li>
                    <NavLink
                      to={navigationResources.occurrences.path}
                      /*"/occurrences"*/ activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.occurrences.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to={navigationResources.condominium.path}
                    /*"/condominium"*/ activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.condominium.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={navigationResources.documents.path} //"/documents"
                    className="detailed"
                    activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.documents.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={navigationResources.notices.path}
                    /*"/notices"*/ activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.notices.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={navigationResources.receipts.path}
                    /*"/receipts"*/
                /* activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.receipts.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to={navigationResources.currentAccounting.path}
                    activeClassName="selected"
                  >
                    <div className="menu-item">
                      <div>
                        <span className="title">
                          {navigationResources.currentAccounting.link}
                        </span>
                      </div>
                      <div className="icon-thumbnail">
                        <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                      </div>
                    </div>
                  </NavLink>
                </li>
                {showExerciseBudget && (
                  <li>
                    <NavLink
                      to={navigationResources.exerciseBudget.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.exerciseBudget.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon icon={faChartArea}></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {showBudgetAnalysis && (
                  <li>
                    <NavLink
                      to={navigationResources.budgetAnalysis.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.budgetAnalysis.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon icon={faChartBar}></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {showFractionsDebts && (
                  <li>
                    <NavLink
                      to={navigationResources.fractionsDebts.path}
                      /*"/fractions_debts"*/ activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.fractionsDebts.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon
                            icon={faMoneyBillAlt}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {showBankBalances && (
                  <li>
                    <NavLink
                      to={navigationResources.bankBalances.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.bankBalances.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon
                            icon={faUniversity}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {showBalance && (
                  <li>
                    <NavLink
                      to={navigationResources.balance.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.balance.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon
                            icon={faBalanceScale}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {showExerciseExpenses && (
                  <li>
                    <NavLink
                      to={navigationResources.exerciseExpenses.path}
                      activeClassName="selected"
                    >
                      <div className="menu-item">
                        <div>
                          <span className="title">
                            {navigationResources.exerciseExpenses.link}
                          </span>
                        </div>
                        <div className="icon-thumbnail">
                          <FontAwesomeIcon icon={faCoins}></FontAwesomeIcon>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
              </Fragment>
            )}
            {getStoreRestrictedPages() !== "profile" && (
              <li>
                <NavLink
                  to={navigationResources.user.path}
                  /*"/user"*/ activeClassName="selected"
                >
                  <div className="menu-item">
                    <div>
                      <span className="title">
                        {navigationResources.user.link}
                      </span>
                    </div>
                    <div className="icon-thumbnail">
                      <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {userSettings && userSettings.isBlogEditor === true && (
              <li className="m-t-xl">
                <NavLink
                  to={navigationResources.blog.path}
                  activeClassName="selected"
                >
                  <div className="menu-item">
                    <div>
                      <span className="title">
                        {navigationResources.blog.link}
                      </span>
                    </div>
                    <div className="icon-thumbnail">
                      <FontAwesomeIcon icon={faFileAlt}></FontAwesomeIcon>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            <li className="menu-item-sign-out">
              <button onClick={LogOut}>
                <div className="menu-item">
                  <div>
                    <span className="title">{globalResources.signOut}</span>
                  </div>
                  <div className="icon-thumbnail">
                    <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;
