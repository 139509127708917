import { apiAuthRequest } from "../server/api";

export function SaveOccurrence(condomium_id, occurrence) {
  let path = `v1/Occurrences/${condomium_id}`;
  if (occurrence.id > 0) path = path + "/" + occurrence.id;

  return apiAuthRequest({
    url: path,
    data: {
      subject: occurrence.subject,
      description: occurrence.description,
    },
    method: occurrence.id > 0 ? "PATCH" : "POST",
  });
}

export function SaveAttachment(condomium_id, occurrence_id, attachment) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}/${occurrence_id}/Attachments`,
    data: attachment,
    method: "POST",
  });
}

export function RemoveAttachment(condomium_id, occurrence_id, name) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}/${occurrence_id}/Attachments`,
    data: {
      name: name,
    },
    method: "DELETE",
  });
}

export function RemoveOccurrence(condomium_id, id) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}/${id}`,
    method: "DELETE",
  });
}

export function RateOccurrence(condomium_id, id, ratingValue) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}/${id}/Rating/${ratingValue}`,
    method: "POST",
  });
}

export function GetOccurrences(condomium_id) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}`,
  });
}

export function GetDetail(condomium_id, id) {
  return apiAuthRequest({
    url: `v1/Occurrences/${condomium_id}/${id}`,
  });
}
