import React from "react";
import { Link } from "react-router-dom";
import { globalResources } from "../../resources/resources";
import { getCompanyName } from "../../storage/company_details";
import { client_id, default_client_id } from "../../assets/config";
import ScriptTag from "react-script-tag";
import { navigationResources } from "../../resources/resources";

const Footer = () => {
  return (
    <footer className="footer-website">
      <div className="container">
        <div className="row">
          <div className="col-sm-auto">
            {client_id() === default_client_id ? (
              <div>
                <span className="op-8">Copyright © 2021 </span>
                <span>{globalResources.gecondmycondominium}. </span>
                <span className="op-8">{globalResources.rightsReserved} </span>
              </div>
            ) : (
              <div>
                <span className="op-8">{getCompanyName()}</span>
              </div>
            )}
            <div className="p-t-m">
              <span id="ss_img_wrapper_115-55_image_en">
                <a
                  href="http://www.alphassl.com/ssl-certificates/wildcard-ssl.html"
                  target="_blank"
                  title="SSL Certificates"
                >
                  <img
                    alt="Wildcard SSL Certificates"
                    border="0"
                    id="ss_img"
                    src="//seal.alphassl.com/SiteSeal/images/alpha_noscript_115-55_en.gif"
                    title="SSL Certificate"
                  />
                </a>
              </span>
              <ScriptTag
                type="text/javascript"
                src="//seal.alphassl.com/SiteSeal/alpha_image_115-55_en.js"
              />
            </div>
          </div>
          {client_id() === default_client_id && (
            <div className="col-sm-auto">
              <Link to={navigationResources.terms_of_use.path}/*"/terms-of-use"*/ className="m-l-s m-r-s">
                {globalResources.terms}
              </Link>
              <span>|</span>
              <Link to={navigationResources.privacy_policy.path}/*"/privacy-policy"*/ className="m-l-s m-r-s">
                {globalResources.privacyPolicy}
              </Link>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
