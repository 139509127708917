import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { getBase64, isFileImage } from "../../assets/utils";
import "react-dropzone-uploader/dist/styles.css";
import Resizer from "react-image-file-resizer";

const FilePicker = (props) => {
  const {
    label,
    onUpload,
    onLoading,
    failUpload,
    maxWidth,
    maxHeight,
    disabled,
    accept,
    buttonText,
    className,
  } = props;
  const [formInput, setInput] = useState(null);

  const handleChangeStatus = (file, status) => {
    if (status === "preparing" && onLoading) {
      onLoading()
    }
    if (status === "headers_received") {
      if (isFileImage(file.file) && (maxWidth || maxHeight)) {
        Resizer.imageFileResizer(
          file.file,
          maxWidth,
          maxHeight,
          "JPEG",
          100,
          0,
          (base64) => {
            onUploadEvent(base64.split(",")[1], file);
          },
          "base64"
        );
      } else {
        getBase64(
          file.file,
          (base64) => {
            onUploadEvent(base64, file);
          },
          (error) => {
            if (failUpload) failUpload(error);
          }
        );
      }
    } else if (status === "aborted") {
      if (failUpload) failUpload();
    }
  };

  const RenderLabel = ({ children }) => {
    if (label) {
      return (
        <div className="form-group">
          <label>{label}</label>
          {children}
        </div>
      );
    } else {
      return children;
    }
  };

  const onUploadEvent = (image, file) => {
    if (onUpload) onUpload(image, file);
    if (formInput) formInput.onChange(image);
  };

  let inputUpdate = null;
  useEffect(() => {
    if (inputUpdate !== null) {
      setInput(inputUpdate);
      inputUpdate = null;
    }
  });

  return (
    <div className="file-picker">
      <RenderLabel>
        <Dropzone
          key="file_dropzone"
          getUploadParams={() => ({ url: "https://httpbin.org/post" })}
          onChangeStatus={handleChangeStatus}
          canCancel={false}
          multiple={false}
          disabled={disabled}
          PreviewComponent={null}
          SubmitButtonComponent={null}
          inputContent={
            <div key="file-zone" className={className ? className : "btn btn-primary"} type="button">
              {buttonText}
            </div>
          }
          accept={accept}
        />
      </RenderLabel>
    </div>
  );
};

export default FilePicker;
