import { GET_DASHBOARD } from "../actions/types";

const resourcesReducerDefaultState = {
  list: [],
};

export default (state = resourcesReducerDefaultState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return action.dashboard;

    default:
      return state;
  }
};
