import React, { Fragment, useState, useEffect } from "react";
import LoginForm from "../components/forms/LoginForm";
import Footer from "../components/website/Footer";
import Navbar from "../components/elements/Navbar";
import { ForCondominiums } from "../templates/forcondominiums";
import { ForOwners } from "../templates/forowners";
import {
  websiteResources,
  loginResources,
  getCurrent,
  setLanguage,
  globalResources,
  navigationResources,
} from "../resources/resources";
import { InstallPWA } from "../InstallPWA/InstallPWA";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { client_id, default_client_id } from "../assets/config";
import Card from "../components/containers/Card";
import { number } from "../assets/forms/validation";
import IsLoading from "../components/elements/IsLoading";
import moment from "moment";
import { GetDetail, GetBlog } from "../actions/blog";
import { rewriteName } from "../assets/utils";

const HomePage_blog = (props) => {
  const blogValues_labels=websiteResources.blogText.blogValues;
  const [blog, setBlog]=useState((props.location && props.location.state && props.location.state.blog) ? props.location.state.blog : undefined);
  const [article, setArticle]=useState();
  let _lang = getCurrent();
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);
  const articlesPerPage = 3;
  let path = window.location.pathname.replace(/[/]/g, 'message');
  path = path.split("-");
  const detail=path[path.length-1];

  useEffect(()=>{
    if(detail>0){
      GetDetail(detail).then((response) => response.json())
      .then(result => {
        setArticle(result);
      })
      .catch(e => {setArticle(null)});
    }
  },[detail]);

  useEffect(() => {
    if(client_id()===default_client_id && blog===undefined){
      GetBlog().then((response) => response.json())
      .then(result => {
        setBlog(result);
      })
      .catch(e => {setBlog([])});
    }
  }, [blog]);

  useEffect(() => {
    if (lang && lang.split("-")[0] !== getCurrent()) {
      //REPLACE URL
      if (article && article.texts){
        const texts = (article.texts && article.texts.length>0) ? article.texts.filter(item=> item.culture===lang) : undefined;
        let name_rewrite = rewriteName(texts[0].title);
        window.history.replaceState && window.history.replaceState(
          null, '',  name_rewrite+"-"+article.id
        );
      }
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  function renderArticles(){
    var moment = require("moment");
    const articlesBlog = blog.slice(0, articlesPerPage+1);
    let count=0;
    return(
      <div>
        {articlesBlog.map((item, index) => {
          if(item.id===article.id || count>=3) return null;
          else count++;
          const texts = (item.texts && item.texts.length>0) ? item.texts.filter(item=> item.culture===blogValues_labels.culture) : undefined; 
          let name_rewrite = rewriteName(texts[0].title);
          return(
              <div key={item.id}>
                  <a className="cur-point" onClick={()=>{props.history.push(`/blog/${name_rewrite+"-"+item.id}`, { blog: blog })}}>
                    <div className="other-articles-image-container">
                      <div style={{ backgroundImage: `url(${(item.image && item.image.url) ? item.image.url : "/images/blog-banner.jpg"})` }} className="article-image"/>
                      {item.creationDate && <span className="article-date">{moment(item.creationDate).format("DD-MM-YYYY")}</span>}
                    </div>
                  </a>
                  {(texts && texts.length>0) && 
                    <div className="other-articles-text-container">
                      <h2 className="title">{texts[0].title}</h2>
                    </div>
                  }
                  <div className="m-t-s m-b-xxl text-center">
                    <button
                        className="btn btn-primary"
                        onClick={()=>{props.history.push(`/blog/${name_rewrite+"-"+item.id}`, { blog: blog })}}
                    >{websiteResources.blogText.readMore}</button>
                  </div>
              </div>
          );
        })}
      </div>
    );
  }

  const texts = (article && article.texts && article.texts.length>0) ? article.texts.filter(item=> item.culture===blogValues_labels.culture) : undefined; 
  
  return article ? (
    <Fragment>
      <Navbar
        onLanguageChange={(language) => {
            setLang(language);
        }}
        onModeChange={(newMode)=>{
            newMode===0 && props.history.push(navigationResources.dashboard.path)
            newMode===1 && props.history.push(navigationResources.blog.path)
        }}
        nameMode0={(client_id()===default_client_id) ? websiteResources.home : ""}
        nameMode1={(client_id()===default_client_id) ? websiteResources.blog : ""}
        mode={(client_id()===default_client_id) ? 1 : ""}
      />
     <div className="banner">
        <div className="banner-item-blog" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${(article.image && article.image.url) ? article.image.url : "/images/blog-banner.jpg"})` }}>
          {(texts && texts.length>0 && texts[0].title) &&
            <span>
              <p>{texts[0].title}</p>
            </span>
          }
        </div>
        <Card subclass="blog-container m-b-0" >
          <div className="container">
            <p className="blog-breadcrumb" >
              <a className="active" onClick={()=>{props.history.push(navigationResources.dashboard.path)}}>{websiteResources.home}</a> 
              <a>{'>'}</a> 
              <a className="active" onClick={()=>{props.history.push(navigationResources.blog.path)}}>{websiteResources.blog}</a>
              {(texts && texts.length>0) &&
                <Fragment>
                  <a>{'>'}</a> 
                  <a >{texts[0].title}</a>
                </Fragment>
              }
            </p>
            <div className="article-page-container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="article-image-container">
                    <div style={{ backgroundImage: `url(${(article.image && article.image.url) ? article.image.url : "/images/blog-banner.jpg"})` }} className="article-image"/>
                    {article.creationDate && <span className="article-date">{moment(article.creationDate).format("DD-MM-YYYY")}</span>}
                  </div>
                  {(texts && texts.length>0) &&
                    <div className="article-text-container">
                      {texts[0].title && <h2 className="title">{texts[0].title}</h2>}
                      {texts[0].introduction && <p className="introduction">{texts[0].introduction}</p>}
                      {texts[0].description && <p className="description">{texts[0].description}</p>}
                    </div>
                  }
                </div>
                {(article && article.id && blog && blog.length>0) &&
                  <div className="col-lg-4">
                    <div className="other-articles-container">
                      {renderArticles()}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </Card>
      </div>
    <Footer />
    </Fragment>
  )
  :
  (<Card>
    <IsLoading text={websiteResources.loading} />
  </Card>);
};

export default HomePage_blog;
