import React, { useState, Fragment } from "react";
import List from "../elements/List";
import { GetMessages, GetMessage } from "../../actions/messages";
import Card from "../containers/Card";
import { MessagesResources } from "../../resources/resources";
import MessageDetail from "../details/MessageDetail";
import { useSelector } from "react-redux";
import { notifyError } from "../../components/elements/Notify";

const MessagesList = ({ filterCondominium = false }) => {
  const [resources] = useState(MessagesResources);
  const statusTypes = {
    any: "anyStatus",
    read: "onlyRead",
    unread: "onlyUnread",
  };
  const [status, setStatus] = useState(statusTypes.any);
  const [message, setMessage] = useState();
  const condominium = useSelector((state) => state.user.current_condominium);
  var moment = require("moment");

  const getDetail = (selected) => {
    if (!message || message.id !== selected.id)
      GetMessage(selected.id, selected.condominiumId)
        .then((response) => {
          setMessage(response.data);
        })
        .catch(() => {
          notifyError(resources.local.failDetail);
        });
  };

  const renderList = () => {
    return (
      <Card title={resources.local.title} subclass="card-messages-list">
        <div className="only-unread-messages">
          <div className={`checkbox check-success active`}>
            <input
              type="checkbox"
              defaultChecked={status === statusTypes.unread}
              onChange={(e) => {
                setStatus(
                  e.target.checked ? statusTypes.unread : statusTypes.any
                );
              }}
            />
            <label>{resources.local.unread}</label>
          </div>
        </div>
        <List
          nodata={<div className="message">{resources.local.noMessages}</div>}
          databinder={(page, pageSize, success) => {
            GetMessages(
              page,
              pageSize,
              status,
              filterCondominium ? condominium.id : undefined
            )
              .then((response) => {
                success(response.data.itemsPage, response.data.totalItems);
              })
              .catch(() => {
                notifyError(resources.local.failList);
              });
          }}
          filters={status}
          render={(_message) => {
            return (
              <div
                key={_message.id}
                className={`message ${_message.wasReadByMe ? "" : "unread"} ${
                  message && message.id === _message.id ? "active" : ""
                }`}
                onClick={() => {
                  return getDetail(_message);
                }}
              >
                <div className="message-date">
                  {moment.utc(_message.date).format("DD-MM-YYYY hh:mm")}
                </div>
                {!filterCondominium && (
                  <div className="message-condo">
                    {_message.condominiumName}
                  </div>
                )}
                <div className="message-subject">{_message.subject}</div>
              </div>
            );
          }}
        />
      </Card>
    );
  };

  if (!filterCondominium || condominium) {
    return (
      <div className="row">
        <div className="col-md-auto messages-list-container">
          {renderList()}
        </div>
        <div className="col-md messages-detail-container">
          <div className="row justify-content-center">
            <div className="col">
              {message ? (
                <MessageDetail
                  onClose={() => {
                    setMessage(undefined);
                  }}
                  message={message}
                ></MessageDetail>
              ) : (
                <h1>{resources.local.noSelection}</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

export default MessagesList;
