import { apiAuthRequest } from "../server/api";

const url = "https://devdigsign.improxy.com";

export function SendCMDdata(data) {
  return apiAuthRequest({
    urlComplete: `${url}/certificate-v3`,
    data: data,
    contentType: "multipart/form-data",
    method: "POST",
  });
}

export function ForceSMS(data) {
  return apiAuthRequest({
    urlComplete: `${url}/forceSMS`,
    data: data,
    contentType: "multipart/form-data",
    method: "POST",
  });
}

export function ValidateOTP(data) {
  return apiAuthRequest({
    urlComplete: `${url}/otp-v3`,
    data: data,
    contentType: "multipart/form-data",
    method: "POST",
  });
}

export function GetPublicKey(data) {
  return apiAuthRequest({
    urlComplete: `${url}/certificate`,
    data: data,
    method: "GET",
  });
}
