import React, { useState, Fragment, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { required } from "../../assets/forms/validation";
import { RenderInput, RenderTextarea, RenderRadio } from "../../assets/forms/render";
import { useSelector } from "react-redux";
import { RemoveFromArray } from "../../assets/utils";
import {
  BlogTableResources,
  globalResources,
} from "../../resources/resources";
import Card from "../../components/containers/Card";
import FilePicker from "../../components/elements/FilePicker";
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { faTimes, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  notifySuccess,
  notifyError,
  notifyWarning,
} from "../../components/elements/Notify";
import IsLoading from "../elements/IsLoading";
import { GetDetail, SaveArticle } from "../../actions/blog";

const BlogForm = (props) => {
  const [article, setArticle]=useState({
    id: 0,
    status: "false",
    texts: [
      {
        culture: "en-GB",
        title: null,
        introduction: null,
        description: null
      },
      {
        culture: "pt-PT",
        title: null,
        introduction: null,
        description: null
      }
    ],
    image: null
  });
  const { after, id } = props;
  const [loadingImage, setLoadingImage] = useState(false);
  const [resources] = useState(BlogTableResources);
  const [newImage, setNewImage] = useState();
  const condominium = useSelector((state) => state.user.current_condominium);

  useEffect(()=>{
    if(id>0){
      GetDetail(id).then((response) => response.json())
      .then(result => {
        result.status = result.status ? "true" : "false"
        setArticle(result);
      })
      .catch(e => {setArticle(null)});
    }
  },[id]);

  // USED TO ENSURE THAT WHEN CHANGING CONDOMINIUMS IT GOES BACK TO THE BLOG LIST
  useEffect(() => {
    if (condominium && article && article.id>0) {
      after();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);


  const onSubmit = async (values, form) => {
    values.status=(values.status == "true") ? true : false;
    if(newImage && newImage.base64ContentBytes){
      values.imageName=newImage.name;
      values.imageBase64ContentBytes=newImage.base64ContentBytes;
    }
    SaveArticle(values).then(response => response.text())
    .then((result) => {
      if(result.error){
        notifyError(resources.local.detail.fail);
      }
      else{
        notifySuccess(resources.local.detail.success);
        after(true);
      }
    })
    .catch((e) => {
      notifyError(resources.local.detail.fail);
    });
  }

  const onUpload = (base64, file) => {
    setNewImage({
      base64ContentBytes: base64,
      imageMimeType: file.meta.type,
      name: file.file.name,
    });
    setLoadingImage(false);
  };

  let textsEnIndex;
  let textsPtIndex;
  if(id === 0 || (id > 0 && article && article.id > 0)){
    textsEnIndex = article.texts.findIndex(item=> item.culture==="en-GB");
    textsPtIndex = article.texts.findIndex(item=> item.culture==="pt-PT");
  }

  return id === 0 || (id > 0 && article && article.id > 0) ? (
    <Form
      initialValues={article}
      subscription={{ submitting: true, values:true }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, values }) => {
        return (
          <Fragment>
            <form
              className="article"
              onSubmit={(e) => {
                handleSubmit(e, form);
                e.preventDefault();
              }}
            >
              <Card title={article.id===0 ? resources.local.detail.new : resources.local.detail.edit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label >{resources.local.detail.image}</label>
                      {(((article.image && article.image.url && article.image.url!=="") || (newImage && newImage.base64ContentBytes)) && !loadingImage) && 
                        <Card subclass="card-form-image">
                          <img src={newImage ? `data:image/jpeg;base64,${newImage.base64ContentBytes}` : article.image.url}/>
                          {newImage &&
                          <a
                            onClick={() => {setNewImage()}}
                            rel="noopener noreferrer"
                            className="btn btn-remove"
                          >
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                            <span>{globalResources.verbs.remove}</span>
                          </a>}
                        </Card>
                      }
                      {loadingImage && 
                        <Card subclass="card-form-image">
                          <IsLoading text={resources.local.loadingImage} />
                        </Card>
                      }
                      <FilePicker
                        onUpload={(base64, file) =>
                          onUpload(base64, file)
                        }
                        onLoading={()=>setLoadingImage(true)}
                        failUpload={()=>setLoadingImage(false)}
                        maxWidth={1024}
                        buttonText={(newImage || article.image) ? resources.local.detail.editImage
                          : resources.local.detail.addImage}
                        accept="image/*"
                      ></FilePicker>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group m-b-0">
                      <label htmlFor="name">
                        {resources.local.detail.status_label}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <Field
                          id="active"
                          name="status"
                          type="radio"
                          label={globalResources.active}
                          value={String(true)}
                          component={RenderRadio}
                        />
                      </div>
                      <div className="col-auto">
                        <Field
                          id="inactive"
                          name="status"
                          type="radio"
                          label={globalResources.inactive}
                          value={String(false)}
                          component={RenderRadio}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Field
                      name={`texts[${textsPtIndex}].title`}
                      label={resources.local.detail.subject_pt}
                      component={RenderInput}
                      validate={required}
                      autoFocus
                    />
                    <Field
                      name={`texts[${textsPtIndex}].introduction`}
                      label={resources.local.detail.introduction_pt}
                      component={RenderTextarea}
                      validate={required}
                    />
                    <Field
                      name={`texts[${textsPtIndex}].description`}
                      label={resources.local.detail.description_pt}
                      component={RenderTextarea}
                      validate={required}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name={`texts[${textsEnIndex}].title`}
                      label={resources.local.detail.subject_en}
                      component={RenderInput}
                      validate={required}
                      autoFocus
                    />
                    <Field
                      name={`texts[${textsEnIndex}].introduction`}
                      label={resources.local.detail.introduction_en}
                      component={RenderTextarea}
                      validate={required}
                    />
                    <Field
                      name={`texts[${textsEnIndex}].description`}
                      label={resources.local.detail.description_en}
                      component={RenderTextarea}
                      validate={required}
                    />
                  </div>
                </div>
                <div className="row m-t-m justify-content-end">
                  <div className="col-auto">
                    {!loadingImage &&
                      <Fragment>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitting}
                        >
                          {article.id > 0
                            ? globalResources.verbs.change
                            : globalResources.verbs.register}
                        </button>{" "}
                      </Fragment>
                    }
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => after()}
                    >
                      {globalResources.verbs.cancel}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Fragment>
        );
      }}
    />
  ) : (
    <Fragment></Fragment>
  );
};

export default BlogForm;
