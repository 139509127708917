import React, { Fragment, useState, useEffect } from "react";
import IsLoading from "../elements/IsLoading";
import {
  InquiriesResources,
  globalResources,
  navigationResources,
} from "../../resources/resources";
import Card from "../../components/containers/Card";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { getDate, getTime } from "../../assets/utils";
import { toInquirieStatusLabel, toDateTime } from "../../assets/FormatAndParse";
import { GetInquiries } from "../../actions/inquiries";

const InquiriesList = (props) => {
  const [resources] = useState(InquiriesResources);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(true);
  const records = 10;
  
  useEffect(() => {
    GetInquiries(open, page, records).then((response) => response.json())
    .then((result) => {
      setData(result);
    }).catch((err)=>setData([]));
  },[open, page]);

  const renderList = (list) => {
    return(
        <Fragment>
          {list.map((item, index)=>(
            <Card key={"inquirieItem-"+index} subclass={`card-b-right ${item.status?.id===1 ? "card-b-grey" : (item.status?.id===2 ? "card-b-yellow" : "card-b-green")}`}>
              <h3>{item.title}</h3>
              <p className="linebreak">{item.description}</p>
              {item.status &&
                <p>
                  <span>{resources.local.inquirieStatus}</span>
                  <span className={item.status?.id===3 ? "selected" : (item.status?.id===2 ? "warning" : "grey")}>
                    {toInquirieStatusLabel(item.status, resources.local.statusLabels)}
                  </span>
                </p>
              }
              {item.answeringOpeningDate &&
                <p><span>{resources.local.openingDate}</span><span>{toDateTime(item.answeringOpeningDate)}</span></p>
              }
              {item.answeringClosingDate &&
                <p><span>{resources.local.closingDate}</span><span>{toDateTime(item.answeringClosingDate)}</span></p>
              }
              {((item?.status?.id===3 || item?.status?.id===2) && item.userNotification) &&
                <button className="btn btn-primary m-t-m" onClick={(e) => {
                    props.history.push(navigationResources.inquiries.path + "?nid="+item.userNotification.notificationId)
                }}>
                  {item?.status?.id===3 ?
                    (item.userFinishedAnswering ? 
                      (item.usersCanChangeAnswersWhileOpen ? resources.local.reviewAnswers : resources.local.checkSubmitedAnswers) 
                      : resources.local.fillInquirie)
                    : 
                    (resources.local.consultInquirie)
                  }
                </button>
              }
              {(item?.status?.id===1 && item.shareResultsWithUsers) &&
                <button className="btn btn-primary m-t-m" onClick={(e) => {
                  props.history.push(navigationResources.inquiries.path + "?nid="+item.userNotification.notificationId)
                }}>
                  {resources.local.viewResults}
                </button>
              }
            </Card>
          ))}
        </Fragment>
    );
  }

  const pagingBtns = () =>{
      return (page>1 || data.totalResults>page*records) ? (
        <div className="page-selector-buttons text-right m-b-l">
          <button
            className={`btn btn-primary btn-page-selector  ${page>1 ? "" : "disabled"}`}
            onClick={()=>{setPage(page-1)}}
          >{"<"}</button>
          <button
            className={`btn btn-primary btn-page-selector  ${data.totalResults>page*records ? "" : "disabled"}`}
            onClick={()=>{setPage(page+1)}}
          >{">"}</button>
        </div>
    ) : (null);
  }
  
  return data ? (
    <Fragment>
      <div className="table-filter m-b-l"> 
        <div className="row">
          <div className="col">
            <button
            className={`btn btn-filter no-extras ${open ? "active" : ""}`}
            onClick={() => {
                setOpen(true);
                setPage(1);
            }}>
            <span>{resources.local.unfinishedInquiries}</span>
            </button>
            <button
            className={`btn btn-filter no-extras ${!open ? "active" : ""}`}
            onClick={() => {
                setOpen(false);
                setPage(1);
            }}>
            <span>{resources.local.allInquiries}</span>
            </button>
          </div>
        </div>
      </div>
      
      {(data.items && data.totalResults>0) ?
        <Fragment>
          {pagingBtns()}
          {renderList(data.items)}
          {pagingBtns()}
        </Fragment>
        :
        <Card>{resources.local.noInquiries}</Card>
      }
      
    </Fragment>
  ) : (
    <Card>
      <IsLoading text={globalResources.loading} />
    </Card>
  );
};

export default InquiriesList;
