import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { required, complexPassword } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import { ResetPassword } from "../../actions/user";
import { loginResources } from "../../resources/resources";

const RecoverForm = (props) => {
  const code = props.match.params.code;
  const [message, setMessage] = useState("");

  const onSubmit = async (values) => {
    ResetPassword(code, values.password)
      .then((response) => {
        window.open("/", "_self");
      })
      .catch((e) => {
        let message = "";
        if (e.response.data && e.response.data.error)
          message = e.response.data.error;

        switch (message.toLowerCase()) {
          case "InvalidOrOutdatedRecoveryCode".toLowerCase():
            window.open("/", "_self");
            break;
          default:
            setMessage(loginResources.recoverFailed);
            break;
        }
      });
  };

  return (
    <Form
      initialValues={{}}
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (values.confirm !== values.password) {
          errors.confirm = loginResources.recoverPasswordNoMach;
        }
        return errors;
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
            e.preventDefault();
          }}
        >
          <Field
            name="password"
            type="password"
            label={loginResources.newPassword}
            validate={(required, complexPassword)}
            component={RenderInput}
            autoFocus
          />
          <Field
            name="confirm"
            type="password"
            label={loginResources.confirmPassword}
            validate={(required, complexPassword)}
            component={RenderInput}
          />
          <br></br>
          <br></br>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={submitting}
          >
            {loginResources.confirmRecover}
          </button>

          {message && message.length > 0 && <i>{message}</i>}
        </form>
      )}
    />
  );
};

export default RecoverForm;
