import React, { useState, Fragment } from "react";
import { Form, Field } from "react-final-form";
import { required, email } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import { Login, SendRecoverPasswordEmail } from "../../actions/user";
import { set_token, set_DemoToken } from "../../storage/token";
import { loginResources, getCurrent } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { default_client_id, client_id } from "../../assets/config";
import { lang } from "moment";
import { notifyError } from "../../components/elements/Notify";
import { InfoPopup } from "../elements/Popup";

const LoginForm = (props) => {
  const [inquiriesUrl] = useState(props.location?.state?.inquiriesUrl);
  const [message, setMessage] = useState(
    (function () {
      if (props.match.params && props.match.params.status) {
        switch (parseInt(props.match.params.status)) {
          case 5:
            return loginResources.recoverSuccess;
          case 6:
            return loginResources.activateExpired;
          case 7:
            return loginResources.activateCriticalFail;
          case 8:
            return loginResources.recoverExpired;
          default:
            return "";
        }
      }
      return "";
    })()
  );
  const [recover_message, setRecoverMessage] = useState("");
  const [mode, setMode] = useState(0); // 0 Login, 1 Recover
  const [errorPopUpMessage, setErrorPopUpMessage] = useState(null);
  const onSubmit = async (values, form, isDemo) => {
    if (mode === 0) {
      Login(values.username, values.user_password)
        .then((response) => {
          if (isDemo === true) {
            set_DemoToken(response.data.access_token);
          } else {
            set_token(response.data.access_token);
          }
          if (inquiriesUrl) {
            window.open(`/inquiries${inquiriesUrl}`, "_self");
          } else {
            !props.location || props.location.pathname === "/"
              ? window.location.reload(false)
              : window.open("/", "_self");
          }
        })
        .catch((e) => {
          if (e?.response?.data?.error === "AuthenticationFail") {
            setErrorPopUpMessage(
              <div>
                <h3>{loginResources.feedback.authenticationFailPopUp1}</h3>
                <p>{loginResources.feedback.authenticationFailPopUp2}</p>
                <a
                  className="text-decoration-underline cur-point"
                  onClick={() => {
                    setMode(1);
                    setErrorPopUpMessage(null);
                  }}
                >
                  {loginResources.feedback.authenticationFailPopUp3}
                </a>
              </div>
            );
          } else if (e?.response?.data?.error === "UserNotFound") {
            setErrorPopUpMessage(
              <div>
                <p>{loginResources.feedback.userNotFoundPopUp1}</p>
                <p>{loginResources.feedback.userNotFoundPopUp2}</p>
                <p>{loginResources.feedback.userNotFoundPopUp3}</p>
              </div>
            );
          }
          switch (e.response.status) {
            case 405:
              const lang = e.response.data.error_description
                .split("termsCulture=")[1]
                .split("&acceptanceToken")[0];
              const token =
                e.response.data.error_description.split("&acceptanceToken=")[1];
              props.history.push({
                pathname: `/terms/${lang}/${values.username}/${token}`,
                state: { password: values.user_password },
              });
              break;
            case 401:
              form.initialize(values);
              setMessage(loginResources.feedback.fail);
              //notifyError(loginResources.feedback.fail);
              break;
            default:
              form.initialize(values);
              setMessage(loginResources.feedback.error);
              //notifyError(loginResources.feedback.error);
              break;
          }
        });
    } else {
      let getParam = null;
      if (client_id() !== default_client_id)
        getParam = "?app=" + client_id().toString();
      SendRecoverPasswordEmail(values.recover, getCurrent())
        .then((response) => {
          values.recover = "";
          form.initialize(values);
          setMessage(loginResources.feedback.recoverEmailSent);
          setMode(0);
        })
        .catch((e) => {
          let message = "";
          if (e.response.data && e.response.data.error)
            message = e.response.data.error;
          switch (message.toLowerCase()) {
            case "usernotfound":
              form.initialize(values);
              setRecoverMessage(loginResources.feedback.recoverEmailNotFound);
              break;
            case "usercannotredefinepassword":
              form.initialize(values);
              setRecoverMessage(loginResources.feedback.cannotRedefinePassword);
              break;
            default:
              setRecoverMessage(loginResources.feedback.recoverEmailFail);
              break;
          }
        });
    }
  };

  return (
    <Form
      initialValues={{
        username: "",
        user_password: "",
      }}
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e, form);
            e.preventDefault();
          }}
        >
          {mode === 0 && (
            <Fragment>
              <InfoPopup
                messageComp={errorPopUpMessage}
                visible={errorPopUpMessage !== null}
                next={() => {
                  setErrorPopUpMessage(null);
                }}
              />
              <Field
                name="username"
                type="text"
                label={loginResources.userName}
                help="ex. random@mail.com"
                validate={required}
                component={RenderInput}
              />
              <Field
                name="user_password"
                type="password"
                label={loginResources.userPassword}
                validate={required}
                component={RenderInput}
              />
              <div className="btn-forgot-password">
                <a onClick={() => setMode(1)}>{loginResources.recover}</a>
              </div>
              <br></br>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting}
              >
                {loginResources.login}
              </button>
              <br />
              <br />
              {client_id() === default_client_id && (
                <span id="login-demo-button">
                  <a
                    onClick={() => {
                      onSubmit(
                        {
                          username: loginResources.demo_user,
                          user_password: loginResources.demo_password,
                        },
                        form,
                        true
                      );
                    }}
                  >
                    {loginResources.clickHereToTry}
                  </a>
                </span>
              )}
              {message && message.length > 0 && (
                <Fragment>
                  <br></br>
                  <br></br>
                  <i>{message}</i>
                </Fragment>
              )}
            </Fragment>
          )}
          {mode === 1 && (
            <Fragment>
              <Field
                name="recover"
                type="text"
                label={loginResources.recoverEmail}
                help="ex. random@mail.com"
                validate={(required, email)}
                component={RenderInput}
              />
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting || pristine}
              >
                {loginResources.recoverSubmit}
              </button>
              <br></br>
              <br></br>
              <div className="btn-forgot-password">
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                <a onClick={() => setMode(0)}>{loginResources.recoverBack}</a>
              </div>
              {recover_message && recover_message.length > 0 && (
                <Fragment>
                  <br></br>
                  <i>{recover_message}</i>
                </Fragment>
              )}
            </Fragment>
          )}
        </form>
      )}
    />
  );
};

export default LoginForm;
