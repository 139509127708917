import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import MessagesList from "../components/lists/MessagesList";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const MessagesPage = (props) => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.messages.breadcrumb} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-l-0 p-r-xxl">
            <MessagesList {...props}></MessagesList>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default MessagesPage;
