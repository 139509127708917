//import 'mock-local-storage'

export const default_client_id = "FC8320BD-6242-4E7A-B3D5-DE4F74A3B9A6"; // DEFAULT ID THAT WILL BE USED TO COMPARE IF THE CLINET ID IS DIFFERENT

export const client_id = () => {
  return sessionStorage.getItem("client_id");
};

export let CryptoKey = undefined;
// process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
//   ? undefined
//   : "Improxy GCOM 2020";

export function ApiCall(path) {
  //const apipath = "https://api.improxy.com/";
  //const apipath = "https://devapi.improxy.com/";
  let apipath = "https://api.improxy.com/";
  // if (
  //   window.location.hostname === "qa.gecond.com" ||
  //   window.location.hostname === "localhost"
  // ) {
  //   apipath = "https://api.improxy.com/";
  // }
  return apipath + path;
}

export function ApiCallDev(path) {
  //const apipath = "https://apicore.improxy.com/";
  //const apipath = "https://devapicore.improxy.com/";
  let apipath = "https://apicore.improxy.com/";
  // if (
  //   window.location.hostname === "qa.gecond.com" ||
  //   window.location.hostname === "localhost"
  // ) {
  //   apipath = "https://apicore.improxy.com/";
  // }
  return apipath + path;
}
