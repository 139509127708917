import React, { useState, useEffect } from "react";
import Table, {
  Column,
  Number,
  NoData,
  Option,
  Loading,
  Date,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import {
  GetReceipts,
  GetCurrentAccount,
  PostEmailsCurrentAccount,
} from "../../actions/documents";
import Card from "../containers/Card";
import {
  CurrentAccountingTableResources,
  globalResources,
  navigationResources,
} from "../../resources/resources";
import { useSelector } from "react-redux";
// import ReceiptsDetails from "../details/ReceiptsDetails";
import { InfoPopup } from "../elements/Popup";
import { notifyError, notifySuccess } from "../elements/Notify";
import { NavLink } from "react-router-dom";
import { formatNumber, toCurrency } from "../../assets/FormatAndParse";

const CurrentAccountsTable = () => {
  const [data, setData] = useState();
  const [currentAccounts, setCurrentAccounts] = useState();
  const [currentAccountsData, setCurrentAccountsData] = useState();
  const [resources] = useState(CurrentAccountingTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const [isOpen, setIsOpen] = useState(false);
  const [dataReceipt, setDataReceipt] = useState([]);
  const [documentTypeString, setDocumentTypeString] = useState("");
  const [visible, setVisible] = useState(false);
  const [debitBalance, setDebitBalance] = useState(0);
  const [creditBalance, setCreditBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    if (condominium) {
      GetCurrentAccount(condominium.id).then((response) => {
        response.data.accountMovements.forEach((doc) => {
          doc.date = new window.Date(doc.date);
        });

        var responseCurrentAccounts = response?.data?.accountMovements;

        //console.log("responseCurrentAccounts: ", responseCurrentAccounts);
        //console.log("response: ", response);

        var currentAccountsArray = [];
        let debitBalance = 0;
        let creditBalance = 0;
        let totalBalance = 0;

        if (!!response) {
          setCurrentAccounts(response?.data);
          if (!!responseCurrentAccounts) {
            for (let a = 0; a < responseCurrentAccounts.length; a++) {
              //console.log("a: ", a);
              let responseDocumentType = "";

              if (
                responseCurrentAccounts[a]?.documentType === "INITIAL_BALANCE"
              ) {
                responseDocumentType =
                  resources.local.documentTypes.initialBalance;
              } else if (
                responseCurrentAccounts[a]?.documentType === "COLLECTION_NOTICE"
              ) {
                responseDocumentType = resources.local.documentTypes.warning;
              } else if (
                responseCurrentAccounts[a]?.documentType === "CREDIT"
              ) {
                responseDocumentType = resources.local.documentTypes.credit;
              } else if (
                responseCurrentAccounts[a]?.documentType === "CREDIT_IN_ADVANCE"
              ) {
                responseDocumentType =
                  resources.local.documentTypes.creditInAdvance;
              } else if (
                responseCurrentAccounts[a]?.documentType === "EXTRA_REVENUE"
              ) {
                responseDocumentType =
                  resources.local.documentTypes.extraRevenue;
              } else if (
                responseCurrentAccounts[a]?.documentType === "RECEIPT"
              ) {
                responseDocumentType = resources.local.documentTypes.receipt;
              } else if (
                responseCurrentAccounts[a]?.documentType === "REFUND"
              ) {
                responseDocumentType = resources.local.documentTypes.refund;
              }

              totalBalance = totalBalance + responseCurrentAccounts[a].value;

              if (responseCurrentAccounts[a].value <= 0) {
                debitBalance = debitBalance + responseCurrentAccounts[a].value;
              } else {
                creditBalance =
                  creditBalance + responseCurrentAccounts[a].value;
              }

              currentAccountsArray[a] = {
                id: responseCurrentAccounts[a]?.id,
                fractionDescription:
                  responseCurrentAccounts[a]?.fractionDescription,
                documentNumber: responseCurrentAccounts[a]?.documentNumber,
                date: responseCurrentAccounts[a]?.date,
                value: responseCurrentAccounts[a]?.value,
                balance: responseCurrentAccounts[a]?.balance,
                description: responseCurrentAccounts[a]?.description,
                documentType: responseDocumentType,
                documentTypeIdentificationLetter:
                  responseCurrentAccounts[a]?.documentTypeIdentificationLetter,
                documentLink: {
                  url: responseCurrentAccounts[a]?.documentLink?.url,
                },
                condominiumId: responseCurrentAccounts[a]?.condominiumId,
                signature: responseCurrentAccounts[a]?.signature,
                currencySymbol: response?.data?.currency?.symbol,
                bankType: responseCurrentAccounts[a]?.paymentMethod?.id,
              };
            }
            setCurrentAccountsData(currentAccountsArray);
            setDebitBalance(debitBalance);
            setCreditBalance(creditBalance);
            setTotalBalance(totalBalance);
            // console.log(totalBalance);
          }
        } else {
          setCurrentAccounts("");
          console.log("error");
        }
        // console.log({ debitBalance });
        // console.log({ creditBalance });
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  const postEmail = (assinature) => {
    //console.log("enviado: ", assinature);
    PostEmailsCurrentAccount(assinature)
      .then((response) => {
        // console.log({ response });
        // console.log(response.status === 204);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 203 ||
          response.status === 204
        ) {
          return response;
        } else {
          throw new Error("Error: ", response);
        }
      })
      .then((result) => {
        // console.log("result: ", result);
        return notifySuccess(resources.local.emailSent);
      })
      .catch((error) => {
        // console.log("error: ", error);
        return notifyError(resources.local.errorSentEmail);
      });
  };

  let currency_symbol = "";
  if (currentAccounts && currentAccounts.length > 0) {
    currency_symbol = globalResources.currencySymbol.replace(
      globalResources.currencySymbol_replaceString,
      currentAccounts.currency.symbol
    );
  }

  //console.log("Account current: ", currentAccounts);
  //console.log("Account current data: ", currentAccountsData);

  return (
    <>
      <InfoPopup
        messageComp={resources.local.emailSent}
        visible={visible}
        next={(e) => setVisible(e)}
        buttonLabel={"OK"}
        disabled={false}
        currentAccount={true}
      />
      <Card subclass="max-width">
        <p>{resources.local.notUpdatedInformation}</p>
        <p>{resources.local.warningTwelveMonths}</p>
      </Card>
      <div className="row">
        <div className={`col col-payment`}>
          <button className={`btn btn-value`}>
            <span>
              {totalBalance <= 0
                ? resources.local.debitBalance
                : resources.local.creditBalance}
              :
            </span>
            <span>
              <h3 className="payment-value">{`${toCurrency(
                totalBalance
              )}€`}</h3>
            </span>
          </button>
          <span
            className={`btn-text active`}
            id="btn-confirm-payment"
            onClick={() =>
              window.open(navigationResources.notices.path, "_self")
            }
          >
            {resources.local.goToNoticeCreditsPage}
          </span>
        </div>
      </div>

      <div>
        <Card subclass="max-width">
          <Table
            data={currentAccountsData}
            pager={{ pageIndex: 0 }}
            open={(e) => setIsOpen(e)}
            setDataReceipt={(e) => setDataReceipt(e)}
          >
            <Column
              Header={resources.local.document}
              //accessor="documentNumber"
              className="collapse"
              Cell={(table) => {
                const doc = table.row.original;

                return (
                  <span>
                    {!!doc
                      ? `${doc.documentType} ${
                          doc.documentNumber ? `Nº ${doc.documentNumber}` : ""
                        }`
                      : ``}
                  </span>
                );
              }}
            />

            <Date
              Header={globalResources.date}
              accessor="date"
              sortType="datetime"
              className="collapse"
              align="right"
              desc={true}
            />
            <Column
              Header={resources.local.fraction}
              //accessor="fractionDescription"
              className="collapse"
              Cell={(table) => {
                const doc = table.row.original;

                return (
                  <span>
                    {doc.fractionDescription
                      ? `${doc.fractionDescription}`
                      : ``}
                  </span>
                );
              }}
            />
            {/* <Column
              Header={resources.local.type}
              //accessor="fractionDescription"
              className="collapse"
              Cell={(table) => {
                const doc = table.row.original;
                console.log({ doc });
                return (
                  <p>
                    {doc?.bankType
                      ? resources.local.paymentMethods[doc?.bankType]
                      : ""}
                  </p>
                );
              }}
            /> */}
            <Column
              Header={resources.local.description}
              //accessor="fractionDescription"
              Cell={(table) => {
                const doc = table.row.original;
                //console.log({ doc });
                return (
                  <p>
                    {doc.description && (
                      <p>{doc.description ? doc.description : ""}</p>
                    )}
                    {doc?.bankType && (
                      <p>
                        {doc?.bankType
                          ? resources.local.paymentMethods[doc?.bankType]
                          : ""}
                      </p>
                    )}
                  </p>
                );
              }}
            />

            <Column
              Header={
                resources.local.value +
                (currency_symbol ? currency_symbol : " (€)")
              }
              className="collapse tableNumber"
              Cell={(table) => {
                const doc = table.row.original;

                return <p>{doc.value ? toCurrency(doc.value) : ""}</p>;
              }}
            />

            {/* <Number
              Header={
                resources.local.value +
                (currency_symbol ? currency_symbol : " (€)")
              }
              Header_has_currency={true}
              //accessor="value"
            />*/}
            {/*<Option
              id="sentEmail"
              Cell={(table) => {
                
                const doc = table.row.original;
                return (
                  <>
                    {doc.signature && (
                      <button
                        style={{
                          //position: "absolute",
                          //right: "2rem",
                          //top: "2rem",
                          zIndex: 2,
                        }}
                        onClick={() =>
                          postEmail({
                            signature: doc?.signature,
                          })
                        }
                      >
                        <div className="icon-thumbnail">
                          
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="envelope"
                            class="svg-inline--fa fa-envelope fa-w-16 "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                            ></path>
                          </svg>
                        </div>
                      </button>
                    )}
                  </>
                );
              }}
            />*/}
            <Option
              id="openPDF"
              Cell={(table) => {
                const doc = table.row.original;
                return (
                  <>
                    {doc.documentLink && doc.documentLink.url && (
                      <button
                        style={{
                          zIndex: 2,
                        }}
                        onClick={() => window.open(doc.documentLink.url)}
                      >
                        <div className="icon-thumbnail">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="file-pdf"
                            class="svg-inline--fa fa-file-pdf fa-w-12 "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                            ></path>
                          </svg>
                        </div>
                      </button>
                    )}
                  </>
                );
              }}
            />
            <NoData>{resources.local.noReceipts}</NoData>
            <Loading value={currentAccounts === undefined}>
              <IsLoading text={resources.local.loading} />
            </Loading>
          </Table>
          <div className="">
            <p
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
                color: "#adb0b4",
              }}
            >
              {resources.local.emited12Months}
            </p>
          </div>
        </Card>
      </div>
    </>
  );
};

export default CurrentAccountsTable;
