import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import resourcesReducer from "./resources";
import userReducer from "./user";
import userSettingsReducer from "./userSettings";
import dashboardReducer from "./dashboard";
import votesReducer from "./votes";
import inquiriesReducer from "./inquiries";

export default combineReducers({
  user: userReducer,
  userSettings: userSettingsReducer,
  dashboard: dashboardReducer,
  resources: resourcesReducer,
  form: formReducer,
  votes: votesReducer,
  inquiries: inquiriesReducer,
});
