export let Global = {
  required: "Campo obrigatório",
  takePhoto: "Tirar fotografia",
  addPhoto: "Inserir imagem",
  addPhotoSuccess: "Imagem inserida com sucesso!",
  addPhotoFail: "Não foi possivel inserir a imagem...",
  deletePhoto: "Remover a imagem",
  deletePhotoSuccess: "Imagem removida com sucesso!",
  deletePhotoFail: "Não foi possivel remover a imagem...",
  hasntVoted: "Não votou",
  votingIncomplete: "Votação incompleta",
  hasntSignedFile: "Não anexou procuração",
  complete: "Completo",
  notOpen: "Não está aberta",
  notAvailableYet: "Ainda não disponível",
  unavailable: "Indisponível",
  active: "Ativo",
  inactive: "Inativo",
  verbs: {
    save: "Guardar",
    save_alt: "Gravar",
    cancel: "Cancelar",
    remove: "Remover",
    register: "Registar",
    download: "Descarregar",
    change: "Alterar",
    accept: "Aceitar",
    decline: "Não aceito",
    vote: "Votar",
    select: "Selecionar",
    submit: "Submeter",
    add: "Adicionar",
    print: "Imprimir",
    goBack: "Voltar",
  },
  totalLabel: "Total:",
  date: "Data",
  page: "Página",
  goToPage: "| ir para a página:",
  show: "Ver",
  languages: {
    pt: "Português",
    en: "Inglês",
  },
  terms: "Termos e condições de uso",
  privacyPolicy: "Política de Privacidade",
  new: "Nova",
  occurrence: "ocorrência",
  seeAllMessages: "Ver todas as mensagens",
  signOut: "Terminar sessão",
  condominium: "Condomínio",
  chooseCondominium: "Escolha o condomínio",
  step: "Passo",
  rightsReserved: "Todos os direitos reservados.",
  followGecond: "Seguir Gecond o meu condomínio",
  gecondmycondominium: "Gecond o meu condomínio",
  gecondLogoPath: "/images/logo-gecond-o-meu-condominio.png",
  gecondLogoWhitePath: "/images/logo-gecond-o-meu-condominio-w.png",
  installApp: "Instalar Gecond o meu condomínio",
  close: "Fechar",
  install: "Instalar",
  light: "Claro",
  dark: "Escuro",
  male: "Masculino",
  female: "Feminino",
  CC: "Cartão de cidadão",
  passport: "Passaporte",
  entityTicket: "Bilhete de identidade",
  residencePermit: "Título de residência",
  validations: {
    minLength: (min) => `Deve ter no mínimo ${min} caracteres`,
    maxLength: (max) => `Deve ter no máximo ${max} caracteres`,
    number: "Deve ser um número",
    email: "Email inválido",
    alphaNumeric: "Apenas caracteres alfanuméricos",
    phoneNumber: "Número de Telemóvel inválido, deve ter 9 digitos",
    complexPassword:
      "A senha deverá ter um mínimo de 8 dígitos, \n pelo menos uma letra maiúscula,\n uma letra minúscula e um algarismo.",
  },
  currencySymbol: " (%currency%)",
  currencySymbol_replaceString: "%currency%",
  yes: "Sim",
  no: "Não",
  continue: "Continuar",
  cancel: "Cancelar",
  noActiveBudget: "Sem orçamento ativo.",
  apiError: {
    activeBudgetNotFound: "ActiveBudgetNotFound",
  },
  openInquiries: "Inqueritos em aberto",
  openInquirie: "Inquerito em aberto",
  loading: "A Carregar dados",
  ok: "Ok",
  inviteExpired_message1:
    "Lamentamos mas o link que está a tentar utilizar para efetuar o registo no “Gecond, o meu condomínio” já expirou.",
  inviteExpired_message2:
    "Por-favor utilize um convite de acesso mais recente ou solicite à sua administração de condomínios o envio de um novo convite.",
  answers: "respostas",
  errorPDF: "Ocorreu um erro a obter a declaração.",
};

export const DashboardPage = {
  welcome: {
    title: "Bem-vindo(a)!",
    p1: "Bem-vindo(a) ao GECOND.COM, a forma mais prática e segura de acompanhar a administração do seu condomínio.",
    p2: "A informação apresentada, assim como a sua exatidão e atualidade, é da responsabilidade da administração do condomínio.",
    p3: "Para esclarecer qualquer dúvida poderá abrir uma nova ocorrência ou contactar a sua administração pelos meios habituais.",
    newOccurrence: "Nova ocorrência",
    noCondo:
      "Atualmente ainda não foi atribuido nenhum condomínio a esta conta, para qualquer esclarecimento contacte a sua administração pelos meios habituais.",
  },
  notices: {
    title: "Em débito",
    seeall: "Ver documentos",
  },
  messages: {
    title: "Mensagens",
    unread: "não lidas",
    seeall: "Ver todas as mensagens",
  },
  occurrences: {
    title: "Ocorrências",
    seeall: "Ver todas as ocorrências",
    pending: "pendentes",
  },
  documents: {
    title: "Documentos",
    seeall: "Ver todos os documentos",
  },
};

export const Login = {
  title: "Gecond",
  subtitle: "Gestão de condominios",
  welcome: "Bem-vindo!",
  accountAccess: "Aceda à sua conta.",
  userName: "Utilizador",
  userPassword: "Palavra-Passe",
  login: "Entrar",
  demo: "Experimentar",
  demo_user: "demo@gecond.com",
  demo_password: "Gecond2020",
  clickHereToTry: "Clique aqui para experimentar",
  recoverEmail: "Email",
  recover: "Perdeu a palavra-passe?",
  recoverSubmit: "Recuperar palavra-passe",
  recoverBack: "Voltar atrás",
  confirmRecover: "Recuperar",
  recoverTitle: "Recuperar a palavra-passe",
  newPassword: "Nova palavra-passe",
  confirmPassword: "Confimar palavra-passe",
  recoverPasswordNoMach: "A palavra-passe indicada não é igual.",
  recoverSuccess: "Password alterada com sucesso.",
  recoverExpired: "O pedido de recuperação de palavra-passe expirou.",
  activateExpired:
    "O pedido de ativação da palavra-passe expirou. Se recebeu mais que um convite por favor use o link do email mais recente.",
  activateCriticalFail:
    "Não foi possivel fazer a ativação da palavra-passe. Para ter acesso à plataforma por favor contacte o seu gestor de cóndominio para fazer um novo convite, ou utilize a funcionalidate de recuperar a palavra-passe usando o email de onde recebeu o convite",
  recoverFailed:
    "Não foi possivel fazer a recuperação de palavra-passe, por favor tente novamente.",
  activateFailed: "Não foi possivel fazer a ativar de palavra-passe.",
  activateTitle: "Ativar Gecond o meu condomínio",
  activateSubTitle: "Definir a palavra-passe",
  confirmActivate: "Ativar",
  activation: "Credenciais de acesso",
  feedback: {
    fail: "Os dados de acesso estão incorretos.",
    error: "Não é possivel aceder à sua conta atualmente",
    recoverEmailSent: "Foi enviado o email para a recuperação da palavra-passe",
    recoverEmailFail:
      "Não foi possivel enviar o email de recuperação da palavra-passe, por favor tente novamente.",
    recoverEmailNotFound: "O email que indicou, não se encontra no sistema.",
    cannotRedefinePassword:
      "O utilizador demo@gecond.com não pode redefinir a sua palavra-passe porque é uma conta de demonstração.",
    termsFail:
      "Não foi possivel validar a aceitação de termos, por favor tente novamente",
    authenticationFailPopUp1: "Credenciais de acesso inválidas.",
    authenticationFailPopUp2:
      "Verifique se a sua senha está correta ou solicite a recuperação da mesma.\n",
    authenticationFailPopUp3: "Clique aqui para recuperar palavra-passe",
    userNotFoundPopUp1: `Não existe nenhum utilizador registado com estes dados de acesso.`,
    userNotFoundPopUp2:
      "P.f. contacte a sua administração de condomínio, e solicite e acesso ao Gecond, o meu condomínio.",
    userNotFoundPopUp3:
      "O registo inicial para acesso será enviado pela sua administração, por email.",
  },
};

export const Website = {
  banner: {
    appTitle: "Nova app mobile",
    title: "O meu condomínio sempre à mão",
    download: "Faça já o download",
    playstoreLink:
      "https://play.google.com/store/apps/details?id=com.improxy.Gecond",
    appstoreLink:
      "https://apps.apple.com/pt/app/gecond-o-meu-condom%C3%ADnio/id1541353491",
    p1: "Quer comunicar e aceder à informação do seu condomínio, sem complicações?",
    p2: "Nada mais fácil, com o GECOND, o meu condomínio, o website que permite, duma forma segura, comunicar e aceder a toda a informação do seu condomínio, 24h por dia. ",
    p3: "Agora já pode consultar tudo no mesmo local, sem ter de procurar papeis, emails ou fazer telefonemas.",
    p4: "Tenha o seu condomínio sempre à mão.",
    p5: "Contacte a sua administração do seu condomínio e solicite o acesso a este serviço.",
  },
  currentAccount: {
    title: "Conta-corrente",
    text: "Verifique a situação da sua conta-corrente e imprima segundas vias de avisos de cobrança e recibos.",
  },
  messages: {
    title: "Mensagens",
    text: "Envie e veja as mensagens da sua administração de condomínio.",
  },
  documents: {
    title: "Documentos",
    text: "Consulte atas de assembleia e outros documentos partilhados pela administração do condomínio.",
  },
  descriptions: {
    condominium: {
      title: "Para as administrações de condomínio",
      text: {
        p1: "Usando o software de gestão de condomínios GECOND, a informação gerada nesta plataforma é automaticamente disponibilizada aos seus condóminos no Gecond, o meu condomínio, criando um canal digital e seguro, sem necessidade de contato presencial.",
        p2: "Uma administração moderna, ativa e transparente com informação disponível 24 horas por dia.",
        p3: "Veja mais informações",
      },
    },
    owner: {
      title: "Para os condóminos",
      text: {
        p1: "O Gecond, o meu condomínio é um serviço disponibilizado pelas administrações de condomínio que utilizem o software de gestão de condomínios Gecond.",
        p2: "Como condómino poderá aceder aos valores pagos e por pagar das suas frações, imprimir segunda vias das mesmas, comunicar com a administração do condomínio e aceder a informação que a mesma possa disponibilizar, como por exemplo atas de assembleias, regulamento interno, etc.",
      },
    },
  },
  promotional_video: "https://www.youtube.com/watch?v=m4mPXuDuY5s",
  home: "HOME",
  blog: "BLOG",
  blogTitle: "Últimas entradas do nosso blog",
  moreArticles: "Mais artigos",
  loading: "A Carregar dados",
  blogText: {
    banner: {
      p1: "Fique a par das últimas notícias sobre gestão de",
      p2: "condomínios com o nosso blog",
    },
    blogValues: {
      culture: "pt-PT",
    },
    readMore: "Ler mais",
  },
};

export let Navigation = {
  dashboard: {
    link: "Início",
    breadcrumb: "Início",
    path: "/",
  },
  user: {
    link: "Dados pessoais",
    breadcrumb: "DADOS PESSOAIS",
    path: "/dados-pessoais",
  },
  condominium: {
    link: "Condomínio",
    breadcrumb: "Condomínio",
    path: "/condominio",
  },
  fractionsDebts: {
    link: "Dívidas das frações",
    breadcrumb: "DÍVIDAS DAS FRAÇÕES",
    path: "/dividas-das-fracoes",
  },
  notices: {
    link: "Valores a pagar",
    breadcrumb: "VALORES A PAGAR",
    path: "/valores-a-pagar",
  },
  votings: {
    link: "Assembleias",
    breadcrumb: "ASSEMBLEIAS",
    vote: "VOTAÇÃO",
    legalRepresentative: "PROCURAÇÃO",
    finalize: "FINALIZAR",
    path: "/assembleias",
  },
  // receipts: {
  //   link: "Recibos",
  //   breadcrumb: "Recibos",
  //   path: "/recibos",
  // },
  currentAccounting: {
    link: "Conta corrente",
    breadcrumb: "Conta corrente",
    path: "/conta-corrente",
  },
  messages: {
    link: "Mensagens",
    breadcrumb: "Mensagens",
    unread: "mensagens não lidas",
    path: "/mensagens",
    path_condominium: "/mensagens-condominio",
  },
  blog: {
    link: "Blog",
    breadcrumb: "BLOG",
    path: "/blog",
    edit: "ARTIGO",
    new: "NOVO ARTIGO",
  },
  bankBalances: {
    link: "Saldos bancários",
    breadcrumb: "SALDOS BANCÁRIOS",
    path: "/saldos-bancarios",
  },
  budgetAnalysis: {
    link: "Análise orçamental",
    breadcrumb: "Análise orçamental",
    path: "/analise-orcamental",
  },
  exerciseBudget: {
    link: "Orçamento do exercício",
    breadcrumb: "Orçamento do exercício",
    path: "/orcamento-do-exercicio",
  },
  balance: {
    link: "Balanço",
    breadcrumb: "Balanço",
    path: "/balanco",
  },
  exerciseExpenses: {
    link: "Despesas do exercício",
    breadcrumb: "Despesas do exercício",
    path: "/despesas-do-exercicio",
  },
  occurrences: {
    link: "Ocorrências",
    breadcrumb: "OCORRÊNCIAS",
    edit: "OCORRÊNCIA",
    new: "NOVA OCORRÊNCIA",
    pending: "ocorrências pendentes",
    path: "/ocorrencias",
    path_edit: "/ocorrencia",
  },
  documents: {
    link: "Documentos",
    breadcrumb: "Documentos",
    path: "/documentos",
  },
  terms_of_use: {
    path: "/termos-de-uso",
  },
  privacy_policy: {
    path: "/politica-de-privacidade",
  },
  activate: {
    path: "/ativar",
  },
  inquiries: {
    breadcrumb: "INQUÉRITOS",
    path: "/inquiries",
    link: "Inquéritos",
    inquirie: "Inquérito",
    stats: "Estatísticas de participação",
  },
};

export const UserPage = {
  insertValidPhoneNumber: "Esse número não é valido, por favor tente de novo",
  details: {
    title: "Dados Pessoais",
    name: "Nome",
    email: "Email",
    phone: "Telemóvel",
    address: "Morada",
    success: "Alteração com succeso.",
    fail: "Não foi possivel alterar os dados.",
    language: "Idioma",
    gender: "Género",
    applicationTheme: "Tema",
    birthDate: "Data de nascimento",
  },
  photo: {
    title: "Foto",
    change: "Alterar foto de perfil",
  },
  password: {
    title: "Alterar a palavra-passe",
    old: "Palavra-Passe atual",
    new: "Nova palavra-passe",
    confirm: "Confimar palavra-passe",
    success: "A palavra-Passe foi alterada.",
    fail: "Não foi possivel alterar a palavra-passe.",
    NoMach: "A palavra-passe indicada não é igual.",
    submit: "Alterar",
    mismatch: "A palavra-passe indicada não está correta.",
  },
  note: {
    title: "Nota:",
    text: {
      p1: "Alterações destes dados pessoais não irão alterar os dados existentes na administração do condomínio.",
      p2: "Caso pretenda comunicar alterações à administração, sugerimos a abertura duma ocorrência para esse efeito.",
    },
  },
};

export const DocumentsTable = {
  title: "DOCUMENTOS ASSOCIADOS",
  date: "Data",
  fileName: "Documento",
  description: "Descrição",
  download: "Descarregar documento",
  noDocuments: "Sem documentos",
  loading: "A Carregar dados",
};

export const NoticesTable = {
  title: "AVISOS E CRÉDITOS PENDENTES",
  fraction: "Fração",
  document: "Documento",
  date: "Data",
  valueInDebt: "Valor por pagar",
  balance: "Saldo",
  description: "Descrição",
  loading: "A Carregar dados",
  noNotices: "Sem avisos nem créditos por liquidar",
  noticeNumber: "Aviso nº ",
  creditNumber: "Crédito nº ",
  dueDate: "Data de vencimento",
  dueDocuments: "documentos vencidos e a vencer nos proximos 15 dias",
  expiredDocuments: "Documentos vencidos",
  allDocuments: "documentos vencidos e por vencer",
  pay: "Pagar",
  MBWAY_title:
    "O pagamento Multibanco é processado pela LUSOPAY, Instituição de Pagamento, Lda.",
  entity: "Entidade:",
  reference: "Referência:",
  amount: "Montante:",
  MBWAY_image: "/images/MBWAY.png",
  MBWAY_white_image: "/images/MBWAY-w.png",
  IBAN_title: "Pagamento por transferência bancária para o IBAN:",
  owedBalance: "Saldo devedor",
  creditorBalance: "Saldo credor",
  value: "valor",
  insertValidValue: "Por favor insira um valor válido",
  noPaymentOption: "Nenhuma opção de pagamento disponível",
  paymentValue: "Valor a pagar",
  continuePayment: "Avançar para pagamento",
  printEveryDocument: "Imprimir todos os documentos",
  IBANCopied: "IBAN copiado",
  download: "Descarregar documento",
  copy: "Copiar",
  notUpdatedInformation:
    "Esta informação poderá não estar atualizada, pois poderá haver ainda lançamentos pendentes.",
  payOnline: "Pagar online",
  directBank: "Pelo banco diretamente da web",
  billPayment: "Pagamento de conta",
  totalToPay: "Valor total",
  ibanPaymentNotFound:
    "Neste momento não se encontra disponível nenhuma forma de pagamento através da aplicação. Por favor, contacte a sua administração.",
};

export const VotingsTable = {
  ordinaryAssembly: "Assembleia Ordinária",
  extraordinaryAssembly: "Assembleia Extraordinária",
  digitalVoting: "Votação digital",
  votingStatus: "Estado da votação: ",
  administrationsValidation: "Validação pela administração: ",
  dateToVote:
    "Poderá votar na Assembleia desde {%START_DATE%} até {%END_DATE%}.",
  dateTillVote: "Poderá votar na Assembleia até {%END_DATE%}.",
  loading: "A Carregar dados",
  noVotes: "Sem assembleias",
  agendaItems: "Pontos de ordem de trabalhos: ",
  subject: "Título",
  start: "Data de início",
  end: "Data Conclusão",
  vote: "Voto",
  informative: "Informativo",
  voting: "Votação",
  voting: "Finalizar",
  representative: "Representante",
  otherRepresentative: "Outro representante",
  failList: "Não foi possivel ler os dados das votaçoes",
  chooseOption: "Escolha uma opção",
  cantChangeVotes: "Não pode alterar as votações",
  cantChangeRepresentative: "Não pode alterar o representante",
  fillAllOtherRepresentativeFields:
    "Preencha todos os campos do seu representante",
  noSelection: "Não se encontra seleccionada nenhuma votação!",
  selected: "Selecionado",
  loading: "A Carregar dados",
  assemblyPresidentLabel: "Presidente da mesa da assembleia",
  printSignSubmitContract: "Imprimir, assinar e anexar procuração assinada.",
  SignedContract: "Procuração assinada.",
  signWMobileKey: "Assinar com chave móvel",
  addRepresentationDocument: "Anexar procuração já assinada",
  printRepresentationDocument: "Imprimir procuração para assinar",
  digitallySignRepresentation: "Assinar com Chave Móvel Digital",
  printSignedRepresentationDocument:
    "Imprimir documento de representação assinado",
  printDocumentAlrAdded: "Imprimir Procuração já adicionada.",
  changeVotesOrRepresentative: "Alterar votação ou representação",
  checkRepresentationDocument: "Ver documento de representação",
  openVoting1: "1 Assembleia em aberto",
  openVotings: "{%NUMBER_VOTES%} Assembleias em aberto",
  participateNow: "Participe já!",
  checkDocumentation: "Verificar documentação",
  assemblyRequiresLegalDocument:
    "Esta assembleia obriga à emissão de procuração.",
  assemblyDoesntRequireLegalDocument:
    "Esta assembleia não obriga à emissão de procuração.",
  printContract: "Imprimir contrato.",
  votingAgendaItems: "Votação dos pontos da ordem de trabalhos",
  presentialAssemblyDate: "Data da assembleia presencial: ",
  videoconferenceAssemblyDate: "Data da assembleia por videoconferência: ",
  mixedAssemblyDate: "Data da assembleia mista: ",
  alternativeAssemblyDate: "Segunda convocatória da assembleia: ",
  proxyIssue: "Emissão de Procuração",
  proxyIssue_description_p1:
    "A emissão da procuração é obrigatória e, para a votação ser considerada, a mesma deverá ser impressa, assinada e anexada a esta votação.",
  proxyIssue_description_p2:
    "Para obedecer aos requisitos legais, após concluir a votação, deverá escolher o representante e imprimir a procuração. Após assinar, deverá digitalizar e anexá-la.",
  proxyIssue_description_p3:
    "Alternativamente poderá fazer chegar a procuração já assinada à administração do condomínio por outros meios.",
  selectRepresentative: "Selecione o seu representante",
  condominiumAdministrationRepresentative:
    "Representante da administração do condomínio",
  indicateMyRepresentative: "Indico o meu representante",
  details: {
    representativeName: "Nome do representante",
    typeDocument: "Tipo de documento de identificação",
    number: "Número",
    expirationDate: "Validade",
    numberCC: "Número de cartão de cidadão",
    expirationDateCC: "Validade do cartão de cidadão",
    numberPassport: "Número de passaporte",
    expirationDatePassport: "Validade do passaporte",
    numberEntityTicket: "Número de bilhete de identidade",
    expirationDateEntityTicket: "Validade do bilhete de identidade",
    numberResidencePermit: "Número de título de residência",
    expirationDateResidencePermit: "Validade do título de residência",
    successRepresentative: "Representante alterado com sucesso.",
    failRepresentative: "Não foi possivel definir o representante.",
    successVotes: "Votos alterados com sucesso.",
    failVotes: "Não foi possivel definir os votos.",
    duplicatedAttachment: "Já existe um ficheiro com o mesmo nome",
    addDocumentSuccess: "Documento adicionado",
    addDocumentFail: "Não foi possivel adicionar o documento",
    inFavor: "A favor",
    against: "Contra",
    abstent: "Abstenção",
    failDeleteSignedDocument: "Não foi possivel apagar a procuração assinada.",
    successDeleteSignedDocument: "Procuração apagada com sucesso.",
    CMDSignFail: "Ocorreu um erro, tente novamente.",
  },
  validation_states: {
    valid: "Validada pela Administração",
    valid_description:
      "A sua votação será considerada na Assembleia. Caso pretenda efetuar alguma alteração por favor contactar a administração do condomínio.",
    invalid: "Votação inválida",
    invalid_description:
      "A sua votação foi considerada inválida. Em caso de dúvida por favor contactar a administração do condomínio.",
    validating: "Por validar pela Administração",
  },
  assemblyIsntAvailableYet: "Assembleia ainda não está disponível.",
  checkAgendaItems: "Ver pontos da ordem de trabalhos",
  goToVoting: "Ir para a votação",
  checkVoting: "Ver votação",
  deleteSignedDocumentConfirmationMessage:
    "Caso deseje continuar, a procuração assinada anteriormente adicionada será apagada, sendo necessário imprimir, assinar e anexar novamente.",
  votingForFractions_singular: "Votação referente à fração: ",
  votingForFractions_plural: "Votação referente às frações: ",
  noFullOwnership_infoText:
    "Condóminos em regime de compropriedade terão de emitir uma procuração personalizada ou comparecer na assembleia presencial.",
  documentsForConsultation: "Documentos para consulta",
  download: "Descarregar documento",
  videoconference: "Videoconferência",
  videoconferenceAccess: "Acesso à videoconferência",
  videoconferenceDescription:
    "Deverá ficar disponível momentos antes do inicio da assembleia",
  videoconferenceOtherInfo: "Informações complementares da videoconferência:",
  accessPassword: "Senha de acesso",
  minutesMeeting: "Ata da assembleia",
  minutesSubscriptionButton: "Declaração de subscrição da Ata",
  minutesSubscriptionPopup_description:
    "Deverá imprimir a declaração e fazê-la chegar à administração do condomínio, devidamente assinada, e através de carta ou correio eletrónico",
  CMDSignFormTitle: "Assinar com Chave Móvel Digital",
  phoneNumber: "Número de Telemóvel",
  signaturePin: "PIN de Assinatura",
  pin_question: "PIN?",
  fillAllFields: "Preencha todos os campos",
  forceSMS_title: "Confirmação do código de segurança",
  forceSMS_description:
    "Para continuar insira nos próximos 5 minutos o código que recebeu no seu telemóvel via aplicação Autenticação Gov",
  forceSMS_input: "Código de segurança",
  forceSMS_resend: "Enviar por SMS",
  plsWait: "Por favor aguarde",
  nextStepIndication: "Depois deste passo o seu documento será assinado. ",
  newNextStepIndication:
    "Depois deste passo o seu documento será assinado e será irreversível. Todo o processo de assinatura é da total responsabilidade legal do assinante.",
  newNextStepIndicationLink: "Pode consultar as políticas da CMD em ",
  stepIndicationLink: "www.autenticacao.gov.pt/cmd-assinatura",
  officialDocumentation: "Documentação oficial",
  clickToActivateCMD:
    "Clique para ativar ou gerir a assinatura da sua Chave Móvel Digital",
  linkToActivateCMD: "https://www.autenticacao.gov.pt/cmd-pedido-chave",
  signManually_title:
    "Imprimir a procuração, assinar manualmente, digitalizar e anexar procuração assinada",
  signDigitally_title: "Assinar usando a Chave Móvel Digital que pussuo",
  invalidFile: "Ficheiro invalido",
  confirmThisFile: "Tem a certeza que é este o documento a assinar?",
  confirm: "Confirmar",
  enterData: "Introduzir dados",
  amaLogoPath: "/images/ama-logo.svg",
  option1: "Opção 1 - ",
  option2: "Opção 2 - ",
  smsExplanation:
    "Para enviar o código de segurança por SMS clique 'ENVIAR POR SMS'",
  cmdSignSuccess: "Procuração assinada com sucesso",
  downloadDocument: "Baixar documento assinado",
  assignDescription1: "(1/4 Pré-visualizar o documento)",
  assignDescription2: "(2/4 Autenticação com chave móvel digital)",
  assignDescription3: "(3/4 Confirmação do código de segurança)",
  assignDescription4: "(4/4 Documento assinado)",
  dontSavePin:
    "O pin não é guardado localmente nem trasnmitido, sendo que a cada assinatura de documento terão de preencher com os dados.",
  loadingPDF: "A carregar documento. Aguarde p.f.",
};

export const ReceiptsTable = {
  title: "RECIBOS",
  number: "Número",
  document: "Documento",
  date: "Data",
  value: "Valor",
  payment: "Pagamento",
  type: "Forma de Pagamento",
  bank: "Banco",
  docNumber: "N.º Documento",
  paymentMethods: {
    1: "Numerário",
    2: "Cheque",
    3: "Transferência",
    4: "Multibanco",
    5: "Débito directo",
    6: "Outra",
    7: "Payshop",
    8: "Depósito",
    9: "TPA",
  },
  loading: "A Carregar dados",
  noReceipts: "Sem Recibos",
  notUpdatedInformation:
    "Esta informação poderá não estar atualizada. Todos os pagamentos efetuados apenas são considerados depois de validação e conciliação bancária. Em caso de dúvidas por favor contactar a administração do condomínio.",
  warning: "Aviso",
  sendEmail: "Enviar por e-mail",
  typeDocument: "Tipo de documento",
};

export const CurrentAccounts = {
  noAccounts: "Sem contas correntes",
  loading: "A Carregar dados",
  warning: "Aviso",
  sendEmail: "Enviar por e-mail",
  typeDocument: "Tipo de documento",
  documentTypes: {
    initialBalance: "Saldo inicial",
    warning: "Aviso",
    receipt: "Recibo",
    extraRevenue: "Receitas extra",
    creditInAdvance: "Adiantamento",
    credit: "Crédito",
    refund: "Reembolsos",
  },
  document: "Documento",
  fraction: "Fracção",
  value: "Valor",
  emailSent: "Email enviado com sucesso.",
  description: "Descrição",
  notUpdatedInformation:
    "Esta informação poderá não estar atualizada. Todos os pagamentos efetuados apenas são considerados depois de validação e conciliação bancária. Em caso de dúvidas por favor contactar a administração do condomínio.",
  debitBalance: "Saldo devedor",
  creditBalance: "Saldo credor",
  warningTwelveMonths:
    "Documentos emitidos nos últimos 12 meses - até ao dia de hoje - independentemente da data de vencimento.",
  emited12Months: "Movimentos lançados nos últimos 12 meses.",
  goToNoticeCreditsPage: "Verifique os valores a pagar",
  type: "Forma de Pagamento",
  paymentMethods: {
    1: "Numerário",
    2: "Cheque",
    3: "Transferência",
    4: "Multibanco",
    5: "Débito directo",
    6: "Outra",
    7: "Payshop",
    8: "Depósito",
    9: "TPA",
  },
};

export const OccurrencesTable = {
  title: "Ocorrências",
  subject: "Título",
  start: "Data de inicio",
  end: "Data Conclusão",
  add: "Nova ocorrência",
  loading: "A Carregar dados",
  noOccurrences: "Sem ocorrências",
  unStarted: "Por iniciar",
  onGoing: "Em curso",
  completed: "Concluídas",
  rating: "Avaliação",
  takeRatingNow: "Faça agora a avaliação",
  rate: "avaliar",
  detail: {
    new: "Nova ocorrência",
    edit: "Alterar ocorrência",
    done: "Ocorrência",
    subject: "Título",
    obs: "Descrição",
    type: "Tipo",
    success: "Ocorrência gravada com sucesso",
    fail: "Não foi possivel gravar a ocorrência",
    removeSuccess: "Ocorrência removida com sucesso",
    removeFail: "Não foi possivel gravar a ocorrência",
    ratingSuccess: "Ocorrência avaliada com sucesso",
    ratingFail: "Não foi possivel avaliar a ocorrência",
    date: "Data da ocorrência",
    noAttachments: "Sem anexos",
    attachments: "Anexos",
    addAttachments: "Adicionar",
    addAttachmentTitle: "Novo anexo",
    addAttachmentSuccess: "Anexo adicionado",
    addAttachmentFail: "Não foi possivel adicionar o anexo",
    removeAttachmentSuccess: "Anexo removido",
    removeAttachmentFail: "Não foi possivel remover o anexo",
    duplicatedAttachment: "Já existe um ficheiro com o mesmo nome",
    fileName: "Ficheiro",
    interventionStatus: "Estado da intervenção",
    closeDate: "Data de conclusão",
    rating: "Classificação ",
    administrationInformation: "Comentários da administração",
    unStarted: "Por iniciar",
    onGoing: "Em curso",
    completed: "Concluída",
    in_connector: " em ",
  },
};

export const CondominiumPage = {
  info: "INFORMAÇÕES",
  condominium: "Condomínio",
  subtitle: "Informações do Condomínio",
  exercise: "Excercício ativo",
  contributor: "N.º Contribuinte",
  iban: "IBAN",
  name: "Nome",
  address: "Morada",
  fractions: "Frações",
  quotas: "Quotas Anuais",
  code: "Código",
  description: "Descrição",
  permilage: "Permilagem",
  budget: "Orçamento",
  fund: "Fundo Reserva",
  insurance: "Seguro",
  photo: "Logótipo",
  total: "Total:",
  annualQuota: "Total",
  noFractions: "Sem fracções",
  loading: "A Carregar dados",
};

export const FractionsDebtsTable = {
  fraction: "Fração",
  inDebt: "Em débito",
  other_debts: "other_debts",
  otherDebts: "Outros devedores",
  previousOwners: " (anteriores proprietários)",
  noDebts: "Não existe informação disponível relativa a dívidas",
  loading: "A Carregar dados",
  total: "Total:",
  notUpdatedInformation:
    "Esta informação poderá não estar atualizada. Todos os pagamentos efetuados apenas são considerados depois de validação e conciliação bancária. Em caso de dúvidas por favor contactar a administração do condomínio.",
};

export const Messages = {
  title: "Mensagens",
  failList: "Não foi possivel ler os dados das mensagems",
  failDetail: "Não foi possivel ler o detalhe da mensagem",
  unread: "Por ler",
  noMessages: "Sem mensagens",
  noSelection: "Não se encontra seleccionada nenhuma mensagem!",
  noFractions: "Sem fracções",
};

export const BlogTable = {
  number: "Número",
  date: "Data",
  subject: "Título",
  image: "Imagem",
  newArticle: "Novo artigo",
  loading: "A Carregar dados",
  loadingImage: "A Carregar imagem",
  noArticles: "Sem artigos",
  edit: "editar",
  remove: "remover",
  detail: {
    new: "novo artigo",
    edit: "editar artigo",
    image: "imagem",
    subject: "titulo",
    subject_pt: "titulo em portugues",
    subject_en: "titulo em ingles",
    introduction: "introdução",
    introduction_pt: "introdução em portugues",
    introduction_en: "introdução em ingles",
    description: "descrição",
    description_pt: "descrição em portugues",
    description_en: "descrição em ingles",
    status_label: "estado do artigo",
    attachments: "Anexos",
    addImage: "Adicionar Imagem",
    editImage: "Alterar Imagem",
    fileName: "Ficheiro",
    removeSuccess: "Artigo removido com sucesso",
    removeFail: "Não foi possivel gravar o artigo",
    success: "Artigo gravado com sucesso",
    fail: "Não foi possivel gravar o artigo",
  },
  tableValues: {
    culture: "pt-PT",
  },
};

export const BankBalancesTable = {
  info_p1: "Valores não consolidados e obtidos em {%DATE%} às {%TIME%}.",
  info_p2: "Poderão haver pagamentos ou recebimentos ainda não lançados.",
  loading: "A Carregar dados",
  cashAccountBalance: "Saldo da conta caixa: ",
  noAccountTypes: {
    currentAccounts: "Sem informação disponível",
    savingsAccounts: "Sem informação disponível",
    reservationAccounts: "Sem informação disponível",
  },
  accountTypes: {
    cash: "Caixa",
    current: "Contas à ordem",
    savings: "Contas poupança",
    reservation: "Contas de fundo de reserva",
  },
  tableHeaders: {
    accountType: "Tipo de conta",
    balance: "Saldo",
    description: "Descrição",
    bank: "Banco",
    iban: "IBAN",
  },
};

export const BudgetAnalysis = {
  info_p1: "Valores obtidos em {%DATE%} às {%TIME%}.",
  info_p2: "Poderão haver pagamentos ou recebimentos ainda não lançados.",
  title: "Análise orçamental",
  loading: "A Carregar dados",
  date_connector: " a ",
  tableHeaders: {
    expenses: "Despesas",
    budgeted: "Orçamentado",
    posted: "Lançado",
    deviation: "Desvio",
    payed: "Liquidado",
    unpayed: "Por liquidar",
    revenues: "Receitas",
  },
  expensesTotal: "Total das despesas",
  zoneTotal: "Total da zona",
  revenuesTotal: "Total das receitas",
  total: "Total",
  noData: "Sem dados",
  static_description: {
    expenses: {
      budgeted: "Orçamentado: valor da rubrica no orçamento.",
      posted:
        "Lançado: valor dos documentos de fornecedores emitidos durante o exercício, associados à rubrica e do tipo orçamento.",
      deviation: "Desvio: diferença entre Orçamentado e Lançado.",
      payed:
        "Liquidado: valor liquidado de documentos de fornecedores do tipo orçamento, no exercício, para cada rubrica.",
      unpayed: "Por liquidar: lançado - Liquidado.",
    },
    revenues: {
      budgeted: "Orçamentado: valor da rubrica no orçamento.",
      posted:
        "Lançado: valor dos avisos de receitas extraordinárias no exercício, do tipo orçamento e associados à rubrica.",
      deviation: "Desvio: diferença entre Orçamentado e Lançado.",
      payed:
        "Liquidado: valor recebido, no exercício, dos avisos de receitas do tipo orçamento, para cada rubrica.",
      unpayed: "Por liquidar: lançado - Recebido.",
    },
  },
  noValues: "Sem informação disponível",
};

export const ExerciseBudget = {
  info_p1: "Valores obtidos em {%DATE%} às {%TIME%}.",
  info_p2: "Poderão haver pagamentos ou recebimentos ainda não lançados.",
  title: "Orçamento",
  loading: "A Carregar dados",
  date_connector: " a ",
  tableHeaders: {
    rubrics: "Rubricas",
    value: "Valor",
  },
  subtotal: "SubTotal",
  total: "Total",
  budgetTotal: "Total do orçamento",
  quotas_title: "Quotas a cobrar",
  quotas_tableHeaders: {
    zones: "Zonas",
    budget: "Orçamento",
    reserveFund: "Fundo de reserva",
    insurance: "Seguro",
    total: "Total",
  },
  noValues: "Sem informação disponível",
};

export const Balance = {
  liabilities: "Passivo",
  info_p1: "Valores obtidos em {%DATE%} às {%TIME%}.",
  info_p2: "Balanço até {%DATE%}",
  total: "Total",
  cashAndBankBalances: "Saldos de caixa e bancos",
  unpaid: "Por receber",
  equity: "Situação líquida",
  liabilities_plus_equity: "Passivo + Situação líquida",
  noValues: "Sem informação disponível",
  accountTypes: {
    cash: "Caixa",
    current: "Contas à ordem",
    savings: "Contas a prazo",
    reservation: "Fundo de reserva",
  },
  ownersDebts: "Dívidas de condóminos",
  extraRevenues: "Receitas extra por receber",
  ownersCredits: "Adiantamento de condóminos",
  unpayedExpenses: "Dívidas a fornecedores",
  reservationFunds: "Fundo de reserva",
  noReservationFunds: "Resultados líquidos",
};

export const ExerciseExpenses = {
  noValues: "Sem informação disponível",
  tableHeaders: {
    emission: "Emissão",
    rubric: "Rubrica",
    amount: "Valor ",
    balance: "Por Liquidar",
  },
};

export const Inquiries = {
  email: "Email",
  fillValidEmail: "Insira um email valido",
  EmailPopUpTitle: "Insira o seu email",
  noInquiries: "Sem inqueritos",
  invalidInquirie: "O inquérito já não se encontra disponível",
  inquirieStatus: "Estado do inquerito: ",
  statusLabels: {
    closed: "Fechado",
    waiting_opening: "Por abrir",
    in_progress: "Em progresso",
  },
  openingDate: "Data de abertura: ",
  closingDate: "Data de encerramento: ",
  fillInquirie: "Preencher inquerito",
  consultInquirie: "Consultar inquerito",
  checkSubmitedAnswers: "Consultar respostas dadas",
  reviewAnswers: "Rever respostas",
  viewResults: "Ver resultados",
  openInquiries: "Inquéritos em curso",
  unfinishedInquiries: "Inquéritos por finalizar",
  allInquiries: "Todos inquéritos",
  attachments: "Anexos",
  questions: "Questões",
  required: "Resposta obrigatória",
  availableChars: "{%NUM%} carateres disponíveis",
  selectOption: "Selecione uma resposta",
  previous: "Anterior",
  next: "Seguinte",
  continue: "Continuar",
  submit: "Submeter",
  ignore: "Ignorar",
  answersFailed: "Não foi possivel guardar as suas respostas.",
  commentsFailed: "Não foi possivel guardar os seus comentários.",
  comments: "Comentários finais",
  cantSubmitEmptyComment: "Não pode submeter um comentário vazio.",
  fillAllRequires: "Preencha todas as questões obrigatórias",
  final: "Finalização do processo",
  finish: "Finalizar",
  errorOccurred: "Ocorreu um erro",
  successFinish: "Inquerito finalizado com sucesso",
  numberAnswers: "Número de respostas",
  perThousand: "Por permilagem",
  perUser: "Por utilizador",
  participatingUsers: "Participantes",
  usersThatDidntRespond: "Não participantes",
  participations: "Participações",
  total: "Total: ",
  inquirieNotOpen: "O inquérito ainda não está aberto a participação",
  inquirieWhenOpen: "Poderá preencher o inquerito a partir de {%START_DATE%}",
  resultsWillBeAvailable:
    "Os resultados poderão ser visualizados após o fecho do inquérito.",
  tyForParticipating1: "Obrigado pela sua participação",
  tyForParticipating2: "A sua opinião é importante para nós.",
  inquirieValidBetweenDates:
    "Inquérito realizado entre {%START_DATE%} e {%END_DATE%}",
  betweenDates1: "De {%START_DATE%} ",
  betweenDates2: "a {%END_DATE%}",
  backToInquirie: "Voltar ao inquérito",
  invalidAccess:
    "Acesso inválido. Verifique se o email que inseriu corresponde à caixa de correio da qual obteve este acesso.",
};
