import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import { startLanguage } from "../resources/resources";

export default (initialState = {}) => {
  const store = createStore(reducers, initialState, applyMiddleware(thunk));

  store.getState();
  startLanguage();

  return store;
};
