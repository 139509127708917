import React, { useState, useEffect } from "react";
import { set_token } from "../storage/token";
import { setStoreRestrictedPages } from "../storage/restricted_pages";

const SupportPage = (props) => {
    const token = props?.match?.params?.token;

    useEffect(()=>{
        if(token) {
            set_token(token);
            setStoreRestrictedPages("profile");
        }
        window.open("/", "_self")
    })


    return null

}

export default SupportPage;