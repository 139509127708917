import React, { Fragment, useState, useEffect, useRef } from "react";
import Card from "../containers/Card";
import Select from "react-select";
import { globalResources } from "../../resources/resources";
import { notifyWarning, notifySuccess, notifyError } from "../elements/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FilePicker from "../elements/FilePicker";
import { PostLegalRepresentative } from "../../actions/votes";
import {
  toIdentificationDocumentSelectOptions,
  toIdentificationDocumentSelectOption,
  toRepresentativeObject,
} from "../../assets/FormatAndParse";
import { getObjectInObjectArray, RemoveFromArray } from "../../assets/utils";
import { Form, Field } from "react-final-form";
import {
  RenderInput,
  DatePickerAdapter,
  RenderRadio,
} from "../../assets/forms/render";
import IsLoading from "../elements/IsLoading";
import SignDocumentForm from "./SignDocumentForm";
import { isDateBeforeToday, isTodayBetweenDates } from "../../assets/utils.js";
import classNames from "classnames";

const RepresentativeForm = (props) => {
  const {
    assembly,
    resources,
    identificationDocumentTypes,
    resetUnsignedDocument,
    getAssembly,
    clearAssembly,
  } = props;
  const allowedRepresentatives = assembly.allowedLegalRepresentativeTypes;
  const [selectedRepresentative, setSelectedRepresentative] = useState();
  const changesAllowed =
    assembly.userVotingStatus &&
    (assembly.userVotingStatus.id === 3 || assembly.userVotingStatus.id === 5)
      ? false
      : true;

  // GET UNSIGNED DOCUMENT
  /*useEffect(()=>{
        if(assembly.id && unsignedDocument===undefined) {
            GetUnsignedLegalDocument(assembly.id).then((response) => response.json())
            .then(result => {
                setUnsignedDocument(result);
            })
            .catch(e => {});
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsignedDocument]);*/

  // GET SIGNED DOCUMENT
  /*const getSignedLegalDocument =()=>{
        GetSignedLegalDocument(assembly.id).then((response) => response.json())
        .then(result => {
            if (result && result.url){
                setSignedDocument(result);
            }
            else{
                setSignedDocument(null);
            }
        })
        .catch(e => {setSignedDocument(null)});
    }*/

  // CALL THE FUNCTION THAT GETS THE SIGNED DOCUMENT, WHEN THE PAGE IS FIRST RENDERED
  /*useEffect(()=>{
        if(assembly.id && signedDocument===undefined) {
            getSignedLegalDocument();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedDocument]);*/

  // GETS USERS PREVIOUS SELECTED REPRESENTATIVE
  useEffect(() => {
    if (allowedRepresentatives && selectedRepresentative === undefined) {
      const legalRepresentativeType = {
        id: assembly.chosenLegalRepresentativeType
          ? assembly.chosenLegalRepresentativeType.id
          : null,
        code: assembly.chosenLegalRepresentativeType
          ? assembly.chosenLegalRepresentativeType.code
          : null,
      };
      const userCustomLegalRepresentative = {
        name: assembly.userCustomLegalRepresentative
          ? assembly.userCustomLegalRepresentative.name
          : null,
        identificationDocumentType: {
          id: assembly.userCustomLegalRepresentative
            ? assembly.userCustomLegalRepresentative.identificationDocumentType
                .id
            : 0,
          code: assembly.userCustomLegalRepresentative
            ? assembly.userCustomLegalRepresentative.identificationDocumentType
                .code
            : null,
        },
        identificationDocument: assembly.userCustomLegalRepresentative
          ? assembly.userCustomLegalRepresentative.identificationDocument
          : null,
        identificationDocumentExpirationDate:
          assembly.userCustomLegalRepresentative
            ? assembly.userCustomLegalRepresentative
                .identificationDocumentExpirationDate
            : null,
      };

      setSelectedRepresentative({
        legalRepresentativeType: legalRepresentativeType,
        userCustomLegalRepresentative: userCustomLegalRepresentative,
      });
    }
  }, [allowedRepresentatives, selectedRepresentative]);

  function toggleRepresentative(representative, formValues) {
    //let newUserChoices={...formValues};
    //newUserChoices.representative=representative;
    //setUserChoices({...newUserChoices});
  }

  const selectRepresentative = (representative) => {
    return selectedRepresentative !== undefined &&
      selectedRepresentative !== null &&
      selectedRepresentative.legalRepresentativeType === representative ? (
      <div className="icon-thumbnail selected">
        <span className="title m-l-s">{resources.local.selected}</span>
      </div>
    ) : (
      <button
        className="btn btn-primary"
        onClick={() => {
          toggleRepresentative(representative);
        }}
      >
        {globalResources.verbs.select}
      </button>
    );
  };

  function handleUsersRepresentative(value, type, formValues) {
    if (
      isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) &&
      changesAllowed
    ) {
      let newRepresentative = { ...formValues.userCustomLegalRepresentative };
      switch (type) {
        case "NAME":
          newRepresentative.name = value;
          break;
        case "DOCUMENT_TYPE":
          newRepresentative.identificationDocumentType = { ...value.value };
          break;
        case "DOCUMENT_NUMBER":
          newRepresentative.identificationDocument = value;
          break;
        default:
          newRepresentative.identificationDocumentExpirationDate = value;
          break;
      }

      setSelectedRepresentative({
        legalRepresentativeType: selectedRepresentative.legalRepresentativeType,
        userCustomLegalRepresentative: newRepresentative,
      });
    } else {
      notifyError(resources.local.cantChangeRepresentative);
    }
  }

  const postRepresentative = async (representative) => {
    PostLegalRepresentative(assembly.id, representative)
      .then((response) => response.text())
      .then((result) => {
        if (result.length === 0) {
          notifySuccess(resources.local.details.successRepresentative);
          getAssembly();
          resetUnsignedDocument();
        } else {
          notifyError(resources.local.details.failRepresentative);
        }
      })
      .catch((e) => {
        notifyError(resources.local.details.failRepresentative);
      });
  };

  // CHECKS IF THE USER HAS FILLED ALL OF THE FIELDS REGARDING ITS CHOSEN REPRESENTATIVE
  const verifyUserCustomRepresentative = async (values) => {
    const custom = values.userCustomLegalRepresentative;
    if (
      custom &&
      custom.name &&
      custom.identificationDocument &&
      custom.identificationDocumentExpirationDate &&
      custom.identificationDocumentType &&
      custom.identificationDocumentType.code
    ) {
      const nameLeng = custom.name.trim().length;
      const docLeng = custom.identificationDocument.trim().length;
      const expDateLeng = custom.identificationDocumentExpirationDate.length;
      const docTypeID = custom.identificationDocumentType.id;
      const docTypeCodeLeng =
        custom.identificationDocumentType.code.trim().length;
      if (
        nameLeng === 0 ||
        docLeng === 0 ||
        expDateLeng === 0 ||
        !docTypeID > 0 ||
        docTypeCodeLeng === 0
      ) {
        notifyError(resources.local.fillAllOtherRepresentativeFields);
      } else {
        postRepresentative(values);
      }
    } else {
      notifyError(resources.local.fillAllOtherRepresentativeFields);
    }
  };

  // CHECKS IF ITS GONNA CHANGE THE SELECTED REPRESENTATIVE
  const checkRepresentative = async (values) => {
    if (values.legalRepresentativeType.id !== 0) {
      // IN THIS IF, ITS EITHER THE ASSEMBLY PRESIDENT, OR THE PROPOSED ONE
      postRepresentative(values);
    } else if (
      values.legalRepresentativeType.id === 0 &&
      getObjectInObjectArray(allowedRepresentatives, "id", 0) !== null
    ) {
      verifyUserCustomRepresentative(values); // VERIFY CUSTOM REPRESENTATIVE VALUES, AND THEN POST
    } else {
      notifyError(resources.local.cantChangeRepresentative);
    }
  };

  // HANDLE THE SELECTED REPRESENTATIVE
  const handleSelectedRepre = (id, formValues) => {
    if (
      isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) &&
      changesAllowed
    ) {
      id = parseInt(id);
      let repre = { ...formValues };
      repre.legalRepresentativeType = { ...toRepresentativeObject(id) };
      setSelectedRepresentative({ ...repre });
    } else {
      notifyError(resources.local.cantChangeRepresentative);
    }
  };

  const onSubmit = async (values) => {
    if (
      isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) &&
      changesAllowed
    ) {
      checkRepresentative(values);
    } else {
      notifyError(resources.local.cantChangeRepresentative);
    }
  };

  /*const documentTypeId = ( selectedRepresentative && selectedRepresentative.userCustomLegalRepresentative && selectedRepresentative.userCustomLegalRepresentative.identificationDocumentType) ? 
        selectedRepresentative.userCustomLegalRepresentative.identificationDocumentType.id : 0;

    const documentNumber_label = documentTypeId===2 ? resources.local.details.numberEntityTicket 
                            : (documentTypeId===3 ? resources.local.details.numberPassport 
                                : documentTypeId===4 ? resources.local.details.numberCC : resources.local.details.numberResidencePermit);

    const documentexpirationDate_label = documentTypeId===2 ? resources.local.details.expirationDateEntityTicket 
                                    : (documentTypeId===3 ? resources.local.details.expirationDatePassport 
                                        : documentTypeId===4 ? resources.local.details.expirationDateCC : resources.local.details.expirationDateResidencePermit);*/

  return (
    <Fragment>
      {allowedRepresentatives ? (
        <Fragment>
          {changesAllowed && (
            <Card title={resources.local.proxyIssue}>
              <p>{resources.local.proxyIssue_description_p1}</p>
              <p>{resources.local.proxyIssue_description_p2}</p>
              <p className="m-b-0">
                {resources.local.proxyIssue_description_p3}
              </p>
            </Card>
          )}
          {changesAllowed && (
            <Form
              initialValues={selectedRepresentative}
              onSubmit={onSubmit}
              render={({ handleSubmit, form, values }) => {
                return (
                  <Card title={resources.local.selectRepresentative}>
                    <form
                      className="voting"
                      onSubmit={(e) => {
                        handleSubmit(e, form);
                        e.preventDefault();
                      }}
                    >
                      <div
                        className={`${
                          !isTodayBetweenDates(
                            assembly.onlineVotingStartDate,
                            assembly.onlineVotingEndDate
                          ) || !changesAllowed
                            ? "disable-click"
                            : ""
                        }`}
                        onChange={(event) => {
                          handleSelectedRepre(event.target.value, values);
                        }}
                      >
                        {allowedRepresentatives.length > 0 &&
                          getObjectInObjectArray(
                            allowedRepresentatives,
                            "id",
                            1
                          ) && (
                            <div className="row m-l-0 m-r-0">
                              <Field
                                id="assemblyPresident"
                                name="legalRepresentativeType.id"
                                type="radio"
                                label={resources.local.assemblyPresidentLabel}
                                value={1}
                                component={RenderRadio}
                              />
                            </div>
                          )}
                        {allowedRepresentatives.length > 0 &&
                          getObjectInObjectArray(
                            allowedRepresentatives,
                            "id",
                            2
                          ) && (
                            <div className="row m-l-0 m-r-0">
                              <Field
                                id="proposedRepresentative"
                                name="legalRepresentativeType.id"
                                type="radio"
                                label={
                                  resources.local
                                    .condominiumAdministrationRepresentative
                                }
                                value={2}
                                component={RenderRadio}
                              />
                            </div>
                          )}
                        {allowedRepresentatives.length > 0 &&
                          getObjectInObjectArray(
                            allowedRepresentatives,
                            "id",
                            0
                          ) && (
                            <div className="row m-l-0 m-r-0">
                              <Field
                                id="userCustomRepresentative"
                                name="legalRepresentativeType.id"
                                type="radio"
                                label={resources.local.indicateMyRepresentative}
                                value={0}
                                component={RenderRadio}
                              />
                            </div>
                          )}
                      </div>
                      {selectedRepresentative &&
                        selectedRepresentative.legalRepresentativeType &&
                        selectedRepresentative.legalRepresentativeType.id ===
                          0 && (
                          <div className="row">
                            <div className="col-12">
                              <Field
                                readOnly={
                                  !isTodayBetweenDates(
                                    assembly.onlineVotingStartDate,
                                    assembly.onlineVotingEndDate
                                  ) || !changesAllowed
                                }
                                type="text"
                                label={
                                  resources.local.details.representativeName
                                }
                                name="userCustomLegalRepresentative.name"
                                component={RenderInput}
                              />
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label>
                                  {resources.local.details.typeDocument}
                                </label>
                                <Select
                                  placeholder={resources.local.chooseOption}
                                  isClearable={false}
                                  isDisabled={false}
                                  value={
                                    selectedRepresentative &&
                                    selectedRepresentative.userCustomLegalRepresentative &&
                                    selectedRepresentative
                                      .userCustomLegalRepresentative
                                      .identificationDocumentType.id !== 0
                                      ? toIdentificationDocumentSelectOption(
                                          selectedRepresentative
                                            .userCustomLegalRepresentative
                                            .identificationDocumentType
                                        )
                                      : null
                                  }
                                  onChange={(value) => {
                                    handleUsersRepresentative(
                                      value,
                                      "DOCUMENT_TYPE",
                                      values
                                    );
                                  }}
                                  className="user-profile__select"
                                  classNamePrefix="user-profile__select"
                                  options={toIdentificationDocumentSelectOptions(
                                    identificationDocumentTypes,
                                    "id"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <Field
                                readOnly={
                                  !isTodayBetweenDates(
                                    assembly.onlineVotingStartDate,
                                    assembly.onlineVotingEndDate
                                  ) || !changesAllowed
                                }
                                type="text"
                                label={resources.local.details.number}
                                name="userCustomLegalRepresentative.identificationDocument"
                                component={RenderInput}
                              />
                            </div>
                            <div className="col-3">
                              <Field
                                readOnly={
                                  !isTodayBetweenDates(
                                    assembly.onlineVotingStartDate,
                                    assembly.onlineVotingEndDate
                                  ) || !changesAllowed
                                }
                                label={resources.local.details.expirationDate}
                                name="userCustomLegalRepresentative.identificationDocumentExpirationDate"
                                dateFormat="dd-MM-yyyy"
                                component={DatePickerAdapter}
                              />
                            </div>
                          </div>
                        )}
                      {changesAllowed && (
                        <div className="row justify-content-end m-t-m">
                          <div className="col-auto">
                            <button
                              onClick={() => {
                                clearAssembly();
                              }}
                              className="btn btn-primary"
                            >
                              {globalResources.verbs.cancel}
                            </button>
                          </div>
                          <div className="col-auto">
                            <button
                              disabled={assembly.isOpenForVoting ? false : true}
                              type="submit"
                              className={classNames("btn", {
                                "btn-primary":
                                  assembly.isOpenForVoting === true,
                                "btn-disabled disable-click":
                                  assembly.isOpenForVoting === false,
                              })}
                            >
                              {globalResources.verbs.save}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </Card>
                );
              }}
            />
          )}
          <SignDocumentForm
            assembly={assembly}
            resources={resources}
            unsignedDocument={props.unsignedDocument}
            signedDocument={props.signedDocument}
            resetSignedDocument={() => {
              props.resetSignedDocument();
            }}
            getAssembly={() => {
              getAssembly();
            }}
            deleteSignedDocument={() => {
              props.deleteSignedDocument();
            }}
          />
        </Fragment>
      ) : (
        <Card>
          <IsLoading text={resources.local.loading} />
        </Card>
      )}
    </Fragment>
  );
};

export default RepresentativeForm;
