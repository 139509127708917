import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import ExerciseExpensesTable from "../components/tables/ExerciseExpensesTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const ExerciseExpensesPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.exerciseExpenses.breadcrumb} />
      <div className="container-fluid">
        <ExerciseExpensesTable></ExerciseExpensesTable>
      </div>
    </Master>
  );
};

export default ExerciseExpensesPage;
