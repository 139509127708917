import React, { useState, useEffect, Fragment } from "react";
import Table, {
  Number,
  Column,
  NoData,
  Option,
  Loading,
  DateTime,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetBlog, RemoveArticle } from "../../actions/blog";
import Card from "../containers/Card";
import {
  BlogTableResources,
  navigationResources,
  globalResources,
} from "../../resources/resources";
import BlogForm from "../forms/BlogForm";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faEdit } from "@fortawesome/free-regular-svg-icons";
import Breadcrumb from "../elements/Breadcrumb";
import { notifySuccess, notifyError } from "../../components/elements/Notify";

const BlogTable = (props) => {
  const [data, setData] = useState();
  const [resources] = useState(BlogTableResources);
  const [detail, setDetail] = useState(null);
  const user = useSelector((state) => state.user.detail);
  
  const getBlogInfo = ()=>{
    GetBlog(true).then((response) => response.json())
    .then(result => {
      setData(result);
    })
    .catch(e => {setData([])});
  }
  
  useEffect(() => {
    if(data===undefined){
      getBlogInfo();
    }
  }, [data]);

  function Remove(id){
    RemoveArticle(id)
    .then((response) => {
      notifySuccess(resources.local.detail.removeSuccess);
      setData();
    })
    .catch((e) => {
      notifyError(resources.local.detail.removeFail);
    });
  }

  return (
    <Fragment>
      <Breadcrumb
        page={
          detail === null
            ? navigationResources.blog.breadcrumb
            : detail > 0
            ? navigationResources.blog.edit
            : navigationResources.blog.new
        }
        parent={
          detail === null
            ? undefined
            : {
                name: navigationResources.blog.breadcrumb,
                onClick: () => {
                  setDetail(null);
                },
              }
        }
      />
      {detail === null && (
        <Card subclass="max-width">
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-primary m-r-s"
                onClick={() => setDetail(0)}
              >
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                {resources.local.newArticle}
              </button>
            </div>
          </div>
          <div className="rows-clickable">
          <Table
            data={data}
            pager={{ pageIndex: 0 }}
            onRowClick={(e, row) => {
              setDetail(row.original.id);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Column 
              Header={resources.local.number} 
              accessor="id"
              align="left"
              className="collapse"
            />
            <DateTime
              Header={globalResources.date}
              accessor="creationDate"
              align="left"
              className="collapse"
              desc={true}
            />
            <Column
              Header={resources.local.subject}
              align="left"
              className="collapse"
              Cell={(table) => { 
                const doc = table.row.original;
                const textsIndex = (doc && doc.texts && doc.texts.length>0) ? doc.texts.findIndex(item=> item.culture===resources.local.tableValues.culture) : 0; 
                return (
                  <span>
                    {(doc && doc.texts && doc.texts.length>0) ? 
                      doc.texts[textsIndex].title
                      : ""
                    }
                  </span>
                );
              }}
            />
            <Option
              id="remove"
              Cell={({ row, tag }) => {
                const article = row.original;
                return (
                  <div className="btn-inside-center">
                    <button
                      onClick={(e) => {
                        Remove(article.id);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      title={globalResources.verbs.remove}
                      className="btn btn-danger"
                    >
                      <FontAwesomeIcon className="m-0" icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </div>
                  );
              }}
            />
            <NoData>{resources.local.noArticles}</NoData>
            <Loading value={data === undefined}>
              <IsLoading text={resources.local.loading} />
            </Loading>
          </Table>
          </div>
        </Card>
      )}
      {detail !== null && (
        <BlogForm
          id={detail}
          after={(update) => {
            setDetail(null);
            if (update) setData(undefined);
          }}
        ></BlogForm>
      )}
    </Fragment>
  );
};

export default BlogTable;
