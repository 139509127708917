import React from "react";

export const ForOwners = (language) =>
  language === "pt" ? (
    <div>
      <p>
        O Gecond, o meu condomínio é um serviço disponibilizado pelas
        administrações de condomínio que utilizem o software de gestão de
        condomínios Gecond.
      </p>
      <p>
        Como condómino poderá aceder aos valores pagos e por pagar das suas
        frações, imprimir segunda vias das mesmas, comunicar com a administração
        do condomínio e aceder a informação que a mesma possa disponibilizar,
        como por exemplo atas de assembleias, regulamento interno, etc.
      </p>
    </div>
  ) : (
    <div>
      <p>
        Gecond, my condominium is a service provided by condominium
        administrations that use Gecond condominium management software.
      </p>
      <p>
        As a apartment owner, you will be able to access the paid and unpaid
        amounts of your fractions, print copies of them, communicate with the
        condominium administration and access information that it may provide,
        such as minutes of meetings, internal regulations, etc.
      </p>
    </div>
  );
