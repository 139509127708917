import React, { Fragment, useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Form, Field } from "react-final-form";
import Card from "../containers/Card";
import {
  VotingsTableResources,
  globalResources,
  navigationResources,
} from "../../resources/resources";
import {
  RenderInput,
  DatePickerAdapter,
  RenderRadio,
} from "../../assets/forms/render";
import { notifyError, notifySuccess } from "../../components/elements/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  toIdentificationDocumentSelectOptions,
  toIdentificationDocumentSelectOption,
} from "../../assets/FormatAndParse";
import {
  GetIdentificationDocumentTypes,
  GetAssembly,
  PostLegalRepresentative,
  PostVotes,
} from "../../actions/votes";
import {
  getObjectInObjectArray,
  findIndexInObjectArray,
} from "../../assets/utils";
import IsLoading from "../elements/IsLoading";
import SignDocumentForm from "./SignDocumentForm";
import RepresentativeForm from "./RepresentativeForm";
import Breadcrumb from "../elements/Breadcrumb";
import { isDateBeforeToday, isTodayBetweenDates } from "../../assets/utils.js";
import classNames from "classnames";

const VotingsForm = (props) => {
  const { assembly, clearAssembly, resources, getAssembly } = props;
  //const [mode, setMode] = useState(0); // MODE 0 IS VOTES, MODE 1 IS REPRESENTATIVE AND , MODE 2 IS SIGN DOCUMENT
  //const [identificationDocumentTypes, setIdentificationDocumentTypes] = useState(props.identificationDocumentTypes);
  const [agendaItemId, setAgendaItemId] = useState(null);
  const [userVotes, setUserVotes] = useState([]);
  const usersPreviousVotes = useRef();
  const changesAllowed =
    assembly.userVotingStatus &&
    (assembly.userVotingStatus.id === 3 || assembly.userVotingStatus.id === 5)
      ? false
      : true;

  /*// LOAD ASSEMBLY INFO
  useEffect(() => {
    if (assembly.agendaItems===undefined) {
      GetAssembly(assembly.id).then((response) => response.json())
        .then(result => {
            setAssembly(result);
        })
        .catch(e => {});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  },[assembly]);*/

  /*// GET THE IDENTIFICATION DOCUMENT TYPES ALLOWED
  useEffect(()=>{
    if(identificationDocumentTypes.length===0){
        GetIdentificationDocumentTypes().then((response) => response.json())
        .then(result => {
            setIdentificationDocumentTypes(result);
        })
        .catch(error => {});
    }
  }, [identificationDocumentTypes]);*/

  // SETS THE SELECTED REPRESENTATIVE, AND THE USER'S VOTING VOTES
  useEffect(() => {
    if (assembly.agendaItems) {
      let votes = [];
      assembly.agendaItems.forEach((doc) => {
        if (doc.votedOption) {
          votes = [
            ...votes,
            {
              agendaItemId: doc.id,
              votingOptionId: doc.votedOption.id,
            },
          ];
        }
      });
      usersPreviousVotes.current = [...votes];
      setUserVotes([...votes]);
    }
  }, [assembly]);

  function goNext() {
    if (
      !isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) ||
      !changesAllowed
    ) {
      notifyError(resources.local.cantChangeVotes);
    }
    if (assembly.requiresLegalRepresentativeDocument) {
      notifySuccess(resources.local.details.successVotes);
      props.goNext();
    }
  }

  //console.log(!isTodayBetweenDates(assembly.onlineVotingStartDate, assembly.onlineVotingEndDate) || !changesAllowed)

  const postVotes = async () => {
    PostVotes(assembly.id, userVotes)
      .then((response) => response.text())
      .then((result) => {
        if (result.length === 0) {
          //notifySuccess(resources.local.details.successVotes);
          getAssembly();
          usersPreviousVotes.current = [...userVotes];
          goNext();
        } else {
          notifyError(resources.local.details.failVotes);
        }
      })
      .catch((e) => {
        notifyError(resources.local.details.failVotes);
        //setVotesTakenCareOf(false);
      });
  };

  const checkVotes = async () => {
    if (
      isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) &&
      userVotes.length > 0 &&
      changesAllowed
    ) {
      postVotes();
    } else {
      goNext();
    }
  };

  const onSubmit = async () => {
    setAgendaItemId(null);
    if (
      JSON.stringify(userVotes) !== JSON.stringify(usersPreviousVotes.current)
    ) {
      // FORM VALUES ARE DIFFERENT TO THE PREVIOUS ONES, THEREFORE CONTINUE TO VALIDATIONS
      if (
        !isTodayBetweenDates(
          assembly.onlineVotingStartDate,
          assembly.onlineVotingEndDate
        ) ||
        !changesAllowed
      ) {
        goNext(); // GO NEXT PAGE
      } else {
        checkVotes();
        //checkRepresentative(values);
      }
    } else {
      goNext(); // GO NEXT PAGE
    }
  };

  const renderList = () => {
    let votingOptions = []; // VOTING OPTIONS FOR THIS AGENDA ITEM
    let userVotedOption = {}; // VOTED OPTION FOR THIS AGENDA ITEM
    let selectedAgendaItem = {}; // IF AN AGENDA ITEM IS BEING DISPLAYED, THEN THIS HOLDS THE INFORMATION OF SAID ITEM
    if (agendaItemId !== null) {
      selectedAgendaItem = assembly.agendaItems.filter(
        (option) => option.id === agendaItemId
      )[0];
      selectedAgendaItem.allowedVotingOptions.forEach((doc) => {
        votingOptions = [
          ...votingOptions,
          {
            value: doc.id,
            label: doc.description,
          },
        ];
      });

      const userChoiceForThisVote = userVotes.filter(
        (option) => option.agendaItemId === agendaItemId
      );
      if (userChoiceForThisVote.length > 0 && votingOptions.length > 0) {
        const voteOption = votingOptions.filter(
          (option) => option.value === userChoiceForThisVote[0].votingOptionId
        );
        userVotedOption = {
          value: userChoiceForThisVote[0].votingOptionId,
          label: voteOption[0].label,
        };
      }
    }

    return (
      <Fragment>
        {assembly.agendaItems.map((item, index) => {
          const formValueForItem = userVotes.filter(
            (option) => option.agendaItemId === item.id
          );
          const voteDescription =
            formValueForItem.length === 0
              ? ""
              : getObjectInObjectArray(
                  item.allowedVotingOptions,
                  "id",
                  formValueForItem[0].votingOptionId
                ).description;
          return (
            <div
              key={item.id}
              onClick={(event) => {
                agendaItemId === item.id
                  ? setAgendaItemId(null)
                  : setAgendaItemId(item.id);
              }}
            >
              <Card
                subclass={`vote ${changesAllowed ? "changesAllowed" : ""} ${
                  agendaItemId === item.id ? "active" : ""
                }`}
              >
                <div className="vote-subject">
                  {index + 1}. {item.title}
                </div>
                {item.description && (
                  <div
                    className={`vote-text ${
                      agendaItemId === item.id ? "active" : ""
                    }`}
                  >
                    {item.description}
                  </div>
                )}
                <div className="row vote-type">
                  <span className={"grey-text f-w-600"}>
                    {item.isInformativeAgendaItem
                      ? resources.local.informative
                      : resources.local.vote}
                  </span>
                  <span
                    className={`vote-description ${
                      voteDescription === resources.local.details.inFavor
                        ? "green-text"
                        : voteDescription === resources.local.details.against
                        ? "red-text"
                        : voteDescription === resources.local.details.abstent
                        ? "yellow-text"
                        : "grey-text"
                    }`}
                  >
                    {voteDescription}
                  </span>
                </div>
                <div className="row no-margin">
                  <Fragment>
                    {agendaItemId === item.id && changesAllowed ? (
                      <div className="vote-options">
                        {!selectedAgendaItem.isInformativeAgendaItem &&
                          votingMethod(
                            selectedAgendaItem,
                            userVotedOption,
                            votingOptions,
                            userVotes,
                            formValueForItem
                          )}
                      </div>
                    ) : (
                      <div />
                    )}
                  </Fragment>
                </div>
              </Card>
            </div>
          );
        })}
        {changesAllowed && (
          <Card>
            <div className="row justify-content-end">
              <div className="col-auto">
                <button
                  onClick={() => {
                    clearAssembly();
                  }}
                  className="btn btn-primary"
                >
                  {globalResources.verbs.cancel}
                </button>
              </div>
              <div className="col-auto">
                <button
                  disabled={assembly.isOpenForVoting ? false : true}
                  type="submit"
                  className={classNames("btn", {
                    "btn-primary": assembly.isOpenForVoting === true,
                    "btn-disabled disable-click":
                      assembly.isOpenForVoting === false,
                  })}
                >
                  {globalResources.verbs.save_alt}
                </button>
              </div>
            </div>
          </Card>
        )}
      </Fragment>
    );
  };

  function votingMethod(
    selectedAgendaItem,
    userVotedOption,
    votingOptions,
    votes,
    formValueForItem
  ) {
    const inFavor = getObjectInObjectArray(
      selectedAgendaItem.allowedVotingOptions,
      "description",
      resources.local.details.inFavor
    );
    const abstent = getObjectInObjectArray(
      selectedAgendaItem.allowedVotingOptions,
      "description",
      resources.local.details.abstent
    );
    const against = getObjectInObjectArray(
      selectedAgendaItem.allowedVotingOptions,
      "description",
      resources.local.details.against
    );

    const voteForAgenda = userVotes.filter(
      (option) => option.agendaItemId === selectedAgendaItem.id
    );

    if (
      selectedAgendaItem.allowedVotingOptions.length === 3 &&
      inFavor &&
      against &&
      abstent
    ) {
      return (
        <div className="row voting-buttons">
          <div
            className={`btn btn-vote inFavor ${
              voteForAgenda.length > 0
                ? voteForAgenda[0].votingOptionId == inFavor.id
                  ? "active"
                  : "non-active"
                : ""
            }`}
            onClick={(e) => {
              handleUsersVote(inFavor.id, e);
            }}
          >
            {inFavor.description}
          </div>
          <div
            className={`btn btn-vote abstent ${
              voteForAgenda.length > 0
                ? voteForAgenda[0].votingOptionId == abstent.id
                  ? "active"
                  : "non-active"
                : ""
            }`}
            onClick={(e) => {
              handleUsersVote(abstent.id, e);
            }}
          >
            {abstent.description}
          </div>
          <div
            className={`btn btn-vote against ${
              voteForAgenda.length > 0
                ? voteForAgenda[0].votingOptionId == against.id
                  ? "active"
                  : "non-active"
                : ""
            }`}
            onClick={(e) => {
              handleUsersVote(against.id, e);
            }}
          >
            {against.description}
          </div>
        </div>
      );
    } else {
      return (
        <div
          onChange={(event) => {
            handleUsersVote(parseInt(event.target.value), event);
          }}
          onClick={(event) => event.stopPropagation()}
          className={`${
            !isTodayBetweenDates(
              assembly.onlineVotingStartDate,
              assembly.onlineVotingEndDate
            ) || !changesAllowed
              ? "disable-click"
              : ""
          }`}
        >
          {selectedAgendaItem.allowedVotingOptions.map((item, index) => {
            return (
              <div key={item.id} className="radio radio-success ">
                <input
                  defaultChecked={item.id === userVotedOption.value}
                  name="vote"
                  type="radio"
                  value={item.id}
                />
                <label htmlFor="vote">{item.description}</label>
              </div>
            );
          })}
        </div>
        /*<Select
                placeholder={resources.local.chooseOption}
                isClearable={false}
                isDisabled={false}
                value={userVotedOption.value ? userVotedOption : null}
                onChange={(value)=>{handleUsersVote(value.value)}}
                className="user-profile__select"
                classNamePrefix="user-profile__select"
                options={[...votingOptions]}/>*/
      );
    }
  }

  function handleUsersVote(id, e) {
    e.stopPropagation();
    if (
      isTodayBetweenDates(
        assembly.onlineVotingStartDate,
        assembly.onlineVotingEndDate
      ) &&
      changesAllowed
    ) {
      let newVotes = [];
      for (let vote of userVotes) {
        if (vote.agendaItemId !== agendaItemId) {
          newVotes = [...newVotes, vote];
        }
      }
      newVotes = [
        ...newVotes,
        {
          agendaItemId: agendaItemId,
          votingOptionId: id,
        },
      ];

      setUserVotes([...newVotes]);
    } else {
      notifyError(resources.local.cantChangeVotes);
    }
  }

  const allowedRepresentatives = assembly.allowedLegalRepresentativeTypes;

  return (
    <Fragment>
      {assembly.agendaItems ? (
        <Form
          initialValues={userVotes}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, values }) => {
            return (
              <Fragment>
                <Card
                  subclass="card-just-title"
                  title={resources.local.votingAgendaItems}
                />
                <form
                  className="voting m-b-l"
                  onSubmit={(e) => {
                    handleSubmit(e, form);
                    e.preventDefault();
                  }}
                >
                  {renderList()}
                </form>
              </Fragment>
            );
          }}
        />
      ) : (
        <Card>
          <IsLoading text={resources.local.loading} />
        </Card>
      )}
    </Fragment>
  );
};

export default VotingsForm;
