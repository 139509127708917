import React, { Fragment, useState, useEffect } from "react";
import Table, {
  Column,
  NoData,
  Loading,
  Number,
  Option,
  Date,
  Header,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetBudgetsExercise } from "../../actions/documents";
import Card from "../../components/containers/Card";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExerciseBudgetResources, globalResources } from "../../resources/resources";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { toAccountTypeLabel, toTableNumber } from "../../assets/FormatAndParse";
import { getDate, getTime } from "../../assets/utils";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";

const ExerciseBudgetTable = () => {
  const [data, setData] = useState();
  const analysisDate = useRef("");
  const [resources] = useState(ExerciseBudgetResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const activeBudget = useRef(true);

  useEffect(() => {
    if (condominium) {
        activeBudget.current=true;
        setData();
        GetBudgetsExercise(condominium.id).then((response) => {
          if (response.data.analysisDate){
            analysisDate.current=response.data.analysisDate;
          }
          setData(response.data);
      }).catch((err)=>{
        const errorMessage = globalResources.apiError.activeBudgetNotFound;
        if(err.response.data && err.response.data.error===errorMessage){
          activeBudget.current=false;
        } 
        analysisDate.current="";
        setData([]);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  const renderBudgetContainers = () => {
    return(
        <Fragment>
            {data.exercise.budgetContainers.map((item, index) => {
            const nameContainer = (item.ContainerName && item.ShowContainerName) ? item.ContainerName : "";
            const extraClass = (item.ContainerName && item.ShowContainerName) ? "container-grey-title" : "";
            const totalFooter_label = (data.exercise.budgetContainers.length>1) ? 
                    ((item.ContainerTotalValueType.id===2) ? resources.local.subtotal : resources.local.total) 
                    : resources.local.budgetTotal;
            const totalFooter_value = (data.exercise.budgetContainers.length>1) ? item.ContainerTotal : data.exercise.budgetTotal;
            return(
                <div key={index} className={extraClass}>
                  <div className={`card-header p-0 ${index!==0 ? "m-t-xxl" : ""}`}><div className="card-title">{nameContainer}</div></div>
                  {item.rubrics.length>0 &&
                  <div className="colorless-table">
                      <Table data={item.rubrics} pager={{}} disabledSort={true}>
                      <Column Header={resources.local.tableHeaders.rubrics} accessor="rubricName"
                          Footer={totalFooter_label}/>
                      <Number Header={resources.local.tableHeaders.value}
                          accessor="rubricValue" className="collapse" Footer={toTableNumber(totalFooter_value)}/>
                      </Table>
                  </div>
                  }
                </div>
            );})}
            {(data.exercise.budgetContainers.length>1) &&
              <div className="headless-table">
                <Table data={[{"rubricValue":data.exercise.budgetTotal, "rubricName":resources.local.budgetTotal}]}>
                  <Column Header={resources.local.tableHeaders.rubrics} accessor="rubricName"/>
                  <Number Header={resources.local.tableHeaders.value} accessor="rubricValue" className="collapse" />
                </Table>
              </div>
            }
        </Fragment>
    )
  }

  return data ? (
      <Fragment>
        {(data && data.budget) &&
            <Card subclass="max-width table-info">
            <div className="row">
                <div className="col-lg-6">
                    {(analysisDate.current!=="" && analysisDate.current!==null) &&
                        <div>
                            <p>{resources.local.info_p1
                            .replace("{%DATE%}", getDate(analysisDate.current))
                            .replace("{%TIME%}", getTime(analysisDate.current))}
                            </p>
                            {/*<p>{resources.local.info_p2}</p>*/}
                        </div>
                    }
                </div>
                <div className="col-lg-6 text-right">
                    <p>{resources.local.title}</p>
                    {(data && data.budget) &&
                        <Fragment>
                            {/*<p>{data.budget.title}</p>*/}
                            <p>{getDate(data.budget.startDate)+resources.local.date_connector+getDate(data.budget.endDate)}</p>
                        </Fragment>
                    }
                </div>
            </div>
            </Card>
        }
        {((data && data.exercise && data.exercise.budgetContainers && data.exercise.budgetContainers.length>0) ||
          (data && data.exercise && data.exercise.zonesQuotas && data.exercise.zonesQuotas.length>0)) &&
          <Card>

          {(data && data.exercise && data.exercise.budgetContainers && data.exercise.budgetContainers.length>0) &&
            renderBudgetContainers()
          }

          {(data && data.exercise && data.exercise.zonesQuotas && data.exercise.zonesQuotas.length>0) &&
            <div className="m-t-xxl">
              <div className="card-header p-0"><div className="card-title">{resources.local.quotas_title}</div></div>
              <div className="colorless-table">
                <Table data={data.exercise.zonesQuotas} disabledSort={true}>
                    <Column accessor="zoneName" Footer={resources.local.total}
                      Header={ data.exercise.budgetContainers.length>1 ? resources.local.quotas_tableHeaders.zones : ""}/>
                    <Number Header={resources.local.quotas_tableHeaders.budget} accessor="budgetValue" className="collapse" Footer={toTableNumber(data.exercise.quotasBudgetTotal)}/>
                    <Number Header={resources.local.quotas_tableHeaders.reserveFund} accessor="reservationFundsValue" className="collapse" Footer={toTableNumber(data.exercise.quotasReservationFundsTotal)}/>
                    <Number Header={resources.local.quotas_tableHeaders.insurance} accessor="insuranceValue" className="collapse" Footer={toTableNumber(data.exercise.quotasInsuranceTotal)}/>
                    <Number Header={resources.local.quotas_tableHeaders.total} accessor="zoneQuotasTotal" className="collapse" Footer={toTableNumber(data.exercise.quotasTotal)}/>
                </Table>
              </div>
            </div>
          }
        </Card>
        }

        {(!activeBudget.current) &&
          <Card>{globalResources.noActiveBudget}</Card>
        }

        {(data && data.length===0 && activeBudget.current) &&
          <Card>{resources.local.noValues}</Card>
        }
          
      </Fragment>
    ) : (
        <Card>
          <IsLoading text={resources.local.loading} />
        </Card>
      );
};

export default ExerciseBudgetTable;
