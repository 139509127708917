import React, { useEffect, useState, useRef, Fragment } from "react";
import Modal from "react-modal";
import IsLoading from "./IsLoading";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";
import { copyToClipboard, validateEmail } from "../../assets/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { ConsoleView } from "react-device-detect";
import { toIBAN } from "../../assets/FormatAndParse";
import { globalResources } from "../../resources/resources";
import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { required } from "../../assets/forms/validation";
import {
  RenderInput,
  RenderPhoneInput,
  RenderInputCMD,
  RenderPhoneInputCMD,
  RenderUserTextarea,
  RenderRadio,
  DatePickerAdapter,
} from "../../assets/forms/render";
import { notifySuccess, notifyError } from "../../components/elements/Notify";
import { JSEncrypt } from "jsencrypt";
import { GetPublicKey } from "../../actions/cmd";
import classNames from "classnames";

export const PaymentPopup = (props) => {
  const paymentOptions = props.paymentOptions;
  const localResources = props.localResources;
  const user = props.user;

  Modal.setAppElement("body");

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  useEffect(() => {
    if (paymentOptions) {
      if (user.detail.applicationTheme.value === 2) {
        document
          .getElementsByClassName("ReactModalPortal")[0]
          .classList.add("dark-theme");
      }
    }
  });

  const MBWAY_logo = () => {
    if (user && user.detail && user.detail.applicationTheme) {
      if (user.detail.applicationTheme.value === 1) {
        return localResources.MBWAY_image;
      } else {
        return localResources.MBWAY_white_image;
      }
    }
  };

  if (paymentOptions === "loading") {
    return (
      <Modal
        isOpen={paymentOptions === "loading"}
        className="Modal"
        overlayClassName="Overlay"
      >
        <IsLoading text={localResources.loading} />
      </Modal>
    );
  } else if (paymentOptions === "error") {
    return (
      <Modal
        isOpen={paymentOptions === "error"}
        className="Modal"
        onRequestClose={props.resetPaymentOptions}
        overlayClassName="Overlay"
      >
        <h1>{localResources.insertValidValue}</h1>
      </Modal>
    );
  } else if (paymentOptions != null) {
    const value = formatNumber(
      toFixed(parseFloat(props?.value.toString().replace(",", ".")), 2)
    );

    let IBAN;
    if (
      paymentOptions?.moneyTransfer &&
      paymentOptions?.moneyTransfer?.length > 0
    ) {
      IBAN = paymentOptions.moneyTransfer[0].iban.replace(/ /g, "");
    }

    let paymentInformationShowcased = null;
    if (paymentOptions.mbPayment && props.value >= 4) {
      // MB PAYMENT
      const mbPaymentAmount =
        formatNumber(
          toFixed(
            parseFloat(
              paymentOptions.mbPayment.amount.toString().replace(",", ".")
            ),
            2
          )
        ) + paymentOptions.currency.symbol;
      paymentInformationShowcased = (
        <div className="new_style_flex_row">
          <div className="new_style_border_right w_50">
            <div className="new_style_flex_row">
              <div id={"MBWAY_title_container"}>
                <div id={"MBWAY_image"}>
                  <img src={MBWAY_logo()} height="100" />
                </div>
              </div>
              <div className={"payment_info_container"}>
                {/*Entidade, referencia, montante */}
                {paymentOptions.mbPayment.entity && (
                  <p>
                    <label className="new_style_payment_info_title">
                      {localResources.entity} {paymentOptions.mbPayment.entity}
                    </label>
                  </p>
                )}
                {paymentOptions.mbPayment.reference && (
                  <p>
                    <label className="new_style_payment_info_title">
                      {localResources.reference}{" "}
                      {paymentOptions.mbPayment.reference}
                    </label>
                  </p>
                )}
                <p>
                  <label className="new_style_payment_info_title">
                    {localResources.amount} {mbPaymentAmount}
                  </label>
                </p>
                {/* */}
              </div>
            </div>
            <div id={"MBWAY_title"} className="pl_20">
              <p className="new_style_payment_description">
                {localResources.MBWAY_title}
              </p>
            </div>
          </div>
          <div className="w_50 new_style_align_center">
            <button
              className="btn new-style-btn-primary new_style_btn_text border_radius"
              onClick={() => {
                console.log("Botão clicado");
              }}
            >
              {localResources.payOnline}
            </button>
            <p className="new_style_text mt_30 ">{localResources.directBank}</p>
          </div>
        </div>
      );
    } else {
      // IBAN PAYMENT
      if (!!IBAN) {
        paymentInformationShowcased = (
          <div className={"payment_info_container"}>
            <p>
              <label className="payment_info_title">
                {localResources.IBAN_title}
              </label>
            </p>
            <span id={"payment_info_iban_paragraph"}>
              <FontAwesomeIcon
                className={"payment-icon"}
                icon={faCopy}
              ></FontAwesomeIcon>
              <span id={"payment_info_iban"}>{toIBAN(IBAN)}</span>
              <button
                className={"btn btn-primary m-l-s"}
                onClick={() => {
                  copyToClipboard(IBAN, localResources.IBANCopied);
                }}
              >
                <span>{localResources.copy}</span>
              </button>
            </span>
          </div>
        );
      } else {
        paymentInformationShowcased = (
          <div className={"payment_info_container"}>
            <p>
              <label className="payment_info_title">
                {localResources.ibanPaymentNotFound}
              </label>
            </p>
          </div>
        );
      }
    }

    return paymentInformationShowcased ? (
      <Modal
        isOpen={paymentOptions != null && paymentOptions != "loading"}
        onRequestClose={props.resetPaymentOptions}
        className="Modal width-700"
        overlayClassName="Overlay"
      >
        {!!IBAN && (
          <div>
            <h2 className="padding_left_xx">{localResources.billPayment}</h2>
            <p className="new_style_title_total ml_xxxx">
              {localResources.totalToPay}{" "}
              <span className="new_style_sub_title_total">{`${value}${
                paymentOptions?.currency?.symbol &&
                paymentOptions?.currency?.symbol
              }`}</span>
            </p>
          </div>
        )}
        {paymentInformationShowcased}
      </Modal>
    ) : (
      <Modal
        isOpen={paymentOptions != null && paymentOptions != "loading"}
        onRequestClose={props.resetPaymentOptions}
        className="Modal width-700"
        overlayClassName="Overlay"
      >
        <div className={"payment_info_container"}>
          <p>
            <label className="payment_info_title">
              {localResources.ibanPaymentNotFound}
            </label>
          </p>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export const ConfirmPopup = ({
  message,
  visible,
  popUpResponse,
  requestInvalid,
}) => {
  const user = useSelector((state) => state.user);

  Modal.setAppElement("body");

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  useEffect(() => {
    if (visible) {
      if (user.detail.applicationTheme.value === 2) {
        document
          .getElementsByClassName("ReactModalPortal")[0]
          .classList.add("dark-theme");
      }
    }
  });

  return (
    <Modal
      isOpen={visible}
      className="Modal"
      overlayClassName="Overlay"
      onRequestClose={() => {
        popUpResponse(false);
      }}
    >
      <div className={"text-center"}>
        <h3>{message}</h3>
        {requestInvalid === true && (
          <p style={{ color: "red" }}>{globalResources.errorPDF}</p>
        )}
        <div className="y-n-container">
          <button
            className="btn btn-primary"
            onClick={() => {
              popUpResponse(false);
            }}
          >
            {globalResources.cancel}
          </button>
          <button
            disabled={requestInvalid}
            className={classNames("btn ", {
              "btn-primary": !requestInvalid,
              "btn-disabled disable-click": requestInvalid,
            })}
            onClick={() => {
              popUpResponse(true);
            }}
          >
            {globalResources.continue}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const RatingPopup = ({ message, visible, popUpResponse }) => {
  const user = useSelector((state) => state.user);
  const [rating, setRating] = useState(0);

  Modal.setAppElement("body");

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  useEffect(() => {
    if (visible) {
      if (user.detail.applicationTheme.value === 2) {
        document
          .getElementsByClassName("ReactModalPortal")[0]
          .classList.add("dark-theme");
      }
    }
  });

  return (
    <Modal
      isOpen={visible}
      className="Modal"
      overlayClassName="Overlay"
      onRequestClose={() => {
        popUpResponse(false, 0);
      }}
    >
      <div className={"text-center"}>
        <h3>{message}</h3>
        <div className="rating-icons">
          <FontAwesomeIcon
            icon={rating >= 1 ? faStar : faStarEmpty}
            onClick={() => setRating(1)}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={rating >= 2 ? faStar : faStarEmpty}
            onClick={() => setRating(2)}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={rating >= 3 ? faStar : faStarEmpty}
            onClick={() => setRating(3)}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={rating >= 4 ? faStar : faStarEmpty}
            onClick={() => setRating(4)}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={rating == 5 ? faStar : faStarEmpty}
            onClick={() => setRating(5)}
          ></FontAwesomeIcon>
        </div>
        <div className="y-n-container">
          <button
            className="btn btn-primary"
            onClick={() => {
              popUpResponse(false, 0);
            }}
          >
            {globalResources.cancel}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              popUpResponse(true, rating);
            }}
          >
            {globalResources.continue}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const CMDSign = ({ resources, visible, next, publicKey }) => {
  const user = useSelector((state) => state.user);
  let userId = "";

  function handleTelChange(type, val) {
    if (type == "tel") {
      userId = val;
    }
  }

  function checkValues(values) {
    //console.log(values);
    //console.log("+351 " + values.phone.replace("351", ""));
    if (
      values.phone &&
      values.phone !== "" &&
      values.pin &&
      values.pin !== ""
    ) {
      // let JSEPublicKey =
      //   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzK1MF/FXZ7OnaUCwAm+/yUfdJ0S40nntsJRsYXo5wZFSgiPm9WlooUFmBM6C518ailenaVPJo78SSwepBiGtNwf95jCU2+2PAR2uf5ZH5fSbkKZS84O5Kelskf+AQoeT555iSZTFWouC+tmMRcrtJHBhRh4nJ0iPnNQLv9q3s0NdMKqOcpCMbaDQIxfXZI9kcx4k8TvT/4NxBOrRTDFs9aojJZaVMb4iSbN2GHY1i3rPYo1JX0rtUS+eH/OSe1+HJQ6ybR98rqWdIRIMGgosYPYivbds8FkF60vyWvpEs2v0+qqgF+st/gGk7+eh0OzAsKFfb6kL7rAF5AtDvsX6OQIDAQAB";
      var encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);

      var encrypted = encrypt.encrypt(
        "+351 " + values.phone.replace("351", "")
      );
      var encrypted2 = encrypt.encrypt(values.pin);
      //console.log(encrypted + "\n\n\n" + encrypted2);
      next(true, { phone: encrypted, pin: encrypted2 });
    } else {
      notifyError(resources.local.fillAllFields);
    }
  }

  return (
    <div>
      <div className="cmd-header">
        <h3 className="cmd-title">{resources.local.CMDSignFormTitle}</h3>
        <h3 className="cmd-sub-title">{resources.local.assignDescription2}</h3>
      </div>
      <a
        className={"text-decoration-underline color-blue"}
        href={resources.local.linkToActivateCMD}
        target="_blank"
      >
        <i>{resources.local.clickToActivateCMD}</i>
      </a>
      <p className="cmd-color m-t-m">{resources.local.enterData}</p>
      <Form
        initialValues={{
          phone: "",
          pin: "",
        }}
        subscription={{ submitting: true, pristine: true }}
        onSubmit={checkValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="m-t-s"
            onKeyUp={(e) =>
              handleTelChange(
                e.target.attributes.type.value,
                e.target.attributes.value.value
              )
            }
            onSubmit={(e) => {
              handleSubmit(e, form);
              e.preventDefault();
            }}
          >
            <Field
              name="phone"
              type="text"
              requiredField={false} //true
              label={resources.local.phoneNumber}
              component={RenderPhoneInputCMD}
            />
            <Field
              name="pin"
              type="password"
              requiredField={false} //true
              placeholder={resources.local.pin_question}
              label={resources.local.signaturePin}
              component={RenderInputCMD}
            />
            <p className="cmd-color m-t-m">{resources.local.dontSavePin}</p>
            <div className="y-n-container space-between p-h-0">
              <button
                className="btn btn-primary btn-cmd danger m-t-m"
                onClick={() => {
                  next(false, 0);
                }}
              >
                {globalResources.cancel}
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-cmd m-t-m"
                disabled={submitting || pristine}
              >
                {globalResources.ok}
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export const CMDConfirmFile = ({
  resources,
  visible,
  next,
  unsignedDocument,
  cancel,
  requestInvalid,
}) => {
  //console.log(encodeURI(unsignedDocument.replace("http", "https")));
  const [waitPDF, setWaitPDF] = useState(
    <div>
      <h3 className="cmd-sub-title-black">{resources.local.loadingPDF}</h3>
    </div>
  );

  const [PDFEmbed, setPDFEMbed] = useState(
    !requestInvalid ? (
      <embed
        src={
          unsignedDocument.startsWith("https")
            ? encodeURI(unsignedDocument)
            : encodeURI(unsignedDocument.replace("http", "https"))
        }
        type="application/pdf"
        width="100%"
        height="320px"
      />
    ) : (
      <p style={{ color: "red", textAlign: "center" }}>
        {globalResources.errorPDF}
      </p>
    )
  );

  useEffect(() => {
    setTimeout(() => {
      setWaitPDF(PDFEmbed);
    }, 4000);
  });
  return (
    <Fragment>
      <div>
        <div className="cmd-header">
          <h3 className="cmd-title">{resources.local.confirmThisFile}</h3>
          <h3 className="cmd-sub-title">
            {resources.local.assignDescription1}
          </h3>
        </div>
        {
          waitPDF
          /*<embed
            src={encodeURI(unsignedDocument.replace("http", "https"))}
            type="application/pdf"
            width="100%"
            height="320px"
          />*/
        }
        <div className="y-n-container space-between p-h-0">
          <button
            onClick={cancel}
            type="button"
            className="btn btn-primary btn-cmd danger m-t-l"
          >
            {globalResources.no}
          </button>
          <button
            onClick={next}
            type="button"
            disabled={requestInvalid}
            className={classNames("btn btn-cmd m-t-l", {
              "btn-primary": !requestInvalid,
              "btn-disabled disable-click": requestInvalid,
            })}
          >
            {globalResources.yes}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export const CMDPhoneCode = ({
  resources,
  visible,
  next,
  forceSMS,
  cancel,
  publicKey,
}) => {
  const [showSendSMS, setShowSendSMS] = useState(true);
  const user = useSelector((state) => state.user);
  let userId = "";

  function handleTelChange(type, val) {
    if (type == "tel") {
      userId = val;
    }
  }

  function checkValues(values) {
    if (values.code && values.code !== "") {
      var encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);

      var encrypted = encrypt.encrypt(values.code);

      next(true, { code: encrypted });
    } else {
      notifyError(resources.local.fillAllFields);
    }
  }

  return (
    <div>
      <div className="cmd-header">
        <h3 className="cmd-title">{resources.local.forceSMS_title}</h3>
        <h3 className="cmd-sub-title">{resources.local.assignDescription3}</h3>
      </div>
      <p>{resources.local.forceSMS_description}</p>
      <Form
        initialValues={{
          code: "",
        }}
        subscription={{ submitting: true }}
        onSubmit={checkValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="m-t-xl"
            onKeyUp={(e) =>
              handleTelChange(
                e.target.attributes.type.value,
                e.target.attributes.value.value
              )
            }
            onSubmit={(e) => {
              handleSubmit(e, form);
              e.preventDefault();
            }}
          >
            <Field
              name="code"
              type="number"
              requiredField={false} //true
              label={resources.local.forceSMS_input}
              component={RenderInputCMD}
            />
            <p>{resources.local.newNextStepIndication}</p>
            <p>
              {resources.local.newNextStepIndicationLink}
              <a
                className="text-decoration-underline"
                href="https://www.autenticacao.gov.pt/cmd-assinatura"
                target="_blank"
              >
                {resources.local.stepIndicationLink}
              </a>
            </p>
            <p className="m-t-m">{resources.local.smsExplanation}</p>

            <div className="y-n-container space-between p-h-0">
              <button
                type="button"
                className="btn btn-primary btn-cmd danger m-t-m"
                onClick={cancel}
              >
                {globalResources.cancel}
              </button>
              {showSendSMS && (
                <button
                  type="button"
                  className="btn btn-primary btn-cmd m-t-m"
                  onClick={() => {
                    forceSMS();
                    setShowSendSMS(false);
                  }}
                >
                  {resources.local.forceSMS_resend}
                </button>
              )}
              <button
                type="submit"
                className="btn btn-primary btn-cmd m-t-m"
                disabled={submitting || pristine}
              >
                {globalResources.continue}
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export const CMDError = ({ cancelErrorMessage, cmdErrorMessage }) => {
  return (
    <Fragment>
      <div className="cmd-header">
        <h3 className="cmd-title">{cmdErrorMessage}</h3>
      </div>
      <div className="y-n-container space-between p-h-0">
        <button
          className="btn btn-primary btn-cmd danger m-t-m"
          onClick={cancelErrorMessage}
        >
          {globalResources.cancel}
        </button>
      </div>
    </Fragment>
  );
};

export const CMDSuccess = ({ resources, cancel, signedDocument }) => {
  return (
    <Fragment>
      <div className="cmd-header">
        <h3 className="cmd-title">{resources.local.cmdSignSuccess}</h3>
        <h3 className="cmd-sub-title">{resources.local.assignDescription4}</h3>
      </div>
      <div className="y-n-container space-between p-h-0">
        {signedDocument && signedDocument.url && (
          <a href={signedDocument.url} target="_blank">
            <button className="btn btn-primary btn-cmd m-t-m">
              {resources.local.downloadDocument}
            </button>
          </a>
        )}
        <button className="btn btn-primary btn-cmd m-t-m" onClick={cancel}>
          {globalResources.close}
        </button>
      </div>
    </Fragment>
  );
};

export const CMDPopup = ({
  resources,
  cmdSignature,
  afterConfirm,
  afterCertificate,
  afterOTP,
  forceSMS,
  unsignedDocument,
  cancel,
  cancelErrorMessage,
  signedDocument,
  publicKey,
  requestInvalid,
}) => {
  const user = useSelector((state) => state.user);

  Modal.setAppElement("body");

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  useEffect(() => {
    if (cmdSignature !== null) {
      if (user.detail.applicationTheme.value === 2) {
        document
          .getElementsByClassName("ReactModalPortal")[0]
          .classList.add("dark-theme");
      }
    }
  });

  let component;
  switch (cmdSignature) {
    case "confirmFile": //confirmFile
      component = (
        <CMDConfirmFile
          resources={resources}
          next={afterConfirm}
          unsignedDocument={unsignedDocument}
          cancel={cancel}
          requestInvalid={requestInvalid}
        />
      );
      break;
    case "certificate": //certificate
      component = (
        <CMDSign
          resources={resources}
          next={afterCertificate}
          publicKey={publicKey}
        />
      );
      break;
    case "load": //load
      component = <IsLoading text={resources.local.plsWait} />;
      break;
    case "otp": //otp
      component = (
        <CMDPhoneCode
          resources={resources}
          next={afterOTP}
          forceSMS={forceSMS}
          cancel={cancel}
          publicKey={publicKey}
        />
      );
      break;
    case "success": //success
      component = (
        <CMDSuccess
          resources={resources}
          cancel={cancel}
          signedDocument={signedDocument}
        />
      );
      break;
    default:
      component = (
        <CMDError
          cancelErrorMessage={cancelErrorMessage}
          cmdErrorMessage={cmdSignature}
        />
      );
      break;
  }

  return (
    <Modal
      isOpen={cmdSignature !== null}
      className="Modal width-700"
      overlayClassName="Overlay"
    >
      {component}
    </Modal>
  );
};

export const EmailPopup = ({ resources, visible, next }) => {
  //const user = useSelector((state) => state.user);

  //Modal.setAppElement('body');

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  /*useEffect(()=>{
        if (visible && user && user.detail){
            if(user.detail.applicationTheme.value===2){
                document.getElementsByClassName("ReactModalPortal")[0].classList.add("dark-theme");
            }
        }
    })*/

  function checkValues(values) {
    if (values.email && values.email !== "" && validateEmail(values.email)) {
      next(values.email);
    } else {
      notifyError(resources.local.fillValidEmail);
    }
  }

  return (
    <Modal
      isOpen={visible}
      className="Modal"
      overlayClassName="Overlay Email-Modal"
    >
      <div>
        <h3 className={"text-center"}>{resources.local.EmailPopUpTitle}</h3>
        <Form
          initialValues={{ email: "" }}
          subscription={{ submitting: true, pristine: true }}
          onSubmit={checkValues}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              className="m-t-xl"
              onSubmit={(e) => {
                handleSubmit(e, form);
                e.preventDefault();
              }}
            >
              <Field
                name="email"
                type="text"
                label={resources.local.email}
                component={RenderInput}
              />
              <div className="y-n-container">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting || pristine}
                >
                  {globalResources.continue}
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};

export const InfoPopup = ({
  messageComp,
  visible,
  next,
  disabled,
  buttonLabel,
  currentAccount,
}) => {
  const user = useSelector((state) => state.user);

  Modal.setAppElement("body");

  // THIS CHECKS IF THE WEBSITE IS IN DARK-THEME, AND IN CASE IT IS, IT ADDS THE DARK-THEME CLASS TO THE MODAL DIV
  useEffect(() => {
    if (visible) {
      if (user && user.detail && user.detail.applicationTheme.value === 2) {
        document
          .getElementsByClassName("ReactModalPortal")[0]
          .classList.add("dark-theme");
      }
    }
  });

  return (
    <Modal
      isOpen={visible}
      className="Modal"
      overlayClassName="Overlay"
      onRequestClose={() => {
        !disabled && next();
      }}
    >
      <div className={"text-center"}>
        {currentAccount ? <h3>{messageComp}</h3> : messageComp}
        {!disabled && (
          <div className="y-n-container">
            <button
              className="btn btn-primary"
              onClick={() => {
                next();
              }}
            >
              {buttonLabel ? buttonLabel : globalResources.ok}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
