import { AES, enc } from "crypto-js";
import { CryptoKey } from "../assets/config";

export const token_storage = "gcomstk";

export function get_token() {
  var token_local = localStorage.getItem(token_storage);
  var token_session = sessionStorage.getItem(token_storage);
  var token;
  if(token_local && token_local !== ""){
    token=token_local;
  }
  else if(token_session && token_session !== ""){
    token=token_session;
  }

  if (token && token !== "") {
    return CryptoKey ? AES.decrypt(token, CryptoKey).toString(enc.Utf8) : token;
  }
}

export function set_token(token) {
  localStorage.setItem(
    token_storage,
    CryptoKey ? AES.encrypt(token, CryptoKey) : token
  );
}

export function set_DemoToken(token) {
  sessionStorage.setItem(
    token_storage,
    CryptoKey ? AES.encrypt(token, CryptoKey) : token
  );
}

export function remove_token() {
  localStorage.removeItem(token_storage);
  sessionStorage.removeItem(token_storage);
}

export function LogOut() {
  remove_token();
  window.open("/", "_self");
}
