import { apiAuthRequest } from "../server/api";

export function GetMessages(page, pageSize, status, condominium_id) {
  return apiAuthRequest({
    url: `v1/Messages${condominium_id ? "/" + condominium_id : ""}`,
    data: {
      messageReadingStatusFilter: status,
      page: page,
      resultsPerPage: pageSize,
    },
  });
}

export function GetMessage(id, condominium_id) {
  return apiAuthRequest({
    url: `v1/Messages/${condominium_id}/${id}`,
  });
}
