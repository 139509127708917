import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import BankBalanceList from "../components/lists/BankBalanceList";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const BankBalancePage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.bankBalances.breadcrumb} />
      <div className="container-fluid">
        <BankBalanceList></BankBalanceList>
      </div>
    </Master>
  );
};

export default BankBalancePage;
