import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import CondominiumForm from "../components/forms/CondominiumForm";
import Master from "../components/containers/Master";
import { useSelector } from "react-redux";
import { navigationResources } from "../resources/resources";

const CondominiumPage = () => {
  const user = useSelector((state) => state.user);

  return user && user.current_condominium ? (
    <Master>
      <Breadcrumb page={navigationResources.condominium.breadcrumb} />
      <div className="container-fluid">
        <CondominiumForm
          condominium={user.current_condominium}
        ></CondominiumForm>
      </div>
    </Master>
  ) : (
    <Master></Master>
  );
};

export default CondominiumPage;
