import React from "react";
import OccurrencesTable from "../components/tables/OccurrencesTable";
import Master from "../components/containers/Master";

const OccurrencesPage = () => {
  return (
    <Master>
      <div className="container-fluid">
        <OccurrencesTable></OccurrencesTable>
      </div>
    </Master>
  );
};

export default OccurrencesPage;
