import React, { useState, useEffect } from "react";
import ActivateForm from "../components/forms/ActivateForm";
import {
  globalResources,
  loginResources,
  getCurrent,
  setLanguage,
} from "../resources/resources";
import Navbar from "../components/elements/Navbar";

const ActivatePage = (props) => {
  let _lang = getCurrent();
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);

  useEffect(() => {
    if (lang && lang.split("-")[0] !== getCurrent()) {
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="activation-container">
      <Navbar
        onLanguageChange={(language) => {
          setLang(language);
        }}
      />
      <div className="activation-banner">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5>{loginResources.welcome}</h5>
              <h1>{loginResources.activateTitle}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="form-nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="form-navbar">
                <li>
                  <div className="form-nav-item">
                    {globalResources.step} 2 - {loginResources.activation}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="terms">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>{loginResources.activateSubTitle}</h3>
              <div className="row">
                <div className="col">
                  <ActivateForm {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-activation">
        <div className="container">
          <div className="row">
            <div className="col">
              <span className="hint-text">Copyright © 2020 </span>
              <span>Gecond. </span>
              <span className="hint-text">
                {globalResources.rightsReserved}{" "}
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ActivatePage;
