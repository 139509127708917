const image = "logo_image";
const name = "company_name";
const clientId = "client_id";

export function getClientId () {
  return sessionStorage.getItem(clientId);
}

export function getLogoImage() {
  return sessionStorage.getItem(image);
}

export function getCompanyName() {
  return sessionStorage.getItem(name);
}

export function setClientId(id) {
  sessionStorage.setItem(clientId, id);
}

export function setLogoImage(image_url) {
  sessionStorage.setItem(image, image_url);
}

export function setCompanyName(company_name) {
  sessionStorage.setItem(name, company_name);
}