import React, { useState, Fragment, useEffect } from "react";
import { Form } from "react-final-form";
import { CondominiumPageResources } from "../../resources/resources";
import Card from "../../components/containers/Card";
import ImagePicker from "../../components/elements/ImagePicker";
import "react-image-lightbox/style.css";
import { GetDetail } from "../../actions/condominium";
import FractionsTable from "../tables/FractionsTable";
import IsLoading from "../elements/IsLoading";

const CondominiumForm = (props) => {
  const [detail, setDetail] = useState();
  const [resources] = useState(CondominiumPageResources);
  const { condominium } = props;

  useEffect(() => {
    if (condominium) {
      setDetail();
      GetDetail(condominium.id)
        .then((response) => {
          setDetail(response.data);
        })
        .catch((e) => {
          setDetail(undefined);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium.id]);

  const onSubmit = async (values, form) => {
    // Save(values)
    //   .then((response) => {
    //   })
    //   .catch((e) => {
    //   });
  };
  
  const onUpload = (base64, file) => {
    //UploadImage(base64, file.file.type, file.file.name);
  };
  
  const RenderInfo = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-6 m-b-l">
            <Card subclass="card-condo-image">
              <div className="row">
                <div className="col-sm-auto">
                  {detail.photoBase64ByteContent &&
                    detail.photoBase64ByteContent !== "" && (
                      <ImagePicker
                        name="photoBase64ByteContent"
                        disabled={true}
                        remove={false}
                        addImageText=""
                        addImageIcon={undefined}
                        onUpload={onUpload}
                        maxWidth={200}
                        maxHeight={150}
                      ></ImagePicker>
                    )}
                </div>
                <div className="col-sm">
                  <div className="row">
                    <div className="col-12">
                      <h3>{detail.name}</h3>
                    </div>
                    {detail.address && (
                      <div className="col-12">
                        <div className="condo-address">
                          {detail.address.split("\n").map((paragraph, i) => {
                            return <span key={i}>{paragraph}</span>;
                          })}
                        </div>
                      </div>
                    )}
                    {detail.vatNumber && (
                      <div className="col-12">
                        <div className="condo-vatnumber">
                          {resources.local.contributor}: {detail.vatNumber}
                        </div>
                      </div>
                    )}
                    {detail.iban && (
                      <div className="col-12">
                        <div>
                          {resources.local.iban}: {detail.iban}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {detail.vatNumber && detail.vatNumber.toString().startsWith("9") && (
            <div className="col-lg-6">
              <Card>
                <div className="row">
                  <div className="col-sm-auto">
                    <img
                      src={`${detail.subsidiary.logotype.url}`}
                      alt=""
                      width="200px"
                    />
                  </div>
                  <div className="col-sm">
                    <h3>{detail.subsidiary.commercialName}</h3>
                    <div className="condo-address">
                      <span>{detail.subsidiary.address.address}</span>
                      <span>{detail.subsidiary.address.zipCode}</span>
                      <span>{detail.subsidiary.address.zipLocation}</span>
                    </div>
                    <div className="condo-contact">
                      {detail.subsidiary.mainTelephone}
                    </div>
                    <div className="condo-contact">
                      {detail.subsidiary.mainCellPhone}
                    </div>
                    <div className="condo-contact">
                      {detail.subsidiary.mainEmailAddress}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  return detail ? (
    <Form
      initialValues={detail}
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <Fragment>
          <form
            className="condominium"
            onSubmit={(e) => {
              handleSubmit(e, form);
              e.preventDefault();
            }}
          >
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-12">{RenderInfo()}</div>
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col">
              <FractionsTable
                fractions={detail.fractionsOverview}
                currency={detail.subsidiary.settings.currency}
              ></FractionsTable>
            </div>
          </div>
        </Fragment>
      )}
    />
  ) : (
    <Card>
      <IsLoading text={resources.local.loading} />
    </Card>
  );
};

export default CondominiumForm;
