import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Card from "../containers/Card";
import { globalResources } from "../../resources/resources";
import { notifyWarning, notifySuccess, notifyError } from "../elements/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import FilePicker from "../elements/FilePicker";
import { GetUnsignedLegalDocument, SaveAttachment } from "../../actions/votes";
import {
  SendCMDdata,
  ForceSMS,
  ValidateOTP,
  GetPublicKey,
} from "../../actions/cmd";
import {
  getObjectInObjectArray,
  RemoveFromArray,
  isNumberPortuguese,
  isDevEnvir,
} from "../../assets/utils";
import IsLoading from "../elements/IsLoading";
import { isDateBeforeToday } from "../../assets/utils.js";
import { ConfirmPopup, CMDPopup, InfoPopup } from "../elements/Popup";
import { get_token } from "../../storage/token";
import { client_id } from "../../assets/config";

const SignDocumentForm = (props) => {
  const user = useSelector((state) => state.user.detail);
  const {
    assembly,
    resources,
    unsignedDocument,
    signedDocument,
    getAssembly,
    deleteSignedDocument,
  } = props;
  const [nonDigitalSignedFiles, setNonDigitalSignedFiles] = useState([]);
  const [popUpMessage, setPopUpMessage] = useState(null);
  const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
  const changesAllowed =
    assembly.userVotingStatus &&
    (assembly.userVotingStatus.id === 3 || assembly.userVotingStatus.id === 5)
      ? false
      : true;
  const [digitalSignInfo, setDigitalSignInfo] = useState({});
  const [cmdSignature, setCmdSignature] = useState(null);
  const [isPhonePt] = useState(isNumberPortuguese(user.phone));

  const [publicKey, setPublicKey] = useState();
  const [publicKeyAPI, setPublicKeyAPI] = useState();

  const [requestInvalid, setRequestInvalid] = useState(false);

  const getPublicKey = async () => {
    try {
      fetch("https://devdigsign.improxy.com/certificate", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: 0,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setPublicKeyAPI(result);
          const PB = result?.pb;
          const PI = result?.pi;

          //console.log(PB, PI);

          setPublicKey(`${PI}${PB}`);
        })
        .catch((error) => {
          console.log("e: ", error);
        });
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    getPublicKey();
  }, []);

  // console.log("PC: ", publicKey);

  const removeAttachment = (attachment) => {
    let newArray;
    newArray = RemoveFromArray(
      nonDigitalSignedFiles,
      "fileName",
      attachment.fileName
    );
    setNonDigitalSignedFiles(newArray);
  };

  const renderNonDigitalySignedFiles = () => {
    return nonDigitalSignedFiles && nonDigitalSignedFiles.length > 0 ? (
      nonDigitalSignedFiles.map((attachment, i) => {
        return (
          <div className="col-12" key={i}>
            <div className="gallery-item">
              {attachment.base64ContentBytes.length > 0 && (
                <Fragment>
                  <div className="gallery-thumbnail">
                    <img
                      src={`data:image/jpeg;base64,${attachment.base64ContentBytes}`}
                      alt=""
                    />
                  </div>
                  <div>{attachment.fileName}</div>
                </Fragment>
              )}
              <a
                onClick={() => removeAttachment(attachment)}
                rel="noopener noreferrer"
                className="btn btn-remove"
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                <span>{globalResources.verbs.remove}</span>
              </a>
            </div>
          </div>
        );
      })
    ) : (
      <Fragment></Fragment>
    );
  };

  function addNonDigitalSignedFile(base64, file) {
    if (
      getObjectInObjectArray(nonDigitalSignedFiles, "fileName", file.file.name)
    ) {
      notifyWarning(resources.local.details.duplicatedAttachment);
      setNonDigitalSignedFiles([...nonDigitalSignedFiles]);
    } else {
      setNonDigitalSignedFiles([
        ...nonDigitalSignedFiles,
        {
          base64ContentBytes: base64,
          imageMimeType: file.meta.type,
          fileName: file.file.name,
        },
      ]);
    }
  }

  function submitDocuments(attachments) {
    SaveAttachment(assembly.id, attachments[0])
      .then((response) => response.text())
      .then((result) => {
        if (result.length === 0) {
          setNonDigitalSignedFiles([]);
          notifySuccess(resources.local.details.addDocumentSuccess);
          getAssembly();
          props.resetSignedDocument();
        } else {
          notifyError(resources.local.details.addDocumentFail);
        }
      })
      .catch((e) => {
        notifyError(resources.local.details.addDocumentFail);
      });
  }

  function signFile(value, valueObj) {
    SendCMDdata(value)
      .then((response) => {
        // console.log("setDigitalSignInfo: ", response);
        if (response?.data?.success === 1) {
          setDigitalSignInfo({
            ...valueObj,
            puid: response.data.puid,
            nakedHash: response.data.nakedHash,
            processId: response.data.processId,
          });
          setCmdSignature("otp");
        } else {
          setCmdSignature(null);
          setCmdSignature(resources.local.details.CMDSignFail);
        }
      })
      .catch((e) => {});
  }

  function forceSMS() {
    var formData = new FormData();
    formData.append("userId", digitalSignInfo.userId);
    formData.append("processId", digitalSignInfo.processId);
    ForceSMS(formData);
  }

  function validateOTP(value) {
    setCmdSignature("load");
    ValidateOTP(value)
      .then((response) => {
        // console.log("response: ", response);
        // console.log("digitallySign: ", digitalSignInfo);
        if (response?.data?.success !== 1) {
          setCmdSignature(resources.local.details.CMDSignFail);
          //notifyError(resources.local.details.CMDSignFail);
        } else {
          setCmdSignature("success");
        }
        setDigitalSignInfo({});
        getAssembly();
        props.resetSignedDocument();
      })
      .catch((e) => {});
  }

  const popUpResponse = (yes) => {
    if (yes) {
      deleteSignedDocument();
    }
    setConfirmPopupVisible(false);
  };

  function digitallySign(success, val) {
    if (success) {
      const url = new URL(unsignedDocument.url);
      const action = url.searchParams.get("action");
      const parameters = url.searchParams.get("parameters");
      var formData = new FormData();
      const environment =
        window.location.hostname === "dev.gecond.com" ||
        window.location.hostname === "localhost"
          ? "dev2"
          : "reports";

      formData.append("userId", val.phone);
      formData.append("pin", val.pin);
      formData.append("pdf-action", action);
      formData.append(
        "pdf-parameters",
        String(parameters.replaceAll(" ", "+"))
      );
      formData.append("pdf-path", environment);
      signFile(formData, {
        userId: val.phone,
        pin: val.pin,
      });
      //console.log("formData: ", formData);
      //console.log("signFile: ", signFile);
      setCmdSignature("load");
    } else {
      setCmdSignature(null);
    }
  }

  function confirmPhoneCode(success, val) {
    if (success) {
      const environment =
        window.location.hostname === "dev.gecond.com" ||
        window.location.hostname === "localhost"
          ? "dev"
          : "";
      var formData = new FormData();
      formData.append("userId", digitalSignInfo.userId);
      formData.append("puid", digitalSignInfo.puid);
      formData.append("nakedHash", digitalSignInfo.nakedHash);
      formData.append("processId", digitalSignInfo.processId);
      formData.append("otpCode", val.code);
      formData.append("assemblyId", assembly.id);
      formData.append("client_id", client_id());
      formData.append("bearer", get_token());
      formData.append("api-path", environment);
      validateOTP(formData);
      //console.log("FormData: ", formData);
    }
  }

  const httpRequest = (link) => {
    try {
      console.log("teste123");
      fetch(link, {
        method: "GET",
        headers: {
          Pragma: "no-cache",
          Expires: 0,
        },
      })
        .then((response) => setRequestInvalid(false))
        .catch((error) => {
          //console.log("error: ", error);
          console.log("teste1");
          setRequestInvalid(true);
        });
    } catch (e) {
      console.log("error2: ", e);
      // error reading value
      setRequestInvalid(true);
    }
  };

  return unsignedDocument !== undefined ? (
    <Fragment>
      <Card
        title={
          assembly.requiresLegalRepresentativeDocument
            ? resources.local.SignedContract
            : resources.local.printContract
        }
      >
        <InfoPopup
          messageComp={popUpMessage}
          visible={popUpMessage !== null}
          next={() => {
            setPopUpMessage(null);
          }}
        />
        {/* {(window.location.hostname === "dev.gecond.com" ||
          window.location.hostname === "localhost") && ( */}
        <CMDPopup
          requestInvalid={requestInvalid}
          resources={resources}
          cmdSignature={cmdSignature}
          afterConfirm={() => setCmdSignature("certificate")}
          unsignedDocument={unsignedDocument?.url}
          afterCertificate={(e, i) => {
            digitallySign(e, i);
          }}
          afterOTP={(e, i) => {
            confirmPhoneCode(e, i);
          }}
          forceSMS={forceSMS}
          cancel={() => setCmdSignature(null)}
          cancelErrorMessage={() => setCmdSignature(null)}
          signedDocument={signedDocument}
          publicKey={publicKey}
        />
        {/* )} */}
        {unsignedDocument &&
        unsignedDocument.url &&
        changesAllowed &&
        assembly &&
        assembly.chosenLegalRepresentativeType ? (
          <Fragment>
            <p>
              {
                /*isDevEnvir() &&*/ isPhonePt && (
                  <b>{resources.local.option1}</b>
                )
              }
              {resources.local.signManually_title}
            </p>
            <div className="m-t-m">
              <a href={unsignedDocument.url} target="_blank">
                <button type="button" className="btn btn-primary btn-sign">
                  {resources.local.printRepresentationDocument}
                </button>
              </a>
            </div>
          </Fragment>
        ) : signedDocument && signedDocument.url ? (
          <div className="m-t-m">
            <a href={signedDocument.url} target="_blank">
              <button type="button" className="btn btn-primary btn-sign">
                {resources.local.printDocumentAlrAdded}
              </button>
            </a>
          </div>
        ) : null}
        {assembly.userVotingValidationStatus &&
          assembly.userVotingValidationStatus.id === 0 &&
          !changesAllowed &&
          assembly.userVotingStatus.id !== 5 && (
            <div className="m-t-m">
              <button
                type="button"
                onClick={() => {
                  httpRequest(signedDocument?.url);
                  setConfirmPopupVisible(true);
                }}
                className="btn btn-primary btn-sign"
              >
                {resources.local.changeVotesOrRepresentative}
              </button>
              <ConfirmPopup
                message={
                  resources.local.deleteSignedDocumentConfirmationMessage
                }
                visible={confirmPopupVisible}
                popUpResponse={(response) => {
                  popUpResponse(response);
                }}
              />
            </div>
          )}
        {nonDigitalSignedFiles.length < 1 &&
          isDateBeforeToday(assembly.onlineVotingStartDate) &&
          (!signedDocument || !signedDocument.url) &&
          assembly.userVotingStatus.id !== 5 &&
          assembly.userVotingStatus.id !== 3 && (
            <div className="m-t-m">
              <FilePicker
                onUpload={(base64, file) => {
                  addNonDigitalSignedFile(base64, file);
                }}
                maxWidth={1024}
                className="btn btn-primary btn-sign"
                buttonText={resources.local.addRepresentationDocument}
                accept="image/*,.pdf"
              ></FilePicker>
            </div>
          )}
        {unsignedDocument &&
          unsignedDocument.url &&
          changesAllowed &&
          assembly &&
          assembly.chosenLegalRepresentativeType &&
          /*isDevEnvir() &&*/
          isPhonePt && (
            <div className="m-t-xl">
              <p>
                <b>{resources.local.option2}</b>
                {resources.local.signDigitally_title}
              </p>
              <div className="m-t-m ">
                <a
                  onClick={() => setCmdSignature("confirmFile")}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-cmd btn-sign"
                  >
                    {resources.local.digitallySignRepresentation}
                  </button>
                </a>
                <div className="m-t-m">
                  <a
                    className={"text-decoration-underline color-blue"}
                    href={resources.local.linkToActivateCMD}
                    target="_blank"
                  >
                    {resources.local.clickToActivateCMD}
                  </a>
                </div>
              </div>
            </div>
          )}
        {nonDigitalSignedFiles.length > 0 && (
          <div className="row m-t-m">{renderNonDigitalySignedFiles()}</div>
        )}
        {nonDigitalSignedFiles.length > 0 && (
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  submitDocuments(nonDigitalSignedFiles);
                }}
              >
                {globalResources.verbs.submit}
              </button>
            </div>
          </div>
        )}
      </Card>
    </Fragment>
  ) : (
    <Card>
      <IsLoading text={resources.local.loading} />
    </Card>
  );
};

export default SignDocumentForm;
