import React, { useState, Fragment, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { required } from "../../assets/forms/validation";
import { RenderInput, RenderTextarea, DatePickerAdapter } from "../../assets/forms/render";
import { useSelector } from "react-redux";
import { RemoveFromArray } from "../../assets/utils";
import {
  OccurrencesTableResources,
  globalResources,
} from "../../resources/resources";
import Card from "../../components/containers/Card";
import FilePicker from "../../components/elements/FilePicker";
import "react-image-lightbox/style.css";
import {
  SaveOccurrence,
  GetDetail,
  SaveAttachment,
  RemoveAttachment,
  RateOccurrence,
} from "../../actions/occurrences";
import "react-datepicker/dist/react-datepicker.css";
import { faTimes, faFileDownload, faStar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  notifySuccess,
  notifyError,
  notifyWarning,
} from "../../components/elements/Notify";
import {RatingPopup} from '../elements/Popup';
import {makeRatingComponent} from '../../assets/componentGenerator';
import {toInterventionStatusLabel, toDate} from '../../assets/FormatAndParse';

const OccurrenceForm = (props) => {
  const [occurrence, setOccurence] = useState({
    id: 0,
    subject: "",
    description: "",
    attachments: [],
  });
  const { id = 0, after } = props;
  const [change, setChange] = useState(0);
  const [resources] = useState(OccurrencesTableResources);
  const [add, setAdd] = useState();
  const condominium = useSelector((state) => state.user.current_condominium);
  const [rateId, setRateId] = useState(0);

  useEffect(() => {
    if (condominium && id && id > 0 && occurrence.id===0) {
      GetDetail(condominium.id, id)
        .then((response) => {
          setOccurence(response.data);
        })
        .catch((e) => {
          setOccurence(undefined);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, occurrence]);

  // USED TO ENSURE THAT WHEN CHANGING CONDOMINIUMS IT GOES BACK TO THE LIST OF OCCURRENCES
  useEffect(() => {
    if (condominium && occurrence && occurrence.id>0) {
      after();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  const saveAttachment = (
    condominium_id,
    occurrence_id,
    attachment,
    run_after
  ) => {
    //console.log(attachment)
    SaveAttachment(condominium_id, occurrence_id, attachment)
      .then((response) => {
        notifySuccess(resources.local.detail.addAttachmentSuccess);
        run_after();
      })
      .catch((e) => {
        let message = "";
        if (e.response.data && e.response.data.error)
          message = e.response.data.error;

        switch (message.toLowerCase()) {
          case "AlreadyExistingFileName".toLowerCase():
            notifyWarning(resources.local.detail.duplicatedAttachment);
            break;
          default:
            notifyError(resources.local.detail.addAttachmentFail);
            break;
        }
      });
  };

  const deleteAttachment = (
    condominium_id,
    occurrence_id,
    attachment,
    run_after
  ) => {
    RemoveAttachment(condominium_id, occurrence_id, attachment.name)
      .then((response) => {
        notifySuccess(resources.local.detail.removeAttachmentSuccess);
        run_after();
      })
      .catch((e) => {
        notifyError(resources.local.detail.removeAttachmentFail);
      });
  };

  const saveAttachments = (
    condominium_id,
    occurrence_id,
    attachments,
    run_after
  ) => {
    if (attachments.length > 0) {
      const attachment = attachments.pop();
      SaveAttachment(condominium_id, occurrence_id, attachment)
        .then((response) => {
          saveAttachments(
            condominium_id,
            occurrence_id,
            attachments,
            run_after
          );
        })
        .catch((e) => {
          saveAttachments(
            condominium_id,
            occurrence_id,
            attachments,
            run_after
          );
        });
    } else {
      run_after();
    }
  };

  const onSubmit = async (values, form) => {
    SaveOccurrence(condominium.id, values)
      .then((response) => {
        if (values.id === 0 && occurrence.attachments.length > 0) {
          saveAttachments(
            condominium.id,
            response.data.id,
            [...occurrence.attachments],
            () => {
              notifySuccess(resources.local.detail.success);
              after(true);
            }
          );
        } else {
          notifySuccess(resources.local.detail.success);
          after(true);
        }
      })
      .catch((e) => {
        notifyError(resources.local.detail.fail);
      });
  };

  const onUpload = (base64, file) => {
    setAdd({
      name: file.file.name,
      description: "",
      base64ContentBytes: base64,
      new: true,
      last: true,
    });
  };

  const removeAttachment = (attachment) => {
    if (occurrence.id > 0) {
      deleteAttachment(condominium.id, occurrence.id, attachment, () => {
        occurrence.attachments = RemoveFromArray(
          occurrence.attachments,
          "name",
          attachment.name
        );
        setOccurence(occurrence);
        setChange(change + 1);
      });
    } else {
      occurrence.attachments = RemoveFromArray(
        occurrence.attachments,
        "name",
        attachment.name
      );
      setOccurence(occurrence);
      setChange(change + 1);
    }
  };

  const addAttachment = () => {
    return add ? (
      <div className="form-group">
        <label>{add.name}</label>
        <input
          className="form-control"
          placeholder={resources.local.detail.obs}
          onChange={(e) => {
            add.description = e.target.value;
          }}
          maxLength={255}
          autoFocus
        />{" "}
        {add.required && (
          <div className="form-feedback">
            <span>{globalResources.required}</span>
          </div>
        )}
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            if (
              add.description &&
              add.description !== null &&
              add.description.length > 0
            ) {
              if (occurrence.id > 0) {
                saveAttachment(condominium.id, occurrence.id, add, () => {
                  occurrence.attachments.push(add);
                  setOccurence(occurrence);
                  setAdd(undefined);
                });
              } else {
                occurrence.attachments.push(add);
                setOccurence(occurrence);
                setAdd(undefined);
              }
            } else {
              add.required = true;
              setChange(change + 1);
            }
          }}
        >
          {resources.local.detail.addAttachments}
        </button>{" "}
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            setAdd(undefined);
          }}
        >
          {globalResources.verbs.cancel}
        </button>
      </div>
    ) : (
      <Fragment></Fragment>
    );
  };

  const renderFiles = () => {
    let attachments = [];
    if (occurrence.attachments && occurrence.attachments.length > 0) {
      attachments = occurrence.attachments.slice(0, 4);
    }

    return attachments && attachments.length > 0 ? (
      attachments.map((attachment, i) => {
        attachment.last = undefined;
        return (
          <div className="col-4" key={i}>
            <div className="gallery-item">
              {attachment.attachmentUrl && attachment.attachmentUrl.url && (
                <Fragment>
                  <div className="gallery-thumbnail">
                    <img src={attachment.attachmentUrl.url} alt="" />
                  </div>
                  <div>{attachment.name}</div>
                  <div>{attachment.description}</div>

                  <a
                    href={attachment.attachmentUrl.url}
                    target="_blank"
                    title={globalResources.verbs.download}
                    rel="noopener noreferrer"
                    className="btn btn-danger"
                  >
                    <FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon>
                    <span>{globalResources.verbs.download}</span>
                  </a>
                </Fragment>
              )}
              {attachment.base64ContentBytes &&
                attachment.base64ContentBytes.length > 0 && (
                  <Fragment>
                    <div className="gallery-thumbnail">
                      <img
                        src={`data:image/jpeg;base64,${attachment.base64ContentBytes}`}
                        alt=""
                      />
                    </div>
                    <div>{attachment.name}</div>
                    <div>{attachment.description}</div>

                    <a
                      href={`data:image/jpeg;base64,${attachment.base64ContentBytes}`}
                      target="_blank"
                      title={globalResources.verbs.download}
                      rel="noopener noreferrer"
                      className="btn btn-danger"
                    >
                      <FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon>
                      <span>{globalResources.verbs.download}</span>
                    </a>
                  </Fragment>
                )}
              <a
                onClick={() => removeAttachment(attachment, i)}
                rel="noopener noreferrer"
                className="btn btn-remove"
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                <span>{globalResources.verbs.remove}</span>
              </a>
            </div>
          </div>
        );
      })
    ) : (
      <Fragment></Fragment>
    );
  };

  const Rate = async (condominium_id, id, score) => {
    if (condominium_id && id && id > 0) {
      RateOccurrence(condominium_id, id, score)
        .then((response) => {
          notifySuccess(resources.local.detail.ratingSuccess);
          setOccurence({
            id: 0,
            subject: "",
            description: "",
            attachments: [],
          });
        })
        .catch((e) => {
          notifyError(resources.local.detail.ratingFail);
        });
    }
  };

  const popUpResponse = (yes, score)=>{
    if(yes && score>0){
      Rate(condominium.id, rateId, score);
    }
    setRateId(0);
  }

  return id === 0 || (id > 0 && occurrence && occurrence.id > 0) ? (
    <Form
      initialValues={occurrence}
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        return (
          <Fragment>
            <form
              className="occurrence "
              onSubmit={(e) => {
                handleSubmit(e, form);
                e.preventDefault();
              }}
            >
              {occurrence.id>0 &&
              <Card>
                  {(occurrence && occurrence.interventionStatus) &&
                  <div className="form-group special-title">
                    <label>{resources.local.detail.interventionStatus+":"}</label>
                      <span className="m-l-xs">
                        {toInterventionStatusLabel(occurrence.interventionStatus, resources.local.detail)}
                        {(occurrence.interventionStatus && occurrence.interventionStatus.id===2) && resources.local.detail.in_connector}
                        {(occurrence.intervention && occurrence.intervention.closeDate && occurrence.interventionStatus && occurrence.interventionStatus.id===2)
                         && toDate(occurrence.intervention.closeDate)
                        }
                      </span>
                  </div>
                  }
                  {/*(occurrence && occurrence.intervention && occurrence.intervention.closeDate) &&
                  <div className="form-group special-title">
                    <label>{resources.local.detail.closeDate+":"}</label>
                    <span className="m-l-xs">
                      {toDate(occurrence.intervention.closeDate)}
                    </span>
                  </div>
                  */}
                  {(occurrence && occurrence.interventionStatus && occurrence.interventionStatus.id===2
                    && condominium && condominium.gecondComSettings 
                    && condominium.gecondComSettings.isPremiumCondomium && occurrence.wasInsertedByMe) &&
                    <div className="form-group special-title">
                      <label>{resources.local.detail.rating+":"}</label>
                      {occurrence.rating===null ?
                        <div>
                          <button
                            onClick={(e) => {
                              setRateId(occurrence.id);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            className="btn btn-warning">
                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                            <span>{resources.local.takeRatingNow}</span>
                          </button>
                          <RatingPopup 
                            message={resources.local.takeRatingNow}
                            visible={rateId!==0}
                            popUpResponse={(response, score)=>{popUpResponse(response, score)}}/>
                        </div>
                        :
                        <span className="rating m-l-xs">
                          {makeRatingComponent(occurrence.rating)}
                        </span>
                      }
                    </div>
                  }
                  {(occurrence && occurrence.administrationInformation!==null 
                    && occurrence.administrationInformation!==undefined && occurrence.administrationInformation!==""  
                    && condominium && condominium.gecondComSettings 
                    && condominium.gecondComSettings.isPremiumCondomium) &&
                    <div className="form-group special-title">
                      <label>{resources.local.detail.administrationInformation+":"}</label>
                      <p className="m-0">
                        {occurrence.administrationInformation}
                      </p>
                    </div>
                  }
              </Card>
              }
              <div className="row">
                <div className="col-md-6">
                  <Card title={occurrence.id===0 ? resources.local.detail.new : 
                    (((occurrence.interventionStatus && occurrence.interventionStatus.id===2) || !occurrence.canEdit) ? 
                        resources.local.detail.done : resources.local.detail.edit)}>
                    <div className="row">
                      <div className="col-12">
                      {((occurrence && occurrence.canEdit) || occurrence.id==0) ?
                        <Field
                          name="subject"
                          label={resources.local.detail.subject}
                          validate={required}
                          component={RenderInput}
                          autoFocus
                        />
                        :
                        <div className="form-group">
                          {occurrence.subject &&
                          <Fragment>
                            <label>{resources.local.detail.subject}</label>
                            <div /*className="m-l-xs"*/>
                              {occurrence.subject}
                            </div>
                          </Fragment>
                          }
                        </div>
                      }
                      {((occurrence && occurrence.canEdit) || occurrence.id==0) ?
                        <Field
                          name="description"
                          label={resources.local.detail.obs}
                          validate={required}
                          component={RenderTextarea}
                        />
                        :
                        <div className="form-group">
                          {occurrence.description &&
                          <Fragment>
                            <label>{resources.local.detail.obs}</label>
                            <div /*className="m-l-xs"*/>
                              {occurrence.description}
                            </div>
                          </Fragment>
                          }
                        </div>
                      }
                      </div>
                    </div>
                    <div className="row m-t-m justify-content-end">
                      <div className="col-auto">
                      {((occurrence && occurrence.canEdit && occurrence.wasInsertedByMe) || occurrence.id===0) &&
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitting || pristine}
                        >
                          {id > 0
                            ? globalResources.verbs.save_alt
                            : globalResources.verbs.register}
                        </button>
                      }{" "}
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => after()}
                        >
                          {(occurrence && occurrence.canEdit) ? globalResources.verbs.cancel : globalResources.verbs.goBack}
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card
                    title={
                      add
                        ? resources.local.detail.addAttachmentTitle
                        : resources.local.detail.attachments
                    }
                  >
                    {add ? (
                      <div className="row">
                        <div className="col">{addAttachment()}</div>
                      </div>
                    ) : (
                      occurrence.attachments && (
                        <Fragment>
                          {(occurrence.attachments.length < 5 && ((occurrence && occurrence.canEdit) || occurrence.id===0)) && (
                            <div className="row justify-content-end">
                              <div className="col-auto">
                                <FilePicker
                                  onUpload={(base64, file) =>
                                    onUpload(base64, file)
                                  }
                                  maxWidth={1024}
                                  buttonText={
                                    resources.local.detail.addAttachments
                                  }
                                  accept="image/*,.pdf"
                                ></FilePicker>
                              </div>
                            </div>
                          )}
                          {((occurrence && occurrence.canEdit) || occurrence.attachments.length>0 || occurrence.id==0) ?
                            <div className="row">{renderFiles()}</div>
                            :
                            <p>{resources.local.detail.noAttachments}</p>
                          }
                        </Fragment>
                      )
                    )}
                  </Card>
                </div>
              </div>
            </form>
          </Fragment>
        );
      }}
    />
  ) : (
    <Fragment></Fragment>
  );
};

export default OccurrenceForm;
