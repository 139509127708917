import React, { useState, useEffect, Fragment, useRef } from "react";
import IsLoading from "../elements/IsLoading";
import { Form, Field } from "react-final-form";
import { required, complexPassword } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import {
  isImage,
  makeArrayJustKey,
  thrower,
  getDateTime,
  replaceMultiple,
} from "../../assets/utils";
import Card from "../../components/containers/Card";
import {
  InquiriesResources,
  globalResources,
  navigationResources,
} from "../../resources/resources";
import { get_token } from "../../storage/token";
import { EmailPopup } from "../elements/Popup";
import {
  TextAnswer,
  RangeAnswer,
  ChoiceAnswer,
} from "../elements/InquirieInputs";
import {
  GetInquirie,
  SaveComment,
  Finish,
  GetAnswers,
  SaveAnswers,
  GetInquirieUnregisteredUser,
  GetComments,
} from "../../actions/inquiries";
import { notifyError, notifySuccess } from "../elements/Notify";
import { InfoPopup } from "../elements/Popup";
import { toInquirieStatusLabel } from "../../assets/FormatAndParse";

const InquiriesForm = (props) => {
  const { nid, eurl, l, logged, renderAttachments } = props;
  const [data, setData] = useState(props?.location?.state?.inquirie);
  const [render, reRender] = useState(0);
  const answers = useRef();
  const invalidUser = useRef(false);
  const [token] = useState(get_token());
  const [page, setPage] = useState(0);
  const [tab, setTab] = useState(1); // 1 IS QUESTIONS, 2 IS COMMENTS, 3 IS FINAL PAGE , 4 IS PAGE WITH INFORMATION REGARDING THE START OF THE INQUIRIE
  const [emailVisible, setEmailVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState(undefined);
  const [resources] = useState(InquiriesResources);
  const [missingAnswers, setMissingAnswers] = useState([]);
  const [popupMessage, setPopupMessage] = useState(null);
  const values = useRef([]);
  const cantChange =
    (data?.userFinishedAnswering && !data?.usersCanChangeAnswersWhileOpen) ||
    data?.status?.id !== 3
      ? true
      : false;

  function getComments() {
    GetComments(nid)
      .then((response) => response.text())
      .then((result) => {
        if (result) {
          setComments(result);
        } else {
          setComments("");
        }
      })
      .catch((err) => setComments(""));
  }

  function getInquirieUnregisteredUser(userEmail) {
    GetInquirieUnregisteredUser(nid, l, userEmail)
      .then((response) => {
        if (response.status === 400) {
          invalidUser.current = true;
          thrower("Invalid notificationId");
        }
        return response.ok
          ? response.json()
          : thrower("Invalid notificationId");
      })
      .then((result) => {
        if (result.error && result.error_description) {
          setData(null);
        } else {
          setData(result);
        }
      })
      .catch((err) => setData(null));
  }

  function insertEmail(e) {
    setEmail(e);
    setEmailVisible(false);
    getInquirieUnregisteredUser(e);
  }

  useEffect(() => {
    if (comments === undefined) {
      getComments();
    }
  });

  function getAnswers() {
    GetAnswers(nid)
      .then((response) =>
        response.ok ? response.json() : thrower("Invalid notificationId")
      )
      .then((result) => {
        answers.current = result;
        if (result.length > 0) {
          let newValues = [];
          for (let e of result) {
            newValues = [
              ...newValues,
              {
                id: e.questionId,
                answer:
                  e.answersValues.length > 1
                    ? [...e.answersValues.map(Number)]
                    : isNaN(e.answersValues[0])
                    ? e.answersValues[0]
                    : parseInt(e.answersValues[0]),
              },
            ];
          }
          values.current = [...newValues];
        }
        reRender(render + 1);
      })
      .catch((err) => {
        answers.current = [];
        reRender(render + 1);
      });
  }

  useEffect(() => {
    if (answers.current === undefined) {
      getAnswers();
    }
  });

  useEffect(() => {
    if (data === undefined) {
      if (logged) {
        GetInquirie(nid, l)
          .then((response) => {
            if (response.status === 400) {
              invalidUser.current = true;
              thrower("Invalid notificationId");
            }
            return response.ok
              ? response.json()
              : thrower("Invalid notificationId");
          })
          .then((result) => {
            if (result.error && result.error_description) {
              setData(null);
            } else {
              setData(result);
            }
          })
          .catch((err) => setData(null));
      } else {
        setEmailVisible(true);
      }
    } else {
      if (
        ((data?.userFinishedAnswering &&
          !data?.usersCanChangeAnswersWhileOpen &&
          data?.status?.id !== 2) ||
          data?.status?.id === 1) &&
        tab !== 3
      ) {
        setTab(3);
      }
      if (data?.status?.id === 2 && tab !== 4) {
        setTab(4);
      }
    }
  });

  function prepareValuesToPost(info) {
    let returnArray = [];
    for (let e of info.current) {
      let answersValues = [];
      if (Array.isArray(e.answer)) {
        for (let x of e.answer) {
          answersValues = [...answersValues, String(x)];
        }
      } else {
        answersValues = [String(e.answer)];
      }
      returnArray = [
        ...returnArray,
        {
          questionId: e.id,
          answersValues: answersValues,
        },
      ];
    }
    return returnArray;
  }

  function saveAnswers() {
    const valuesToPost = [...prepareValuesToPost(values)];
    SaveAnswers(nid, valuesToPost)
      .then((response) => response.text())
      .then((result) => {
        if (data.allowUsersFinalComment) setTab(2);
        else setTab(3);
      })
      .catch((err) => notifyError(resources.local.answersFailed));
  }

  function saveComment() {
    SaveComment(nid, comments)
      .then((response) => response.text())
      .then((result) => {
        setTab(3);
      })
      .catch((err) => notifyError(resources.local.commentsFailed));
  }

  function finish() {
    Finish(nid)
      .then((response) => response.text())
      .then((result) => {
        setPopupMessage(
          <>
            <h2>{resources.local.tyForParticipating1}</h2>
            <h2>{resources.local.tyForParticipating2}</h2>
          </>
        );
      })
      .catch((err) => notifyError(resources.local.errorOccurred));
  }

  function changeValue(id, answer) {
    const index = values.current.findIndex((e) => e.id === id);
    let tempArray = [...values.current];
    if (index >= 0) {
      tempArray.splice(index, 1);
    }
    values.current = [...tempArray, { id: id, answer: answer }];
  }

  function checkQuestionsFilled() {
    const requiredQuestions = makeArrayJustKey(
      data.questions.filter((e) => e.isAnswerRequired),
      "id"
    );
    const requiredAnswered = makeArrayJustKey(
      values.current.filter(
        (e) =>
          requiredQuestions.includes(e.id) &&
          e.answer !== "" &&
          e.answer !== 0 &&
          (!Array.isArray(e.answer) || e.answer.length > 0)
      ),
      "id"
    );
    let missingAnswersNew = [];
    for (let i in data.questions) {
      if (
        data.questions[i].isAnswerRequired &&
        !requiredAnswered.includes(data.questions[i].id)
      )
        missingAnswersNew = [
          ...missingAnswersNew,
          { id: data.questions[i].id, page: parseInt(i) },
        ];
    }

    if (missingAnswersNew.length > 0) {
      setPage(missingAnswersNew[0].page);
      setMissingAnswers([...missingAnswersNew]);
      return false;
    } else {
      setMissingAnswers([]);
      return true;
    }
  }

  const pagingBtns = () => {
    return (
      <div
        className={`page-selector-buttons text-right ${
          missingAnswers.length > 0 ? "m-t-0" : "m-t-l"
        } m-b-0`}
      >
        {page > 0 && (
          <button
            className={`btn btn-primary`}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {resources.local.previous}
          </button>
        )}
        {data.questions.length > page + 1 && (
          <button
            className={`btn btn-primary m-l-s`}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {resources.local.next}
          </button>
        )}
        {data.questions.length === page + 1 && (
          <button
            className={`btn btn-primary m-l-s`}
            onClick={() => {
              if (checkQuestionsFilled()) saveAnswers();
              //setTab(2)
              else {
                notifyError(resources.local.fillAllRequires);
              }
            }}
          >
            {resources.local.submit}
          </button>
        )}
      </div>
    );
  };

  function answerType(item, disabled) {
    switch (item.answerType.id) {
      case 1:
        return (
          <TextAnswer
            key={"TextAnswer-" + page}
            resources={resources}
            info={item.answerAsText}
            id={item.id}
            changeValue={(e, x) => {
              changeValue(e, x);
            }}
            parentValues={values.current}
            disabled={disabled}
          />
        );
      case 2:
        return (
          <RangeAnswer
            key={"RangeAnswer-" + page}
            resources={resources}
            info={item.answerAsIntRange}
            id={item.id}
            changeValue={(e, x) => {
              changeValue(e, x);
            }}
            parentValues={values.current}
            disabled={disabled}
          />
        );
      case 3:
        return (
          <ChoiceAnswer
            key={"ChoiceAnswer-" + page}
            resources={resources}
            info={item.answerAsChoice}
            id={item.id}
            changeValue={(e, x) => {
              changeValue(e, x);
            }}
            parentValues={values.current}
            disabled={disabled}
          />
        );
    }
  }

  const renderQuestion = (item, disabled, number) => {
    return (
      <div>
        {/* resources.local.required*/}
        <h4 className="m-b-m">
          {number ? number : page + 1}. {item.question}
          {item.isAnswerRequired && !disabled && (
            <span className="error"> *</span>
          )}
        </h4>

        {item.showExplanation && (
          <p className="m-t-0">{"(" + item.explanation + ")"}</p>
        )}
        {answerType(item, disabled)}
      </div>
    );
  };

  const renderComment = (disabled) => {
    const maxChars = 600;
    return (
      <Fragment>
        {disabled && <h4 className="m-b-m">{resources.local.comments}</h4>}
        <textarea
          readOnly={disabled ? true : false}
          defaultValue={comments}
          name={"TextArea-Comments"}
          type="text"
          className="form-control"
          maxLength={maxChars}
          onKeyUp={(e) => setComments(e.target.value)}
          rows="9"
        />
        {!disabled && (
          <Fragment>
            <p>
              {resources.local.availableChars.replace(
                "{%NUM%}",
                maxChars - comments.length
              )}
            </p>
            <div className="text-right m-t-l">
              {!cantChange && data?.status?.id === 3 && (
                <button
                  className={`btn btn-primary`}
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  {resources.local.previous}
                </button>
              )}
              <button
                className={`btn btn-primary m-l-s`}
                onClick={() => {
                  setTab(3);
                }}
              >
                {resources.local.ignore}
              </button>
              <button
                className={`btn btn-primary m-l-s`}
                onClick={() => {
                  if (comments === "")
                    notifyError(resources.local.cantSubmitEmptyComment);
                  else saveComment();
                }}
              >
                {resources.local.submit}
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const renderFinal = () => {
    return (
      <Fragment>
        <div className="text-right">
          {!cantChange && data?.status?.id === 3 && (
            <button
              className={`btn btn-primary m-r-s`}
              onClick={() => {
                setTab(2);
              }}
            >
              {resources.local.previous}
            </button>
          )}
          {!cantChange && data?.status?.id === 3 && (
            <button
              className={`btn btn-primary`}
              onClick={() => {
                finish();
              }}
            >
              {resources.local.finish}
            </button>
          )}
        </div>
        <h4 className="m-b-m">{resources.local.questions}</h4>
        {data.questions.map((item, index) => (
          <div key={"finalPageAnswers-" + index} className={"m-t-xxxl"}>
            {renderQuestion(data.questions[index], true, index + 1)}
          </div>
        ))}
        {data.allowUsersFinalComment &&
          data?.status?.id === 3 &&
          !cantChange && <div className="m-t-xxxl">{renderComment(true)}</div>}
        <div className="text-right m-t-xl">
          {!cantChange && data?.status?.id === 3 && (
            <button
              className={`btn btn-primary`}
              onClick={() => {
                finish();
              }}
            >
              {resources.local.finish}
            </button>
          )}
        </div>
      </Fragment>
    );
  };

  const renderWaitingOpening = () => (
    <Fragment>
      <h3>{resources.local.inquirieNotOpen}</h3>
      <p>
        {replaceMultiple(
          resources.local.inquirieWhenOpen,
          ["{%START_DATE%}"],
          [getDateTime(data.answeringOpeningDate)]
        )}
      </p>
    </Fragment>
  );

  return data !== undefined && answers.current !== undefined ? (
    <Fragment>
      {
        data ? (
          <Fragment>
            <InfoPopup
              buttonLabel={globalResources.close}
              disabled={logged ? false : true}
              messageComp={popupMessage}
              visible={popupMessage !== null}
              next={() => {
                if (logged)
                  props.history.push(navigationResources.inquiries.path);
              }}
            />
            <div className={"form-header-container"}>
              <h2 className="m-t-0">{data.title}</h2>
              <p className="linebreak">{data.description}</p>
              {data.attachments.length > 0 && (
                <div>{renderAttachments(data.attachments)}</div>
              )}
              {data?.userFinishedAnswering &&
                data?.status?.id === 3 &&
                data?.shareResultsWithUsers && (
                  <p className="note">
                    {resources.local.resultsWillBeAvailable}
                  </p>
                )}
              <div className="m-t-m">
                <span
                  className={
                    data.status?.id === 3
                      ? "selected"
                      : data.status?.id === 2
                      ? "warning"
                      : "grey"
                  }
                >
                  {toInquirieStatusLabel(
                    data.status,
                    resources.local.statusLabels
                  )}
                </span>
                {data?.status?.id !== 2 && (
                  <span className="color-white m-l-s">
                    {data.answeringOpeningDate &&
                      replaceMultiple(
                        resources.local.betweenDates1,
                        ["{%START_DATE%}"],
                        [getDateTime(data.answeringOpeningDate)]
                      )}
                    {data.answeringClosingDate &&
                      replaceMultiple(
                        resources.local.betweenDates2,
                        ["{%END_DATE%}"],
                        [getDateTime(data.answeringClosingDate)]
                      )}
                  </span>
                )}
              </div>
              {data?.status?.id === 1 && data?.shareResultsWithUsers && (
                <div className="note">
                  <a
                    className="cur-point text-decoration-underline note"
                    onClick={() =>
                      props.history.push(
                        navigationResources.inquiries.path +
                          "?stats=1&nid=" +
                          nid,
                        { inquirie: data }
                      )
                    }
                  >
                    {resources.local.viewResults}
                  </a>
                </div>
              )}
            </div>
            {data.questions.length > 0 && (
              <Card
                title={`${tab === 1 ? resources.local.questions : ""} ${
                  tab === 2 ? resources.local.comments : ""
                } ${
                  tab === 3 && !cantChange && data.status.id === 3
                    ? resources.local.final
                    : ""
                }`}
                subclass={tab !== 4 ? "inquirie-card" : ""}
              >
                {tab === 1 &&
                  renderQuestion(
                    data.questions[page],
                    cantChange ? true : false
                  )}
                {tab === 2 && renderComment(false)}
                {tab === 3 && renderFinal()}
                {tab === 4 && renderWaitingOpening()}
                {missingAnswers.length > 0 && (
                  <div className="page-selector-buttons text-center m-t-xxxl m-b-l">
                    {data.questions.map((item, index) => {
                      return (
                        <button
                          key={"missingAnswers-" + index}
                          className={`btn btn-primary btn-page-selector ${
                            missingAnswers.find((e) => e.id === item.id)
                              ? "danger"
                              : "success"
                          }`}
                          onClick={() => {
                            setPage(index);
                          }}
                        >
                          {index + 1}
                        </button>
                      );
                    })}
                  </div>
                )}
                <div /*className="p-abs b-l r-l"*/>
                  {tab === 1 && pagingBtns()}
                </div>
              </Card>
            )}
          </Fragment>
        ) : (
          <InfoPopup
            messageComp={
              <h2>
                {invalidUser.current
                  ? resources.local.invalidAccess
                  : resources.local.invalidInquirie}
              </h2>
            }
            visible={true}
            disabled={logged ? false : true}
            next={() => {
              window.open("/inquiries", "_self");
            }}
          />
        )
        /*<Card>{resources.local.invalidInquirie}</Card>*/
      }
    </Fragment>
  ) : (
    <Card>
      <EmailPopup
        resources={resources}
        visible={emailVisible}
        next={(e) => {
          insertEmail(e);
        }}
      />
      <IsLoading text={globalResources.loading} />
    </Card>
  );
};

export default InquiriesForm;
