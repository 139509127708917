import React, { Fragment, useState, useEffect } from "react";
import Table, {
  Column,
  NoData,
  Loading,
  Option,
  Date,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetDocuments } from "../../actions/documents";
import Card from "../../components/containers/Card";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentsTableResources } from "../../resources/resources";
import { useSelector } from "react-redux";

const DocumentsTable = () => {
  const [data, setData] = useState();
  const [resources] = useState(DocumentsTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);

  useEffect(() => {
    if (condominium) {
      GetDocuments(condominium.id).then((response) => {
        response.data.forEach((doc) => {
          doc.date = new window.Date(doc.date);
        });
        setData(response.data);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  return (
    <Card subclass="max-width">
      <Table data={data} pager={{ pageIndex: 0 }}>
        <Date
          Header={resources.local.date}
          accessor="date"
          sortType="datetime"
          className="collapse"
        />
        <Column Header={resources.local.description} accessor="description" />
        <Option
          id="download"
          Cell={({ row }) => {
            return (
              <Fragment>
                {row.original.documentLink &&
                  row.original.documentLink.url &&
                  row.original.documentLink.url !== "" && (
                    <a
                      href={row.original.documentLink.url}
                      title={resources.local.download}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faCloudDownloadAlt}
                      ></FontAwesomeIcon>
                    </a>
                  )}
              </Fragment>
            );
          }}
        />
        <NoData>{resources.local.noDocuments}</NoData>
        <Loading value={data === undefined}>
          <IsLoading text={resources.local.loading} />
        </Loading>
      </Table>
    </Card>
  );
};

export default DocumentsTable;
