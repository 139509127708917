import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/configureStore";
import { toast } from "react-toastify";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.scss";
import IndexSetPages from "./indexSetPages";

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});

if (window.location.hash && window.location.hash.length > 0) {
  window.open(window.location.hash.replace("#", ""), "_self");
}

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <IndexSetPages />
  </Provider>
);

ReactDOM.render(jsx, document.querySelector("#root"));
