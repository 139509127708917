import { globalResources } from "../resources/resources";
import {Global as Global_en} from "../resources/en";
import {Global as Global_pt} from "../resources/pt";

export const toPercentage = (number) => {
  if (number) {
    return parseFloat(number).toFixed(2).replace(".", ",");
  }
  return "";
};

export const toFixed = (number, decimals) => {
  switch (true) {
    case !decimals || decimals === null || decimals == 0:
      return parseInt(number);
    default:
      return (
        Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      ).toFixed(decimals);
  }
};

export const formatNumber = (num, decimals) => {
  if (!decimals) decimals = 3;

  var re = new RegExp("(\\d)(?=(\\d{" + decimals + "})+(?!\\d))", "g");

  return num.toString().replace(".", ",").toString().replace(re, "$1.");
};

export const toNumber = (number, oldnumber, locale = "de") => {
  if (number) {
    if (isNaN(number)) {
      return oldnumber;
    } else {
      return new Intl.NumberFormat(locale).format(number);
    }
  }
  return "";
};

export const reverseNumber = (number, oldnumber, locale = "de") => {
  if (number) {
    const unumber = number.toString().split(".").join("");
    if (isNaN(unumber)) {
      return oldnumber;
    } else {
      return unumber;
    }
  }
  return undefined;
};

export const toCurrency = (number, locale = "de", decimals = 2) => {
  if (number) {
    number = number.toString().replace(",", ".");
    if (isNaN(number)) {
      return number;
    } else {
      return new Intl.NumberFormat(locale, {
        style: "decimal",
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      }).format(number);
    }
  }
  return "";
};

export const reverseCurrency = (number) => {
  return number ? (number = number.toString().split(".").join("")) : undefined;
};

export const toIBAN = (string) => {
  return string.slice(0,4) + " " + string.slice(4,8) + " " + string.slice(8,12) + " " + string.slice(12,23) + " " + string.slice(23,25);
};

export const toSelectOptions = (list, value, label) => {
  let options = [];
  list.forEach((item) => {
    options.push({
      value: item[value],
      label: item[label],
    });
  });
  return options;
};

export const toIdentificationDocumentSelectOptions = (list, id) => {
  let options = [];
  let langFile=globalResources;

  list.forEach((item) => {
    let optionSelect = {};
    switch (item[id]) {
      case 2:
        optionSelect = {
          value: {...item},
          label: langFile.entityTicket,
        };
        break;
      case 3:
        optionSelect = {
          value: {...item},
          label: langFile.passport,
        };
        break;
      case 4:
        optionSelect = {
          value: {...item},
          label: langFile.CC,
        };
        break;
      default:
        optionSelect = {
          value: {...item},
          label: langFile.residencePermit,
        };
    }
    options.push({...optionSelect});
  });
  return options;
};

export const toIdentificationDocumentSelectOption = (value) => {
  let optionSelect = {};
  let langFile=globalResources;

  switch (value.id) {
    case 2:
      optionSelect = {
        value: {...value},
        label: langFile.entityTicket,
      };
      break;
    case 3:
      optionSelect = {
        value: {...value},
        label: langFile.passport,
      };
      break;
    case 4:
      optionSelect = {
        value: {...value},
        label: langFile.CC,
      };
      break;
    default:
      optionSelect = {
        value: {...value},
        label: langFile.residencePermit,
      };
  }

  return optionSelect;
};

export const toVotingStatusLabel = (value) => {
  switch (value.id) {
    case 1:
      return globalResources.hasntVoted;
    case 2:
      return globalResources.votingIncomplete;
    case 3:
      return globalResources.complete;
    case 4:
      return globalResources.hasntSignedFile;
    default:
      return globalResources.unavailable;
  }
};

export const toInquirieStatusLabel = (value, resources) => {
  switch (value.id) {
    case 1:
      return resources.closed;
    case 2:
      return resources.waiting_opening;
    default:
      return resources.in_progress;
  }
};

export const toValidationStatusLabel = (value, resources) => {
  switch (value.id) {
    case 1:
      return resources.valid;
    case 2:
      return resources.invalid;
    default:
      return resources.validating;
  }
};

export const toRealizationModeLabel = (value, resources) => {
  switch (value.id) {
    case 1:
      return resources.presentialAssemblyDate;
    case 2:
      return resources.videoconferenceAssemblyDate;
    default:
      return resources.mixedAssemblyDate;
  }
};

export const toAccountTypeLabel = (value, resources) => {
  switch (value.code) {
    case "CURRENT":
      return resources.current;
    case "SAVINGS":
      return resources.savings;
    case "RESERVATION":
      return resources.reservation;
    default:
      return resources.cash;
  }
};

export const toInterventionStatusLabel = (value, resources) => {
  switch (value.id) {
    case 0:
      return resources.unStarted;
    case 1:
      return resources.onGoing;
    default:
      return resources.completed;
  }
};

export const toGenderSelectOption = (id, lang) => {
  let optionSelect = {};
  let langFile;
  if (lang){
    if(lang==="en-GB"){
      langFile=Global_en;
    }
    else{
      langFile=Global_pt;
    }
  }
  else{
    langFile=globalResources;
  }

  switch (id) {
    case 1:
      optionSelect = {
        value: 1,
        label: langFile.male,
      };
      break;
    default:
      optionSelect = {
        value: 2,
        label: langFile.female,
      };
  }

  return optionSelect;
};

export const toThemeSelectOption = (id, lang) => {
  let optionSelect = {};
  let langFile;
  if (lang){
    if(lang==="en-GB"){
      langFile=Global_en;
    }
    else{
      langFile=Global_pt;
    }
  }
  else{
    langFile=globalResources;
  }

  switch (id) {
    case 1:
      optionSelect = {
        value: 1,
        label: langFile.light,
      };
      break;
    default:
      optionSelect = {
        value: 2,
        label: langFile.dark,
      };
  }

  return optionSelect;
};

export const toGenderObject = (option) => {
  let optionSelect = {};
  let optionToSwitch = option.value ? option.value : option;

  switch (optionToSwitch) {
    case 1:
      optionSelect = {
        id: 1,
        code: "MALE",
      };
      break;
    default:
      optionSelect = {
        id: 2,
        code: "FEMALE",
      };
  }

  return optionSelect;
};

export const toThemeObject = (option) => {
  let optionSelect = {};
  let optionToSwitch = option.value ? option.value : option;

  switch (optionToSwitch) {
    case 1:
      optionSelect = {
        id: 1,
        code: "LIGHT_THEME",
      };
      break;
    default:
      optionSelect = {
        id: 2,
        code: "DARK_THEME",
      };
  }

  return optionSelect;
};

export const toRepresentativeObject = (option) => {
  let optionSelect = {};
  let optionToSwitch = option.value ? option.value : option;
  
  switch (optionToSwitch) {
    case 1:
      optionSelect = {
        id: 1,
        code: "ASSEMBLY_PRESIDENT",
      };
      break;
    case 2:
      optionSelect = {
        id: 2,
        code: "PROPOSED_LEGAL_REPRESENTATIVE",
      };
      break;
    default:
      optionSelect = {
        id: 0,
        code: "USER_CUSTOM_LEGAL_REPRESENTATIVE",
      };
  }

  return optionSelect;
};

export const toTableNumber = (number) => {
  return formatNumber(toFixed(parseFloat(number.toString().replace(",", ".")),2));
}

export const toDate = (date) => {
  var moment = require('moment');
  return moment(date).format("DD-MM-YYYY");
}

export const toDateTime = (date) => {
  var moment = require('moment');
  return moment(date).format("DD-MM-YYYY HH:mm");
}
