import React, {useEffect, useState} from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import {
  globalResources,
  getCurrent,
  setLanguage,
} from "../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import InquiriesForm from "../components/forms/InquiriesForm";
import InquiriesList from "../components/lists/InquiriesList";
import InquiriesStats from "../components/lists/InquiriesStats";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";
import { get_token } from "../storage/token";

const InquiriesPage = (props) => {
  const search = new URLSearchParams(props.location?.search);
  const nid = search.get("nid");
  const eurl = search.get("eurl");
  const l = search.get("l");
  const stats = search.get("stats");
  const token = get_token();
  const [logged] = useState((token && token.length > 0) ? true : false);

  // IN CASE USER DOESNT HAVE TO LOGIN, CHANGE TO THE LANG IN THE URL
  function checkLang(){
    if((!eurl || eurl==0) && l){
        if (l.split("-")[0] !== getCurrent()) {
          setLanguage(l.split("-")[0], false);
        }
      }
  }
  
  useEffect(()=>{
    if(eurl == 1 && !logged){
      props.history.push("/", {inquiriesUrl:props.location?.search})
    } else{
      checkLang()
    }
  },[logged])

  const renderAttachments = (items) =>{
    return(
      <div className="row m-0">
        {items.map((item, index)=>(
          <a key={"inquirieAttachment-"+index} 
            className="a-sixth m-t-m m-r-s"  
            href={item.documentLink.url}
            title={item.description}
            target="_blank">
            <FontAwesomeIcon className="inquirie-attachment" icon={faFileDownload}/>
            <span className="m-l-s color-white">{item.name}</span>
          </a>
        ))}
      </div>
    )
  }

  const inqueriesFormElem = 
    <div className="container-fluid">
      <InquiriesForm {...props} nid={nid} eurl={eurl} l={l} logged={logged} renderAttachments={(items)=>renderAttachments(items)}/>
    </div>;

  const inqueriesStatsElem = 
    <div className="container-fluid">
      <InquiriesStats {...props} nid={nid} eurl={eurl} l={l} logged={logged} renderAttachments={(items)=>renderAttachments(items)}/>
    </div>;

  if(nid && !stats){                                                            // CASE THERE IS AN NID THEREFORE ITS A FORM
    return (logged) ? (                                                         // CASE LOGIN NECESSARY AND ALREADY LOGGED IN
      <Master>
        <Breadcrumb
          page={navigationResources.inquiries.inquirie}
          parent={
          {
            name: navigationResources.inquiries.breadcrumb,
            onClick: () => {props.history.push(navigationResources.inquiries.path)},
          }}
        />
        {inqueriesFormElem}
      </Master>
    ) 
    : ((eurl == 1 && !logged) ? (                                              // CASE LOGIN NECESSARY BUT NOT LOGGED IN YET
        null
      )
      :                                                                        // CASE NO LOGIN NECESSARY
      (inqueriesFormElem)
    );
  } else if(nid && stats){                                                    // CASE THERE IS AN NID AND ITS SUPPOST TO DISPLAY THE STATS      
    return (logged) ? (                                                         // CASE LOGIN NECESSARY AND ALREADY LOGGED IN
      <Master>
        <Breadcrumb page={navigationResources.inquiries.stats}
          parent={
            {
              name: navigationResources.inquiries.breadcrumb,
              onClick: () => {props.history.push(navigationResources.inquiries.path)},
            }} 
        />
        {inqueriesStatsElem}
      </Master>
    ) 
    : ((eurl == 1 && !logged) ? (                                              // CASE LOGIN NECESSARY BUT NOT LOGGED IN YET
        null
      )
      :                                                                        // CASE NO LOGIN NECESSARY
      (inqueriesStatsElem)
    );     
  } else{                                                                     // CASE THERE IS NO NID THEREFORE ITS A LIST
    return(
      <Master>
        <Breadcrumb page={navigationResources.inquiries.breadcrumb} />
        <div className="container-fluid">
          <InquiriesList {...props}/>
        </div>
      </Master>
    )
  }

  
};

export default InquiriesPage;
