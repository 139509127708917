import React from "react";
import VotingsTable from "../components/tables/VotingsTable";
import Master from "../components/containers/Master";

const VotingsPage = () => {
  return (
    <Master>
      <div className="container-fluid">
        <VotingsTable></VotingsTable>
      </div>
    </Master>
  );
};

export default VotingsPage;
