import React, { Fragment } from "react";

export const Terms = (language) =>
  language === "pt" ? (
    <Fragment>
      <h3>TERMOS E CONDIÇÕES DE USO DA ÁREA RESERVADA</h3>
      <h6>1. INTRODUÇÃO</h6>
      <p>
        <span>Bem-vindo à sua área reservada!</span>
      </p>
      <p>
        Poderá aceder aqui à a informação associada ao seu condomínio, bem como
        a toda a documentação que necessita para tomar as suas decisões enquanto
        condómino e acompanhar a sua gestão, quase em tempo real, bastando para
        tal, que aceite os presentes Termos e Condições.
      </p>
      <p>
        A possibilidade que lhe é conferida de aceder à presente área reservada,
        constitui uma funcionalidade integrada no software Gecond utilizado,
        pela administração que gere o condomínio onde se insere a sua fração
      </p>
      <p>
        Assim, estando o seu condomínio a ser gerido com recurso a um dos nossos
        softwares, a Improxy concede-lhe acesso a uma “área reservada” no site
        de internet <a href="https://www.gecond.com/">www.Gecond.com</a> para
        que, enquanto “<span>Utilizador Autorizado</span>”, usufrua de algumas
        das funcionalidades disponibilizadas pela nossa plataforma.
      </p>
      <p>
        Neste documento explicamos-lhe quais são os concretos termos e condições
        para aceder à área reservada Gecond.com enquanto{" "}
        <span>Utilizador Autorizado</span>
      </p>
      <h6>2. ATIVAÇÃO DO REGISTO NO Gecond.COM</h6>
      <p>
        O acesso à “área reservada” está dependente da aceitação dos presentes
        Termos e Condições e da Política de Privacidade, no momento de ativação
        do seu registo
      </p>
      <p>
        Caso não concorde com os concretos termos e com qualquer uma das
        condições estabelecidas no presente documento, pedimos-lhe que não
        conclua o processo de ativação do registo em Gecond.com.
      </p>
      <p>
        Enquanto utilizador do Gecond.com será responsável pela veracidade dos
        dados que eventualmente possa comunicar com a administração do
        condomínio. Para isso compromete-se a manter os seus que nos tenha
        fornecido devidamente atualizados.
      </p>
      <h6>3. FUNCIONALIDADES ACESSÍVEIS AO CONDÓMINO</h6>
      <p>
        Enquanto proprietário de uma fração inserida num condomínio gerido por
        um dos softwares da Improxy, terá acesso ao Gecond.com com as seguintes
        funcionalidades:
      </p>
      <p>
        · Acesso constante e imediato a toda a documentação referente ao seu
        condomínio disponibilizada pela administração do seu condomínio;
      </p>
      <p>
        · Conteúdo integral de todas as atas e documentação de suporte que
        tenham sido disponibilizadas pela administração de condomínio;
      </p>
      <p>
        · Acesso aos avisos de cobrança, créditos e recibos, emitidos em seu
        nome
      </p>
      <p>
        · Possibilidade de registar avarias ou problemas no seu condomínio, que
        serão disponibilizadas à administração do condomínio para respetivo
        tratamento.
      </p>
      <p>
        · Visualização de mensagens gerais colocadas pela administração do
        condomínio
      </p>
      <h6>4. RESPONSABILIDADES DO UTILIZADOR</h6>
      <p>
        Enquanto utilizador do Gecond.com o “<span>Utilizador Autorizado</span>
        ”:
      </p>
      <p>
        4.1 É responsável por quaisquer ações efetuadas através da sua conta de
        utilizador, diretamente ou mediante terceiros autorizados pelo
        utilizador.
      </p>
      <p>
        4.2 É responsável pela guarda e correta utilização da sua informação
        pessoal, bem como por qualquer dano ou prejuízo causado a terceiros,
        resultante da utilização incorreta, perda ou furto da informação pessoal
        ou qualquer informação referente ao condomínio que seja disponibilizada
        ou acedida por via da “<span>área reservada</span>”.
      </p>
      <p>
        4.3 Garante que usará os Serviços em conformidade com os presentes
        Termos e Condições de Uso, Política de Privacidade e com a lei e
        regulamentos em vigor.
      </p>
      <h6>5. RENUNCIA DE RESPONSABILIDADES E GARANTIAS</h6>
      <p>
        5.1 O <span>Utilizador Autorizado</span>, bem como, a administração do
        condomínio são os únicos e exclusivos responsáveis pela utilização do
        Gecond.com, na parte dos concretos conteúdos relacionados com a gestão
        do condomínio, nomeadamente, a sua conformidade com a lei e regulamento
        do condomínio.
      </p>
      <p>
        5.2 A Improxy não é responsável, nem assume responsabilidades pelos
        conteúdos que são disponibilizados ao <span>Utilizador</span>{" "}
        <span>Autorizado</span> por parte da administração do condomínio, sendo
        esta última a única e exclusiva responsável, pela comunicação de
        informação ou dados incorretos, falsos, ou de terceiros, sem o seu
        consentimento, bem como pela utilização incorreta dos mesmos.
      </p>
      <p>
        5.3 A Improxy não é responsável, nem poderá ser responsabilizada, por
        qualquer meio ou modo, pelas incorreções e erros, ou quaisquer danos
        resultantes da utilização de informação disponibilizada no Gecond.com
        aos <span>Utilizadores Autorizados</span> por parte das administrações
        de condomínio.
      </p>
      <p>
        5.4 A Improxy não assume qualquer responsabilidade por quaisquer danos
        resultantes da impossibilidade de acesso aos serviços disponibilizados
        através do Gecond.com ou provocados por quaisquer, acessos ilegítimos,
        vírus, ficheiros danificados, erros, omissões, interrupções do serviço,
        cancelamento de conteúdos, questões relacionadas com a Internet,
        prestadores de serviços quaisquer alterações de dados.
      </p>
      <p>
        5.5 A Improxy não será responsável por quaisquer atrasos, falhas e
        qualidade na prestação de acesso ou funcionalidades do Gecond.com sempre
        que o atraso, falha ou impossibilidade de cumprir qualquer obrigação,
        decorra, direta ou indiretamente, de facto alheio à Improxy,
        nomeadamente, distúrbios nos sistemas elétricos, telecomunicações ou
        outras falhas de serviços públicos, terremotos, tempestades, inundações
        ou outros fenómenos da natureza, bloqueios, embargos, tumultos,
        manifestações, ataques cibernéticos (incluindo, sem limitação, ataques
        de negação de serviço distribuídos, <em>malware</em>,{" "}
        <em>ransomware</em> e quaisquer outros eventos cibernéticos), atos ou
        ordens do governo, atos de terrorismo, guerra ou pandemias.
      </p>
      <p>
        5.6 A Improxy empenhará os seus melhores esforços, respeitando os
        melhores padrões vigentes no setor, para disponibilizar e manter o
        Gecond.com acessível, minimizando erros e interrupções do Serviço,
        sempre que tal esteja ao seu alcance. O <span>Utilizador</span>{" "}
        <span>Autorizado</span> reconhece que a sua “área reservada” pode ficar
        temporariamente indisponível, nomeadamente, por motivos de manutenção
        previamente programados, decorrente de uma situação imprevista que
        obrigue a uma manutenção urgente ou por qualquer outro motivo que
        estravasse o controlo da Improxy. Sempre que tal seja razoavelmente
        possível, a Improxy usará os seus melhores esforços, para previamente
        avisar de qualquer indisponibilidade interrupção programada.
      </p>
      <h6>
        6. ALERAÇÕES ÀS CONDIÇÔES DE ACESSO AO GECOND.COM E FUNCIONALIDADES
        DISPONIBILIZADAS
      </h6>
      <p>
        6.1 A Improxy reserva-se o direito de modificar em qualquer momento, as
        funcionalidades disponibilizadas na “área reservada” bem como as
        condições de que depende o acesso à mesma, caso em que, disponibilizar
        por e-mail ao <span>Utilizador</span> <span>Autorizado </span>as
        alterações a introduzir.
      </p>
      <p>
        6.2 Qualquer alteração às funcionalidades ou funcionamento do
        Gecond.com, passará automaticamente a vigorar, após notificação das
        alterações ao <span>Utilizador</span> <span>Autorizado</span>, No seu
        próximo acesso ao Gecond.COM
      </p>
      <p>
        6.3 Ao continuar a aceder ao Gecond.com depois de qualquer alteração que
        lhe tenha sido expressamente comunicada nos termos da presente Cláusula,
        será interpretado como a sua aceitação e concordância com o teor dos
        mesmos, passando a estar vinculado aos mesmos.
      </p>
      <p>
        6.4 Em determinados casos pontuais a Improxy no seguimento de uma
        alteração de relevo ao presentes Termos e Condições, poderá notificar o
        Utilizador para que este aceite os novos Termos e Condições de Uso, e
        suspender o acesso ao presente Gecond.com até que os novos Termos sejam
        aceites.
      </p>
      <h6>7. PROPRIEDADE E DIREITOS DE AUTOR</h6>
      <p>
        7.1 A Improxy Tecnologias de Informação, Lda. NIF 504097750, com sede da
        Rua Dr. Ilídio Sardoeira, 28 C sala 1.1 4400-107 Vila Nova de Gaia
        mantem a propriedade integral, exclusiva e sem restrições ou reservas,
        sobre todos os Direitos de Propriedade Intelectual dos Serviços e
        quaisquer conteúdos a estes associados, incluindo direitos de autor,
        marcas comerciais, segredos comerciais e de negócio, patentes e outros
        direitos de propriedade que sejam por aquela detidos, ao abrigo de
        quaisquer leis aplicáveis em qualquer parte do mundo, e todos os
        direitos morais aplicáveis que lhe estejam relacionados.
      </p>
      <p>
        7.2 O <span>Utilizador Autorizado</span> não pode (i) licenciar,
        sublicenciar, vender, revender, alugar, arrendar, transferir, atribuir,
        distribuir, partilhar o tempo, oferecer numa agência de serviço, ou de
        outra forma disponibilizar o acesso à sua “área reservada” a terceiros;
        (ii) modificar, copiar ou criar quaisquer obras derivadas com base na
        “área reservada” ou nas funcionalidades disponibilizadas; (iii) Não
        aceitará, autorizará ou permitirá a reprodução, engenharia reversa;
      </p>
      <p>
        7.3 O <span>Utilizador Autorizado</span> deverá incluir, e não poderá em
        quaisquer circunstâncias eliminar, o <em>copyright</em>, a marca
        registada, a marca de serviço e outras informações relativas à
        propriedade da Improxy e respetivos licenciantes, apostas em quaisquer
        suportes. A inclusão de uma menção ao <em>copyright </em>em qualquer
        parte do <em>Software</em>, documentação ou informação disponibilizada
        pela Improxy, não significará, em quaisquer casos, que estes sejam ou
        passem a ser considerados uma obra publicada ou, em geral, publicamente
        acessível.
      </p>
      <p>
        7.4 O <span>Utilizador Autorizado</span> não pode desmontar,
        descompilar, retroverter ou aplicar outros procedimentos no sentido de
        descobrir o código fonte, nem pode utilizar quaisquer conteúdos ou
        componentes dos Serviços, para o desenvolvimento, produção ou
        comercialização de soluções similares.
      </p>
      <h6>8. LEI APLICÁVEL E REGULAÇÃO DE LITÍGIOS</h6>
      <p>
        Qualquer questão relacionada com os serviços disponibilizados ou a
        interpretação dos presentes Termos e Condições, será sujeita à lei
        portuguesa e ao Tribunal da Comarca de Vila Nova de Gaia, com expressa
        renúncia a qualquer outro.
      </p>
      <h6>9. COMUNICAÇÕES E RECLAMAÇÕES</h6>
      <p>
        Assuntos relacionados com a informação disponibilizada relativa ao seu
        condomínio, deverá ser tratada diretamente com a respetiva
        administração.
      </p>
      <p>
        O contacto para qualquer questão geral relativa aos nossos serviços,
        excluindo dúvidas de carater técnico ou de funcionamento, deverá ser
        feito para o email <a href="mailto:info@gecond.com">info@gecond.com</a>.
      </p>
      <br />
      <p>Data: 4 de maio de 2020</p>
    </Fragment>
  ) : (
    <Fragment>
      <h3>TERMS AND CONDITIONS FOR USE OF YOUR PERSONAL AREA</h3>
      <h6>1. INTRODUCTION</h6>
      <p>
        <span>Welcome to your personal area!</span>
      </p>
      <p>
        Here you can access the information associated with your condominium, as
        well as all the documentation you need to make your decisions as a
        condominium member and monitor your management, almost in real time,
        just by accepting these Terms and Conditions.
      </p>
      <p>
        The possibility given to access this personal area, is a functionality
        integrated in the Gecond software used, by the administration that
        manages the condominium where your property is located.
      </p>
      <p>
        Thus, with your condominium being managed using one of our software,
        Improxy grants you access to a &quot;personal area&quot; on the website
        www.Gecond.com so that, as an &quot;Authorized User&quot;, you can enjoy
        some of the features provided by our platform.
      </p>
      <p>
        In this document we explain to you the specific terms and conditions to
        access the personal area Gecond.com as an Authorized User.
      </p>
      <h6>2. REGISTERING AT Gecond.COM&nbsp;</h6>
      <p>
        Access to the &quot;personal area&quot; is subject to the acceptance of
        these Terms and Conditions and the Privacy Policy at the time of
        registration.
      </p>
      <p>
        If you do not agree with the specific terms and any of the conditions
        set out in this document, we ask you not to register at Gecond.com.
      </p>
      <p>
        As a Gecond.com user you will be responsible for the accuracy of any
        information you make available to the condominium administration. To
        this end, you undertake to keep all information you have provided duly
        updated.
      </p>
      <h6>3. ACCESSIBLE FEATURES TO THE CONDOMINIUM MEMBER</h6>
      <p>
        As the owner of a property set in a condominium managed by one of our
        software, you will have access to Gecond.com with the following
        features:
      </p>
      <p>
        &bull;Constant and immediate access to all the documentation regarding
        your condominium, made available by your condominium management;
      </p>
      <p>
        &bull;Full content of all minutes and supporting documentation that have
        been made available by the condominium management;
      </p>
      <p>&bull;Access to reminders, claims and receipts issued in your name</p>
      <p>
        &bull;Possibility of registering malfunctions or problems in your
        condominium, that will be reported to the condominium administration so
        they can be repaired;
      </p>
      <p>
        &bull;Receiving of general messages sent by the condominium
        administration
      </p>
      <h6>4. USER&rsquo;S RESPONSIBILITY</h6>
      <p>As Gecond.com user the &quot;Authorized User&quot;:&nbsp;</p>
      <p>
        4.1 is responsible for any actions carried out through the User account,
        directly or through third parties authorized by the User.&nbsp;
      </p>
      <p>
        4.2 &nbsp;is responsible for the safekeeping and correct use of personal
        information as well as for any damage or injury caused to third parties
        resulting from misuse, loss or theft of personal information or any
        information regarding the condominium that is made available or accessed
        via the &quot;personal area&quot;.
      </p>
      <p>
        4.3 warrants to use the Services in accordance with these Terms and
        Conditions of Use, Privacy Policy and the law and regulations in force.
      </p>
      <h6>5. WAIVER OF LIABILITY AND GUARANTEES</h6>
      <p>
        5.1 The Authorized User, as well as the management of the condominium,
        are solely and exclusively responsible for the use of Gecond.com,
        regarding the specific contents related to the management of the
        condominium, namely, its compliance with the law and the condominium
        regulations.
      </p>
      <p>
        5.2 Improxy is not responsible, nor does it assume responsibility for
        the content made available to the Authorized User by the management of
        the condominium, the latter being solely and exclusively responsible,
        for the communication of incorrect, false or third party information or
        data, without its consent, as well as for the incorrect use of any
        information.
      </p>
      <p>
        5.3 Improxy is not responsible, and shall not be liable in any way or
        form, for inaccuracies and errors, or any damages resulting from the use
        of information made available on Gecond.com to Authorized Users by the
        condominium administrations.
      </p>
      <p>
        5.4 Improxy assumes no responsibility for any damages resulting from the
        inability to access the services provided through Gecond.com or caused
        by any, illegitimate access, viruses, damaged files, errors, omissions,
        service interruptions, cancellation of content, Internet related issues,
        service providers any data changes.
      </p>
      <p>
        5.5 Improxy shall not be liable for any delays, failures and quality in
        providing access or features of the Gecond. whenever the delay, failure
        or inability to perform any obligation arises, directly or indirectly,
        from a fact beyond the control of Improxy, namely, disturbances in
        electrical systems, telecommunications or other utility failures,
        earthquakes, storms, floods or other natural phenomena, blockades,
        embargoes, riots, demonstrations, cyber-attacks (including, without
        limitation, distributed denial of service attacks, malware, ransomware
        and any other cyber events), acts or orders of government, acts of
        terrorism, war or pandemics.
      </p>
      <p>
        5.6 Improxy will use its best efforts, respecting the best standards in
        the industry, to make Gecond.com available and accessible, minimizing
        errors and interruptions of the Service, whenever this is within its
        reach. The Authorized User acknowledges that its &quot;personal
        area&quot; may be temporarily unavailable, including for previously
        scheduled maintenance reasons, due to an unforeseen situation requiring
        urgent maintenance or for any other reason beyond the control of
        Improxy. Where reasonably possible, Improxy will use its best efforts to
        give notice of any scheduled downtime.
      </p>
      <h6>
        6. CHANGES TO THE CONDITIONS OF ACCESS TO GECOND.COM AND FEATURES
        PROVIDED &nbsp; &nbsp;
      </h6>
      <p>
        6.1 Improxy reserves the right to modify at any time the functionalities
        made available in the &quot;personal area&quot; as well as the
        conditions on which access to it depends, in which case, the changes to
        be implemented, will be made available by e-mail to the Authorized User.
      </p>
      <p>
        6.2 Any changes to the functionalities or operation of Gecond.com shall
        automatically become effective, upon notification of the changes to the
        Authorized User, on his next access to Gecond.com
      </p>
      <p>
        6.3 By continuing to access Gecond.com after any change has been
        expressly communicated to it pursuant to this Clause, it shall be
        construed as your acceptance of and agreement with the contents thereof
        and shall be bound by them.
      </p>
      <p>
        6.4 In certain occasional cases Improxy following a material change to
        these Terms and Conditions may notify the User to accept the new Terms
        and Conditions of Use, and suspend access to this Gecond.com until the
        new Terms are accepted.
      </p>
      <h6>7. OWNERSHIP AND COPYRIGHT</h6>
      <p>
        7.1 Improxy Tecnologias de Informa&ccedil;&atilde;o, Lda. NIF 504097750,
        with registered office at Rua Dr. Il&iacute;dio Sardoeira, 28 C sala 1.1
        4400-107 Vila Nova de Gaia, maintains full, exclusive and unrestricted
        ownership of all Intellectual Property Rights of the Services and any
        content associated therewith, including copyrights, trademarks, trade
        and business secrets, patents and other proprietary rights held by it
        under any applicable laws anywhere in the world, and all applicable
        moral rights relating thereto.&nbsp;
      </p>
      <p>
        7.2 The Authorized User may not (i) license, sublicense, sell, resell,
        rent, lease, transfer, assign, distribute, share time, offer in a
        service agency, or otherwise make access to its &quot;personal
        area&quot; available to any third party; (ii) modify, copy or create any
        derivative works based on the &quot;personal area&quot; or the
        functionality made available; (iii) not accept, authorize or permit
        reproduction, reverse engineering;
      </p>
      <p>
        7.3 The Authorized User shall include, and in no circumstances delete,
        the copyright, trademark, service mark and other proprietary information
        of Improxy and its licensors, affixed to any media. &nbsp;The inclusion
        of a copyright notice in any part of the Software, documentation or
        information made available by Improxy shall in no event mean that it is
        or becomes a published or generally accessible work.
      </p>
      <p>
        7.4 The Authorized User may not disassemble, decompile, retrovert or
        apply other procedures in order to discover the source code, nor may the
        Authorized User use any content or components of the Services, for the
        development, production or marketing of similar solutions.
      </p>
      <h6>8. APPLICABLE LAW AND DISPUTE RESOLUTION</h6>
      <p>
        Any question related to the services provided or the interpretation of
        these Terms and Conditions, will be subject to Portuguese law and the
        Court of Vila Nova de Gaia, with express waiver of any other.
      </p>
      <h6>9. COMMUNICATIONS AND COMPLAINTS</h6>
      <p>
        Issues related to the information provided regarding your condominium
        should be dealt with directly with the respective management.
      </p>
      <p>
        Contact for any general questions regarding our services, excluding
        technical or operational questions, should be made to info@gecond.com.
      </p>
      <br />
      <p>Date: May 4, 2020</p>
    </Fragment>
  );
