import { apiAuthRequestDev } from "../server/api";

/*export function GetBlog() {
    const url = (window.location.hostname==="localhost") ? window.location.hostname+":3000/blog/blog.json" 
        : window.location.hostname+"/blog/blog.json";
    return apiRequestLocalFile({url: "https://dev.gecond.com/api/blog"});
}*/


export function GetBlog(any) {
  return apiAuthRequestDev({url: `v1/Blog${any ? "" : "?status=true"}`});
}

export function GetDetail(id) {
  return apiAuthRequestDev({url: `v1/Blog/${id}`});
}

export function RemoveArticle(id) {
  return apiAuthRequestDev({
    url: `v1/Blog/${id}`,
    method: "DELETE",
  });
}

export function SaveArticle(article) {
    let path = "v1/Blog";
    if (article.id > 0) path = path + "/" + article.id;
    return apiAuthRequestDev({
      url: path,
      data: {...article},
      method: article.id > 0 ? "PATCH" : "POST",
    });
  }

