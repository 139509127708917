import React from "react";
import { Link } from "react-router-dom";
import { globalResources, navigationResources } from "../../resources/resources";
import Flag from "react-flags";
import { client_id, default_client_id } from "../../assets/config";
import { getLogoImage, getCompanyName } from "../../storage/company_details";

const Navbar = (props) => {
  const {onLanguageChange} = props;
  return (
    <nav className="navbar">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <div className="navbar-brand">
              <Link to={navigationResources.dashboard.path}/*"/"*/>
                {client_id() !== default_client_id &&
                getLogoImage() !== "null" ? (
                  <img
                    src={getLogoImage()}
                    alt={getCompanyName()}
                    height="60"
                  />
                ) : (
                  <img
                    src={globalResources.gecondLogoPath}
                    alt="Gecond o meu condominio"
                    height="40"
                  />
                )}
              </Link>
            </div>
          </div>
          {(props.nameMode0 && props.nameMode1) &&
            <div className="col-auto">
              <div className="navbar-page-modes d-flex">
                <a
                  className={`page-mode-name ${props.mode===0 && "active"}`}
                  onClick={() => {
                    if (props.onModeChange) props.onModeChange(0);
                  }}
                >
                  <label>{props.nameMode0}</label>
                </a>
                <a
                  className={`page-mode-name ${props.mode===1 && "active"}`}
                  onClick={() => {
                    if (props.onModeChange) props.onModeChange(1);
                  }}
                >
                  <label>{props.nameMode1}</label>
                </a>
              </div>
            </div>
          }
          <div className="col-auto">
            <ul className="country-flag-list d-flex">
              <li>
                <button
                  className="country-flag"
                  onClick={() => {
                    if (onLanguageChange) onLanguageChange("pt-PT");
                  }}
                >
                  <Flag
                    name="PT"
                    format="png"
                    pngSize={32}
                    shiny={true}
                    alt="Portugal Flag"
                    basePath="/images/flags/"
                  />
                </button>
              </li>
              <li>
                <button
                  className="country-flag"
                  onClick={() => {
                    if (onLanguageChange) onLanguageChange("en-GB");
                  }}
                >
                  <Flag
                    name="GB"
                    format="png"
                    pngSize={32}
                    shiny={true}
                    alt="United Kingdom Flag"
                    basePath="/images/flags/"
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
