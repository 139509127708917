import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import ExerciseBudgetTable from "../components/tables/ExerciseBudgetTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const ExerciseBudgetPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.exerciseBudget.breadcrumb} />
      <div className="container-fluid">
        <ExerciseBudgetTable></ExerciseBudgetTable>
      </div>
    </Master>
  );
};

export default ExerciseBudgetPage;
