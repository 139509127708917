import React from "react";

export const PrivacyPolicy = (language) =>
  language === "pt" ? (
    <div>
      <h6 className="m-t-0">POLÍTICA DE PRIVACIDADE</h6>
      <p>
        A IMPROXY Tecnologias de Informação, Lda – doravante designada por
        IMPROXY - com sede na Rua Dr. Ilídio Sardoeira, 28 C Sala 1.1, 4400-107
        Vila Nova de Gaia – é a entidade responsável pelo tratamento de dados
        pessoais para efeitos desta Politica de Privacidade, e por referência ao
        serviço Gecond.com, o qual é constituído por uma área reservada dedicada
        a cada utilizador, através da qual o utilizador poderá realizar diversas
        ações e interações relativas ao condomínio em que reside ou é
        proprietário.
      </p>
      <p>
        Este documento visa esclarecer sobre os dados que recolhemos, o motivo
        dessa recolha, bem como as opções que disponibilizamos para que os
        titulares desses dados acedam, retifiquem ou eliminem a informação,
        entre outras informações relevantes.
      </p>
      <p>
        O utilizador poderá cancelar o seu registo no Gecond.com a qualquer
        momento, devendo, para o efeito enviar um e-mail para
        registo@Gecond.com.
      </p>
      <p>
        Os seus dados pessoais serão tratados de acordo e em conformidade com o
        Regulamento Geral sobre a Proteção de Dados – RGPD – Regulamento (UE)
        2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016,
        relativo à proteção das pessoas singulares no que diz respeito ao
        tratamento de dados pessoais e à livre circulação desses dados, bem como
        a Lei n.º 58/2019, de 8 de agosto que assegura a execução, na ordem
        jurídica nacional, do Regulamento (UE) 2016/679 do Parlamento e do
        Conselho, de 27 de abril de 2016, relativo à proteção das pessoas
        singulares no que diz respeito ao tratamento de dados pessoais e à livre
        circulação desses dados.
      </p>
      <h6>
        COMO UTILIZAMOS OS DADOS QUE RECOLHEMOS / FINALIDADE DO TRATAMENTO
      </h6>
      <p>Utilizamos as informações que recolhemos para os seguintes fins:</p>
      <ol>
        <li>
          Permitir e otimizar a visita do utilizador ao website, e em
          particular, à respetiva área reservada;
        </li>
        <li>
          Corresponder a pedidos do utilizador, nomeadamente o envio de
          informações, através de e-mail ou diretamente através das opções
          disponíveis durante a navegação no website / área reservada;
        </li>
        <li>Fins estatísticos de dados de navegação;</li>
        <li>
          Disponibilizar, manter, proteger e melhorar as funcionalidades
          disponibilizadas na área reservada, bem como desenvolver novas
          funcionalidades relevantes para a prestação de um melhor serviço ao
          titular dos dados, entre os quais, a possibilidade de realização de
          votações relevantes para os condomínios onde estes residam;
        </li>
        <li>Garantir o exercício dos direitos dos titulares dos dados;</li>
        <li>
          Melhorar a experiência e qualidade de navegação do utilizador nos
          Websites, por via de informações recolhidas por cookies ou pixel tags;
        </li>
        <li>Monitorização de segurança das redes e sistemas do Website.</li>
      </ol>
      <p>
        A recolha dos dados, conforme mencionado, é feito com o consentimento do
        titular dos dados.
      </p>
      <p>
        Os dados pessoais dos utilizadores poderão, na medida do necessário, ser
        tratados, nomeadamente para o cumprimento de uma obrigação legal a que a
        IMPROXY esteja sujeita. Os dados poderão ainda ser utilizados para a
        prossecução de interesses legítimos da IMPROXY, nomeadamente os
        previstos na lista acima.
      </p>
      <p>
        Salvo devida fundamentação legal, os seus dados não serão utilizados
        para fins diferentes daqueles expressamente previstos na presente
        Política de Privacidade.
      </p>
      <h6>DADOS RECOLHIDOS</h6>
      <p>
        Serão recolhidos os seguintes dados no momento da ativação do registo:
        email, data e hora do processo de ativação.
      </p>
      <p>
        Também poderão ser recolhidas informações respeitantes ao seu
        dispositivo sempre que visite o website / área reservada. As informações
        obtidas dizem respeito ao endereço IP, sistema operativo, browser,
        resolução do ecrã, entre outros, de caracter meramente técnico.
      </p>
      <h6>RECOLHA DE INFORMAÇÃO ATRAVÉS DE COOKIES E ETIQUETAS DE PIXELS</h6>
      <p>
        A IMPROXY recolhe automaticamente informações da atividade do utilizador
        através da utilização de cookies. Os cookies são pequenos ficheiros de
        texto com informações relevantes que o dispositivo de acesso carrega,
        através do navegador da internet, quando o website é visitado.
      </p>
      <p>
        Apenas a IMPROXY tem acesso às informações recolhidas através dos
        cookies, sendo estes recolhidos de forma anónima, permitindo, assim, uma
        melhoria na experiência de utilização do website / área reservada.
      </p>
      <p>
        A aceitação do uso de cookies é essencial para tirar proveito de muitas
        das funcionalidades e serviços do website / área reservada. Apenas
        utilizaremos cookies não essenciais com o seu consentimento.
      </p>
      <p>
        Os cookies poderão ser geridos através das configurações do seu browser.
        Caso opte pelo seu bloqueio ou eliminação através das configurações do
        seu browser, certas e determinadas funcionalidades e serviços do website
        poderão não funcionar ou funcionar defeituosamente.
      </p>
      <p>
        Além dos cookies, poderemos utilizar etiquetas de pixéis (
        <em>pixel tags</em>), que nos permitem monitorizar a atividade do
        utilizador do website / área. Apenas recolhemos <em>pixel tags</em>
        para efeitos estatísticos.
      </p>
      <h6>TRANSFERÊNCIA DE DADOS</h6>
      <p>
        No âmbito da prestação dos serviços (acesso ao website / área
        reservada), não transmitiremos os seus dados a terceiros. Igualmente não
        transferiremos nem alojaremos os seus dados fora do Espaço Económico
        Europeu.
      </p>
      <h6>MEDIDAS DE SEGURANÇA</h6>
      <p>
        A IMPROXY tem implementadas medidas técnicas e organizativas que
        asseguram a confidencialidade dos dados do utilizador, protegendo-os de
        destruição acidental ou ilícita, perda, alteração, divulgação ou acesso
        não autorizado e contra outras formas ilegais de tratamento de dados.
      </p>
      <p>
        Qualquer entidade subcontratada estará vinculada a medidas de segurança
        equivalentes, atuando permanentemente sob as instruções da IMPROXY.
      </p>
      <h6>DIREITOS DOS UTILIZADORES DOS DADOS</h6>
      <p>
        O utilizador tem direito a aceder, atualizar, retificar, eliminar,
        limitar e opor-se ao tratamento dos seus dados, bem como solicitar a sua
        portabilidade, o que será atendido, salvo imperativo legal, mediante
        pedido por email para registo@Gecond.com
      </p>
      <p>
        Poderá, pela mesma via e a todo o tempo, retirar o consentimento que
        tenha dado para o tratamento de dados pessoais, não afetando, contudo, a
        licitude do tratamento que até esse momento tenha sido efetuado, com
        base no consentimento previamente prestado.
      </p>
      <p>
        O utilizador tem direito a apresentar uma reclamação relativamente ao
        tratamento de dados pessoais junto da autoridade de controlo (Comissão
        Nacional de Proteção de Dados – CNPD).
      </p>
      <h6>PRAZO DE CONSERVAÇÃO DOS DADOS</h6>
      <p>
        Os dados pessoais transmitidos pelo utilizador e recolhidos pela IMPROXY
        serão conservados até ao momento de desativação da conta do utilizador
        da área reservada, sendo posteriormente apagados.
      </p>
      <h6>ALTERAÇÕES À POLÍTICA DE PRIVACIDADE</h6>
      <p>
        A IMPROXY reserva-se ao direito de alterar a sua Politica de
        Privacidade. Qualquer alteração será destacada no website / área
        reservada, sendo o utilizador notificado da mesma através de e-mail, num
        período anterior à sua entrada em vigor.
      </p>
      <h6>CONTACTOS RELEVANTES</h6>
      <p>URL: www.Gecond.com</p>
      <p>E-mail: registo@Gecond.com</p>
      <p>
        O titular dos dados pessoais poderá ainda contactar o encarregado de
        proteção de dados da IMPROXY através do endereço dpo@improxy.com
      </p>
      <br />
      <p>Data: 4 de maio de 2020</p>
      <br />
    </div>
  ) : (
    <div>
      <h6>PRIVACY POLICY</h6>
      <p>
        IMPROXY Tecnologias de Informação, Lda - hereinafter referred to as
        IMPROXY - with headquarters at Rua Dr. Ilídio Sardoeira, 28 C Sala 1.1,
        4400-107 Vila Nova de Gaia - is the entity responsible for processing
        personal data for the purposes of this Privacy Policy, and by reference
        to the Gecond.com service, which consists of a personal area dedicated
        to each user, through which the user may perform various actions and
        interactions relating to the condominium in which he or she resides or
        owns.
      </p>
      <p>
        This document aims to clarify the data we collect, the reason for such
        collection, as well as the options we make available for the owners of
        such data to access, withdraw or delete the information, among other
        relevant information.
      </p>
      <p>
        You may cancel your registration with Gecond.com at any time by sending
        an e-mail to registo@Gecond.com.
      </p>
      <p>
        Your personal data will be processed in accordance and in compliance
        with the General Regulation on Data Protection - RGPD - Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016
        on the protection of individuals with regard to the processing of
        personal data and on the free movement of such data, as well as with Law
        n. 58/2019 of 8 August ensuring the implementation, in the national
        legal order, of Regulation (EU) 2016/679 of the Parliament and of the
        Council of 27 April 2016 on the protection of individuals with regard to
        the processing of personal data and on the free movement of such data.
      </p>

      <h6>HOW WE USE THE DATA WE COLLECT / PURPOSE OF PROCESSING</h6>
      <p>We use the information we collect for the following purposes:</p>
      <p>
        1. To enable and optimize the user's visit to the website, and in
        particular to his personal area;
      </p>
      <p>
        2. To respond to user requests, including sending information, via
        e-mail or directly through the options available while browsing the
        website / reserved area;
      </p>
      <p>3. Statistical purposes of navigation data;</p>
      <p>
        4. To make available, maintain, protect and improve the functionalities
        made available in the personal area, as well as to develop new
        functionalities relevant for the provision of a better service to the
        data owner, among which, the possibility of holding relevant votes for
        the condominiums where they reside;
      </p>
      <p>5. Ensure the exercise of the rights of data subjects;</p>
      <p>
        6. Improve the user experience and quality of navigation on websites,
        through information collected by cookies or pixel tags;
      </p>
      <p>7. Monitoring the security of networks and systems of the Website.</p>
      <p>
        The collection of personal data, as mentioned, is done with the consent
        of the data subject.
      </p>
      <p>
        The user's personal data may, to the extent necessary, be processed, in
        particular to comply with a legal obligation to which IMPROXY is
        subject. The data may also be used to pursue IMPROXY's legitimate
        interests, in particular the above listed.
      </p>
      <p>
        Unless duly justified by law, your data will not be used for purposes
        other than those expressly provided for in this Privacy Policy.
      </p>

      <h6>COLLECTED DATA</h6>
      <p>
        The following data will be collected at the time of registration
        activation: email, date and time of the activation process.
      </p>
      <p>
        Information regarding your device may also be collected whenever you
        visit the website / private area. The information obtained relates to
        the IP address, operating system, browser, screen resolution, among
        others, of purely technical character.
      </p>

      <h6>COLLECTION OF INFORMATION THROUGH COOKIES AND PIXEL TAGS</h6>
      <p>
        IMPROXY automatically collects information from user activity through
        the use of cookies. Cookies are small text files with relevant
        information that the access device loads via the internet browser when
        the website is visited.
      </p>
      <p>
        Only IMPROXY has access to the information collected through cookies,
        which are collected anonymously, thus allowing an improvement in the
        user experience of the website / reserved area.
      </p>
      <p>
        The acceptance of the use of cookies is essential to take advantage of
        many of the features and services of the website / reserved area. We
        will only use non-essential cookies with your consent.
      </p>
      <p>
        Cookies can be managed through your browser settings. If you choose to
        block or delete them through your browser settings, certain features and
        services of the website may not work or may malfunction.
      </p>
      <p>
        In addition to cookies, we may use pixel tags, which allow us to monitor
        user activity of the website / area. We only collect pixel tags for
        statistical purposes.
      </p>

      <h6>DATA TRANSFER</h6>
      <p>
        In the context of providing the services (access to the website /
        reserved area), we will not pass on your data to third parties. Nor will
        we transfer or host your data outside the European Economic Area.
      </p>

      <h6>SECURITY MEASURES</h6>
      <p>
        IMPROXY has implemented technical and organizational measures to ensure
        the confidentiality of user data, protecting it from accidental or
        unlawful destruction, loss, alteration, unauthorized disclosure or
        access and against other unlawful forms of data processing.
      </p>
      <p>
        Any sub-contractor will be bound by equivalent security measures, acting
        permanently under IMPROXY's instructions.
      </p>

      <h6>DATA SUBJECT RIGHTS</h6>
      <p>
        The user has the right to access, update, rectify, delete, limit and
        oppose the processing of their data, as well as to request their
        portability, which will be met, unless required by law, by email to
        registo@Gecond.com.
      </p>
      <p>
        You may, by the same means and at any time, withdraw the consent you
        have given for the processing of personal data, but this shall not
        affect the lawfulness of the processing that has been carried out up to
        that point, based on the consent previously given.
      </p>
      <p>
        The user is entitled to lodge a complaint regarding the processing of
        personal data with the supervisory authority (Comissão Nacional de
        Proteção de Dados - CNPD).
      </p>

      <h6>DATA RETENTION PERIOD</h6>
      <p>
        Personal data transmitted by the user and collected by IMPROXY will be
        stored until the moment of deactivation of the user's account in the
        reserved area, and will subsequently be deleted.
      </p>

      <h6>CHANGES TO PRIVACY POLICY</h6>
      <p>
        IMPROXY reserves the right to change its Privacy Policy. Any change will
        be highlighted on the website / reserved area, and the user will be
        notified of it by e-mail, in a period prior to its entry into force.
      </p>

      <h6>RELEVANT CONTACTS</h6>
      <p>URL: www.Gecond.com</p>
      <p>E-mail: registo@Gecond.com</p>
      <p>
        The data subject may also contact the IMPROXY Data Protection Officer at
        dpo@improxy.com.
      </p>
      <br />
      <p>Date: May 4, 2020</p>
    </div>
  );
