import React, { Fragment } from "react";
import { globalResources } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const Pager = ({
  gotoPage,
  previousPage,
  maxButtons = 10,
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  nextPage,
  pageSize,
  setPageSize,
}) => {
  const margin = Math.floor(maxButtons / 2);
  let buttons = [];
  let left = pageIndex - margin;
  let right = left + maxButtons - 1;
  if (left < 0) {
    right = right + Math.abs(left);
    left = 0;
  }
  if (right > pageCount - 1) {
    left = left - Math.abs(pageCount - 1 - right);
    right = pageCount - 1;
    if (left < 0) {
      left = 0;
    }
  }
  for (var j = 0; j < pageCount; j++) {
    if (j >= left && j <= right) {
      buttons.push(j);
    }
  }
  return (
    <Fragment>
      <div className="pagination">
        <div className="">
          <span>
            {globalResources.page + " "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{" "}
          </span>
        </div>
        <div>
          <button
            onClick={(e) => {
              gotoPage(0);
              e.stopPropagation();
              e.preventDefault();
            }}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>
          </button>{" "}
          <button
            onClick={(e) => {
              previousPage();
              e.stopPropagation();
              e.preventDefault();
            }}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
          </button>{" "}
          <span>
            {buttons.map((page, index) => {
              return (
                <button
                  key={page}
                  type="button"
                  className={`page ${pageIndex === page ? "current" : ""}`}
                  onClick={(e) => {
                    gotoPage(page);
                  }}
                >
                  {page + 1}
                </button>
              );
            })}
          </span>{" "}
          <button
            onClick={(e) => {
              nextPage();
              e.stopPropagation();
              e.preventDefault();
            }}
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
          </button>{" "}
          <button
            onClick={(e) => {
              gotoPage(pageCount - 1);
              e.stopPropagation();
              e.preventDefault();
            }}
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
          </button>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {globalResources.show} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Fragment>
  );
};

export default Pager;
