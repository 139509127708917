import React, { Fragment, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import LoginForm from "../components/forms/LoginForm";
import Footer from "../components/website/Footer";
import Navbar from "../components/elements/Navbar";
import { ForCondominiums } from "../templates/forcondominiums";
import { ForOwners } from "../templates/forowners";
import {
  websiteResources,
  loginResources,
  getCurrent,
  setLanguage,
  globalResources,
  navigationResources,
} from "../resources/resources";
import { InstallPWA } from "../InstallPWA/InstallPWA";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { client_id, default_client_id } from "../assets/config";
import HomePage_blog from "./HomePage_blog";
import Card from "../components/containers/Card";
import { GetBlog } from "../actions/blog";
import { rewriteName } from "../assets/utils";

const HomePage = (props) => {
  let _lang = getCurrent();
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);
  const [mode, setMode] = useState(0);
  const [detail, setDetail] = useState(0);
  const [blogData, setBlogData] = useState();
  const blogValues_labels = websiteResources.blogText.blogValues;

  const loginForm_container = (
    <div className="col-sm-auto">
      <div className="card-banner">
        <div className="card-login-form">
          <div className="card-body">
            <div className="login-welcome">
              <span>{loginResources.welcome}</span>{" "}
              {loginResources.accountAccess}
            </div>
            <LoginForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (lang && lang.split("-")[0] !== getCurrent()) {
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (
      client_id() === default_client_id &&
      blogData === undefined &&
      (window.location.hostname === "dev.gecond.com" ||
        window.location.hostname === "localhost")
    ) {
      GetBlog()
        .then((response) => response.json())
        .then((result) => {
          setBlogData(result);
        })
        .catch((e) => {
          setBlogData([]);
        });
    }
  }, [blogData]);

  const createInstallPWA = () => {
    if (isMobile && client_id() === default_client_id) {
      return <InstallPWA />;
    }
  };

  function renderArticles(blogInfo) {
    var moment = require("moment");
    const twoArticlesBlog = blogInfo.slice(0, 2);
    return (
      <ul className="container">
        <div className="row">
          {twoArticlesBlog.map((item, index) => {
            const texts =
              item.texts && item.texts.length > 0
                ? item.texts.filter(
                    (item) => item.culture === blogValues_labels.culture
                  )
                : undefined;
            let name_rewrite = rewriteName(texts[0].title);

            return (
              <li
                key={"blogArticle-" + item.id}
                className="col-md-6 m-b-xxl article-container m-t-0"
              >
                <a
                  className="cur-point"
                  onClick={() => {
                    props.history.push(
                      `/blog/${name_rewrite + "-" + item.id}`,
                      { blog: blogData }
                    );
                  }}
                >
                  <div className="article-image-container">
                    <div
                      style={{
                        backgroundImage: `url(${
                          item.image && item.image.url
                            ? item.image.url
                            : "/images/blog-banner.jpg"
                        })`,
                      }}
                      className="article-image"
                    />
                    {item.creationDate && (
                      <span className="article-date">
                        {moment(item.creationDate).format("DD-MM-YYYY")}
                      </span>
                    )}
                  </div>
                </a>
                {texts && texts.length > 0 && (
                  <div className="article-text-container">
                    <h2 className="title">{texts[0].title}</h2>
                    <p className="introduction">{texts[0].introduction}</p>
                  </div>
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    props.history.push(
                      `/blog/${name_rewrite + "-" + item.id}`,
                      { blog: blogData }
                    );
                  }}
                >
                  {websiteResources.blogText.readMore}
                </button>
              </li>
            );
          })}
        </div>
      </ul>
    );
  }

  return (
    <Fragment>
      <Navbar
        onLanguageChange={(language) => {
          setLang(language);
        }}
        onModeChange={(newMode) => {
          newMode === 1 &&
            props.history.push(navigationResources.blog.path, {
              blog: blogData,
            });
        }}
        nameMode0={
          client_id() === default_client_id && blogData && blogData.length > 0
            ? websiteResources.home
            : ""
        }
        nameMode1={
          client_id() === default_client_id && blogData && blogData.length > 0
            ? websiteResources.blog
            : ""
        }
        mode={
          client_id() === default_client_id && blogData && blogData.length > 0
            ? mode
            : ""
        }
      />
      <div>
        <div className="banner">
          <div
            className={`banner-item ${
              client_id() === default_client_id ? "mobile-app-ad" : ""
            }`}
          >
            <div className="banner-content">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className={`col-sm ${
                      client_id() === default_client_id ? "height-total" : ""
                    }`}
                  >
                    {client_id() === default_client_id ? (
                      <div className="app-ad-description">
                        <div className="banner-title">
                          <h1 className="app-ad-h1">
                            {websiteResources.banner.appTitle}
                          </h1>
                          <h2>
                            <i>{websiteResources.banner.title}</i>
                          </h2>
                        </div>
                        <div className="banner-download">
                          <span>
                            <b>{websiteResources.banner.download}</b>
                          </span>
                          <div className="m-t-s">
                            <a
                              href={websiteResources.banner.appstoreLink}
                              target="_blank"
                            >
                              <img
                                className="m-r-s"
                                src="/images/app_store.png"
                                height="40"
                              />
                            </a>
                            <a
                              href={websiteResources.banner.playstoreLink}
                              target="_blank"
                            >
                              <img src="/images/google_play.png" height="40" />
                            </a>
                          </div>
                        </div>
                        <div className="banner-description banner-link">
                          <div className="social-media">
                            <a
                              href="https://www.facebook.com/gecond"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                className="facebook"
                                icon={faFacebookSquare}
                              ></FontAwesomeIcon>
                              <span>
                                <i>{globalResources.followGecond}</i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="banner-description">
                        <h1
                          className={`${
                            client_id() === default_client_id ? "app-ad-h1" : ""
                          }`}
                        >
                          {websiteResources.banner.title}
                        </h1>
                      </div>
                    )}
                  </div>
                  {client_id() === default_client_id ? (
                    <div className={"banner-form-desktop"}>
                      {loginForm_container}
                    </div>
                  ) : (
                    loginForm_container
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {client_id() === default_client_id && (
          <div className="banner-form-mobile">{loginForm_container}</div>
        )}
        {client_id() === default_client_id && (
          <div>
            <div className="intro">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h2>{websiteResources.banner.p1}</h2>
                  </div>
                  <div className="col-md-8">
                    <p>{websiteResources.banner.p2}</p>
                    <p>{websiteResources.banner.p3}</p>
                    <p className="f-w-500">{websiteResources.banner.p4}</p>
                    <p className="f-w-500">{websiteResources.banner.p5}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-features">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="main-feature-item">
                      <div className="icon">
                        <img
                          src="/images/icons/svg/file.svg"
                          alt=""
                          width="70"
                        />
                      </div>
                      <div className="text">
                        <h3>{websiteResources.currentAccount.title}</h3>
                        <p>{websiteResources.currentAccount.text}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="main-feature-item">
                      <div className="icon">
                        <img
                          src="/images/icons/svg/envelope-alt.svg"
                          alt=""
                          width="70"
                        />
                      </div>
                      <div className="text">
                        <h3>{websiteResources.messages.title}</h3>
                        <p>{websiteResources.messages.text}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="main-feature-item">
                      <div className="icon">
                        <img
                          src="/images/icons/svg/folder.svg"
                          alt=""
                          width="70"
                        />
                      </div>
                      <div className="text">
                        <h3>{websiteResources.documents.title}</h3>
                        <p>{websiteResources.documents.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-description p-b-50 p-t-50">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    {/*<video key={websiteResources.promotional_video} autoPlay loop controls muted className="main-description-video" src={websiteResources.promotional_video} 
                      type="video/mp4"/>*/}
                    <div className="main-description-video">
                      <ReactPlayer
                        url={websiteResources.promotional_video}
                        loop
                        controls
                        muted
                        playing
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <div className="main-description-text">
                      <h2>{websiteResources.descriptions.owner.title}</h2>
                      {ForOwners(lang.split("-")[0])}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="main-description-image"></div>
                    <div className="main-description-text">
                      <h2>{websiteResources.descriptions.condominium.title}</h2>
                      {ForCondominiums(lang.split("-")[0])}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {blogData && blogData.length > 0 && (
              <Card subclass="blog-container m-b-0">
                <h1 className="card-title">{websiteResources.blogTitle}</h1>
                <div className="articles-container">
                  {renderArticles(blogData)}
                </div>
                <div className="div-more-articles">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      props.history.push(navigationResources.blog.path, {
                        blog: blogData,
                      });
                    }}
                  >
                    {websiteResources.moreArticles}
                  </button>
                </div>
              </Card>
            )}
          </div>
        )}
      </div>
      <Footer />
      {createInstallPWA()}
    </Fragment>
  );
};

export default HomePage;
