import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import NoticesTable from "../components/tables/NoticesTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const NoticesPage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.notices.breadcrumb} />
      <div className="container-fluid">
        <NoticesTable></NoticesTable>
      </div>
    </Master>
  );
};

export default NoticesPage;
