import React, { useState, useEffect, Fragment, useRef } from "react";
import Table, {
  Column,
  Number,
  NoData,
  Loading,
  Date,
  DateTime,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetNotices, GetPaymentOptions } from "../../actions/documents";
import Card from "../containers/Card";
import {
  NoticesTableResources,
  globalResources,
} from "../../resources/resources";
import { useSelector } from "react-redux";
import { PaymentPopup } from "../elements/Popup";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { notifyError } from "../../components/elements/Notify";

const NoticesTable = () => {
  const [dataFilter, setDataFilter] = useState(15);
  const [valueSelected, setValueSelected] = useState(0);
  const [data, setData] = useState();
  const [paymentOptions, setPaymentOptions] = useState(null); // VARIABLE THAT HOLDS ALL THE INFORMATION, REGARDING THE PAYMENT METHOD, IN CASE ITS NULL, HIDE THE POPUP
  const [resources] = useState(NoticesTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const user = useSelector((state) => state.user);
  const totalBalance = useRef(0);
  const totalPDF = useRef("");
  const lockButton = useRef(false);

  const preparePaymentOptions = async () => {
    setPaymentOptions("loading");
    GetPaymentOptions(condominium.id, valueSelected)
      .then((response) => {
        const data = response.data;
        if (
          data.moneyTransfer.length > 0 ||
          (data.mbPayment && valueSelected >= 4)
        ) {
          setPaymentOptions(data);
        } else {
          //notifyError(resources.local.noPaymentOption);
          setPaymentOptions(resources.local.ibanPaymentNotFound);
        }
      })
      .catch((e) => {
        notifyError(resources.local.insertValidValue);
        setPaymentOptions(null);
      });
  };

  useEffect(() => {
    if (condominium) {
      totalBalance.current = 0;
      totalPDF.current = "";
      lockButton.current = true;
      setData();
      setValueSelected(0);
      GetNotices(condominium.id, dataFilter).then((response) => {
        if (response.data.url) {
          totalPDF.current = response.data.url.url;
        }

        response.data.items.forEach((doc) => {
          totalBalance.current = totalBalance.current + doc.balance;

          if (doc.date !== null) doc.date = new window.Date(doc.date);
          if (doc.dueDate !== null) doc.dueDate = new window.Date(doc.dueDate);

          switch (doc.documentType) {
            case "COLLECTION_NOTICE":
              doc.document = resources.local.noticeNumber + doc?.documentNumber;
              break;
            case "CREDIT_NOTE":
              doc.document = resources.local.creditNumber + doc?.documentNumber;
              break;
            default:
              doc.document = doc?.documentNumber;
          }
        });
        setValueSelected(toFixed(parseFloat(totalBalance.current), 2)); // THIS IS USED TO ROUND THE VALUE, NECESSARY DUE TO A JAVASCRIPT ERROR
        lockButton.current = false;
        setData(response.data.items);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium, dataFilter]);

  function format_value(value) {
    if (data) {
      return (
        formatNumber(
          toFixed(
            parseFloat(value.toString().replace(",", ".").replace("-", "")),
            2
          )
        ) + data[0].currency.symbol
      );
    } else {
      return 0;
    }
  }

  function printDocuments() {
    /*for (let document of documents.current){
      window.open(document);
    }*/
  }

  return (
    <Fragment>
      <Card subclass="max-width">
        <p>{resources.local.notUpdatedInformation}</p>
      </Card>
      <div className="table-filter">
        <div className="row">
          <div className={`col col-payment`}>
            <button className={`btn btn-value`}>
              <span>
                {totalBalance.current >= 0
                  ? resources.local.owedBalance
                  : resources.local.creditorBalance}
                :
              </span>
              <span>
                <h3 className="payment-value">
                  {totalBalance.current != 0
                    ? format_value(totalBalance.current)
                    : 0}
                </h3>
              </span>
            </button>
            <div
              className={`btn btn-value ${valueSelected > 0 ? "active" : ""}`}
            >
              <span>{resources.local.pay}:</span>
              <span>
                <h3 className="payment-value">
                  <input
                    className={`form-control input-payment-value ${
                      valueSelected <= 0 ? "inactive" : ""
                    }`}
                    disabled={!totalBalance.current > 0}
                    name="value"
                    type="number"
                    min={0}
                    placeholder={0}
                    value={valueSelected}
                    onChange={(event) => setValueSelected(event.target.value)}
                  />
                </h3>
              </span>
            </div>
            <span
              className={`btn-text ${valueSelected > 0 ? "active" : ""}`}
              id="btn-confirm-payment"
              onClick={() => {
                if (valueSelected > 0) {
                  preparePaymentOptions();
                }
              }}
            >
              {resources.local.continuePayment}
            </span>
            {totalPDF.current.length > 0 && (
              <a
                href={totalPDF.current}
                title={resources.local.download}
                target="_blank"
                rel="noopener noreferrer"
                className={`btn-text active`}
                id="btn-confirm-print"
                onClick={() => {
                  printDocuments();
                }}
              >
                {resources.local.printEveryDocument}
                <FontAwesomeIcon
                  id="btn-confirm-print-icon"
                  icon={faFileAlt}
                ></FontAwesomeIcon>
              </a>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <button
              className={`btn btn-filter a-third ${
                dataFilter === 0 ? "active" : ""
              }`}
              onClick={() => {
                if (!lockButton.current) {
                  setDataFilter(0);
                }
              }}
            >
              <span>{resources.local.expiredDocuments}</span>
            </button>
            <button
              className={`btn btn-filter a-third ${
                dataFilter === 15 ? "active" : ""
              }`}
              onClick={() => {
                if (!lockButton.current) {
                  setDataFilter(15);
                }
              }}
            >
              <span>{resources.local.dueDocuments}</span>
            </button>
            <button
              className={`btn btn-filter a-third ${
                dataFilter === null ? "active" : ""
              }`}
              onClick={() => {
                if (!lockButton.current) {
                  setDataFilter(null);
                }
              }}
            >
              <span>{resources.local.allDocuments}</span>
            </button>
          </div>
        </div>
      </div>
      <Card subclass="max-width">
        <PaymentPopup
          paymentOptions={paymentOptions}
          localResources={resources.local}
          resetPaymentOptions={() => {
            setPaymentOptions(null);
          }}
          value={valueSelected}
          user={user}
        />
        <Table data={data} pager={{ pageIndex: 0 }}>
          <Column
            Header={resources.local.document}
            accessor="document"
            Footer={globalResources.totalLabel}
            className="collapse"
          />
          <Column
            Header={resources.local.fraction}
            accessor="fractionDescription"
            className="collapse"
          />
          <Date
            Header={resources.local.dueDate}
            accessor="dueDate"
            sortType="datetime"
            align="right"
          />
          <Column Header={resources.local.description} accessor="description" />
          <Number
            Header={resources.local.valueInDebt}
            Header_has_currency={true}
            accessor="balance"
            Total={true}
            className="collapse"
          />
          {/* PREVIOUS COLUMN: "VALUE"
          <Number
            Header={resources.local.valueInDebt}
            Header_has_currency={true}
            accessor="value"
            Total={true}
            className="collapse"
          />*/}
          {/* PREVIOUS METHOD OF CHOOSING HOW MUCH TO PAY
          <Option
            id="select"
            Cell={({ row }) => {
              if (row.original.balance>0){
                return (
                  <Fragment>
                        <div className={`checkbox check-success active`}>
                          <input
                            key={row.id}
                            type="checkbox"
                            defaultChecked={listValuesSelected.current.includes(row.original.id)}
                            onClick={()=>{
                              if(listValuesSelected.current.includes(row.original.id)){
                                let array = [...listValuesSelected.current];
                                const index = array.indexOf(row.original.id);
                                if (index > -1) {
                                  array.splice(index, 1);
                                }
                                listValuesSelected.current=array;
                                setValueSelected(valueSelected => Math.round((valueSelected - row.original.balance)* 100)/100);
                              }
                              else{
                                listValuesSelected.current=[...listValuesSelected.current, row.original.id];
                                setValueSelected(valueSelected => Math.round((valueSelected + row.original.balance)* 100)/100);
                              }
                            }}
                          />
                          <label className="noLabel"/>
                        </div>
                  </Fragment>
                );
              }
              else{
                return null ;
              }
            }}
          />*/}
          {/* PREVIOUS COLUMN: "BALANCE"
          <Number
            Header={resources.local.balance}
            Header_has_currency={true}
            accessor="balance"
            Total={true}
            className="collapse"
          />*/}
          {/* PREVIOUS COLUMN WITH THE LINK TO THE FILE
          <Option
            id="download"
            Cell={({ row }) => {
              return (
                <Fragment>
                  {row.original.documentLink &&
                    row.original.documentLink.url &&
                    row.original.documentLink.url !== "" && (
                      <a
                        href={row.original.documentLink.url}
                        target="_blank"
                        title={resources.local.download}
                      >
                        <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>
                      </a>
                    )}
                </Fragment>
              );
            }}
          />*/}
          <NoData>{resources.local.noNotices}</NoData>
          <Loading value={data === undefined}>
            <IsLoading text={resources.local.loading} />
          </Loading>
        </Table>
      </Card>
    </Fragment>
  );
};

export default NoticesTable;
