import { AES, enc } from "crypto-js";
import { notifySuccess } from "../components/elements/Notify";

export function copyToClipboard(text, successMessage) {
  navigator.clipboard.writeText(text);
  notifySuccess(successMessage);
}

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

export function getPageData(data = [], page = 1, rpp = 0) {
  let pagedata = data;

  if (pagedata && rpp > 0 && pagedata.length > rpp) {
    pagedata = pagedata.slice((page - 1) * rpp, (page - 1) * rpp + rpp);
  } else {
    if (!pagedata) pagedata = [];
  }

  return pagedata;
}

export const removeEmptyStrings = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== "" && obj[prop] !== undefined) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getFilterObject = (filter) => {
  return filter
    ? {
        value: filter,
        label: numberWithSpaces(filter),
      }
    : "";
};

export const numberWithSpaces = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const setLocation = (district, county = "", parish = "") => {
  let location = "";

  if (district) {
    location += district;
    if (county) {
      location += ", " + county;
      if (parish) {
        location += ", " + parish;
      }
    }
  }

  return location;
};

export const setThumbnail = (image) => {
  const imageName = image ? image.split(/[/ ]+/).pop() : "";
  const imageUrl = image ? image.slice(0, -imageName.length) : "";

  return imageUrl + "i" + imageName;
};

export const setTitle = (note, type) => {
  return note ? note : type;
};

export const setDescription = (description, characterLimit) => {
  return description
    ? characterLimit === 0
      ? description
      : description.substring(0, characterLimit) + "..."
    : "";
};

export const setPostCode = (postCode) => {
  return postCode.slice(0, 4) + "-" + postCode.slice(4, 7);
};

export const RemoveFromArray = (array, field, value) => {
  var result = [];
  for (var j = 0; j < array.length; j++) {
    if (array[j][field] !== value) result.push(array[j]);
  }
  return result;
};

export const moveArray = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const RemoveIdenticalFromArray = (array, field) => {
  var result = [];
  for (var j = 0; j < array.length; j++) {
    if (!ContainsFieldValue(result, field, array[j][field]))
      result.push(array[j]);
  }
  return result;
};
export const ContainsFieldValue = (array, field, value) => {
  for (var j = 0; j < array.length; j++) {
    if (array[j][field] === value) return true;
  }
  return false;
};

export const indexOfField = (array, field, value) => {
  for (var j = 0; j < array.length; j++) {
    if (array[j][field] === value) return j;
  }
  return -1;
};

export const ReplaceAll = function (text, from, to) {
  var str = text;
  try {
    var pos = str.indexOf(from);
    while (pos > -1) {
      str = str.replace(from, to);
      pos = str.indexOf(from);
    }
  } catch (ex) {
    //Provavelmente a string nao tem os caracteres a serem subtituidos
  }

  return str;
};

// Decodes the <string> url
export const urlDecode = function (text) {
  text = ReplaceAll(text, "%2A", "*");
  text = ReplaceAll(text, "%2B", "+");
  text = ReplaceAll(text, "%2F", "/");
  text = ReplaceAll(text, "%40", "@");
  text = ReplaceAll(text, "+", "%20");
  text = ReplaceAll(text, "%5C", "\\");
  return text;
};

// Encodes the <string> for a valid url request
export const urlEncode = function (text) {
  var str = escape(text); //eslint-disable-next-line no-useless-escape
  return str.replace(/[*+\/@]|%20/g, function (s) {
    switch (s) {
      case "*":
        s = "%2A";
        break;
      case "+":
        s = "%2B";
        break;
      case "/":
        s = "%2F";
        break;
      case "@":
        s = "%40";
        break;
      case "%20":
        s = "+";
        break;
      default:
        break;
    }
    return s;
  });
};

// Encodes the <string> for a valid url request
export const JsonUrlEncode = function (text) {
  return urlEncode(JSON.stringify(text));
};

export const SameObj = function (a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const reversePropValue = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    newObj[obj[prop]] = prop;
  });
  return newObj;
};

export const clone = function (a) {
  return JSON.parse(JSON.stringify(a));
};

export const base64decrypt = function (text, key) {
  return AES.decrypt(enc.Utf8.stringify(enc.Base64.parse(text)), key).toString(
    enc.Utf8
  );
};

export const base64encrypt = function (text, key) {
  return enc.Base64.stringify(enc.Utf8.parse(AES.encrypt(text, key)));
};

export function getBase64(file, success, onfail) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    success(reader.result.split(",")[1]);
  };
  reader.onerror = function (error) {
    onfail(error);
  };
}

export function FileExtension(name) {
  var index = name.lastIndexOf(".");
  if (index >= 0) {
    return name.substr(index + 1);
  } else {
    return name;
  }
}

export function isFileImage(file) {
  return file && file.type.indexOf("image/") >= 0;
}

export function getObjectInObjectArray(array, key, value) {
  for (let i of array) {
    if (i[key] === value) {
      return i;
    }
  }
  return null;
}

export function findIndexInObjectArray(array, key, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return i;
    }
  }
  return null;
}

export function isTodayBetweenDates(startDate, endDate) {
  var moment = require("moment");
  startDate = moment(startDate);
  endDate = moment(endDate);
  const now = moment();
  if (now > startDate && now < endDate) {
    return true;
  } else {
    return false;
  }
}

export function isDateBeforeToday(date) {
  var moment = require("moment");
  date = moment(date);
  const now = moment();
  if (now > date) {
    return true;
  }
  return false;
}

//FUNCTION THAT SORTS THE TABLE IF THE COLUMN SELECTED CONTAINS DATES
export function datesOrderDESC(key, tableData) {
  let moment = require("moment");
  let orderedArray = tableData.slice();
  let aDate;
  let bDate;

  orderedArray.sort(function (a, b) {
    if (a[key]) {
      aDate = moment(a[key]).valueOf();
    } else {
      aDate = "0";
    }

    if (b[key]) {
      bDate = moment(b[key]).valueOf();
    } else {
      bDate = "0";
    }

    return bDate - aDate;
  });
  return orderedArray;
}

export const getDateTime = (date) => {
  let moment = require("moment");
  return moment(date).format("DD-MM-YYYY HH:mm");
};

export const getDate = (date) => {
  let moment = require("moment");
  return moment(date).format("DD-MM-YYYY");
};

export const getTime = (date) => {
  let moment = require("moment");
  return moment(date).format("HH:mm");
};

export const rewriteName = (name) => {
  let accents = `ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`;
  let original = `AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr`;

  let name_rewrite = "";
  for (let i = 0; i < name.length; i++) {
    let replace = false;
    for (let a = 0; a < accents.length; a++) {
      if (name.substr(i, 1) == accents.substr(a, 1)) {
        name_rewrite += original.substr(a, 1);
        replace = true;
        break;
      }
    }
    if (replace == false) {
      name_rewrite += name.substr(i, 1);
    }
  }

  name_rewrite = name_rewrite
    .toLowerCase()
    .replace(/[^a-z0-9]+/gi, "-")
    .replace(/^-+|-+$/g, "");
  return name_rewrite;
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isImage(name) {
  const extension = name.split(".").pop();
  const imageTypes = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
  for (var e of imageTypes) {
    if (extension === e || extension === e.toUpperCase()) return true;
  }
  return false;
}

export function makeArrayJustKey(array, key) {
  var newArray = [];
  for (var e of array) {
    if (e[key] !== undefined) newArray = [...newArray, e[key]];
  }
  return newArray;
}

export function thrower(message) {
  throw message;
}

export function replaceMultiple(text, x, y) {
  for (let i in x) {
    text = text.replace(x[i], y[i]);
  }
  return text;
}

export function isDevEnvir() {
  if (
    window.location.hostname === "dev.gecond.com" ||
    window.location.hostname === "localhost"
  ) {
    return true;
  }
  return false;
}

export function isNumberPortuguese(number) {
  const first3numbers = number ? number.slice(0, 3) : "";
  if (first3numbers === "351") {
    return true;
  }
  return false;
}
