import {
  GET_USER,
  UPDATE_USER,
  SET_DEFAULT_CONDOMINIUM,
} from "../actions/types";
import { setLanguage, getCurrent } from "../resources/resources";
import {
  toGenderSelectOption,
  toThemeSelectOption,
} from "../assets/FormatAndParse";

const resourcesReducerDefaultState = {
  detail: undefined,
  condominiums: undefined,
  current_condominium: undefined,
};

export default (state = resourcesReducerDefaultState, action) => {
  switch (action.type) {
    case GET_USER:
      if (
        action.user.detail.language &&
        action.user.detail.language.length > 0 &&
        getCurrent() !== action.user.detail.language.split("-")[0]
      ) {
        setLanguage(action.user.detail.language.split("-")[0]);
      }
      const condominiums =
        action.user && action.user.condominiums ? action.user.condominiums : [];

      if (action.user.detail.applicationTheme) {
        action.user.detail.applicationTheme = toThemeSelectOption(
          action.user.detail.applicationTheme.id
        );
      }

      if (action.user.detail.gender) {
        action.user.detail.gender = toGenderSelectOption(
          action.user.detail.gender.id
        );
      }

      return {
        detail: action.user.detail,
        condominiums: condominiums,
        current_condominium:
          condominiums && condominiums.length > 0
            ? condominiums[condominiums.length > 2 ? 2 : 0]
            : undefined, // Raplace on release release - action.user.condominiums[0],
      };

    case UPDATE_USER:
      if (
        action.user.language &&
        action.user.language.length > 0 &&
        getCurrent() !== action.user.language.split("-")[0]
      ) {
        setLanguage(action.user.language.split("-")[0]);
      }
      return {
        detail: action.user,
        condominiums: state.condominiums,
        current_condominium: state.current_condominium,
      };

    case SET_DEFAULT_CONDOMINIUM:
      return {
        detail: state.detail,
        condominiums: state.condominiums,
        current_condominium: state.condominiums.find((cond) => {
          return cond.id === action.id;
        }),
      };

    default:
      return state;
  }
};
