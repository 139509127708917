import { apiAuthRequest } from "../server/api";
import { GET_CONDOMINIUMS } from "./types";

export function GetList() {
  return async (dispatch) => {
    await apiAuthRequest({
      url: "v1/Condominiums/AccessibleCondominiums",
    }).then((response) =>
      dispatch({
        type: GET_CONDOMINIUMS,
        user: response.data,
      })
    );
  };
}

export function GetDetail(id) {
  return apiAuthRequest({
    url: "v1/Condominiums",
    data: {
      condominiumId: id,
    },
  });
}

export function GetDebts(id) {
  return apiAuthRequest({
    url: "v1/Condominiums/FractionsDebts",
    data: {
      condominiumId: id,
    },
  });
}
