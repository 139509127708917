import React from "react";
import { Link } from "react-router-dom";
import { globalResources, navigationResources } from "../../resources/resources";
import { getCompanyName } from "../../storage/company_details";
import { client_id, default_client_id } from "../../assets/config";
import ScriptTag from "react-script-tag";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-auto">
            <div className="copyright">
              {client_id() === default_client_id ? (
                <p>
                  <span className="hint-text">Copyright © 2021 </span>
                  <span>Gecond. </span>
                  <span className="hint-text">
                    {globalResources.rightsReserved}{" "}
                  </span>
                  <Link to={navigationResources.terms_of_use.path}/*"/terms-of-use"*/ className="m-l-s m-r-s">
                    {globalResources.terms}
                  </Link>
                  <span>|</span>
                  <Link to={navigationResources.privacy_policy.path}/*"/privacy-policy"*/ className="m-l-s m-r-s">
                    {globalResources.privacyPolicy}
                  </Link>
                </p>
              ) : (
                <p>
                  <span>{getCompanyName()}</span>
                </p>
              )}
              <p>
                <span id="ss_img_wrapper_115-55_image_en">
                  <a
                    href="http://www.alphassl.com/ssl-certificates/wildcard-ssl.html"
                    target="_blank"
                    title="SSL Certificates"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Wildcard SSL Certificates"
                      border="0"
                      id="ss_img"
                      src="//seal.alphassl.com/SiteSeal/images/alpha_noscript_115-55_en.gif"
                      title="SSL Certificate"
                    />
                  </a>
                </span>
                <ScriptTag
                  type="text/javascript"
                  src="//seal.alphassl.com/SiteSeal/alpha_image_115-55_en.js"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
