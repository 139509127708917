import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { required, minLength } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import { ChangePassword } from "../../actions/user";
import { UserPageResources, globalResources } from "../../resources/resources";
import Card from "../../components/containers/Card";

const ChangePasswordForm = (props) => {
  const [message, setMessage] = useState("");
  const [resources] = useState(UserPageResources);
  const PasswordInput = React.createRef();
  const { after } = props;

  useEffect(() => {
    if (PasswordInput && PasswordInput.current) {
      PasswordInput.current.focus();
    }
  }, [PasswordInput]);

  const onSubmit = async (values, form) => {
    ChangePassword(values.password, values.new_password)
      .then((response) => {
        form.initialize(values);
        setMessage(resources.local.password.success);
        if (after) after();
      })
      .catch((e) => {
        let message = "";
        if (e.response.data && e.response.data.error)
          message = e.response.data.error;
        switch (message.toLowerCase()) {
          case "CurrentPasswordMismatch".toLowerCase():
            form.initialize(values);
            setMessage(resources.local.password.mismatch);
            break;
          default:
            setMessage(resources.local.password.fail);
            break;
        }
      });
  };

  return (
    <Card title={resources.local.password.title}>
      <Form
        initialValues={{}}
        subscription={{ submitting: true, pristine: true }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (values.confirm !== values.new_password) {
            errors.confirm = resources.local.password.NoMach;
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e, form);
              e.preventDefault();
            }}
          >
            <div className="row">
              <div className="col-md-4">
                <Field
                  name="password"
                  type="password"
                  label={resources.local.password.old}
                  validate={(required, minLength(6))}
                  component={RenderInput}
                  other={{ maxLength: 25 }}
                  autoFocus
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="new_password"
                  type="password"
                  label={resources.local.password.new}
                  validate={(required, minLength(6))}
                  component={RenderInput}
                  other={{ maxLength: 25 }}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="confirm"
                  type="password"
                  label={resources.local.password.confirm}
                  validate={(required, minLength(6))}
                  component={RenderInput}
                  other={{ maxLength: 25 }}
                />
              </div>
            </div>
            <div className="row justify-content-end m-t-m">
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting || pristine}
                >
                  {resources.local.password.submit}
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => after()}
                >
                  {globalResources.verbs.cancel}
                </button>
                {message && message.length > 0 && <i>{message}</i>}
              </div>
            </div>
          </form>
        )}
      />
    </Card>
  );
};

export default ChangePasswordForm;
