import React, { Fragment, useState } from "react";
import { Form, Field } from "react-final-form";
import { required, complexPassword } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import { FirstTimeTermsAccepted } from "../../actions/user";
import { loginResources, globalResources } from "../../resources/resources";
import { Login } from "../../actions/user";
import { notifyError } from "../../components/elements/Notify";
import { set_token } from "../../storage/token";
import { InfoPopup } from '../elements/Popup';

const RecoverForm = (props) => {
  const [errorPopUpMessage, setErrorPopUpMessage] = useState(null);
  const [recoveryCode] = useState(
    props.location && props.location.state
      ? props.location.state.recoveryCode
      : undefined
  );

  const [token] = useState(
    props.location && props.location.state
      ? props.location.state.token
      : undefined
  );

  const [culture] = useState(
    props.location && props.location.state
      ? props.location.state.culture
      : undefined
  );

  const onSubmit = async (values) => {
    FirstTimeTermsAccepted(culture, token, recoveryCode, values.password)
      .then((response) => {
        Login(response.data.email, values.password)
          .then((response) => {
            set_token(response.data.access_token);
            window.open("/", "_self");
          })
          .catch((e) => {
            notifyError(loginResources.feedback.fail);
          });
      })
      .catch((e) => {
        let message = "";
        if (e.response.data && e.response.data.error)
          message = e.response.data.error;
          if(e.response.status==400){
            setErrorPopUpMessage(
              <div>
                <h5>{globalResources.inviteExpired_message1}</h5>
                <p>{globalResources.inviteExpired_message2}</p>
              </div>
            );
          }else{
            window.open("/", "_self");
          }

        /*switch (message.toLowerCase()) {
          case "InvalidOrOutdatedRecoveryCode".toLowerCase():
            window.open("/", "_self");
            break;
          default:
            window.open("/", "_self");
            break;
        }*/
      });
  };

  return (
    <Fragment>
      <InfoPopup messageComp={errorPopUpMessage} visible={errorPopUpMessage!==null} 
        next={()=>{
          setErrorPopUpMessage(null);
          window.open("/", "_self");
        }}/>
      <Form
        initialValues={{}}
        subscription={{ submitting: true, pristine: true }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (values.confirm !== values.password) {
            errors.confirm = loginResources.recoverPasswordNoMach;
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
              e.preventDefault();
            }}
          >
            <Field
              name="password"
              type="password"
              label={loginResources.userPassword}
              validate={(required, complexPassword)}
              component={RenderInput}
              autoFocus
            />
            <Field
              name="confirm"
              type="password"
              label={loginResources.confirmPassword}
              validate={(required, complexPassword)}
              component={RenderInput}
            />
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={submitting}
            >
              {loginResources.confirmActivate}
            </button>
          </form>
        )}
      />
    </Fragment>
  );
};

export default RecoverForm;
