import React from "react";
import { Link } from "react-router-dom";
import { navigationResources } from "../../resources/resources";

const Breadcrumb = ({ page, parent }) => {
  const isParentArray=Array.isArray(parent);
  
  return (
    <div className="breadcrumb">
      <div className="container-fluid">
        <div className="row">
          <div className="col p-l-xxl p-r-xxl">
            <ul>
              {/*<li className="breadcrumb-item">
                <Link to={navigationResources.dashboard.path}>Home</Link>
              </li>*/}
              {(isParentArray===true) ? 
               parent.map((item) => {
                return(
                  <li key={item.name} className="breadcrumb-item parent">
                    <button
                      onClick={(e) => {
                        if (item.onClick) item.onClick();
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                )
               })
               : (parent ? (
                <li className="breadcrumb-item parent">
                  <button
                    onClick={(e) => {
                      if (parent.onClick) parent.onClick();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {parent.name}
                  </button>
                </li>
              ) : null)}
              {page && <li className={`breadcrumb-item ${parent && "active"}`}><a>{page}</a></li>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
