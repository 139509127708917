import React from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import BalanceTable from "../components/tables/BalanceTable";
import Master from "../components/containers/Master";
import { navigationResources } from "../resources/resources";

const BalancePage = () => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.balance.breadcrumb} />
      <div className="container-fluid">
        <BalanceTable></BalanceTable>
      </div>
    </Master>
  );
};

export default BalancePage;
