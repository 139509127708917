import { apiRequest, apiAuthRequest } from "../server/api";
import { recoverPasswordTemplate } from "../emails/recoverPassword";
import { toGenderObject, toThemeObject } from "../assets/FormatAndParse";
import { GET_USER, GET_USER_SETTINGS } from "./types";
import { client_id, default_client_id } from "../assets/config";

export function Login(username, password) {
  return apiRequest({
    url: "token",
    data: {
      grant_type: "password",
      username: username,
      password: password,
    },
    method: "POST",
    form: true,
    contentType:"application/x-www-form-urlencoded"
  });
}

export function UserDetail() {
  return async (dispatch) => {
    await apiAuthRequest({ url: "v1/Users" }).then((user_response) => {
      apiAuthRequest({ url: "v1/Condominiums/AccessibleCondominiums" }).then(
        (response) =>
          dispatch({
            type: GET_USER,
            user: {
              detail: user_response.data,
              condominiums: response.data,
            },
          })
      );
    });
  };
}

export function UserSettings() {
  return async (dispatch) => {
    await apiAuthRequest({ url: "v1/Users/Settings" }).then((response) => {
      dispatch({
        type: GET_USER_SETTINGS,
        userSettings: response.data,
      })
    });
  };
}

export function SaveUser(user) {
  var moment = require("moment");

  user.applicationTheme = toThemeObject(user.applicationTheme);
  user.gender = toGenderObject(user.gender);
  user.birthDate = moment(user.birthDate).format();

  return apiAuthRequest({
    url: "v1/Users",
    data: user,
    method: "PATCH",
  });
}

export function UploadImage(base64, type, name) {
  return apiAuthRequest({
    url: "v1/Users/UpdateProfileImage",
    data: {
      base64ContentBytes: base64,
      imageMimeType: type,
      fileName: name,
    },
    method: "PATCH",
  });
}

export function SendRecoverPasswordEmail(email, language) {
  let informationPlaceholders = {
    userNamePlaceholder: "%USER_NAME%",
    recoveryCodePlaceholder: "%CODE%",
  };

  let template = recoverPasswordTemplate(language);
  const custom_client_id = client_id();
  if(custom_client_id!==null && custom_client_id !== default_client_id){
    template.body=template.body.replace(/{%CLIENT_ID%}/g, "/?app="+custom_client_id);
  } else{
    template.body=template.body.replace(/{%CLIENT_ID%}/g, "");
  }
  let recoveryEmailTemplate = {
    isHtmlContent: true,
    subject: template.subject,
    body: template.body,
    informationPlaceholders: informationPlaceholders,
  };

  return apiAuthRequest({
    url: "v1/Users/SendRecoverPasswordEmail",
    data: {
      userEmail: email,
      recoveryEmailTemplate: recoveryEmailTemplate,
    },
    method: "POST",
  });
}

export function ResetPassword(code, password) {
  return apiAuthRequest({
    url: "v1/Users/ResetPassword",
    data: {
      recoveryCode: code,
      newPassword: password,
    },
    method: "POST",
  });
}

export function ChangePassword(password, newPassword) {
  return apiAuthRequest({
    url: "v1/Users/ChangePassword",
    data: {
      currentPassword: password,
      newPassword: newPassword,
    },
    method: "PATCH",
  });
}

export function SetTerms(culture, token) {
  return apiRequest({
    url: "v1/Terms",
    data: {
      termsCulture: culture,
      acceptanceToken: token,
    },
    method: "POST",
  });
}

export function FirstTimeTermsAccepted(culture, token, code, password) {
  return apiRequest({
    url: "v1/Terms/FirstTimeTermsAccepted",
    data: {
      termsCulture: culture,
      acceptanceToken: token,
      passwordDefinitionCode: code,
      password: password,
    },
    method: "POST",
  });
}
