import React, { useState } from "react";
import Table, { Header, Column, Number, NoData } from "../elements/Table";
import Card from "../../components/containers/Card";
import {
  CondominiumPageResources,
  globalResources,
} from "../../resources/resources";

const FractionsTable = (props) => {
  const  fractions  = props.fractions;
  const [resources] = useState(CondominiumPageResources);
  
  const currency_symbol=globalResources.currencySymbol.replace(globalResources.currencySymbol_replaceString, props.currency.symbol);
  
  return (
    <Card subclass="max-width">
      <Table data={fractions} pager={{}}>
        <Header name={resources.local.fractions} Footer={""}>
          <Column
            Header={resources.local.code}
            accessor="code"
            desc={false}
            className="collapse"
            Footer={globalResources.totalLabel}
          />
          <Column Header={resources.local.description} accessor="complement" />
          <Number
            Header={resources.local.permilage}
            accessor="buildingPerThousand"
            Total={true}
          />
        </Header>
        <Header name={resources.local.quotas} Footer={""}>
          <Number
            Header={resources.local.budget+currency_symbol}
            accessor="budget"
            Total={true}
          />
          <Number
            Header={resources.local.fund+currency_symbol}
            accessor="reservationFunds"
            Total={true}
          />
          <Number
            Header={resources.local.insurance+currency_symbol}
            accessor="insuranceValue"
            Total={true}
          />
          <Number
            Header={resources.local.annualQuota+currency_symbol}
            accessor="annualQuota"
            Total={true}
          />
        </Header>
        <NoData>{resources.local.noFractions}</NoData>
      </Table>
    </Card>
  );
};

export default FractionsTable;
