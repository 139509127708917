import { get_token } from "../storage/token";
import HomePage from "../pages/HomePage";
import HomePage_blog from "../pages/HomePage_blog";

export let isAuthenticatedRoute = (component, login = true) => {
  let token = get_token();
  if (token && token.length > 0) {
    return component;
  } else {
    return login ? HomePage : undefined;
  }
};

export let isAuthenticatedRouteBlog = (component, login = true) => {
  let token = get_token();
  if (token && token.length > 0) {
    return component;
  } else {
    return login ? HomePage_blog : undefined;
  }
};

export let isAuthenticated = () => {
  let token = get_token();
  return token && token.length > 0;
};
