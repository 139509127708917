import React from "react";
import { globalResources } from "../../resources/resources";

//VALIDATION
export const required = (value) =>
  value || typeof value === "number" ? undefined : globalResources.required;

export const minLength = (min) => (value) =>
  value && value.length < min
    ? globalResources.validations.minLength(min)
    : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? globalResources.validations.maxLength(max)
    : undefined;

export const number = (value) =>
  value && isNaN(Number(value))
    ? globalResources.validations.number
    : undefined;

export const normalize_number = (value, previousValue) => {
  if (value) {
    if (value === "-") return value;
    if (isNaN(Number(value))) return previousValue;
  }
  return value;
};

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? globalResources.validations.email
    : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? globalResources.validations.alphaNumeric
    : undefined;

export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? globalResources.validations.phoneNumber
    : undefined;

export const complexPassword = (password) => {
  if (
    password &&
    (password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/\d/.test(password))
  ) {
    return globalResources.validations.complexPassword
      .split("\n")
      .map((value, index) => {
        return <div key={index}>{value}</div>;
      });
  }
  return undefined;
};
