import { GET_USER_SETTINGS } from "../actions/types";

const resourcesReducerDefaultState = null;

export default (state = resourcesReducerDefaultState, action) => {
  switch (action.type) {
    case GET_USER_SETTINGS:
      return action.userSettings;
      
    default:
      return state;
  }
};
