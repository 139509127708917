import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";


export const makeRatingComponent = (number) => {
    const missing = 5 - number;
    let component=[];
    for (let i = 0; i < number; i++) {
      component = [...component, <FontAwesomeIcon key={"star-rated-"+i} icon={faStar}></FontAwesomeIcon>];
    }
    for (let i = 0; i < missing; i++) {
      component = [...component, <FontAwesomeIcon key={"star-rated-"+missing+i} icon={faStarEmpty}></FontAwesomeIcon>];
    }
    return(
      <Fragment>
        {component.map((item)=>(item))}
      </Fragment>
    )
}