import React, { useState, useEffect, Fragment } from "react";
import IsLoading from "../elements/IsLoading";
import { Form, Field } from "react-final-form";
import { required, complexPassword } from "../../assets/forms/validation";
import { RenderInput } from "../../assets/forms/render";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { RemoveIdenticalFromArray } from "../../assets/utils";
import { toSelectOptions } from "../../assets/FormatAndParse";
import Card from "../../components/containers/Card";
import {
  InquiriesResources,
  globalResources,
} from "../../resources/resources";
import { get_token } from "../../storage/token";
import {EmailPopup} from '../elements/Popup';
import { GetInquirie } from "../../actions/inquiries";

export const TextAnswer = ({resources, info, id, changeValue, parentValues, disabled}) => {
    const parentIndex = parentValues.findIndex(e=>e.id===id);
    const [value, setValue] = useState(parentIndex>=0 ? parentValues[parentIndex].answer : "");

    useEffect(()=>{
      changeValue(id, value);
    },[value])

    return info.isShortInputField ? (
        <Fragment>
          <input key={"TextInput-"+id} readOnly={disabled ? true : false} defaultValue={value} name={"TextInput-"+id} type="text" className="form-control" maxLength={info.fieldMaxLength} onKeyUp={(e)=>setValue(e.target.value)}/>
          <p>{resources.local.availableChars.replace("{%NUM%}", (info.fieldMaxLength - value.length))}</p>
        </Fragment>
      ) : (
        <Fragment>
          <textarea key={"TextInput-"+id} readOnly={disabled ? true : false} defaultValue={value} name={"TextArea-"+id} type="text" className="form-control" maxLength={info.fieldMaxLength} onKeyUp={(e)=>setValue(e.target.value)} rows="3"/>
          <p>{resources.local.availableChars.replace("{%NUM%}", (info.fieldMaxLength - value.length))}</p>
        </Fragment>
    )
};

export const RangeAnswer = ({resources, info, id, changeValue, parentValues, disabled}) => {
    const parentIndex = parentValues.findIndex(e=>e.id===id);
    const [value, setValue] = useState(parentIndex>=0 ? parentValues[parentIndex].answer : 0);
    const values = Array.from({length: (info.scaleMaxValue-info.scaleMinValue)+1}, (_, i) => i+1);
    let options;
    const sideLabel=(text, margin)=>(<span className={`rating-labels m-b-0 m-${margin}-m`}>{text}</span>);

    useEffect(()=>{
      changeValue(id, value);
    },[value])
    
    if(info.selectionType.id===1){                           //NUMBERED OPTIONS
        options=
            <div className=" row m-b-0">
              {values.map((item, index)=>(
                <div className={`radio-inquirie ${value===item ? "active" : ""}  ${disabled ? "cur-disabled" : ""}`} key={"ratingOptions-"+index} onClick={()=>!disabled && setValue(item)} >
                  <span htmlFor={item} className="m-b-0">{item}</span>
                  <div className={"radio-inquirie-option"}/>
                </div>
              ))}
            </div>
    } else if(info.selectionType.id===2) {                   //BOXED NUMBERS
        options=
            <div>
              {values.map((item, index)=>(
                <a key={"ratingNumbers-"+index} onClick={()=>!disabled && setValue(item)} className={`rating-box ${value===item ? "selected" : ""}  ${disabled ? "cur-disabled" : ""}`}>{item}</a>
              ))}
            </div>
    } else {                                                  //STARS
        options=
            <div className="rating-icons-s">
              {values.map((item, index)=>(
                <FontAwesomeIcon key={"ratingStar-"+index} className={`${disabled ? "cur-disabled" : ""}`} icon={value>=item ? faStar : faStarEmpty} onClick={()=>!disabled && setValue(item)}></FontAwesomeIcon>
              ))}
            </div>
    }
    
    return(
      <div className="row m-r-0 m-l-0 m-t-m align-center">
        {info.showScaleLabels && sideLabel(info.scaleMinLabel, "r")}
        {options}
        {info.showScaleLabels && sideLabel(info.scaleMaxLabel, "l")}
      </div>
    )
};

export const ChoiceAnswer = ({resources, info, id, changeValue, parentValues, disabled}) => {
  const parentIndex = parentValues.findIndex(e=>e.id===id);
  const [value, setValue] = useState(parentIndex>=0 ? (Array.isArray(parentValues[parentIndex].answer) ? parentValues[parentIndex].answer : [parentValues[parentIndex].answer]) : []);
  let options;
  let options_data = [];
  if (info.possibleOptions) options_data = toSelectOptions(info.possibleOptions, "id", "option");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      opacity: 1,
      zIndex: 9999,
      background: state.isSelected
        ? "rgba(44, 179, 74, 0.6) !important"
        : "#ffffff",
      color: state.isSelected ? "#ffffff" : "#353f4d",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      zIndex: 99999,
    }),
  };

  useEffect(()=>{
    changeValue(id, value);
  },[value])

  function handleSelect(id, multichoice){
    if(value.includes(id)){
      const index = value.indexOf(id);
      let tempArray = value;
      tempArray.splice(index, 1);
      setValue([...tempArray])
    } else{
      multichoice ? setValue([...value, id]) : setValue([id])
    }
  }

  if(info.selectionType.id===1){                             //SINGLE OPTION
    options = 
      <div>
        {info.possibleOptions.map((item, index)=>(
          <div className={`radio-inquirie row m-l-0 m-r-0 m-b-s ${value.includes(item.id) ? "active" : ""} ${disabled ? "cur-disabled" : ""}`} key={"singleChoiceOption-"+index} onClick={()=>!disabled && handleSelect(item.id, false)} >
            <div className={"radio-inquirie-option m-r-s m-t-0"}/>
            <span htmlFor={item.id} className="m-b-0">{item.option}</span>
          </div>
        ))}
      </div>;
  } else if(info.selectionType.id===2){                     //MULTIPLE OPTIONS   
    options = 
    <div>
      {info.possibleOptions.map((item, index)=>(
        <div className={`radio-inquirie row m-l-0 m-r-0 m-b-s ${value.includes(item.id) ? "active" : ""} ${disabled ? "cur-disabled" : ""}`} key={"singleChoiceOption-"+index} onClick={()=>!disabled && handleSelect(item.id, true)} >
          <div className={"checkbox-inquirie-option m-r-s m-t-0"}/>
          <span htmlFor={item.id} className="m-b-0">{item.option}</span>
        </div>
      ))}
    </div>;
  } else {                                                  //DROPDOWN  
    options = 
      <Select
        placeholder={resources.local.selectOption}
        className="inquirie__select"
        classNamePrefix="inquirie__select"
        styles={customStyles}
        options={options_data}
        value={options_data.filter(
          (option) => option.value == value[0]
        )}
        isDisabled={disabled ? true : false}
        onChange={(e) => {handleSelect(e.value, false)}}
      />
  }

  return(
    <div>
      {options}
    </div>
  );     
}; 
