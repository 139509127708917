import React, { useState, useEffect, Fragment }  from "react";
import { Link } from "react-router-dom";
import { navigationResources, VotingsTableResources } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopBarNotification = (props) => {
  const { icon, text, subClass, link } = props;

  return  (
    <div className="">
      <div className={`votes-notification ${subClass ? subClass : ""}`}>
      <Link to={link}>
      <div className="container-fluid">
          <div className="row">
          <div className="col p-l-xxxl p-r-xxxl p-t-s p-b-s">
              <FontAwesomeIcon className={"m-width-xxxxxs"} icon={icon}></FontAwesomeIcon>
              <span className="m-l-m m-r-s">{text}</span>
          </div>
          </div>
      </div>
      </Link>
      </div>
    </div>
  );
};

export default TopBarNotification;
