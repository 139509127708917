// RESOURCES
export const SET_LANGUAGE = "set_language";
export const GET_USER = "get_user";
export const GET_USER_SETTINGS = "get_user_settings";
export const UPDATE_USER = "update_user";
export const GET_CONDOMINIUMS = "get_condominiums";
export const SET_DEFAULT_CONDOMINIUM = "set_default_condominium";
export const GET_DASHBOARD = "get_dashboard";
export const GET_VOTES = "get_votes";
export const GET_INQUIRIES = "get_inquiries";
