import React, { useState, useEffect }  from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { navigationResources, VotingsTableResources } from "../../resources/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVoteYea } from "@fortawesome/free-solid-svg-icons";
//import { GetAssemblies } from "../../actions/votes";
import { isTodayBetweenDates } from "../../assets/utils.js";

const VotesNotification = () => {
    //const [data, setData] = useState();
    const [resources] = useState(VotingsTableResources);
    const condominium = useSelector((state) => state.user.current_condominium);
    const votes = useSelector((state) => state.votes);
    const [numOpenAssemblies, setNumOpenAssemblies] = useState(0);

    useEffect(()=>{
        if(votes && votes.length>0){  
        let count=0;
        votes.map((item)=>{
            if(isTodayBetweenDates(item.onlineVotingStartDate, item.onlineVotingEndDate)){
            count++;
            }
        })
        setNumOpenAssemblies(count);
        } else{
            setNumOpenAssemblies(0);
        }
    },[votes]);

    /*useEffect(() => {
        if (condominium) {
            GetAssemblies(condominium.id).then((response) => response.json())
            .then(result => {
                setData(result);
            })
            .catch(e => {setData(undefined)});
        } // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [condominium]);*/
  
  return votes && numOpenAssemblies>0 ? (
    <div>
        <div className="votes-notification">
        <Link to={navigationResources.votings.path}>
        <div className="container-fluid">
            <div className="row">
            <div className="col p-l-xxxl p-r-xxxl p-t-s p-b-s">
                <FontAwesomeIcon className={"m-width-xxxxxs"} icon={faVoteYea}></FontAwesomeIcon>
                <span className="m-l-m m-r-s">
                    {numOpenAssemblies>1 ?
                        resources.local.openVotings.replace("{%NUMBER_VOTES%}", numOpenAssemblies)   
                        :
                        resources.local.openVoting1
                    }
                </span>
                <span>-</span>
                <span className="m-l-s">{resources.local.participateNow}</span>
            </div>
            </div>
        </div>
        </Link>
        </div>
    </div>
  ) : (<div className="teste"/>);
};

export default VotesNotification;
