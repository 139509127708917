import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { InfoPopup } from "../elements/Popup";
import IsLoading from "../elements/IsLoading";
import {
  InquiriesResources,
  globalResources,
  navigationResources,
} from "../../resources/resources";
import Card from "../../components/containers/Card";
import { thrower, replaceMultiple, getDateTime } from "../../assets/utils";
import {
  GetInquirieStats,
  GetInquirie,
  GetInquirieUnregisteredUser,
} from "../../actions/inquiries";
import { EmailPopup } from "../elements/Popup";

const InquiriesStats = (props) => {
  const { nid, l, logged } = props;
  const [resources] = useState(InquiriesResources);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState();
  const [inquirie, setInquirie] = useState(props?.location?.state?.inquirie);
  const [emailVisible, setEmailVisible] = useState(!logged);
  const colors = [
    "rgb(44, 179, 74)",
    "rgb(217, 83, 79)",
    "rgb(0, 55, 208)",
    "rgb(137, 0, 255)",
    "rgb(215, 122, 1)",
  ];

  function getInquirieUnregisteredUser(userEmail) {
    GetInquirieUnregisteredUser(nid, l, userEmail)
      .then((response) =>
        response.ok ? response.json() : thrower("Invalid notificationId")
      )
      .then((result) => {
        if (result.error && result.error_description) {
          setInquirie(null);
        } else {
          setInquirie(result);
        }
      })
      .catch((err) => setInquirie(null));
  }

  function getInquirieStats() {
    GetInquirieStats(nid, l)
      .then((response) => response.json())
      .then((result) => {
        if (result.error && result.error_description) {
          setData(null);
        } else {
          setData(result);
        }
      })
      .catch((err) => setData(null));
  }

  function getInquirie() {
    GetInquirie(nid, l)
      .then((response) =>
        response.ok ? response.json() : thrower("Invalid notificationId")
      )
      .then((result) => {
        if (result.error && result.error_description) {
          setInquirie(null);
        } else {
          setInquirie(result);
        }
      })
      .catch((err) => setInquirie(null));
  }

  useEffect(() => {
    if (data === undefined) {
      getInquirieStats();
    }
  });

  useEffect(() => {
    if (inquirie === undefined && logged) {
      getInquirie();
    } else if (inquirie && emailVisible) {
      setEmailVisible(false);
    }
  });

  const histogramGraph = (item) => {
    const valueColor = colors[0];
    const perThousandColor = colors[1];
    const axisStyle = {
      fontSize: "1rem",
      fontFamily: "segoe ui",
    };

    let legend = [{ value: resources.local.numberAnswers, color: valueColor }];
    if (item.displayPerThousand) {
      legend = [
        ...legend,
        { value: resources.local.perThousand, color: perThousandColor },
      ];
    }

    return (
      <div style={{ width: "100%", height: 430 }}>
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={item.answersStatistics}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              layout="horizontal"
            >
              <YAxis
                yAxisId="totalAnswers"
                dataKey="totalAnswers"
                type="number"
                orientation="left"
                stroke={valueColor}
              />
              {item.displayPerThousand && (
                <YAxis
                  yAxisId="totalPerThousand"
                  dataKey="totalPerThousand"
                  type="number"
                  orientation="right"
                  stroke={perThousandColor}
                />
              )}
              <XAxis
                type="category"
                dataKey="selectedOptionText"
                stroke={
                  user?.detail?.applicationTheme?.value === 2
                    ? "white"
                    : undefined
                }
                //style={axisStyle}
              />
              <Tooltip
                wrapperStyle={{ width: 300 }}
                labelStyle={{ whiteSpace: "normal", color: "black" }}
              />
              <Bar
                name={resources.local.numberAnswers}
                yAxisId="totalAnswers"
                dataKey="totalAnswers"
                fill={valueColor}
              />
              {item.displayPerThousand && (
                <Bar
                  name={resources.local.perThousand}
                  yAxisId="totalPerThousand"
                  dataKey="totalPerThousand"
                  fill={perThousandColor}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div
          className="p-t-m p-b-m"
          style={{ position: "relative", width: "100%", height: 30 }}
        >
          <Legend wrapperStyle={{ position: "relative" }} payload={legend} />
        </div>
      </div>
    );
  };

  const pieGraph = (item, index) => {
    const legend = (
      <div>
        {item.answersStatistics.map((entry, thisIndex) => (
          <div
            key={`pieGraphLegend-${index}-${thisIndex}`}
            className="display-inline-flex full-width m-t-m align-center"
          >
            <div
              className="m-r-s"
              style={{
                width: 14,
                height: 14,
                background: colors[thisIndex % colors.length],
                borderRadius: 10,
              }}
            />
            <span key={`item-${index}`} className="legend-text">
              {entry.selectedOptionText}
              <span style={{ color: colors[thisIndex % colors.length] }}>{` (${
                entry.totalAnswers
              } ${globalResources.answers}) ${
                item.displayPerThousand
                  ? `(${
                      Math.round(
                        (entry.totalPerThousand + Number.EPSILON) * 100
                      ) / 100
                    }‰)`
                  : ""
              }`}</span>
            </span>
          </div>
        ))}
      </div>
    );

    const customPieLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      value,
    }) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const label = Math.round(value) + "%";

      return (
        <text
          x={x}
          y={y}
          fill={"white"}
          textAnchor={"middle"}
          dominantBaseline="central"
        >
          {label}
        </text>
      );
    };

    return (
      <div className="row">
        <div className="col-lg-4 display-flex align-center m-b-l">
          <Legend
            wrapperStyle={{ position: "relative" }}
            content={() => legend}
          />
        </div>
        <div style={{ height: 350 }} className="col-lg-8 display-inline-flex">
          <div
            style={{
              width: item.displayPerThousand ? "50%" : "100%",
              height: 300,
            }}
          >
            <h4 className="graph-title">{resources.local.perUser}</h4>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={item.answersStatistics}
                  dataKey="totalPercentage"
                  startAngle={360}
                  endAngle={0}
                  outerRadius={80}
                  isAnimationActive={false}
                  labelLine={false}
                  label={customPieLabel}
                >
                  {item.answersStatistics.map((entry, index) => (
                    <Cell
                      key={`slice-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          {item.displayPerThousand && (
            <div style={{ width: "50%", height: 300 }}>
              <h4 className="graph-title">{resources.local.perThousand}</h4>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={item.answersStatistics}
                    dataKey="totalPerThousandPercentage"
                    startAngle={360}
                    endAngle={0}
                    outerRadius={80}
                    isAnimationActive={false}
                    labelLine={false}
                    label={customPieLabel}
                  >
                    {item.answersStatistics.map((entry, index) => (
                      <Cell
                        key={`slice-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    );
  };

  function insertEmail(e) {
    setEmailVisible(false);
    getInquirieUnregisteredUser(e);
  }

  function statsType(item, index) {
    switch (item.answerType.id) {
      case 1:
        return pieGraph(item, index);
      case 2:
        return histogramGraph(item);
      case 3:
        return pieGraph(item, index);
    }
  }

  function renderQuestion(item, index) {
    return (
      <Fragment key={`question-${index}`}>
        <h4 className="m-t-100 m-b-m stat-title">
          {index + 1}. {item.question}
        </h4>
        {statsType(item, index)}
      </Fragment>
    );
  }

  return data !== undefined && inquirie !== undefined ? (
    <Fragment>
      {data?.length > 0 && inquirie ? (
        <Card>
          {inquirie && inquirie.title && (
            <div className="row">
              <div className="col-lg-2" />
              <div className="col-lg-8 m-b-m">
                <h2 className="m-t-0 text-center">{inquirie.title}</h2>
                <p className="m-t-0 text-center">
                  {replaceMultiple(
                    resources.local.inquirieValidBetweenDates,
                    ["{%START_DATE%}", "{%END_DATE%}"],
                    [
                      getDateTime(inquirie.answeringOpeningDate),
                      getDateTime(inquirie.answeringClosingDate),
                    ]
                  )}
                </p>
              </div>
              <div className="col-lg-2 text-right">
                <button
                  className={`btn btn-primary`}
                  onClick={() =>
                    props.history.push(
                      navigationResources.inquiries.path + "?nid=" + nid,
                      { inquirie: !logged ? inquirie : undefined }
                    )
                  }
                >
                  {resources.local.backToInquirie}
                </button>
              </div>
            </div>
          )}
          <div>{data.map((item, index) => renderQuestion(item, index))}</div>
        </Card>
      ) : (
        <InfoPopup
          messageComp={<h2>{resources.local.invalidInquirie}</h2>}
          visible={true}
          disabled={logged ? false : true}
          next={() => {
            window.open("/inquiries", "_self");
          }}
        />
      )}
    </Fragment>
  ) : (
    <Card>
      <EmailPopup
        resources={resources}
        visible={emailVisible}
        next={(e) => {
          insertEmail(e);
        }}
      />
      <IsLoading text={globalResources.loading} />
    </Card>
  );
};

export default InquiriesStats;
