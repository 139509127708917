import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Master from "../components/containers/Master";
import Breadcrumb from "../components/elements/Breadcrumb";
import VotesNotification from "../components/elements/VotesNotification";
import TopBarNotification from "../components/elements/TopBarNotification";
import Card from "../components/containers/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faWpforms,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBell,
  faCalendarAlt,
  faEnvelope,
  faFolderOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  DashboardPageResources,
  globalResources,
} from "../resources/resources";
import { client_id, default_client_id } from "../assets/config";
import { navigationResources } from "../resources/resources";

const DashboardPage = () => {
  const user = useSelector((state) => state.user);
  const inquiries = useSelector((state) => state.inquiries);
  const dashboard = useSelector((state) => state.dashboard);
  const condominium = useSelector((state) => state.user.current_condominium);
  const [resources] = useState(DashboardPageResources);

  let currency_symbol = "";
  if (dashboard.currency) {
    currency_symbol = dashboard.currency.symbol;
  }

  let showFractionsDebts = false;
  let showOccurrences = false;
  let showAssemblies = false;
  if (condominium && condominium.gecondComSettings) {
    showFractionsDebts = condominium.gecondComSettings.showFractionsDebts;
    showOccurrences = condominium.gecondComSettings.showOccurrences;
    showAssemblies = condominium.gecondComSettings.showAssemblies;
  }

  return (
    <Master>
      <Breadcrumb page={navigationResources.dashboard.breadcrumb} />
      <div className="top-bar-notifications-container" />
      <div className={"top-bar-notifications"}>
        {inquiries && inquiries?.totalResults > 0 && (
          <TopBarNotification
            icon={faWpforms}
            subClass={"background-primary"}
            link={navigationResources.inquiries.path}
            text={
              inquiries.totalResults > 1
                ? inquiries.totalResults + " " + globalResources.openInquiries
                : inquiries.totalResults + " " + globalResources.openInquirie
            }
          />
        )}
        {showAssemblies && <VotesNotification />}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            <Card subclass="card-image">
              <div className="card-thumbnail"></div>
              <div className="card-message">
                <h2>{resources.local.welcome.title}</h2>
                <p>{resources.local.welcome.p1}</p>
                {client_id() === default_client_id && (
                  <div className="social-media">
                    <a
                      href="https://www.facebook.com/gecond"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        className="facebook"
                        icon={faFacebookSquare}
                      ></FontAwesomeIcon>
                      <span>{globalResources.followGecond}</span>
                    </a>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-12">
                <Card>
                  <h3 className="text-center">
                    {condominium && condominium.name}
                  </h3>
                  {condominium ? (
                    <Fragment>
                      <p className="text-center m-t-m">
                        {resources.local.welcome.p2}
                      </p>

                      <p className="text-center">
                        {resources.local.welcome.p3}
                      </p>
                      {/*(showOccurrences) &&
                      <div className="card-footer">
                        <Link to={navigationResources.occurrences.path_edit}/>
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                          {resources.local.welcome.newOccurrence}
                        </Link>
                      </div>
                      */}
                    </Fragment>
                  ) : (
                    <p>{resources.local.welcome.noCondo}</p>
                  )}
                </Card>
              </div>
              {dashboard && condominium && (
                <Fragment>
                  <div className="col-sm-6">
                    <Card
                      title={resources.local.notices.title}
                      subtitle={`${dashboard.totalValueToPayInTheNextNDays} ${currency_symbol}`}
                      subclass={"card-widget background-secondary"}
                    >
                      <Link
                        to={navigationResources.notices.path} /*"/notices"*/
                      >
                        <span className="">
                          {resources.local.notices.seeall}
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </Link>
                      <div className="widget-background">
                        <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
                      </div>
                    </Card>
                  </div>
                  <div className="col-sm-6">
                    <Card
                      title={resources.local.messages.title}
                      subtitle={`${dashboard.unreadMessages} ${resources.local.messages.unread}`}
                      subclass={"card-widget background-primary"}
                    >
                      <Link
                        to={navigationResources.messages.path} /*"/messages"*/
                      >
                        <span className="">
                          {resources.local.messages.seeall}
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </Link>
                      <div className="widget-background">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                      </div>
                    </Card>
                  </div>
                  {showOccurrences && (
                    <div className="col-sm-6">
                      <Card
                        title={resources.local.occurrences.title}
                        subtitle={`${dashboard.pendingOccurrences}  ${resources.local.occurrences.pending}`}
                        subclass={"card-widget background-secondary-light"}
                      >
                        <Link
                          to={
                            navigationResources.occurrences.path
                          } /*"/occurrences"*/
                        >
                          <span className="">
                            {resources.local.occurrences.seeall}
                          </span>
                          <FontAwesomeIcon
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                        </Link>
                        <div className="widget-background">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                          ></FontAwesomeIcon>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="col-sm-6">
                    <Card
                      title={resources.local.documents.title}
                      subtitle={
                        dashboard.totalDocuments
                          ? dashboard.totalDocuments
                          : "0"
                      }
                      subclass={"card-widget background-primary-dark"}
                    >
                      <Link
                        to={navigationResources.documents.path} /*"/documents"*/
                      >
                        <span className="">
                          {resources.local.documents.seeall}
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </Link>
                      <div className="widget-background">
                        <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                      </div>
                    </Card>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default DashboardPage;
