import React from "react";

const IsLoading = ({ text }) => {
  return (
    <div className="loading">
      <div className="bounceball"></div>
      <div className="text">{text}</div>
    </div>
  );
};

export default IsLoading;
