import React, { useState, useEffect } from "react";
import Navbar from "../components/elements/Navbar";
import {
  globalResources,
  loginResources,
  getCurrent,
  setLanguage,
} from "../resources/resources";
import { Terms } from "../templates/terms";
import { NavLink } from "react-router-dom";
import { SetTerms, Login } from "../actions/user";
import { notifyError } from "../components/elements/Notify";
import { set_token } from "../storage/token";
import qs from "qs";
import { navigationResources } from "../resources/resources";

const TermsPage = (props) => {
  let _lang = "";

  let user = "";
  let token = "";
  let recoveryCode = "";
  let invite = false;
  const [password] = useState(
    props.location && props.location.state
      ? props.location.state.password
      : undefined
  );

  if (props.match.params.user && props.match.params.user.length > 0) {
    user = decodeURIComponent(props.match.params.user);
    token = decodeURIComponent(props.match.params.token);
    _lang = props.match.params.language
      ? props.match.params.language
      : getCurrent();
  } else {
    invite = true;
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    _lang = query.termsCulture;
    token = decodeURIComponent(query.acceptanceToken);
    recoveryCode = query.passwordDefinition;
  }
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);

  useEffect(() => {
    if (lang && lang.split("-")[0] !== getCurrent()) {
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="activation-container d-flex flex-direction-column">
      <Navbar
        onLanguageChange={(language) => {
          setLang(language);
        }}
      />
      <div className="activation-banner">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5>{loginResources.welcome}</h5>
              <h1>{loginResources.activateTitle}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="form-nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="form-navbar">
                <li>
                  <div className="form-nav-item">
                    {globalResources.step} 1 - {globalResources.terms}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="terms flex-1 o-y-auto container card-terms scrollbar">
        <div className="row">
          <div className="col-12">
            <div className="card-body">{Terms(lang.split("-")[0])}</div>
          </div>
        </div>
      </div>
      <div className="m-t-m p-b-20">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-auto">
              <div className="card-actions">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (invite) {
                      // first time setting password
                      if (recoveryCode && recoveryCode.length > 0) {
                        props.history.push({
                          pathname: `/activate`,
                          state: {
                            recoveryCode: recoveryCode,
                            token: token,
                            culture: lang,
                          },
                        });
                      } else {
                        window.open("/", "_self");
                      }
                    } else {
                      // relogin
                      SetTerms(lang, token)
                        .then(() => {
                          if (password && password.length > 0) {
                            Login(user, password)
                              .then((response) => {
                                set_token(response.data.access_token);
                                window.open("/", "_self");
                              })
                              .catch((e) => {
                                notifyError(loginResources.feedback.fail);
                              });
                          } else {
                            window.open("/", "_self");
                          }
                        })
                        .catch((e) => {
                          notifyError(loginResources.feedback.termsFail);
                        });
                    }
                  }}
                >
                  {globalResources.verbs.accept}
                </button>{" "}
                <NavLink to={navigationResources.dashboard.path}>
                  <button type="button" className="btn btn-primary">
                    {globalResources.verbs.decline}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-activation">
        <div className="container">
          <div className="row">
            <div className="col">
              <span className="hint-text">Copyright © 2020 </span>
              <span>Gecond. </span>
              <span className="hint-text">
                {globalResources.rightsReserved}{" "}
              </span>
            </div>
            <div className="col-auto">
              <span id="ss_img_wrapper_115-55_image_en">
                <a
                  href="http://www.alphassl.com/ssl-certificates/wildcard-ssl.html"
                  target="_blank"
                  title="SSL Certificates"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Wildcard SSL Certificates"
                    border="0"
                    id="ss_img"
                    src="//seal.alphassl.com/SiteSeal/images/alpha_noscript_115-55_en.gif"
                    title="SSL Certificate"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsPage;
