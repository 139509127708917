import React, { Fragment, useState, useEffect } from "react";
import Table, {
  Column,
  NoData,
  Loading,
  Number,
  Option,
  Date,
  Header,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetBudgetsAnalysis } from "../../actions/documents";
import Card from "../../components/containers/Card";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BudgetAnalysisTableResources, globalResources } from "../../resources/resources";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { toAccountTypeLabel, toTableNumber } from "../../assets/FormatAndParse";
import { getDate, getTime } from "../../assets/utils";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";

const BudgetAnalysisTable = () => {
  const [data, setData] = useState();
  const analysisDate = useRef("");
  const [resources] = useState(BudgetAnalysisTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const activeBudget = useRef(true);

  useEffect(() => {
    if (condominium) {
        activeBudget.current=true;
        setData();
        GetBudgetsAnalysis(condominium.id).then((response) => {
          if (response.data.analysisDate){
            analysisDate.current=response.data.analysisDate;
          }
          setData(response.data);
      }).catch((err)=>{
        const errorMessage = globalResources.apiError.activeBudgetNotFound;
        if(err.response.data && err.response.data.error===errorMessage){
          activeBudget.current=false;
        } 
        analysisDate.current="";
        setData([]);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  const renderBudgetContainers = () => {
    return(
        <Fragment>
        {data.analysis.budgetContainers.map((item, index) => {
        const nameContainer = (item.containerName && item.showContainerName) ? item.containerName : "";
        const extraClass = (item.containerName && item.showContainerName) ? "container-grey-title" : "";
        return(
            <div key={index} className={extraClass}>
              <div className={`card-header p-0 ${index!==0 ? "m-t-xxl" : ""}`}><div className="card-title">{nameContainer}</div></div>
                {item.expenses.length>0 &&
                  <div className="colorless-table">
                    <Table data={item.expenses} pager={{}} disabledSort={true}>
                        <Column Header={resources.local.tableHeaders.expenses} accessor="rubricName"
                          Footer={resources.local.expensesTotal}/>
                        <Number Header={resources.local.tableHeaders.budgeted}
                          accessor="budgetedValue" className="collapse" Footer={toTableNumber(item.expensesTotals.budgetedValue)}/>
                        <Number Header={resources.local.tableHeaders.posted}
                          accessor="postedValue" className="collapse" Footer={toTableNumber(item.expensesTotals.postedValue)}/>
                        <Number Header={resources.local.tableHeaders.deviation}
                          accessor="deviationValue" className="collapse" Footer={toTableNumber(item.expensesTotals.deviationValue)}/>
                        {(data.visibleValueColumns && data.visibleValueColumns.includes("PayedValue")) ?
                          <Number Header={resources.local.tableHeaders.payed}
                            accessor="payedValue" className="collapse" Footer={toTableNumber(item.expensesTotals.payedValue)}/>
                          :
                          <Fragment/>
                        }
                        {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) ?
                          <Number Header={resources.local.tableHeaders.unpayed}
                            accessor="unpayedValue" className="collapse" Footer={toTableNumber(item.expensesTotals.unpayedValue)}/>
                            :
                            <Fragment/>
                        }
                    </Table>
                  </div>
                }
                {item.revenues.length>0 &&
                  <div className="colorless-table m-t-xxl">
                    <Table data={item.revenues} pager={{}} disabledSort={true}>
                        <Column Header={resources.local.tableHeaders.revenues} accessor="rubricName"
                          Footer={resources.local.revenuesTotal}/>
                        <Number Header={resources.local.tableHeaders.budgeted} 
                          accessor="budgetedValue" Total={true} className="collapse" Footer={toTableNumber(item.revenuesTotals.budgetedValue)}/>
                        <Number Header={resources.local.tableHeaders.posted} 
                          accessor="postedValue" Total={true} className="collapse" Footer={toTableNumber(item.revenuesTotals.postedValue)}/>
                        <Number Header={resources.local.tableHeaders.deviation} 
                          accessor="deviationValue" Total={true} className="collapse" Footer={toTableNumber(item.revenuesTotals.deviationValue)}/>
                        {(data.visibleValueColumns && data.visibleValueColumns.includes("PayedValue")) ?
                          <Number Header={resources.local.tableHeaders.payed} 
                            accessor="payedValue" Total={true} className="collapse" Footer={toTableNumber(item.revenuesTotals.payedValue)}/>
                            :
                            <Fragment/>
                        }
                        {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) ?
                          <Number Header={resources.local.tableHeaders.unpayed} 
                            accessor="unpayedValue" Total={true} className="collapse" Footer={toTableNumber(item.revenuesTotals.unpayedValue)}/>
                            :
                            <Fragment/>
                        }
                    </Table>
                  </div>
                }
                {(item.expenses.length>0 && item.revenues.length>0) &&
                <div className="headless-table">
                  <Table data={[{...item.containerTotals, "totalLabel":resources.local.zoneTotal}]}>
                    <Column Header={resources.local.tableHeaders.revenues} accessor="totalLabel"/>
                    <Number Header={resources.local.tableHeaders.budgeted} accessor="budgetedValue" className="collapse" />
                    <Number Header={resources.local.tableHeaders.posted} accessor="postedValue" className="collapse" />
                    <Number Header={resources.local.tableHeaders.deviation} accessor="deviationValue" className="collapse" />
                    {(data.visibleValueColumns && data.visibleValueColumns.includes("PayedValue")) ?
                      <Number Header={resources.local.tableHeaders.payed} accessor="payedValue" className="collapse" />
                      :
                      <Fragment/>
                    }
                    {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) ?
                      <Number Header={resources.local.tableHeaders.unpayed} accessor="unpayedValue" className="collapse" />
                      :
                      <Fragment/>
                    }
                  </Table>
                </div>
                }
            </div>
        );
        })}
        </Fragment>
    )
  }
  
  return data ? (
    <Fragment>
      {(data && data.budget) &&
        <Card subclass="max-width table-info">
          <div className="row">
              <div className="col-lg-6">
                  {(analysisDate.current!=="" && analysisDate.current!==null) &&
                      <div>
                          <p>{resources.local.info_p1
                          .replace("{%DATE%}", getDate(analysisDate.current))
                          .replace("{%TIME%}", getTime(analysisDate.current))}
                          </p>
                          <p>{resources.local.info_p2}</p>
                      </div>
                  }
              </div>
              <div className="col-lg-6 text-right">
                  <p>{resources.local.title}</p>
                  {(data && data.budget) &&
                      <Fragment>
                      {/*<p>{data.budget.title}</p>*/}
                      <p>{getDate(data.budget.startDate)+resources.local.date_connector+getDate(data.budget.endDate)}</p>
                      </Fragment>
                  }
              </div>
          </div>
        </Card>
      }
      {((data && data.analysis && data.analysis.budgetContainers && data.analysis.budgetContainers.length>0)) &&
        <Card>

        {(data && data.analysis && data.analysis.budgetContainers && data.analysis.budgetContainers.length>0) &&
            renderBudgetContainers()
        }
        {(data && data.analysis && data.analysis.totals && data.analysis.budgetContainers && data.analysis.budgetContainers.length>1) &&
            <div className="headless-table">
              <Table data={[{...data.analysis.totals, "totalLabel":"Total"}]}>
                <Column Header={resources.local.tableHeaders.revenues} accessor="totalLabel"/>
                <Number Header={resources.local.tableHeaders.budgeted} accessor="budgetedValue" className="collapse" />
                <Number Header={resources.local.tableHeaders.posted} accessor="postedValue" className="collapse" />
                <Number Header={resources.local.tableHeaders.deviation} accessor="deviationValue" className="collapse" />
                {(data.visibleValueColumns && data.visibleValueColumns.includes("PayedValue")) ?
                  <Number Header={resources.local.tableHeaders.payed} accessor="payedValue" className="collapse" />
                  :
                  <Fragment/>
                }
                {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) ?
                  <Number Header={resources.local.tableHeaders.unpayed} accessor="unpayedValue" className="collapse" />
                  :
                  <Fragment/>
                }
              </Table>
            </div>
        }
        </Card>
        }
        {(data && data.analysis && data.analysis.budgetContainers && data.analysis.budgetContainers.length>0) &&
          <div className="card-static-description m-t-xxl">
            <p className="title">{resources.local.tableHeaders.expenses}</p>
              <div className=" m-b-xs">{resources.local.static_description.expenses.budgeted}</div>
              <div className="m-b-xs">{resources.local.static_description.expenses.posted}</div>
              <div className="m-b-xs">{resources.local.static_description.expenses.deviation}</div>
            {/*<div className="row">
              <div className="field-name col-lg-3 m-b-xs">{resources.local.tableHeaders.payed}</div>
              <div className="m-b-xs">{resources.local.static_description.expenses.payed}</div>
            </div>*/}
            {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) &&
              <div className="m-b-xs">{resources.local.static_description.expenses.unpayed}</div>
            }

            {(data.analysis.budgetContainers.find(element => element.revenues && element.revenues.length>0)) &&
            <Fragment>
              <p className="m-t-l title">{resources.local.tableHeaders.revenues}</p>
                <div className="m-b-xs">{resources.local.static_description.revenues.budgeted}</div>
                <div className="m-b-xs">{resources.local.static_description.revenues.posted}</div>
                <div className="m-b-xs">{resources.local.static_description.revenues.deviation}</div>
              {/*<div className="row">
                <div className="field-name col-lg-3 m-b-xs">{resources.local.tableHeaders.payed}</div>
                <div className="m-b-xs">{resources.local.static_description.revenues.payed}</div>
              </div>*/}
              {(data.visibleValueColumns && data.visibleValueColumns.includes("UnpayedValue")) &&
                <div className="m-b-xs">{resources.local.static_description.revenues.unpayed}</div>
              }
            </Fragment>
            }
          </div>
        }

        {(!activeBudget.current) &&
          <Card>{globalResources.noActiveBudget}</Card>
        }

        {(data && data.length===0 && activeBudget.current) &&
          <Card>{resources.local.noValues}</Card>
        }
    </Fragment>
  ) : (
    <Card>
      <IsLoading text={resources.local.loading} />
    </Card>
  );
};

export default BudgetAnalysisTable;
