import React, { useState, useEffect, Fragment } from "react";
import Table, {
  Column,
  NoData,
  Option,
  Loading,
  DateTime,
  Header,
  Number,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetOccurrences, RemoveOccurrence, RateOccurrence } from "../../actions/occurrences";
import Card from "../containers/Card";
import {
  OccurrencesTableResources,
  navigationResources,
  globalResources,
} from "../../resources/resources";
import OccurrenceForm from "../forms/OccurrenceForm";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import Breadcrumb from "../elements/Breadcrumb";
import { notifySuccess, notifyError } from "../../components/elements/Notify";
import {RatingPopup} from '../elements/Popup';
import {makeRatingComponent} from '../../assets/componentGenerator';
import {toDateTime} from '../../assets/FormatAndParse';

const OccurrencesTable = (props) => {
  const [data, setData] = useState(undefined);
  const [resources] = useState(OccurrencesTableResources);
  const [detail, setDetail] = useState(null);
  const condominium = useSelector((state) => state.user.current_condominium);
  const [rateId, setRateId] = useState(0);

  useEffect(() => {
    if (data && data !== null && condominium) {
      setData();
      GetOccurrences(condominium.id).then((response) => {
        response.data.forEach((doc) => {
          doc.date = new window.Date(doc.date);
        });
        //if(response.data.length>0) response.data=filterOccurrences(response.data);
        setData(response.data);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  useEffect(() => {
    if ((!data || data === null) && detail === null && condominium) {
      setData();
      GetOccurrences(condominium.id).then((response) => {
        response.data.forEach((doc) => {
          doc.date = new window.Date(doc.date);
        });
        //if(response.data.length>0) response.data=filterOccurrences(response.data);
        setData(response.data);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, data, condominium]);

  const Remove = async (condominium_id, id) => {
    if (condominium_id && id && id > 0) {
      RemoveOccurrence(condominium_id, id)
        .then((response) => {
          notifySuccess(resources.local.detail.removeSuccess);
          setData(null);
        })
        .catch((e) => {
          notifyError(resources.local.detail.removeFail);
        });
    }
  };

  const Rate = async (condominium_id, id, score) => {
    if (condominium_id && id && id > 0) {
      RateOccurrence(condominium_id, id, score)
        .then((response) => {
          notifySuccess(resources.local.detail.ratingSuccess);
          setData(null);
        })
        .catch((e) => {
          notifyError(resources.local.detail.ratingFail);
        });
    }
  };

  /*const filterOccurrences=(data)=>{
    let unStarted =[];
    let onGoing=[];
    let completed=[];
    data.forEach((doc) => {
      switch(doc.interventionStatus.id){
        case 0:
          unStarted=[...unStarted, doc];
          break;
        case 1:
          onGoing=[...onGoing, doc];
          break;
        case 2:
          completed=[...completed, doc];
          break;
      }
    });
    return { unStarted: [...unStarted], onGoing: [...onGoing], completed: [...completed] };
  }*/

  const popUpResponse = (yes, score)=>{
    if(yes && score>0){
      Rate(condominium.id, rateId, score);
    }
    setRateId(0);
  }
  
  const renderTable = (dataTable)=>{
    return(
    <div className="rows-clickable">
    <Table
      data={dataTable}
      tag={condominium}
      pager={{ pageIndex: 0 }}
      onRowClick={(e, row) => {
        //if (row.original.canEdit || row.original.interventionStatus.id===2) {
          setDetail(row.original.id);
        //}  
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DateTime
        Header={globalResources.date}
        accessor="date"
        sortType="datetime"
        className="collapse"
      />
      <Column Header={resources.local.subject} accessor="subject" />
      <Column
        Header={resources.local.start}
        className="collapse"
        Cell={({ row, tag }) => {
          const occurrence = row.original;
          return (occurrence && occurrence.interventionStatus && occurrence.interventionStatus.id==0) ?(
            resources.local.unStarted
          ) : (
            (occurrence.intervention && occurrence.intervention.startDate) ? 
              toDateTime(occurrence.intervention.startDate) : null
          );
        }}
      />
      <Column
        Header={resources.local.end}
        className="collapse"
        Cell={({ row, tag }) => {
          const occurrence = row.original;
          return (occurrence && occurrence.interventionStatus && occurrence.interventionStatus.id==1) ?(
            resources.local.onGoing
          ) : (
            (occurrence.intervention && occurrence.intervention.closeDate && occurrence.interventionStatus && occurrence.interventionStatus.id==2) ? 
              toDateTime(occurrence.intervention.closeDate) : null
          );
        }}
      />
      {/*(header===resources.local.completed && condominium && condominium.gecondComSettings.isPremiumCondomium) ? 
        <Column 
        Header={resources.local.rating}
        className="one-sixth rating-in-table"
        Cell={({ row, tag }) => {
          const occurrence = row.original;
          return occurrence.wasInsertedByMe ? (
            <div>
              {occurrence.rating!==null ?
                makeRatingComponent(occurrence.rating)
              :
              <div>
                <button
                  onClick={(e) => {
                    setRateId(occurrence.id);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className="btn btn-warning">
                  <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  <span>{resources.local.takeRatingNow}</span>
                </button>
              </div>
              }
            </div>) : (<Option id="placeholder" className="one-sixth" Cell={({ row, tag }) => (
                          <div className="one-sixth"></div>)}/>);
        }} />
        :
        <Option
          id="placeholder"
          className="one-sixth"
          Cell={({ row, tag }) => (
            <div className="one-sixth"></div>
          )}/>
          */}
      <Option
        id="remove"
        Cell={({ row, tag }) => {
          const occurrence = row.original;
          if (occurrence.interventionStatus && occurrence.interventionStatus.id==2){
            return (occurrence.wasInsertedByMe && condominium && condominium.gecondComSettings.isPremiumCondomium ) ? (
              <div>
                {occurrence.rating!==null ?
                  makeRatingComponent(occurrence.rating)
                :
                <div>
                  <button
                    onClick={(e) => {
                      setRateId(occurrence.id);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className="btn btn-warning">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <span>{resources.local.takeRatingNow}</span>
                  </button>
                </div>
                }
              </div>) : (null);
          } else{
            return (
              (occurrence.canEdit && occurrence.wasInsertedByMe) ? (
                <div className="btn-inside-center">
                <button
                  onClick={(e) => {
                    Remove(tag.id, occurrence.id);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  //title={globalResources.verbs.remove}
                  className="btn btn-danger"
                >
                  <FontAwesomeIcon className="m-0" icon={faTrashAlt}></FontAwesomeIcon>
                </button>
                </div>
              ) : (null)
            );
        }
        }}
      />
    </Table>
    </div> );
  }
  
  return (
    <Fragment>
      <Breadcrumb
        page={
          detail === null
            ? navigationResources.occurrences.breadcrumb
            : detail > 0
            ? navigationResources.occurrences.edit
            : navigationResources.occurrences.new
        }
        parent={
          detail === null
            ? undefined
            : {
                name: navigationResources.occurrences.breadcrumb,
                onClick: () => {
                  setDetail(null);
                },
              }
        }
      />
      {detail === null && (
        <Card subclass="max-width">
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-primary m-r-s"
                onClick={() => setDetail(0)}
              >
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                {resources.local.add}
              </button>
            </div>
          </div>
          {data ?
            <div className="tables-container-m-b">
              {/*(data.unStarted && data.unStarted.length>0) && 
                renderTable(data.unStarted, resources.local.unStarted)
              */}
              {/*(data.onGoing && data.onGoing.length>0) && 
                renderTable(data.onGoing, resources.local.onGoing)
              */}
              {/*(data.completed && data.completed.length>0) && 
                renderTable(data.completed, resources.local.completed)
              */}
              {(data.length>0) && 
                renderTable(data)
              } 
              <RatingPopup 
                message={resources.local.takeRatingNow}
                visible={rateId!==0}
                popUpResponse={(response, score)=>{popUpResponse(response, score)}}
              />
            </div>
            :
            <IsLoading text={resources.local.loading} />
          }
          {(data && data.length===0) &&
            resources.local.noOccurrences
          }
        </Card>
      )}
      {detail !== null && (
        <OccurrenceForm
          id={detail}
          after={(update) => {
            setDetail(null);
            setData(undefined);
          }}
        ></OccurrenceForm>
      )}
    </Fragment>
  );
};

export default OccurrencesTable;
