import React, { Fragment, useState, useEffect } from "react";
import IsLoading from "../elements/IsLoading";
import { GetBankBalances } from "../../actions/documents";
import Card from "../../components/containers/Card";
import { BankBalancesTableResources, globalResources } from "../../resources/resources";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { getDate, getTime } from "../../assets/utils";
import { formatNumber, toFixed } from "../../assets/FormatAndParse";

const BankBalanceList = () => {
  const [data, setData] = useState({
    cashAccounts: undefined,
    currentAccounts: undefined,
    savingsAccounts: undefined,
    reservationAccounts: undefined,
  });
  const balanceDate = useRef("");
  const [resources] = useState(BankBalancesTableResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const [accountShown, setAccountShown] = useState("currentAccounts");

  useEffect(() => {
    if (condominium) {
        GetBankBalances(condominium.id).then((response) => {
        if (response.data.balanceDate){
          balanceDate.current=response.data.balanceDate;
        }
        
        if (response.data.bankAccountsBalance){
          let cashAccounts=[];
          let currentAccounts=[];
          let savingsAccounts=[];
          let reservationAccounts=[];
          response.data.bankAccountsBalance.forEach((doc) => {
            if(doc.accountType && doc.accountType.code){
              if(response.data.currency && response.data.currency.symbol){
                doc.currency=response.data.currency;
              }
              switch(doc.accountType.code){
                case "CASH":
                  cashAccounts=[...cashAccounts, doc];
                  break;
                case "CURRENT":
                  currentAccounts=[...currentAccounts, doc];
                  break;
                case "SAVINGS":
                  savingsAccounts=[...savingsAccounts, doc];
                  break;
                case "RESERVATION":
                  reservationAccounts=[...reservationAccounts, doc];
                  break;
              }
            }
          });
          setData({
            cashAccounts: [...cashAccounts],
            currentAccounts: [...currentAccounts],
            savingsAccounts: [...savingsAccounts],
            reservationAccounts: [...reservationAccounts],
          });
        }
        else{
          setData([]);
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condominium]);

  const renderAccountCard = (data, accountType) =>{
    return(
        <div className="table-info m-t-m">
          <p>{accountType}</p>
          {data.map((item, index)=>{
            return(
              <div key={index} className="row account-item">
                {item.account &&
                  <Fragment>
                    <div className="col-lg-8">
                      {item.account.bank && <span className="width-m m-r-m">{item.account.bank}</span>}
                      {item.account.iban && <span>{item.account.iban}</span>}
                    </div>
                  </Fragment>
                }
                <div className="col-lg-4 text-right">
                  {formatNumber(toFixed(parseFloat(item.balance.toString().replace(",", ".")),2)) +
                    item.currency.symbol}
                </div>
              </div>
            )
          })
        }
        </div>
    );
  }
  
  return (
    <Fragment>
      {(balanceDate.current!=="" && balanceDate.current!==null) &&
        <Card subclass="max-width table-info">
          <p>{resources.local.info_p1
            .replace("{%DATE%}", getDate(balanceDate.current))
            .replace("{%TIME%}", getTime(balanceDate.current))}
          </p>
          <p>{resources.local.info_p2}</p>
        </Card>
      }
      <Card subclass="max-width">
      {(data.cashAccounts && data.cashAccounts.length>0 && data.cashAccounts[0].balance!==0) &&
        <div className="table-info">
          <p>
            <span>
              {resources.local.accountTypes.cash}
            </span>
            <span className="fright cash-value">
              {formatNumber(toFixed(parseFloat(data.cashAccounts[0].balance.toString().replace(",", ".")),2)) +
              data.cashAccounts[0].currency.symbol}
            </span>
          </p>
        </div>
      }
      {(data.currentAccounts && data.currentAccounts.length>0) &&
        renderAccountCard(data.currentAccounts, resources.local.accountTypes.current)
      }

      {(data.savingsAccounts && data.savingsAccounts.length>0) &&
        renderAccountCard(data.savingsAccounts, resources.local.accountTypes.savings)
      }

      {(data.reservationAccounts && data.reservationAccounts.length>0) &&
        renderAccountCard(data.reservationAccounts, resources.local.accountTypes.reservation)
      }
      
      {(((!data.cashAccounts || data.cashAccounts.length===0 || data.cashAccounts[0].balance===0) &&
        (!data.currentAccounts || data.currentAccounts.length===0) &&
        (!data.savingsAccounts || data.savingsAccounts.length===0) &&
        (!data.reservationAccounts || data.reservationAccounts.length===0)) || data.length===0) &&
          
        resources.local.noAccountTypes.currentAccounts
      }
      {(data.cashAccounts===undefined && data.currentAccounts===undefined && 
        data.savingsAccounts===undefined && data.reservationAccounts===undefined) &&
          <IsLoading text={resources.local.loading}/>
      }
      </Card>
    </Fragment>
  );
};

export default BankBalanceList;
