import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../components/elements/Navbar";
import Footer from "../components/website/Footer";
import { Terms } from "../templates/terms";
import {
  globalResources,
  getCurrent,
  setLanguage,
} from "../resources/resources";
import {changeLang} from "../serverScripts/changeLang";

const TermsUsePage = () => {
  let _lang = getCurrent();
  const [lang, setLang] = useState(_lang);
  const [render, reRender] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (lang && lang.split("-")[0] !== getCurrent()) {
      setLanguage(lang.split("-")[0], false);
      reRender(render + 1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
    
    const urlLang=changeLang();
    if(urlLang){
      setLang(urlLang);
    }
    
  }, [lang]);

  return (
    <Fragment>
      <Navbar
        onLanguageChange={(language) => {
          setLang(language);
        }}
      />
      <div className="activation-banner">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>{globalResources.terms}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="terms website-terms">
        <div className="container">
          <div className="row">
            <div className="col-12">{Terms(lang.split("-")[0])}</div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TermsUsePage;
