import React, { useState, useEffect } from "react";
import Select from "react-select";

const FormSelect = (props) => {
  const [isDisabled, setDisabled] = useState(props.isDisabled);
  const {
    label,
    valueChange,
    input,
    meta,
    name,
    options,
    valueOnly,
    ...rest
  } = props;
  const { touched, error, warning } = meta;

  useEffect(() => {
    if (options.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [options]);

  const handleChange = (event) => {
    if (valueOnly && event && event.value) event = event.value;
    input.onChange(event);
    if (valueChange) valueChange(event);
  };

  const handleBlurEvent = () => {
    setTimeout(() => {
      input.onBlur(input.value);
    }, 1);
  };

  let value = {};
  if (input.value && !input.value.value && options && options.length > 0) {
    value = { value: options.filter((option) => option.value === input.value) };
  }

  return (
    <div className={`form-group ${props.class ? props.class : ""}`}>
      <label htmlFor={name}>{label}</label>
      <Select
        {...input}
        {...rest}
        {...value}
        isDisabled={isDisabled}
        options={options}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlurEvent}
      />
      {touched &&
        ((error && (
          <div className="form-feedback">
            <span>{error}</span>
          </div>
        )) ||
          (warning && (
            <div className="form-feedback">
              <span>{warning}</span>
            </div>
          )))}
    </div>
  );
};

export default FormSelect;
