
import { setLanguage, getCurrent } from "../resources/resources";
export function changeLang(){

    const url = new URL ( window.location.href ) ;                          // GET URL
    const searchParams = new URLSearchParams(url.search);                   // GET PARAMS OF THE URL
    const lang = searchParams.get("lang");                              // GET APP PARAM 
    
    //REMOVE THE LANG GET PARAM
    window.history.replaceState && window.history.replaceState(
        null, '', window.location.pathname + window.location.search.replace(/[\?&]lang=[^&]+/, '').replace(/^&/, '?')
    );
    
    if (lang && lang!=getCurrent()) {
        return lang;
    }
    else{
        return null;
    }

}