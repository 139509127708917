import { apiAuthRequestDev } from "../server/api";
import { GET_INQUIRIES } from "./types";

export function GetInquiriesDispatch(open, page, records) {
  let url = `api/Inquiries?ongoingAndNotFinished=${open}`;
  if(page!==null) url+= `&currentPage=${page}`;
  if(records!==null) url+= `&recordsPerPage=${records}`;

  return async (dispatch)=>{
    apiAuthRequestDev({
      url: url
    })
    .then((response) => response.json())
    .then((result) => {
      dispatch({
        type: GET_INQUIRIES,
        inquiries: result,
      });
    }).catch(e => {});
  };
}

export function GetInquiries(open, page, records) {
  let url = `api/Inquiries?ongoingAndNotFinished=${open}`;
  if(page!==null) url+= `&currentPage=${page}`;
  if(records!==null) url+= `&recordsPerPage=${records}`;

  return (apiAuthRequestDev({
    url: url
  }));
}

export function GetInquirie(id, lang) {
  let url = `api/Inquiries/${id}`;
  if(lang) url += `?language=${lang}`;
  return (apiAuthRequestDev({
    url: url
  }));
}

export function GetInquirieStats(id, lang) {
  let url = `api/Inquiries/${id}/Reports/ParticipationStatistics`;
  if(lang) url += `?language=${lang}`;
  return (apiAuthRequestDev({
    url: url
  }));
}

export function GetInquirieUnregisteredUser(id, lang, email) {
  let url = `api/Inquiries/${id}/UnregisteredUser?userEmail=${email}`;
  if(lang) url += `&language=${lang}`;
  return (apiAuthRequestDev({
    url: url
  }));
}

export function SaveAnswers(id, data) {
  let url = `api/Inquiries/${id}/Answers`;
  return apiAuthRequestDev({
    url: url,
    data: data,
    method:"POST",
  });
}

export function SaveComment(id, data) {
  let url = `api/Inquiries/${id}/FinalComments`;
  return apiAuthRequestDev({
    url: url,
    data: data,
    method:"POST",
  });
}

export function Finish(id) {
  let url = `api/Inquiries/${id}/Finish`;
  return apiAuthRequestDev({
    url: url,
    method:"POST",
  });
}

export function GetAnswers(id) {
  let url = `api/Inquiries/${id}/Answers`;
  return (apiAuthRequestDev({
    url: url
  }));
}

export function GetComments(id) {
  let url = `api/Inquiries/${id}/FinalComments`;
  return (apiAuthRequestDev({
    url: url
  }));
}