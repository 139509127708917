import { apiAuthRequest } from "../server/api";

export function GetNotices(id, dataFilter) {
  let data = {
    condominiumId: id,
  };
  if (dataFilter !== null) {
    data = {
      ...data,
      onlyNoticesReachedOrReachingDueDateInNextNDays: dataFilter,
    };
  }
  return apiAuthRequest({
    url: "v1/AccountingDocuments/NoticesAndCreditsWithDocument",
    data: { ...data },
  });
}

export function GetPaymentOptions(id, value) {
  return apiAuthRequest({
    url: "v1/PaymentOptions",
    data: {
      condominiumId: id,
      amountToPay: value,
    },
    method: "POST",
  });
}

export function GetReceipts(id) {
  return apiAuthRequest({
    url: "v1/Receipts",
    data: {
      condominiumId: id,
    },
  });
}

export function GetDocuments(id) {
  return apiAuthRequest({
    url: "v1/Documents/CondominiumDocuments",
    data: {
      condominiumId: id,
    },
  });
}

export function GetBankBalances(id) {
  return apiAuthRequest({
    url: `v1/BankAccounts/${id}/Balance`,
  });
}

export function GetBudgetsAnalysis(id) {
  return apiAuthRequest({
    url: `v1/Budgets/${id}/Analysis`,
  });
}

export function GetBudgetsExercise(id) {
  return apiAuthRequest({
    url: `v1/Budgets/${id}/Exercise`,
  });
}

export function GetBalance(id) {
  return apiAuthRequest({
    url: `v1/AccountingDocuments/AccountingBalance?condominiumId=${id}`,
  });
}

export function GetCurrentAccount(id) {
  return apiAuthRequest({
    url: `v1/CurrentAccounts/LastYearExtract?condominiumId=${id}`,
    data: {
      condominiumId: id,
    },
  });
}

export function PostEmailsCurrentAccount(extractMovementSignature) {
  //console.log("extractMovementSignature: ", extractMovementSignature);
  return apiAuthRequest({
    url: `v1/CurrentAccounts/SendExtractDocumentEmail`,
    data: extractMovementSignature,
    contentType: "application/json",
    method: "post",
  });
}
