import { GET_VOTES } from "../actions/types";

const resourcesReducerDefaultState = null;

export default (state = resourcesReducerDefaultState, action) => {
  switch (action.type) {
    case GET_VOTES:
      return action.votes;
      
    default:
      return state;
  }
};
