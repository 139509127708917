import React, { Fragment, useState, useEffect, useRef } from "react";
import Table, {
    Column,
    Number,
    Date,
  } from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetExerciseExpenses } from "../../actions/expenses";
import Card from "../../components/containers/Card";
import { ExerciseExpensesResources, globalResources, websiteResources } from "../../resources/resources";
import { useSelector } from "react-redux";

const ExerciseExpensesTable = () => {
  const [data, setData] = useState();
  const [resources] = useState(ExerciseExpensesResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const activeBudget = useRef(true);

  useEffect(() => {
    if (condominium) {
      activeBudget.current=true;
      setData();
      GetExerciseExpenses(condominium.id).then((response) => {
        if(response.data.expenses && response.data.expenses.length>0){
          response.data.expenses.forEach((doc) => {
            doc.emissionDate = new window.Date(doc.emissionDate);
          });
        }
        setData(response.data);
      }).catch((err)=>{
        const errorMessage = globalResources.apiError.activeBudgetNotFound;
        if(err.response.data && err.response.data.error===errorMessage){
          activeBudget.current=false;
        }
        setData([]);
      });
    }
  }, [condominium]);
  
  return data ? (
    <Fragment>
      <Card>
        {(data.expenses && data.expenses.length>0) &&
          <Table data={data.expenses} pager={{ pageIndex: 0 }}>
            <Date Header={resources.local.tableHeaders.emission} accessor="emissionDate" sortType="datetime" align="left" className="collapse"/>
            <Column Header={resources.local.tableHeaders.rubric} accessor="rubricName" />
            {(data.visibleValueColumns && data.visibleValueColumns.includes("Amount")) ?
                <Number Header={resources.local.tableHeaders.amount}
                accessor="amount" className="collapse"/>
                :
                <Fragment />
            }
            {(data.visibleValueColumns && data.visibleValueColumns.includes("Balance")) ?
                <Number Header={resources.local.tableHeaders.balance}
                accessor="balance" className="collapse"/>
                :
                <Fragment />
            }
          </Table>
        }

        {(!activeBudget.current) &&
          globalResources.noActiveBudget
        }
        {((data.length===0 || data===null || data.expenses.length===0) && activeBudget.current) &&
          resources.local.noValues
        }
      </Card>
    </Fragment>
  ) : (
    <Card>
      <IsLoading text={websiteResources.loading} />
    </Card>
  );
};

export default ExerciseExpensesTable;
