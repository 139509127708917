import React from "react";
import { Button, Modal, Card, CardText, CardBody, CardTitle } from "reactstrap";
import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../hooks/useWebInstallPrompt";
import { globalResources } from "../resources/resources";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
  ] = useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <Modal isOpen centered>
      <Card>
        <img
          className="mx-auto"
          style={{
            borderTopRightRadius: "22%",
            borderTopLeftRadius: "22%",
            backgroundColor: "#fff",
            marginTop: "-50px",
          }}
          width="100px"
          src="/images/favicon.png"
          alt="Icon"
        />
        <CardBody>
          <CardTitle className="text-center">
            <h3>{globalResources.installApp}</h3>
          </CardTitle>
          {iosInstallPrompt && (
            <>
              <CardText className="text-center">
                Tap
                <img
                  src="/images/AppleShare.png"
                  style={{ margin: "auto 8px 8px" }}
                  className=""
                  alt="Add to homescreen"
                  width="20"
                />
                then &quot;Add to Home Screen&quot;
              </CardText>
              <div className="d-flex justify-content-center">
                <Button onClick={handleIOSInstallDeclined}>
                  {globalResources.close}
                </Button>
              </div>
            </>
          )}
          {webInstallPrompt && (
            <div className="d-flex justify-content-around">
              <Button color="primary" onClick={handleWebInstallAccepted}>
                {globalResources.install}
              </Button>
              <Button onClick={handleWebInstallDeclined}>
                {globalResources.close}
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};
