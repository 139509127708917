import React from "react";
import OccurrenceForm from "../components/forms/OccurrenceForm";
import Master from "../components/containers/Master";
import Breadcrumb from "../components/elements/Breadcrumb";
import { navigationResources } from "../resources/resources";
import { withRouter } from "react-router";

const OccurrencePage = (props) => {
  return (
    <Master>
      <Breadcrumb page={navigationResources.occurrences.new} />
      <div className="container-fluid">
        <OccurrenceForm
          after={(update) => {
            props.history.goBack();
          }}
        ></OccurrenceForm>
      </div>
    </Master>
  );
};

export default withRouter(OccurrencePage);
