import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table, {
  Column,
  Option,
  Number,
  NoData,
  Loading,
  DateTime,
} from "../elements/Table";
import IsLoading from "../elements/IsLoading";
import { GetAssemblies, GetAssembly } from "../../actions/votes";
import Card from "../containers/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  VotingsTableResources,
  navigationResources,
  globalResources,
} from "../../resources/resources";
import VotingsForm from "../forms/VotingsForm";
import AssemblyForm from "../forms/AssemblyForm";
import Breadcrumb from "../elements/Breadcrumb";
import {
  toVotingStatusLabel,
  toValidationStatusLabel,
  toRealizationModeLabel,
} from "../../assets/FormatAndParse";
import { notifyError } from "../elements/Notify";
import {
  isTodayBetweenDates,
  isDateBeforeToday,
  datesOrderDESC,
} from "../../assets/utils.js";
import { ConfirmPopup } from "../elements/Popup";

const VotingsTable = () => {
  //const [data, setData] = useState();
  const [resources] = useState(VotingsTableResources);
  const [assembly, setAssembly] = useState(null);
  const condominium = useSelector((state) => state.user.current_condominium);
  const data = useSelector((state) => state.votes);
  const dispatch = useDispatch();
  const [minutePopupVisible, setMinutePopupVisible] = useState(0);
  const [requestInvalid, setRequestInvalid] = useState(true);

  const getAssemblies = () => {
    /*GetAssemblies(condominium.id).then((response) => response.json())
      .then(result => {
        setData(result);
      })
      .catch(e => {setData([])});*/
    if (condominium) {
      dispatch(GetAssemblies(condominium.id));
    }
  };

  const popUpResponse = (yes, url) => {
    if (yes) {
      window.open(url, "_blank");
    }
    setMinutePopupVisible(0);
  };

  /*useEffect(() => {
    if (condominium) {
      getAssemblies();
    }
  },[condominium]);*/

  const renderAttachmentsList = (data) => {
    var moment = require("moment");
    return (
      <div>
        {data.map((item, index) => {
          return (
            <div key={item.description + index} className="row no-margin">
              <p>
                {item.documentLink &&
                  item.documentLink.url &&
                  item.documentLink.url !== "" && (
                    <a
                      href={item.documentLink.url}
                      title={resources.local.download}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="voting-attachment-download"
                    >
                      <FontAwesomeIcon
                        icon={faCloudDownloadAlt}
                      ></FontAwesomeIcon>
                    </a>
                  )}
                <span className="m-l-s">{item.description}</span>
              </p>
              {/*<div className="col-lg-4">
            <p>{moment(item.uploadDate).format("DD-MM-YYYY")}</p>
        </div>*/}
            </div>
          );
        })}
      </div>
    );
  };

  const httpRequest = (link) => {
    try {
      fetch(link, {
        method: "GET",
        headers: {
          Pragma: "no-cache",
          Expires: 0,
        },
      })
        .then((response) => console.log("response: ", response))
        .catch((error) => {
          console.log("error: ", error);
          setRequestInvalid(false);
        });
    } catch (e) {
      console.log("error2: ", e);
      // error reading value
    }
  };

  const renderAssembliesList = () => {
    var moment = require("moment");
    if (data.length === 0) {
      return <Card>{resources.local.noVotes}</Card>;
    } else {
      return (
        <ul className="full-width">
          {data.map((item) => {
            return (
              <li key={item.id} className="list-assemblies-item">
                <Card subclass="card-assembly">
                  <div className="row">
                    <div className="col-lg">
                      <h3>{item.condominium.name}</h3>
                      <p /*className="selected no-margin"*/>
                        {item.isOrdinaryAssembly
                          ? resources.local.ordinaryAssembly
                          : resources.local.extraordinaryAssembly}
                      </p>
                      {item.assemblyStartDate && item.realizationMode && (
                        <p>
                          {toRealizationModeLabel(
                            item.realizationMode,
                            resources.local
                          )}
                          {moment(item.assemblyStartDate).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </p>
                      )}
                      {item.assemblyAlternativeStartDate && (
                        <p>
                          {resources.local.alternativeAssemblyDate}
                          {moment(item.assemblyAlternativeStartDate).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </p>
                      )}
                      {item.userVotingUnits && item.userVotingUnits.length > 0 && (
                        <p>
                          {item.userVotingUnits.length === 1
                            ? resources.local.votingForFractions_singular +
                              item.userVotingUnits[0].code
                            : resources.local.votingForFractions_plural +
                              item.userVotingUnits.map((item) => {
                                return " " + item.code;
                              })}
                        </p>
                      )}
                      <p>
                        {resources.local.agendaItems}
                        <b>{item.agendaItemsCount}</b>
                      </p>
                    </div>
                    {/*<div className="col-lg-4 text-right">
                    <button className="btn btn-primary" onClick={(e) => {
                      setAssembly(item);
                      e.stopPropagation();
                      e.preventDefault();
                    }}>
                      {(isDateBeforeToday(item.onlineVotingStartDate) && item.userVotingStatus.id!==5) ? 
                        ((item.userVotingValidationStatus && item.userVotingValidationStatus.id!==0) ? resources.local.checkVoting : resources.local.goToVoting) 
                        : resources.local.checkAgendaItems}
                    </button>
                      </div>*/}
                  </div>
                  <div className="row">
                    {!isDateBeforeToday(item.onlineVotingEndDate) && (
                      <div className="col-lg-8">
                        <h4 className="m-b-0">
                          {resources.local.digitalVoting}
                        </h4>
                        {item.onlineVotingStartDate &&
                        item.onlineVotingEndDate ? (
                          <p>
                            {resources.local.dateToVote
                              .replace(
                                "{%START_DATE%}",
                                moment(item.onlineVotingStartDate).format(
                                  "DD-MM-YYYY HH:mm"
                                )
                              )
                              .replace(
                                "{%END_DATE%}",
                                moment(item.onlineVotingEndDate).format(
                                  "DD-MM-YYYY HH:mm"
                                )
                              )}
                          </p>
                        ) : (
                          <p>
                            {resources.local.dateTillVote.replace(
                              "{%END_DATE%}",
                              moment(item.onlineVotingEndDate).format(
                                "DD-MM-YYYY HH:mm"
                              )
                            )}
                          </p>
                        )}
                        <p>
                          <span>{resources.local.votingStatus}</span>
                          <span className="selected">
                            {isTodayBetweenDates(
                              item.onlineVotingStartDate,
                              item.onlineVotingEndDate
                            )
                              ? item.userVotingStatus
                                ? toVotingStatusLabel(item.userVotingStatus)
                                : null
                              : globalResources.notAvailableYet}
                          </span>
                        </p>
                        {isTodayBetweenDates(
                          item.onlineVotingStartDate,
                          item.onlineVotingEndDate
                        ) &&
                          item.userVotingStatus &&
                          item.userVotingStatus.id === 3 &&
                          item.userVotingValidationStatus && (
                            <div>
                              <p>
                                <span>
                                  {resources.local.administrationsValidation}
                                </span>
                                <span
                                  className={`${
                                    item.userVotingValidationStatus.id === 2
                                      ? "error"
                                      : "selected"
                                  }`}
                                >
                                  {toValidationStatusLabel(
                                    item.userVotingValidationStatus,
                                    resources.local.validation_states
                                  )}
                                </span>
                              </p>
                              {(item.userVotingValidationStatus.id === 1 ||
                                item.userVotingValidationStatus.id === 2) && (
                                <p>
                                  {item.userVotingValidationStatus.id === 1
                                    ? resources.local.validation_states
                                        .valid_description
                                    : resources.local.validation_states
                                        .invalid_description}
                                </p>
                              )}
                            </div>
                          )}
                        {item.userVotingStatus &&
                          item.userVotingStatus.id === 5 && (
                            <p>{resources.local.noFullOwnership_infoText}</p>
                          )}
                      </div>
                    )}
                    {/*<div className="col-lg-4 agenda-items-col">
                    <div>
                      <h3 className="no-margin text-right">{item.agendaItemsCount}</h3>
                      <p className="m-t-0">{resources.local.agendaItems}</p>
                    </div>
                  </div>*/}
                  </div>
                  {item.videoConferenceInformation &&
                    isTodayBetweenDates(
                      item.onlineVotingStartDate,
                      item.onlineVotingEndDate
                    ) && (
                      <div>
                        <h4 className="m-b-0">
                          {resources.local.videoconference}
                        </h4>
                        {item.videoConferenceInformation.videoconferenceLink &&
                          item.videoConferenceInformation.videoconferenceLink
                            .url && (
                            <div className="m-t-s">
                              <a
                                href={
                                  item.videoConferenceInformation
                                    .videoconferenceLink.url
                                }
                                target="_blank"
                              >
                                <button
                                  id="videoconference-button"
                                  className="btn btn-primary"
                                >
                                  {resources.local.videoconferenceAccess}
                                </button>
                              </a>
                              <i>
                                {resources.local.videoconferenceDescription}
                              </i>
                            </div>
                          )}
                        {item.videoConferenceInformation
                          .complementaryInformation && (
                          <div>
                            <p>{resources.local.videoconferenceOtherInfo}</p>
                            <p className="p-l-m linebreak">
                              {
                                item.videoConferenceInformation
                                  .complementaryInformation
                              }
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  {item.attachments && item.attachments.length > 0 && (
                    <div>
                      <h4 className="m-b-0">
                        {resources.local.documentsForConsultation}
                      </h4>
                      {renderAttachmentsList(item.attachments)}
                    </div>
                  )}
                  {item.minute && item.minute.url && (
                    <div className="row">
                      <div className="col-auto">
                        <h4 className="m-b-0">
                          {resources.local.minutesMeeting}
                        </h4>
                      </div>
                      <div className="col-auto p-t-l">
                        <a
                          href={item.minute.url}
                          title={resources.local.download}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="voting-attachment-download"
                        >
                          <FontAwesomeIcon
                            icon={faCloudDownloadAlt}
                          ></FontAwesomeIcon>
                        </a>
                      </div>
                      {item.minuteSubscriptionStatement &&
                        item.minuteSubscriptionStatement.url && (
                          <Fragment>
                            <button
                              className="btn btn-primary m-t-s m-l-m"
                              onClick={() => {
                                setMinutePopupVisible(item.id);
                                httpRequest(
                                  item.minuteSubscriptionStatement.url
                                );
                              }}
                            >
                              {resources.local.minutesSubscriptionButton}
                            </button>
                            <ConfirmPopup
                              requestInvalid={requestInvalid}
                              message={
                                resources.local
                                  .minutesSubscriptionPopup_description
                              }
                              visible={minutePopupVisible === item.id}
                              popUpResponse={(response) => {
                                popUpResponse(
                                  response,
                                  item.minuteSubscriptionStatement.url
                                );
                              }}
                            />
                          </Fragment>
                        )}
                    </div>
                  )}
                  {!isDateBeforeToday(item.onlineVotingEndDate) && (
                    <button
                      className="btn btn-primary m-t-m"
                      onClick={(e) => {
                        setAssembly(item);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {isTodayBetweenDates(
                        item.onlineVotingStartDate,
                        item.onlineVotingEndDate
                      ) && item.userVotingStatus.id !== 5
                        ? item.userVotingValidationStatus &&
                          item.userVotingValidationStatus.id !== 0
                          ? resources.local.checkVoting
                          : resources.local.goToVoting
                        : resources.local.checkAgendaItems}
                    </button>
                  )}
                </Card>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <Fragment>
      {assembly === null && (
        <Breadcrumb page={navigationResources.votings.breadcrumb} />
      )}
      {assembly === null ? (
        data === null ? (
          <Card subclass="col-lg-4">
            <IsLoading text={resources.local.loading} />
          </Card>
        ) : (
          renderAssembliesList()
        )
      ) : (
        <AssemblyForm
          assembly={assembly}
          clearAssembly={() => {
            setAssembly(null);
            getAssemblies();
            //setData();
          }}
        />
      )}
    </Fragment>
  );
};

export default VotingsTable;
