import { apiAuthRequestDev } from "../server/api";
import { GET_VOTES } from "./types";
import { datesOrderDESC } from "../assets/utils.js";

export function GetAssemblies(condominium_id) {
  return async (dispatch)=>{
    apiAuthRequestDev({
      url: `v1/Assemblies?condominiumId=${condominium_id}`
    })
    .then((response) => response.json())
    .then((result) => {
      if(result.length>0){
        result=[...datesOrderDESC("onlineVotingEndDate", [...result])];
      }
      dispatch({
        type: GET_VOTES,
        votes: result,
      });
    }).catch(e => {});
  };
  /*return apiAuthRequestDev({
    url: `v1/Assemblies?isActive=true&condominiumId=${condominium_id}`
  });*/
}

export function GetAssembly(id) {
    return apiAuthRequestDev({url: `v1/Assemblies/${id}`});
}

export function GetIdentificationDocumentTypes() {
  return apiAuthRequestDev({url: "v1/AssembliesLegalRepresentatives/IdentificationDocumentTypes"});
}

export function PostLegalRepresentative(id, values) {
  let data={
    legalRepresentativeType: {
      id: values.legalRepresentativeType.id,
      code: values.legalRepresentativeType.code
    },
  };

  if(values.userCustomLegalRepresentative){ 
    data={...data, userCustomLegalRepresentative: {
        name: values.userCustomLegalRepresentative.name,
        identificationDocumentType: {
          id: values.userCustomLegalRepresentative.identificationDocumentType.id,
          code: values.userCustomLegalRepresentative.identificationDocumentType.code
        },
        identificationDocument: values.userCustomLegalRepresentative.identificationDocument,
        identificationDocumentExpirationDate: values.userCustomLegalRepresentative.identificationDocumentExpirationDate
      }
    } 
  }
  
  return apiAuthRequestDev({
    url: `v1/AssembliesLegalRepresentatives/${id}`,
    data: data,
    method: "POST",
  });
}

export function PostVotes(id, values) {
  return apiAuthRequestDev({
    url: `v1/AssembliesVotes/${id}`,
    data: values,
    method: "POST",
  });
}

export function GetUnsignedLegalDocument(id) {
  return apiAuthRequestDev({url: `v1/AssembliesLegalRepresentatives/${id}/unsignedLegalDocument`});
}

export function GetSignedLegalDocument(id) {
  return apiAuthRequestDev({url: `v1/AssembliesLegalRepresentatives/${id}/signedLegalDocument`});
}

export function DeleteSignedLegalDocument(id) {
  return apiAuthRequestDev({
    url: `v1/AssembliesLegalRepresentatives/${id}/signedLegalDocument`,
    method: "DELETE",
  });
}

export function SaveAttachment(id, attachment) {
  const data={
    base64ContentBytes: attachment.base64ContentBytes,
    imageMimeType: attachment.imageMimeType,
    fileName: attachment.fileName
  }

  return apiAuthRequestDev({
    url: `v1/AssembliesLegalRepresentatives/${id}/signedLegalDocument`,
    data: data,
    method: "POST",
  });
}
