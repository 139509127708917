export const recoverPasswordTemplate = (language) => {
  let client_id_added = "{%CLIENT_ID%}";

  /*if(client_id()!==null && client_id() !== default_client_id){
    client_id_added="/?app=" + client_id();
  }*/
  let url = "www.gecond.com";
  if (window.location.hostname === "dev.gecond.com") {
    url = "gecond.com";
  }

  let message_body;
  let message_subject;
  switch (language) {
    case "en":
      message_subject = "Gecond, my condominium - Recover password";
      message_body = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'>
      <html lang='en'>
        <head>
          <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta http-equiv='X-UA-Compatible' content='IE=edge' />
          <link
            href='https://fonts.googleapis.com/css2?family=Lato&display=swap'
            rel='stylesheet'
          />
      
          <title>Gecond my condominium – recover password</title>
      
          <style type='text/css'>
            /* Outlines the grids, remove when sending */
            /* table td { border: 1px solid #F5F5F5; } */
      
            /* CLIENT-SPECIFIC STYLES */
            body,
            table,
            td,
            a {
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
            }
      
            table,
            td {
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
            }
      
            img {
              -ms-interpolation-mode: bicubic;
            }
      
            /* RESET STYLES */
            img {
              border: 0;
              outline: none;
              text-decoration: none;
            }
      
            table {
              border-collapse: collapse !important;
            }
      
            body {
              margin: 0 !important;
              padding: 0 !important;
              width: 100% !important;
              font-family: 'Lato', 'Tahoma', 'Sans-Serif';
              font-size: 14px;
            }
      
            /* iOS BLUE LINKS */
            a[x-apple-data-detectors] {
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
            }
      
            /* ANDROID CENTER FIX */
            div[style*='margin: 16px 0;'] {
              margin: 0 !important;
            }
      
            /* EMAIL LINKS FIX */
            font {
              display: none;
            }
          </style>
        </head>
      
        <body
          style='
            margin: 0;
            padding: 0;
            background-color: #fff;
            font-family: 'Lato', 'Tahoma' 'Sans-Serif';
            font-size: 15px;
          '
        >
          <span
            style='
              display: block;
              width: 640px !important;
              max-width: 640px;
              height: 1px;
            '
            class='mobileOff'
          ></span>
      
          <center>
            <table
              width='100%'
              border='0'
              cellpadding='0'
              cellspacing='0'
              bgcolor='#F2F2F2'
            >
              <tr>
                <td align='center' valign='top'>
                  <!-- TOP -->
                  <table
                    width='640'
                    cellpadding='0'
                    cellspacing='0'
                    border='0'
                    class='wrapper'
                    bgcolor='#F8F8F8'
                    style='background: #fff;'
                  >
                    <tr>
                      <td
                        height='10'
                        style='line-height: 10px; background-color: #2cb34a;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td height='25' style='font-size: 10px; line-height: 10px;'>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align='center' valign='top'>
                        <table
                          width='600'
                          cellpadding='0'
                          cellspacing='0'
                          border='0'
                          class='container'
                        >
                          <tr>
                            <td align='center' valign='top'>
                              <span
                                style='
                                  display: block;
                                  font-size: 32px;
                                  color: #2cb34a;
                                '
                                >Recover password</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td
                              height='15'
                              style='font-size: 10px; line-height: 10px;'
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td height='25' style='font-size: 10px; line-height: 10px;'>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width='600' align='center' valign='top'>
                        <p style='word-spacing: 2px;'>
                          We received a request to recover the password to access
                          <br />
                          <br />
                          <a
                            href='https://${url}${client_id_added}'
                            target='_blank'
                            style='
                              text-decoration: none;
                              color: #0b2e59;
                              font-family: Arial;
                              font-weight: bold;
                              font-size: 20px;
                            '
                            >Gecond, my condominium</a
                          >
                          <br />
                          <br />
                          If you didn´t request it, please ignore this email.
                          <br />
                        </p>
                        <p style='word-spacing: 2px;'>
                          To recover the password, please click on the button below:
                          <br />
                          <br />
                          <br />
                        </p>
                        <p>
                          <!--[if mso]>
                            <v:roundrect 
                              xmlns:v='urn:schemas-microsoft-com:vml' 
                              xmlns:w='urn:schemas-microsoft-com:office:word' 
                              href='https://${url}/recover/%CODE%${client_id_added}' 
                              style='
                                height:50px;
                                font-size:16px;
                                v-text-anchor:middle;
                                width:220px;' 
                                arcsize='10%' 
                                stroke='f' 
                                fillcolor='#2cb34a'>
                              <w:anchorlock/>
                              <center>
                            <![endif]-->
                          <a
                            href='https://${url}/recover/%CODE%${client_id_added}'
                            target='_blank'
                            style='
                              background-color: #2cb34a;
                              border-radius: 4px;
                              color: #ffffff;
                              display: inline-block;
                              line-height: 50px;
                              text-align: center;
                              text-decoration: none;
                              font-size: 16px;
                              width: 220px;
                              -webkit-text-size-adjust: none;
                            '
                          >
                            Recover password</a
                          >
                          <!--[if mso]>
                              </center>
                            </v:roundrect>
                          <![endif]-->
                        </p>
                        <br />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td width='600' align='center' valign='top'>
                        <!--[if mso]>
                          <br />
                        <![endif]-->
                        <p style='word-spacing: 2px;'>
                          If the button doesn't work, copy and paste the following
                          link in your browser:<br />
                          <a
                            style='color: #2cb34a; text-decoration: none;'
                            href='https://${url}/recover/%CODE%${client_id_added}'
                            >https://${url}/recover/%CODE%${client_id_added}</a
                          >
                        </p>
                      </td>
                    </tr>
      
                    <tr>
                      <td
                        height='15'
                        bgcolor='#fff'
                        style='font-size: 10px; line-height: 10px;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align='center' valign='top' bgcolor='#fff'>
                        <a
                          style='text-decoration: none;'
                          href='https://${url}${client_id_added}'
                          target='_blank'
                        >
                          <img
                            width='120'
                            style='max-height: 150px; max-width: 120px;'
                            src='https://${url}/images/logo-gecond-my-condominium.png'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height='15'
                        bgcolor='#fff'
                        style='font-size: 10px; line-height: 10px;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </center>
        </body>
      </html>`;
      break;

    default:
      message_subject = "Gecond, o meu condomínio - Recuperar senha de acesso";
      message_body = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'>
      <html lang='en'>
        <head>
          <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta http-equiv='X-UA-Compatible' content='IE=edge' />
          <link
            href='https://fonts.googleapis.com/css2?family=Lato&display=swap'
            rel='stylesheet'
          />
      
          <title>Gecond o meu condomínio – recuperar senha de acesso</title>
      
          <style type='text/css'>
            /* Outlines the grids, remove when sending */
            /* table td { border: 1px solid #F5F5F5; } */
      
            /* CLIENT-SPECIFIC STYLES */
            body,
            table,
            td,
            a {
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
            }
      
            table,
            td {
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
            }
      
            img {
              -ms-interpolation-mode: bicubic;
            }
      
            /* RESET STYLES */
            img {
              border: 0;
              outline: none;
              text-decoration: none;
            }
      
            table {
              border-collapse: collapse !important;
            }
      
            body {
              margin: 0 !important;
              padding: 0 !important;
              width: 100% !important;
              font-family: 'Lato', 'Tahoma', 'Sans-Serif';
              font-size: 14px;
            }
      
            /* iOS BLUE LINKS */
            a[x-apple-data-detectors] {
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
            }
      
            /* ANDROID CENTER FIX */
            div[style*='margin: 16px 0;'] {
              margin: 0 !important;
            }
      
            /* EMAIL LINKS FIX */
            font {
              display: none;
            }
          </style>
        </head>
      
        <body
          style='
            margin: 0;
            padding: 0;
            background-color: #fff;
            font-family: 'Lato', 'Tahoma' 'Sans-Serif';
            font-size: 15px;
          '
        >
          <span
            style='
              display: block;
              width: 640px !important;
              max-width: 640px;
              height: 1px;
            '
            class='mobileOff'
          ></span>
      
          <center>
            <table
              width='100%'
              border='0'
              cellpadding='0'
              cellspacing='0'
              bgcolor='#F2F2F2'
            >
              <tr>
                <td align='center' valign='top'>
                  <!-- TOP -->
                  <table
                    width='640'
                    cellpadding='0'
                    cellspacing='0'
                    border='0'
                    class='wrapper'
                    bgcolor='#F8F8F8'
                    style='background: #fff;'
                  >
                    <tr>
                      <td
                        height='10'
                        style='line-height: 10px; background-color: #2cb34a;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td height='25' style='font-size: 10px; line-height: 10px;'>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align='center' valign='top'>
                        <table
                          width='600'
                          cellpadding='0'
                          cellspacing='0'
                          border='0'
                          class='container'
                        >
                          <tr>
                            <td align='center' valign='top'>
                              <span
                                style='
                                  display: block;
                                  font-size: 28px;
                                  color: #2cb34a;
                                '
                                >Recuperar senha de acesso</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td
                              height='15'
                              style='font-size: 10px; line-height: 10px;'
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td height='25' style='font-size: 10px; line-height: 10px;'>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width='600' align='center' valign='top'>
                        <p style='word-spacing: 2px;'>
                          Recebemos um pedido de recuperação da senha de acesso ao
                          <br />
                          <br />
                          <a
                            href='https://${url}${client_id_added}'
                            target='_blank'
                            style='
                              text-decoration: none;
                              color: #0b2e59;
                              font-family: Arial;
                              font-weight: bold;
                              font-size: 20px;
                            '
                            >Gecond, o meu condomínio</a
                          >
                          <br />
                          <br />
                          Caso não tenha efetuado esse pedido, por favor ignore este
                          email.
                          <br />
                        </p>
                        <p style='word-spacing: 2px;'>
                          Para recuperar a senha de acesso clicar no botão seguinte:
                          <br />
                          <br />
                          <br />
                        </p>
                        <p>
                          <!--[if mso]>
                            <v:roundrect 
                              xmlns:v='urn:schemas-microsoft-com:vml' 
                              xmlns:w='urn:schemas-microsoft-com:office:word' 
                              href='https://${url}/recover/%CODE%${client_id_added}' 
                              style='
                                height:50px;
                                font-size:16px;
                                v-text-anchor:middle;
                                width:280px;' 
                                arcsize='10%' 
                                stroke='f' 
                                fillcolor='#2cb34a'>
                              <w:anchorlock/>
                              <center>
                            <![endif]-->
                          <a
                            href='https://${url}/recover/%CODE%${client_id_added}'
                            target='_blank'
                            style='
                              background-color: #2cb34a;
                              border-radius: 4px;
                              color: #ffffff;
                              display: inline-block;
                              line-height: 50px;
                              text-align: center;
                              text-decoration: none;
                              font-size: 16px;
                              width: 280px;
                              -webkit-text-size-adjust: none;
                            '
                          >
                            Recuperar senha de acesso</a
                          >
                          <!--[if mso]>
                              </center>
                            </v:roundrect>
                          <![endif]-->
                        </p>
                        <br />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td width='600' align='center' valign='top'>
                        <!--[if mso]>
                          <br />
                        <![endif]-->
                        <p style='word-spacing: 2px;'>
                          Caso o botão não funcione, poderá copiar e colar no seu
                          navegador o seguinte link:<br />
                          <a
                            style='color: #2cb34a; text-decoration: none;'
                            href='https://${url}/recover/%CODE%${client_id_added}'
                            >https://${url}/recover/%CODE%${client_id_added}</a
                          >
                        </p>
                      </td>
                    </tr>
      
                    <tr>
                      <td
                        height='15'
                        bgcolor='#fff'
                        style='font-size: 10px; line-height: 10px;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align='center' valign='top' bgcolor='#fff'>
                        <a
                          style='text-decoration: none;'
                          href='https://${url}${client_id_added}'
                          target='_blank'
                        >
                          <img
                            width='120'
                            style='max-height: 150px; max-width: 120px;'
                            src='https://${url}/images/logo-gecond-o-meu-condominio.png'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height='15'
                        bgcolor='#fff'
                        style='font-size: 10px; line-height: 10px;'
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </center>
        </body>
      </html>`;
      break;
  }
  //message_body = message_body.replace("{$client_id_added}",client_id_added);
  return {
    subject: message_subject,
    body: message_body,
  };
};
