import React, { Fragment } from "react";
import Card from "../containers/Card";

const MessageDetail = ({ message, onClose }) => {
  var moment = require("moment");

  return message ? (
    <Card
      onClose={onClose}
      title={`${message.subject} (${moment
        .utc(message.date)
        .format("DD-MM-YYYY hh:mm")})`}
      subclass="card-message-detail scrollbar"
    >
      <span
        dangerouslySetInnerHTML={{
          __html: message.body,
        }}
      ></span>
    </Card>
  ) : (
    <Fragment></Fragment>
  );
};

export default MessageDetail;
