import { apiAuthRequest } from "../server/api";
import { GET_DASHBOARD } from "./types";

export function GetDashboard(id) {
  return async (dispatch) => {
    apiAuthRequest({
      url: "v1/Dashboard",
      data: {
        condominiumId: id,
        onlyNoticesReachedOrReachingDueDateInNextNDays: 15,
      },
    }).then((response) => {
      dispatch({
        type: GET_DASHBOARD,
        dashboard: response.data,
      });
    });
  };
}
