import React, { Fragment, useState, useEffect } from "react";
import IsLoading from "../elements/IsLoading";
import { GetBalance } from "../../actions/documents";
import Card from "../../components/containers/Card";
import { BalanceResources, globalResources, websiteResources } from "../../resources/resources";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { getDate, getTime } from "../../assets/utils";
import { toAccountTypeLabel, toTableNumber } from "../../assets/FormatAndParse";
import Table, {
    Column,
    NoData,
    Loading,
    Number,
    Option,
    Date,
    Header,
  } from "../elements/Table";

const BalanceTable = () => {
  const [data, setData] = useState();
  const [resources] = useState(BalanceResources);
  const condominium = useSelector((state) => state.user.current_condominium);
  const activeBudget = useRef(true);

  useEffect(() => {
    if (condominium) {
      activeBudget.current=true;
      setData();
      GetBalance(condominium.id).then((response) => {
        if(response && response.data && response.data.error){
          setData([]);
        } else{
          setData(response.data);
        }
      }).catch((err)=>{
        const errorMessage = globalResources.apiError.activeBudgetNotFound;
        if(err.response.data && err.response.data.error===errorMessage){
          activeBudget.current=false;
        } 
        setData([]);
      });
    }
  }, [condominium]);

  const ListTwoValueRow = ({ dataInfo, title, accounts, total, nameHeader }) => {
    return(
        <div className={`info-list colorless-table ${nameHeader ? "" : "no-head"}`}>
          <Table pager={{}} disabledSort={true}
            data={dataInfo}>
            {accounts ? 
              <Column 
                Header={nameHeader ? nameHeader : " "} 
                className="collapse"
                Cell={(table) => {
                  const doc = table.row.original;
                  return (
                    <span>
                      {(doc.accountType && doc.accountType.code) ? 
                        toAccountTypeLabel(doc.accountType, resources.local.accountTypes)
                        : ""
                      }
                    </span>
                  );
                }}/>
              : 
              <Column Header={nameHeader ? nameHeader : " "} className="collapse" accessor="name" Footer={total!==undefined ? resources.local.total : null}/>
            }
            <Number Header={""} accessor="balance" className="collapse" Footer={total!==undefined ? toTableNumber(total): null}/>
          </Table>
        </div>
      );
  }

  const TwoValueRow = ({  title, value }) => {
    return(
      <div className="headless-table">
        <Table data={[{name: title, balance:value}]}>
          <Column Header={""} accessor="name" className="collapse" />
          <Number Header={""} accessor="balance" className="collapse" />
        </Table>
      </div>
    );
  }

  return data ? (
    <Fragment>
      {(data.analysisDate!=="" && data.analysisDate!==null && data.analysisDate!==undefined) &&
        <Card subclass="max-width table-info">
          <p>{resources.local.info_p1
            .replace("{%DATE%}", getDate(data.analysisDate))
            .replace("{%TIME%}", getTime(data.analysisDate))}
          </p>
          {(data.accountingBalanceEndDate!=="" && data.accountingBalanceEndDate!==null) &&
            <p>{resources.local.info_p2
              .replace("{%DATE%}", getDate(data.accountingBalanceEndDate))}
            </p>
          }
        </Card>
      }
      <Card subclass="max-width balance-list">

    {(data.length!==0) &&
      <Fragment>
        <div className="row m-t-l">
          {(data.assets) &&
            <div className="col-lg-6 container-grey-title">
              <Fragment>
                <div className="card-title">{globalResources.active}</div>
                {(data.assets.accountsBalance && data.assets.accountsBalance.length>0) &&
                  <ListTwoValueRow dataInfo={data.assets.accountsBalance} title={resources.local.cashAndBankBalances} accounts={true}/>
                }
                <ListTwoValueRow title={""} accounts={false} nameHeader={resources.local.unpaid}
                  dataInfo={[{name: resources.local.ownersDebts, balance: data.assets.ownersDebts}, {name: resources.local.extraRevenues, balance: data.assets.extraRevenues}]}/>
                {(data.assets.total!==null || data.assets.total!==undefined) &&
                  <div className="m-t-xxxl">
                    <TwoValueRow title={resources.local.total} value={data.assets.total}/>
                  </div>
                }
              </Fragment>
            </div>
          }
          {(data.liabilities) &&
          <div className="col-lg-6 container-grey-title">
            <div className="card-title">{resources.local.liabilities}</div>
            <ListTwoValueRow title={""} accounts={false} total={data.liabilities.total}
              dataInfo={[{name:resources.local.ownersCredits, balance:data.liabilities.ownersCredits}, {name:resources.local.unpayedExpenses, balance:data.liabilities.unpayedExpenses}]}/>
            <div className="card-title">{resources.local.equity}</div>
            <ListTwoValueRow title={""} accounts={false} total={data.equity.total}
              dataInfo={[{name:resources.local.reservationFunds, balance:data.equity.reservationFunds}, {name:resources.local.noReservationFunds, balance:data.equity.noReservationFunds}]}/>
            {(data.equityAndLiabilitiesTotal!==null || data.equityAndLiabilitiesTotal!==undefined) &&
              <TwoValueRow title={resources.local.liabilities_plus_equity} value={data.equityAndLiabilitiesTotal}/>
            }
          </div>
          }
        </div> 
      </Fragment>
    }
      {(!activeBudget.current) &&
        globalResources.noActiveBudget
      }
      {((data.length===0 || data===null) && activeBudget.current) &&
        resources.local.noValues
      }
      </Card>
    </Fragment>
  ) : (
    <Card>
      <IsLoading text={websiteResources.loading} />
    </Card>
  );
};

export default BalanceTable;
